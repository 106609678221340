import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DailyNewDepositReport } from '@core/models/daily-new-deposit-report.model';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { MemberInformationDialogComponent } from '../../general/members/dialogs/member-information/member-information.component';
import { MemberDataService } from '../../general/members/services/member-data.service';
import { DailyNewDepositReportDataService } from './services/daily-new-deposit-report-data.service';
import { DailyNewDepositReportEntityService } from './services/daily-new-deposit-report-entity.service';
import { TimezoneDatePipe } from '@core/pipes/timezone-date.pipe';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-daily-new-deposit-report',
  templateUrl: './daily-new-deposit-report.component.html',
  styleUrls: ['./daily-new-deposit-report.component.scss']
})
export class DailyNewDepositReportComponent implements OnInit, OnDestroy {

  form: FormGroup;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  pagination: Pagination;
  params = ``;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  timezoneSelectedItems: any;
  selectedTimezone: any;
  timezoneDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown clear-all-disabled',
    primaryKey: 'offset',
    labelKey: 'offset',
    noDataLabel: '',
    showCheckbox: false
  };
  dropdown = {
    timezones: JSON.parse(localStorage.getItem('timezones')),
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage
  };
  dropdownList: any = [];
  dropdownSettings = {};
  dailyNewDepositReport$: Observable<DailyNewDepositReport[]>;
  membersPaginated$ = [];
  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;

  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  default_sort_by = 'deposit_at';
  default_sort_order = 'desc';

  sortingConfig = {
    'deposit_at': 'desc',
    'currency_code': 'desc',
    'agent_username': 'desc',
    'amount': 'desc',
    'username': 'desc',
    'member_name': 'desc',
    'registration_site': 'desc',
    'register_at': 'desc'
  };
  sortingSelection = {
    'sort_by': this.default_sort_by,
    'sort_order': this.default_sort_order,
  };
  dataLength: number;
  button_loading = false;
  messages$ = this.dailyNewDepositReportDataService.messages$;
  access$ = this.authHttpService.getUserAccess(5, 'Daily New Deposit Report');

  accountTypeDropdownSettings = {};
  accountTypeDropdownList = [];
  accountTypeSelectedItems = [];

  // permissions
  canViewMemberDialog: boolean;
  canExportDailyNewDepositReport: boolean;

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private dailyNewDepositReportDataService: DailyNewDepositReportDataService,
    private dailyNewDepositReportEntityService: DailyNewDepositReportEntityService,
    private loadingBar: LoadingBarService,
    private transactionHttpService: TransactionHttpService,
    private memberDataService: MemberDataService,
    public dialog: MatDialog,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private timeZoneDate: TimezoneDatePipe,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();
    this.pagination = this.dailyNewDepositReportDataService.pagination;

    this.dropdownList = this.membersPaginated$;

    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.accountTypeDropdownList = [
      { id: 0, name: 'Normal'},
      { id: 1, name: 'Dummy'}
    ];

    this.accountTypeSelectedItems = [
      { id: 0, name: 'Normal'},
      { id: 1, name: 'Dummy'}
    ];

    this.accountTypeDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.ranges = this.transactionHttpService.ranges;
    this.timezoneSelectedItems = [this.dropdown.timezones.find(x => x.timezone == this.timezone)];
    this.selectedTimezone = this.timezone;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.daily_new_deposit_report_view_member_dialog;
      this.canExportDailyNewDepositReport = appPermissions.export_daily_new_deposit_report;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }
    this.sortingSelection.sort_by = property;
    this.sortingSelection.sort_order = this.sortingConfig[property];
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }


  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.dailyNewDepositReport$ = this.dailyNewDepositReportEntityService.getWithQuery(`?paginate=1&page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.dailyNewDepositReportDataService.pagination;
        this.loadingBar.complete();
      }),
      catchError((err) => {
        this.loading = false;
        this.loadingBar.complete();
        return throwError(err);
      })
    );
  }

  onClearDate(type: string) {
    if (type === 'deposit') {
      if (this.form.value.deposit_start_date_time !== null) {
        this.form.patchValue({ defaultDateDeposit: null });
      }
    } else {
      if (this.form.value.registration_start_date_time !== null) {
        this.form.patchValue({ defaultDateRegistration: null });
      }
    }
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onDateRange(event: any, type: string) {
    if (event) {
      if (type === 'deposit') {
        this.form.patchValue({
          deposit_start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
          deposit_end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
        });
      } else {
        this.form.patchValue({
          registration_start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
          registration_end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
        });
      }
    }
  }

  onExport() {
    this.button_loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['deposit_start_date_time']) {
          data['deposit_start_date_time'] = moment(data['deposit_start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['deposit_end_date_time'] = moment(data['deposit_end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        if (data['registration_start_date_time']) {
          data['registration_start_date_time'] = moment(data['registration_start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['registration_end_date_time'] = moment(data['registration_end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        if (data['dummy']) {
          if (data['dummy'].length == 2) {
            delete (data['dummy']);
          }
        }

        data['paginate'] = false;

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start();
        this.sortingConfig[this.sortingSelection.sort_by] = 'desc';
        this.sortingSelection.sort_by = this.default_sort_by;
        this.sortingSelection.sort_order = this.default_sort_order;
        this.sortingConfig[this.default_sort_by] = this.default_sort_order;
        return this.dailyNewDepositReportDataService.exportReport(`?${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.messages$ = this.dailyNewDepositReportDataService.messages$;
            this.loadingBar.complete();
            this.button_loading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.button_loading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch(); // Cannot clear selected Agent without this

    this.accountTypeSelectedItems = [
      { id: 0, name: 'Normal'},
      { id: 1, name: 'Dummy'}
    ];

    this.form.patchValue({
      username: null,
      agent_username: null,
      currency_id: 'all',
      deposit_start_date_time: null,
      deposit_end_date_time: null,
      defaultDateDeposit: null, // Do not remove: For Clearing The Range
      registration_start_date_time: null,
      registration_end_date_time: null,
      defaultDateRegistration: null,
      dummy: null
    });
    this.timezoneSelectedItems = [this.dropdown.timezones.find(x => x.timezone == this.timezone)];
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['deposit_start_date_time']) {
          data['deposit_start_date_time'] = moment(data['deposit_start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['deposit_end_date_time'] = moment(data['deposit_end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        if (data['registration_start_date_time']) {
          data['registration_start_date_time'] = moment(data['registration_start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['registration_end_date_time'] = moment(data['registration_end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        delete (data['timezone']);

        if (data['dummy']) {
          if (data['dummy'].length == 2) {
            delete (data['dummy']);
          }
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        this.loading = true;
        this.sortingConfig[this.sortingSelection.sort_by] = 'desc';
        this.sortingSelection.sort_by = this.default_sort_by;
        this.sortingSelection.sort_order = this.default_sort_order;
        this.sortingConfig[this.default_sort_by] = this.default_sort_order;
        return this.dailyNewDepositReport$ = this.dailyNewDepositReportEntityService.getWithQuery(`?perPage=${this.pageSize}${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.dailyNewDepositReportDataService.pagination;
            this.loadingBar.complete();
            this.onSearch = true;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
          }),
          catchError((err) => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.loadingBar.complete();
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  sanitizeNumericField(previousValue: any) {
    const value = String(previousValue).replace(/[,]+/, '');
    return value;
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDateRegistration' && formData[key] !== 'all' && key !== 'defaultDateDeposit') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      agent_username: new FormControl(null),
      currency_id: new FormControl('all'),
      deposit_start_date_time: new FormControl(null),
      deposit_end_date_time: new FormControl(null),
      defaultDateDeposit: new FormControl(null), // Do not remove: For Clearing The Range
      registration_start_date_time: new FormControl(null),
      registration_end_date_time: new FormControl(null),
      defaultDateRegistration: new FormControl(null), // Do not remove: For Clearing The Range
      timezone: new FormControl(this.timezone),
      dummy: new FormControl(null)
    });

    this.form.controls.username.valueChanges.subscribe(value => {
      if (value == null || value.length == 0) {
        this.form.controls.currency_id.enable();
      } else {
        this.form.controls.currency_id.disable();
      }
    })
  }

  onOpenDialog(memberId?: number) {
    if (!this.canViewMemberDialog) {
      Swal.fire({
        icon: 'error',
        title: 'Permission Error',
        text: 'You do not have permission to view member information',
      });
      return;
    }

    const member = this.memberDataService.getById(memberId, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.form.value.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.form.value.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&additional_info=1`);
    this.subscription = member.pipe(
      tap((res) => {
        if (res) {
          const data = res;
          data['timezone'] = this.form.value.timezone;
          this.openDialogBy(MemberInformationDialogComponent, { member: data, mode: 'member-information' });
        }
      })
    ).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { member?: any, mode?: any, isAdmin?: boolean }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '1500px',
      height: '80vh',
      data,
      autoFocus: false
    });
  }

  onChangeTimezone(item: any) {
    if (item.length) {
      this.selectedTimezone = item[0]['timezone'];
    }
    else {
      this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.selectedTimezone);
      this.form.patchValue({
        timezone: this.selectedTimezone
      });
    }
  }

  timezoneDateWithTimezone(date: any, timezone: any, format: any) {
    return this.timeZoneDate.transformWithTimezone(date, timezone, format);
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }
}
