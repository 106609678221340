<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">{{ (data.type == 'sms' ? 'Send SMS Histories' : 'Send Message Histories') | translate }}</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>

    <div class="modal-body">
      <!-- SEARCH FILTER -->
      <form class="row align-items-center px-3" [formGroup]="form">
  
        <div class="col-12 row mb-2 pr-0">
          <!-- REGION -->
          <div class="col-md-1 kt-form__label col-form-label">
            <label>{{ 'Region' | translate }}:</label>
          </div>
          <div class="kt-form__control" [ngClass]="{'col-md-2': data.type == 'sms', 'col-md-3': data.type == 'inbox_message'}">
            <kt-dropdown-wo-lazyload class="dropdown-maxheight col-10" style="padding:0" [form]='form'
              [dropdownList]='regionDropdownList' 
              [dropdownSettings]='regionDropdownSettings'
              [formName]="'region'" 
              [selectionAttributes]="'id'"
              [selectedItems]='regionSelectedItems'>
            </kt-dropdown-wo-lazyload>
          </div>

          <!-- Member Type -->
          <ng-container *ngIf="data.type == 'sms'">
            <div class="col-md-1 kt-form__label col-form-label">
              <label>{{ 'Member Type' | translate }}:</label>
            </div>
            <div class="col-md-2 kt-form__control">
              <select formControlName="member_type" class="form-control">
                <option [value]="item.key" *ngFor="let item of memberTypeFilterType">{{ item.label }}</option>
              </select>
            </div>
          </ng-container>
  
          <!-- DATE/TIME -->
          <div class="col-md-1 kt-form__label col-form-label">
            <label>{{ 'Date/Time' | translate }}:</label>
          </div>
          <div class="col-md-5 kt-form__control">
            <div class="input-group date">
              <select formControlName="datetime_type" class="form-control" style="width: 30%;">
                <option [value]="item.key" *ngFor="let item of dateTimeFilterType">{{ item.label }}</option>
              </select>

              <input type="hidden" formControlName="start_datetime" >
              <input type="hidden" formControlName="end_datetime" >

              <div class="input-group date" style="width: 70%;">
                <input type="text" class="form-control" placeholder="Search"
                  (change)="onDateRange($event)" [formControl]="form.controls['defaultDate']"
                  [timePicker]="true" [timePickerSeconds]="true"
                  [alwaysShowCalendars]="true" [ranges]="ranges"
                  [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                  [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()"
                  ngxDaterangepickerMd
                />
                <span class="input-group-append">
                  <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                </span>
              </div>
            </div>
          </div>
        </div>
  
        <div class="col-12 row mb-2 pr-0">
          <!-- MESSAGE TEMPLATE -->
          <div class="col-md-1 kt-form__label col-form-label">
            <label>{{ 'Message Template' | translate }}:</label>
          </div>
          <div class="col-md-3 kt-form__control campaign-selection">
            <input type="text" formControlName="message_template" placeholder="{{ 'Search' | translate }}" class="form-control" [ngbTypeahead]="this.searchTemplate" [resultFormatter]="this.formatTemplate" [inputFormatter]="this.formatTemplate">
          </div>
  
          <!-- STATUS -->
          <div class="col-md-1 kt-form__label col-form-label">
            <label>{{ 'Status' | translate }}:</label>
          </div>
          <div class="col-md-7 kt-form__control">
            <div class="d-flex flex-wrap status-filter-pt">
              <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.statuses ; let i = index">
                <input type="checkbox" [checked]="item.checked" (change)="onSearchStatus($event, status[item.name], i)" class="custom-control-input" [id]="item.name">
                <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
              </div>
            </div>
          </div>
        </div>
        
      </form>
  
      <!-- ACTIONS -->
      <div class="dropdown dropdown-inline px-3">
        <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
        <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
      </div>
  
      <!-- RESULTS -->
      <div class="col-12 justify-content-between mb-3 mt-3">
        <div class="kt-section">
          <div class="kt-section__content">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-striped table-sortable">
                <thead>
                  <tr>
                    <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                    <th>{{ 'Region' | translate }}</th>
                    <th class="sort-enabled" (click)="onSortColumn('schedule_datetime')" [ngClass]="sortingConfig.schedule_datetime" [class.sort-selected]="sortingSelection.sort_by === 'schedule_datetime'">{{ 'Date/Time' | translate }}</th>
                    <th *ngIf="data.type == 'sms'" class="sort-enabled" (click)="onSortColumn('member_type')" [ngClass]="sortingConfig.member_type" [class.sort-selected]="sortingSelection.sort_by === 'member_type'">{{ 'Member Type' | translate }}</th>
                    <th class="sort-enabled" (click)="onSortColumn('message_templates.code')" [ngClass]="sortingConfig['message_templates.code']" [class.sort-selected]="sortingSelection.sort_by === 'message_templates.code'">{{ 'Message Template' | translate }}</th>
                    <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">{{ 'Status' | translate }}</th>
                    <th class="text-center">{{ 'Remarks' | translate }}</th>
                    <th class="date-column sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">{{ 'Created By' | translate }}</th>
                    <th class="date-column sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">{{ 'Updated By' | translate }}</th>
                    <th class="text-center">{{ 'Actions' | translate }}</th>
                  </tr>
                </thead>
                <tbody *ngIf="(messagesHistories$ | async) as rows">
                  <tr *ngFor="let row of rows">
                    <td class="text-center">{{ row.id }}</td>
                    <td class="text-center">{{ row.settings_currency_code }}</td>
                    <td>
                      {{ convertToUserTimezone(row.schedule_datetime) }}
                    </td>
                    <td *ngIf="data.type == 'sms'" class="text-center">
                      {{ (row.member_type == 1 ? 'Member' : 'Non Member') | translate }}
                    </td>
                    <td>
                      {{ row.message_template_code }}
                    </td>
                    <td class="text-center">
                      <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ statusLabel[row.status] }}</span>
                    </td>
                    <td>
                      <ng-container *ngIf="row.status === 3 || row.status === 4 || row.status === 5; else statusPendingInProgress">
                        Successfully sent {{ row.result.success }} of {{ +row.result.success + +row.result.fail }} member (s).
                      </ng-container>
                      <ng-template #statusPendingInProgress>
                        <ng-container *ngIf="row.result.schedule; else noScheduleData">
                          Successfully scheduled {{ row.result.schedule }} member (s).
                        </ng-container>
                        <ng-template #noScheduleData>
                          -
                        </ng-template>
                      </ng-template>
                    </td>
                    <td>
                      {{ row.created_by ? row.created_by : 'System' }}<br>
                      <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                      <ng-template #createdDateTime>
                        {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                    </td>
                    <td>
                      <ng-container *ngIf="row.created_at !== row.updated_at else inactive">
                        {{ row.updated_by ? row.updated_by : 'System' }}<br>
                        <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                        <ng-template #updatedDateTime>
                          {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                        </ng-template>
                      </ng-container>
                      <ng-template #inactive>
                        
                      </ng-template>
                    </td>
                    <td class="text-center">
                      <button *ngIf="canSendNow && row.status == 1" [disabled]="buttonLoading" matTooltip="Send Now" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onSendNow(row.id)"><i class="fas fa-paper-plane"></i></button>
                      <button *ngIf="canViewSettings" matTooltip="Message Settings" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenEditDialog(row)"><i class="fas fa-cog"></i></button>
                      <button *ngIf="canDownloadSentReport && (row.status == 3 || row.status == 5)" matTooltip="Sent Report" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onDownloadSentReport(row.id)"><i class="fas fa-file-download"></i></button>
                    </td>
                  </tr>
                </tbody>
                <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
              </table>
            </div>
            <!-- Pagination -->
            <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
              <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
              </ngb-pagination>
              <div class="kt-pagination__toolbar">
                <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                  <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                </select>
                <span class="pagination__desc">
                  Showing {{pagination.from}} to {{pagination.to}} of
                  {{ pagination.total | number : '1.0' }} records
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
