import { Component, OnInit, ChangeDetectorRef, ViewChildren, QueryList, OnDestroy, HostListener } from '@angular/core';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { MarqueesDataService } from './services/marquees-data.service';
import { tap, map, catchError } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from "sweetalert2";
import { TransactionHttpService } from '@core/services/transaction-http.service';
import * as moment from 'moment-timezone';
import { Marquees } from '@core/models/marquees.model';
import { Pagination } from '@core/models/pagination.model';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'marquees',
  templateUrl: './marquees.component.html',
  styleUrls: ['./marquees.component.scss']
})

export class MarqueesComponent implements OnInit, OnDestroy {

  @HostListener('window:beforeunload', ['$event'])
  confirmLeavingPageBeforeSaving($event) {

    if(this.checkupdate()){
      $event.preventDefault();
      $event.returnValue = true;
      return $event;
    }
  }

  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;

  form: FormGroup;
  formContent: FormGroup;
  messages$ = this.marqueesDataService.messages$;

  loading = false;
  searchBtnLoading = false;
  clearBtnLoading = false;
  dataLength = 0;
  params = '';
  buttonLoading = true;

  mode: any;
  marquee: any;
  initMarquee: any;
  availableMarquee = [];
  isEmptyContent = true;

  dateTimeStack = {
    start_date: null,
    end_date: null,
  };

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  ranges = this.transactionHttpService.ranges;
  marquee$: Observable<Marquees[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  displayLocale: any;
  backupPageSize = 30;
  backupPage = 1;
  unsaveStatus = true;

  usableVariables = [
    ':brandprefix',
    ':brandname',
  ];
  dateTimeFormat = 'yyyy-MM-dd HH:mm:';
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
  changedObjects: any;

  sortingConfig = {
    'id': 'desc',
    'position': 'desc',
    'start_date': 'desc',
    'end_date': 'desc',
  };

  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    sessions: this.dropdownHttpService.marqueeSession,
    locales: [],
    perPage: this.dropdownHttpService.perPage,
  };

  // permissions
  canCreateNewMarquee: boolean;
  canViewMarqueeDetails: boolean;
  canEditMarquee: boolean;
  canUpdateMarqueeStatus: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  private localesSub = new Subscription();
  private datePickerSubscription = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private loadingBar: LoadingBarService,
    private marqueesDataService: MarqueesDataService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private transactionHttpService: TransactionHttpService,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private appPermissionService: AppPermissionService,
  ) { }

  async canDeactivate(): Promise<boolean | Observable<boolean>> {
    // provide component specific logic to decide if component can or can't be deactivated
    if (this.checkupdate()) {
      const result = await Swal.fire({
        title: '<div class="text-center">Unsaved Changes</div>',
        html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Cancel'),
        confirmButtonText: this.translateService.instant('Discard'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          return (true);
        } else if (result.isDenied) {
          return (false);
        }
      });

      return result;
    }
    return true;
  }

  ngOnInit() {
    this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.dropdown.locales = res;
      this.formInit();
      this.onSubmit();

      this.initMarquee = JSON.stringify({ ...this.formContent.value });
    })).subscribe();

    this.dateTimeStack = {
      start_date: null,
      end_date: null,
    };

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateNewMarquee = appPermissions.create_new_marquee;
      this.canViewMarqueeDetails = appPermissions.view_marquee_details;
      this.canEditMarquee = appPermissions.edit_marquee;
      this.canUpdateMarqueeStatus = appPermissions.update_marquee_status;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.localesSub.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, index === 0 ? this.dateTimeFormat + '00' : this.dateTimeFormat + '59')),
      tap(date => {
        this.formContent.patchValue({ [formKey]: date });
      })
    );
  }

  onClear() {
    this.onClearDate();
    this.form.patchValue({
      site_id: 1,
      start_date: null,
      end_date: null,
      date_type: 'start_date',
      defaultDate: null,
      status: 'all',
      locale_id: 'all',
      label: null,
      session: 'all',
    })
  }

  onClearContent() {
    this.onClearDateContent();
    this.dateTimeStack = {
      start_date: new Date(),
      end_date: null,
    };

    this.formContent.patchValue({
      label: null,
      start_date: this.datePipe.transform(new Date(), this.dateTimeFormat + '00'),
      end_date: null,
      session: 1,
      position: 99,
      status: 1,
    })

    this.dropdown.locales.forEach((element: any) => {
      this.formContent.patchValue({
        contents: {
          [element.id]: {
            content: null,
          }
        }
      });
    });

    this.initMarquee = JSON.stringify({ ...this.formContent.value });
  }

  reload(clearSearch?: boolean) {
    this.isEmptyContent = true;
    this.mode = '';
    this.onClearContent();
    this.onSubmit(clearSearch);
    this.buttonLoading = false;
  }

  async onSubmitClick(clearSearch?: boolean) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.isEmptyContent = true;
      this.mode = '';
      this.onClearContent();
      this.onSubmit(clearSearch);
    }
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;

    if (clearSearch) {
      this.onClear();
    }

    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.marquee$ = this.marqueesDataService.getWithQuery(`?${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.displayLocale = this.displayLocale ? this.displayLocale : res.length && this.form.value.locale_id != 'all' ? this.dropdown.locales.filter(x => x.id == this.form.value.locale_id)[0] : this.dropdown.locales[0];
            this.page = 1;
            this.pagination = this.marqueesDataService.pagination;
            this.loadingBar.complete();
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.buttonLoading = false;
            this.dataLength = res.length;
            this.availableMarquee = res;
            this.cdr.detectChanges();
          })
        );
      }),
    ).subscribe();
  }

  onSave() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
    this.buttonLoading = true;

    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.formContent.setErrors({ 'invalid': true });
    const data = {
      id: this.marquee ? this.marquee.id : null,
      site_id: 1,
      ...this.formContent.value,
      start_date: moment(this.formContent.value.start_date).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(this.formContent.value.end_date).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
    };

    Object.keys(data).forEach((key) => {
      if (data[key] == null || data[key] === '' || data[key] === 'Invalid date') {
        delete data[key];
      }
    });

    switch (this.mode) {
      case 'edit':
        this.subscription = this.marqueesDataService.updateMarquee(this.marquee.id, data).pipe(
          tap((res: any) => {
            this.messages$.next(res.message);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            this.form.enable();
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
        this.subscription = this.marqueesDataService.addMarque(data).pipe(
          tap((res: any) => {
            this.messages$.next(res.message);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.formContent.setErrors(null);
            this.formContent.enable();
            throw error;
          })
        ).subscribe();
        break;
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onClearDateContent() {
    if (this.formContent.value.start_date !== null) {
      this.formContent.patchValue({
        start_date: null,
        end_date: null
      });
    }
  }

  changeLocale(locale: any) {
    this.displayLocale = locale;
  }

  addVariable(variable: any, id: any) {
    let content = this.formContent.value.contents[id]['content'] ? this.formContent.value.contents[id]['content'] : '';
    this.formContent.patchValue({
      contents: {
        [id]: {
          content: content + variable,
        }
      }
    });
  }

  clearContent(locale: any) {
    Swal.fire({
      title: 'Delete Content',
      text: 'Are you sure you want to delete contents for ' + locale.code + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this.formContent.patchValue({
          contents: {
            [locale.id]: {
              content: null,
            }
          }
        });

        let contents = this.formContent.value.contents;
        this.isEmptyContent = true;

        Object.keys(contents).forEach((sf) => {
          if (contents[sf]['content'] != null) {
            this.isEmptyContent = false;
          }
        });

      }
      this.cdr.detectChanges();
    });
  }

  checkContent(locale: any) {
    let contents = this.formContent.value.contents[locale.id].content;

    if (contents == null || contents == '') {
      return false;
    }
    else {
      return true;
    }
  }

  onChangeStatus($event, row: any) {
    const data = {
      id: row.id,
      status: $event.target.checked ? 1 : 0,
    };

    this.subscription = this.marqueesDataService.updateStatus(data).pipe(
      tap((res: any) => {
        this.messages$.next(res.message);
        this.buttonLoading = false;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        throw error;
      })
    ).subscribe();
  }

  async onPerPage(size: Event) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.page = 1;
      this.pageSize = +(size.target as HTMLSelectElement).value;
      this.backupPageSize = this.pageSize;
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
    else {
      this.pageSize = this.backupPageSize;
    }
  }

  async onViewPageBy(page = 1, pageSize?: number, params?: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.backupPage = page;
      pageSize = this.pageSize;
      params = this.params ? `&${this.params}` : '';
      this.loading = true;
      this.loadingBar.start();
      return this.marquee$ = this.marqueesDataService.getWithQuery(`?${this.params}&page=${page}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
        tap(res => {
          this.pagination = this.marqueesDataService.pagination;
          this.loading = false;
          this.dataLength = res.length;
          this.availableMarquee = res;
          this.loadingBar.complete();
        })
      );
    }
    else {
      this.page = this.backupPage;
    }
  }

  expandRowCreate(mode: any, close?: boolean) {
    let marqueeInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initMarquee != marqueeInput ? true : false;

    if (close != true && this.changedObjects == false) {
      this.changedObjects = false;
      this.initMarquee = null;
      this.marquee = null;

      this.mode = mode;
      this.cdr.detectChanges();

      this.datePickerSubscription = forkJoin([
        this.buildDatePicker(0, 'start_date'),
        this.buildDatePicker(1, 'end_date')
      ]).subscribe();

      this.onClearContent();
    } else {
      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {

            this.isEmptyContent = true;
            this.mode = '';
            this.unsaveStatus = true;
            this.onClearContent();

            // Check if reopen needed
            this.checkReopen(mode);
          }
        });
      }
      else {

        this.isEmptyContent = true;
        this.mode = '';
        this.unsaveStatus = true;
        this.onClearContent();
      }
    }

    this.cdr.detectChanges();
  }

  expandRowEdit(mode: any, row?: any, close?: boolean) {
    if (close != true) close = this.marquee == row ? true : false;
    let marqueeInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initMarquee && this.initMarquee != marqueeInput ? true : false;

    if (close != true && this.changedObjects == false) {
      this.marquee = row;
      this.changedObjects = false;
      this.initMarquee = null;

      this.mode = mode;

      this.cdr.detectChanges();

      this.dateTimeStack = {
        start_date: new Date(new Date(this.marquee.start_date).getTime() + this.offset - this.clientOffset),
        end_date: this.marquee.end_date ? new Date(new Date(this.marquee.end_date).getTime() + this.offset - this.clientOffset) : null,
      };

      this.formContent.patchValue({
        label: this.marquee.label,
        start_date: moment(new Date(new Date(this.marquee.start_date).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss'),
        end_date: this.marquee.end_date ? moment(new Date(new Date(this.marquee.end_date).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss') : null,
        session: this.marquee.session,
        position: this.marquee.position,
        status: this.marquee.status,
      });

      // reset all locales to null
      this.dropdown.locales.forEach((element: any) => {
        this.formContent.patchValue({
          contents: {
            [element.id]: {
              content: null
            }
          }
        });
      });

      this.marquee.contents.forEach((element: any) => {
        this.formContent.patchValue({
          contents: {
            [element.locale_id]: {
              content: element.raw_content
            }
          }
        });
      });

      this.datePickerSubscription = forkJoin([
        this.buildDatePicker(0, 'start_date'),
        this.buildDatePicker(1, 'end_date')
      ]).subscribe();

      this.initMarquee = JSON.stringify({ ...this.formContent.value });
    } else {
      this.changedObjects = this.initMarquee != JSON.stringify({ ...this.formContent.value }) ? true : false;
      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {

            this.isEmptyContent = true;
            this.mode = '';
            this.unsaveStatus = true;
            this.onClearContent();

            // Check if reopen needed
            if (this.marquee == row) {
              this.marquee = null;
            }
            else {
              this.marquee = null;
              this.checkReopen(mode, row);
            }
          }
        });
      }
      else {

        this.isEmptyContent = true;
        this.mode = '';
        this.unsaveStatus = true;
        this.marquee = null;
        this.onClearContent();
      }
    }
  }

  checkReopen(mode: any, row?: any) {
    switch (mode) {
      case 'edit':
        this.expandRowEdit(mode, row)
        break;
      case 'create':
        this.expandRowCreate(mode);
        break;
    }
  }

  localeIndex() {
    const index = this.dropdown.locales.findIndex(item => {
      return item.id == this.displayLocale['id'];
    });

    return index;
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== false) {
        fields[key] = (formData[key] === true ? 1 : formData[key]);
      }
    });
    return fields;
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  async onSortColumn(property: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      // Reset other columns
      for (const key in this.sortingConfig) {
        if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
          if (key == property) {
            this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
          } else {
            this.sortingConfig[key] = 'desc';
          }
        }
      }

      // User selection
      this.sortingSelection.sort_by = property;

      if (this.sortingSelection.sort_by === property) {
        // Same column
        this.sortingSelection.sort_order = this.sortingConfig[property];
      } else {
        // Switch to other column
        this.sortingConfig[property] = 'asc';
        this.sortingSelection.sort_order = 'asc';
      }
      // Load Data
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
  }

  private formInit() {
    const buildContents = () => {
      let fields = {};
      this.dropdown.locales.forEach((element: any) => {
        const subFields = new FormGroup({
          locale_id: new FormControl(element.id),
          content: new FormControl(null)
        });
        fields = { ...fields, [element.id]: subFields };
      });
      return fields;
    };

    this.form = new FormGroup({
      // Filter
      site_id: new FormControl(1),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      date_type: new FormControl('start_date'),
      defaultDate: new FormControl(null),
      status: new FormControl('all'),
      locale_id: new FormControl('all'),
      label: new FormControl(null),
      session: new FormControl('all'),
    });

    this.formContent = new FormGroup({
      // Create dialog
      label: new FormControl(null, [Validators.required]),
      start_date: new FormControl(this.dateTimeStack.start_date, [Validators.required]),
      end_date: new FormControl(this.dateTimeStack.start_date),
      session: new FormControl(null, [Validators.required]),
      position: new FormControl(null, [Validators.required]),
      status: new FormControl(null, [Validators.required]),
      contents: new FormGroup(buildContents()),
    });

    this.formContent.valueChanges.subscribe(data => {
      let isContent = false;
      Object.keys(data['contents']).forEach((sf) => {
        if (data['contents'][sf]['content'] != null && data['contents'][sf]['content'] != '') {
          isContent = true;
        }
      })
      this.isEmptyContent = isContent ? false : true;
    })
  }

  checkupdate() {
    return this.initMarquee !== JSON.stringify({ ...this.formContent.value }) ? true : false;
  }

  displayLocaleContent(row: any) {
    let marquee = this.availableMarquee.filter(x => x.id == row.id)[0]['contents'];
    marquee = marquee.filter(x => x['locale_name'] == this.displayLocale['code']);

    if (marquee.length > 0) {
      return marquee[0]['content'];
    }
    else {
      return '-';
    }
  }

  private onCheckUnsave() {
    return new Promise<void>((resolve, reject) => {
      let marqueeInput = JSON.stringify({ ...this.formContent.value });
      this.changedObjects = this.initMarquee && this.initMarquee != marqueeInput ? true : false;

      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {

            this.isEmptyContent = true;
            this.mode = '';
            this.marquee = null;
            this.unsaveStatus = true;
            this.onClearContent();
            resolve();
          }
          else {
            this.unsaveStatus = false;
            resolve();
          }
        });
      }
      else {
        resolve();
      }
    });
  }
}
