import { EditorService } from './../../../../../../core/services/editor.service';
import { CustomUploadAdapterPlugin } from '@core/ckeditor-upload-adapter/CustomUploadAdapterPlugin';
import { map, catchError, tap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, forkJoin } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MessageTemplateEntityService } from './../services/message-template-entity.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Component, OnInit, Inject, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MessageTemplateDataService } from '../services/message-template-data.service';
import { MessageTemplate } from '@core/models/message-template.model';
import * as Editor from '@core/../../assets/js/global/integration/plugins/ckeditor';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { AppPermissionService } from '@core/services/app-permission.service';
import { DialogDataService } from '@views/pages/apps/settings/announcements/dialog/services/dialog-data.service';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'kt-message-template-edit',
  templateUrl: './message-template-edit.component.html',
  styleUrls: ['./message-template-edit.component.scss']
})
export class MessageTemplateEditComponent implements OnInit {
  form: FormGroup;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    locales: this.dropdownHttpService.locales,
    perPage: this.dropdownHttpService.perPage,
    type: this.dropdownHttpService.messageTemplateType,
    section: [],
    locales$: this.messageTemplateDataService.locales$
  };
  @ViewChildren('editors') editorComponent:QueryList<CKEditorComponent>;
  usableVariables: any;
  messages$ = this.messageTemplateDataService.messages$;
  formFields = [];
  formFieldsSelectedItems = [];
  details: any;
  refreshStatus: boolean;
  buttonLoading = false;
  section: string = '';
  type: string = '';
  name: string = '';
  locale: string = '';
  code: string = '';
  public editor = Editor;
  editorConfig = this.editorService.config;

  editable: boolean = true;

  // permissions
  canCreateMessageTemplate: boolean;
  canEditMessageTemplate: boolean;
  canDuplicateMessageTemplate: boolean;

  popupDropdownSettings = {};
  popupDropdownList = [];
  popupDropdownListArray = [];
  popupSelectedItems = [];

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  private localesSub = new Subscription();

  languages: { id: number; code: string; name: string }[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { messageTemplate: any, mode: 'create' | 'edit' | 'duplicate' , section: any},
    private dropdownHttpService: DropdownHttpService,
    private messageTemplateDataService: MessageTemplateDataService,
    private messageTemplateEntityService: MessageTemplateEntityService,
    public loadingBar: LoadingBarService,
    public dialogRef: MatDialogRef<MessageTemplateEditComponent>,
    private editorService: EditorService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
    private dialogDataService: DialogDataService,
  ) { }

  ngOnInit() {
    this.popupDropdownSettings = {
      autoPosition: false,
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'labelKey',
      noDataLabel: '',
      showCheckbox: false
    };

    this.dropdown.section = this.data.section;
    this.dropdownHttpService.fillInForms.subscribe(res => {
      Object.keys(res).forEach(key => {
        this.formFields.push({
          name: res[key],
          id: key
        });
      });
    });

    this.localesSub = this.dropdown.locales$.pipe(tap(res => this.languages = res)).subscribe(res => {
      res.forEach(item => {
        if (this.data.mode === 'edit' || this.data.mode === 'duplicate') {
          let language_id = item.id;
          let details = null;
          if (this.data.messageTemplate.message_details[language_id]) {
            Object.keys(this.data.messageTemplate.message_details[language_id]).forEach((key) => {
              details = {
                ...details,
                [key]:
                  this.data.messageTemplate.message_details[language_id][key]
              };
            });
          }
          this.details = {
            ...this.details,
            [language_id]: {
              ...details
            }
          };
        }
      })
    });

    this.formInit();

    setTimeout(() => {
      this.updateForm();
      this.setPopups();
    }, 2000);

    // let dataMode = this.data.mode == 'duplicate' || this.data.mode == 'edit' ? 'edit' : 'create';
    // this.dropdownHttpService.messageTemplateSection(dataMode).subscribe(res => {
    //   this.dropdown.section = res;
      
    // });

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateMessageTemplate = appPermissions.create_message_template;
      this.canEditMessageTemplate = appPermissions.edit_message_template;
      this.canDuplicateMessageTemplate = appPermissions.duplicate_message_template;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.localesSub.unsubscribe();
    this.onRefresh();
  }

  onSectionChange(sectionID: number, name: string) {
    this.messageTemplateDataService.getMessageTemplatVariables(sectionID, name).subscribe(res => {
      this.usableVariables = res['usable_variables'];
    });
  }

  onSelectUsableVariable(usableVariable: String, i){
    let editorInstance = this.editorComponent.toArray()[i].editorInstance;
    const selection = editorInstance.model.document.selection;
    const range = selection.getFirstRange();
    editorInstance.editing.view.focus();
    editorInstance.model.change ( writer => {
        writer.insert( usableVariable + ' ', range.start );
        const rangeAfterAppend = selection.getFirstRange();
        writer.setSelection( writer.createPositionAt( rangeAfterAppend.start ) );
    } );
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(messageTemplate: MessageTemplate, mode?: string) {
    this.buttonLoading = true;

    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });
    const data = {
      id: messageTemplate ? messageTemplate['message_template'].id : null,
      ...this.form.value,
      details: this.form.value.details
    };

    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || key == 'dialog_popups') && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.messageTemplateEntityService.update(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
      case 'duplicate':
        this.subscription = forkJoin([
          this.messageTemplateDataService.add(data).pipe(
            tap((res: any) => {
              this.form.setErrors(null);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          ),
          this.messageTemplateDataService.messages$
        ]).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onGenerate(param: string) {
    if (param === 'section') {
      this.section = this.form.value.section !== '' && this.form.value.section !== null ? $('.sectionSelect option:selected').text() + '.' : '';
      this.onSectionChange(this.form.value.section, this.form.value.name);
    }
    if (param === 'type') {
      this.type = this.form.value.type !== '' && this.form.value.type !== null ? $('.typeSelect option:selected').text() + '.' : '';
    }
    if (param === 'name') {
      this.name = this.form.value.name !== '' && this.form.value.name !== null ? this.form.value.name + '' : '';
    }
   
    this.code = (this.section + this.type + this.name).replace(/\s/g, "");
  }

  private formInit() {
    if (this.data.mode === 'edit' || this.data.mode === 'duplicate') {
      this.editable = this.dropdown.section.find(x => x.id == +this.data.messageTemplate.message_template.section).edit === 'false' ? false : true;
      this.section = (this.data.mode === 'duplicate' && this.editable) || this.data.mode === 'edit' ? this.data.messageTemplate.message_template.section_name + '.' : '';
      this.type = (this.data.mode === 'duplicate' && this.editable) || this.data.mode === 'edit' ? this.data.messageTemplate.message_template.type_name + '.' : '';
      this.name = this.data.mode === 'duplicate' ? '' : this.data.messageTemplate.message_template.name + '.';
      this.code = this.data.mode === 'duplicate' && this.editable ? (this.data.messageTemplate.message_template.section_name + '.' + this.data.messageTemplate.message_template.type_name).replace(/\s/g, "") : this.data.mode === 'edit' ? this.data.messageTemplate.message_template.code : '';
    }
    let name = this.data.mode === 'create' ? null : this.data.messageTemplate.message_template.name;
    let section = this.data.mode === 'create' ? null : this.data.messageTemplate.message_template.section;
    let type = this.data.mode === 'create' ? null : this.data.messageTemplate.message_template.type;
    let status = this.data.mode === 'create' ? 1 : this.data.messageTemplate.message_template.status;
    let dialog_popups = [];

    const validatorOptions = [];

    const buildSubDetails = () => {
      let details = {};
      const isEditMode = () => {
        let edit = {};
        if (this.data.mode === 'edit' || this.data.mode === 'duplicate') {
          edit = { ...edit, id: new FormControl(null) };
        }
        return edit;
      };

      this.languages.map(element => {
        const detailsGroup = new FormGroup({
          settings_locale_id: new FormControl(element.id, validatorOptions),
          subject: new FormControl(null, validatorOptions),
          message: new FormControl(null),
          ...isEditMode()
        });
        details = { ...details, [element.id]: detailsGroup };
      });
      return details;
    };

    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      section: new FormControl(section, [Validators.required]),
      type: new FormControl(type, [Validators.required]),
      status: new FormControl(status),
      dialog_popups: new FormControl(dialog_popups),
      details: new FormGroup(buildSubDetails()),
    });
    if (this.data.mode === 'edit' || this.data.mode === 'duplicate') {
      let contentDetail = {
        ...this.data.messageTemplate.message_template,
        name: this.data.mode === 'duplicate' ? '' : this.data.messageTemplate.message_template.name,
        section: (this.data.mode === 'duplicate' && this.editable) || this.data.mode === 'edit' ? this.data.messageTemplate.message_template.section : null,
        type: (this.data.mode === 'duplicate' && this.editable) || this.data.mode === 'edit' ? this.data.messageTemplate.message_template.type : null,
        code: (this.data.mode === 'duplicate' && this.editable) || this.data.mode === 'edit' ? (this.data.messageTemplate.message_template.section_name + '.' + this.data.messageTemplate.message_template.type_name).replace(/\s/g, "") : null,
      }

      if (this.data.mode === 'duplicate') {
        if (this.editable) {
          this.code = (this.data.messageTemplate.message_template.section_name + '.' + this.data.messageTemplate.message_template.type_name).replace(/\s/g, "");
        }
        else {
          this.code = null;
        }
      }

      const content = contentDetail;
      const details = this.details;
      this.form.patchValue({ ...content, details});
      this.cdr.detectChanges();
    }
  }

  private updateForm() {
    if (this.data.mode === 'edit' || this.data.mode === 'duplicate') {
      if (+this.form.get('section').value == 0) {
        this.editable = true;
      }
      else {
        this.editable = this.dropdown.section.find(x => x.id == +this.form.get('section').value).edit === 'false' ? false : true;
        this.messageTemplateDataService.getMessageTemplatVariables(this.form.get('section').value, this.form.get('name').value).subscribe(res => {
          this.usableVariables = res['usable_variables'];
        });
      }
      
      if (!this.editable) {
        this.form.controls.name.disable();
        this.form.controls.section.disable();
        this.form.controls.status.disable();
        this.form.controls.type.disable();
      }
    }
  }
  
  onSelectedPopupItems($event: any) {
    if ($event.length == 0) {
      this.popupSelectedItems = [];
    }
    else {
      this.popupSelectedItems = [$event[0]];
    }
  }

  setPopups() {
    let contents = [];
    this.dialogDataService.getWithQuery(`?paginate=false`).subscribe(res => {
      res.map(function (elm) {
        elm.contents.forEach(v => contents.push(v));

        let localeTitle = contents.filter(x => x.title != null && x.popup_id == elm.id);
        localeTitle = localeTitle.sort(function(a, b) { 
          return a.locale_id - b.locale_id;
        });

        if (localeTitle.length > 0) {
          let title = (localeTitle[0]['title'].length > 40) ? localeTitle[0]['title'].slice(0, 40 - 1) + ' . . . ' : localeTitle[0]['title'];
          elm['labelKey'] = elm.code + ' (' + title + ')';
        }
        else {
          elm['labelKey'] = elm.code;
        }
      });

      this.popupDropdownList = this.popupDropdownList.concat(res);
      this.popupDropdownList = this.popupDropdownList.map((x) => {
        return {
          id: x.id,
          start_date: x.start_date,
          end_date: x.end_date,
          labelKey: x.labelKey,
          code: x.code
        }
      });
      
      this.popupDropdownListArray = this.popupDropdownList;
    })
  }

  syncContent() {
    Swal.fire({
      title: 'Sync Message Content',
      html: "<div>Your current message will be overwritten with the selected dialog popup's content for all locales.<br><br>This action cannot be undone. Do you want to proceed?</div>",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
      allowOutsideClick: false
    }).then((response) => {
      if (response.isConfirmed) {
        this.buttonLoading = true;
        this.localesSub = this.dropdown.locales$.pipe(tap(res => this.languages = res)).subscribe(res1 => {
          this.dialogDataService.getWithQuery(`?paginate=false&code=${this.popupSelectedItems[0].code}`).subscribe(res2 => {
            let popupContent = null;
            res1.forEach(item => {
              let language_id = item.id;

              popupContent = {
                ...popupContent,
                [language_id]: {
                  message: null,
                  subject: null,
                  settings_locale_id: language_id,
                }
              }

              res2[0].contents.forEach((x) => {
                if (item.id == x.locale_id) {
                  popupContent = {
                    ...popupContent,
                    [x.locale_id]: {
                      message: x.content,
                      subject: x.title,
                      settings_locale_id: x.locale_id,
                    }
                  }
                }  
              });
            });

          const validatorOptions = [];
          let syncDetails = {};

          const isEditMode = () => {
            let edit = {};
            if (this.data.mode === 'edit' || this.data.mode === 'duplicate') {
              edit = { ...edit, id: new FormControl(null) };
            }
            return edit;
          };

          this.languages.map(element => {
            const detailsGroup = new FormGroup({
              settings_locale_id: new FormControl(element.id, validatorOptions),
              subject: new FormControl(null, validatorOptions),
              message: new FormControl(null),
              ...isEditMode()
            });
            syncDetails = { ...syncDetails, [element.id]: detailsGroup };
          });

          const contentDetails = popupContent;
          this.form.patchValue({
            details: contentDetails,
            dialog_popups: []
          });

          this.popupSelectedItems = [];
          this.buttonLoading = false;
          this.cdr.detectChanges();
          })
        });
      }
    });
  }
}
