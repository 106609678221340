import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TransactionStatus } from '@core/enums/transaction-status.enum';
import { AuthData } from '@core/models/auth-data';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { environment } from '@env/environment';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { loggedUser } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PdfDialogComponent } from '@shared/pdf-dialog/pdf-dialog.component';
import { AppState } from '@store/reducers';
import Echo from 'laravel-echo';
import * as moment from 'moment-timezone';
import { Lightbox } from 'ngx-lightbox';
import { Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, delay, exhaustMap, map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { BankReceiptDataService } from '../../settings/bank-receipts/services/bank-receipt-data.service';
import { MemberInformationDialogComponent } from './../members/dialogs/member-information/member-information.component';
import { MemberDataService } from './../members/services/member-data.service';
import { DepositDetailsComponent } from './dialogs/deposit-details/deposit-details.component';
import { DepositDataService } from './services/deposit-data.service';
import { DepositEntityService } from './services/deposit-entity.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { ShowRemarkHistoryComponent } from './dialogs/show-remark-history/show-remark-history.component';
import { DepositLogWrapperComponent } from './dialogs/deposit-log-wrapper/deposit-log-wrapper.component';
import { AppPermissionService } from '@core/services/app-permission.service';
import { DepositComponentType } from 'typings'; 
import { TranslateService } from '@ngx-translate/core';
import { DepositStatus } from '@core/enums/deposit-status.enum';

@Component({
  selector: 'kt-deposits',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})

export class DepositComponent implements OnInit, OnDestroy {

  @Input()
  type: DepositComponentType;

  @Input()
  merchantBankAccounts: any[] = [];

  @Input()
  memberGroups: any[] = [];

  form: FormGroup;
  @ViewChildren('filterInput') filterInput: QueryList<ElementRef>;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    statuses: [
      {
        name: 'Pending', value: DepositStatus.Pending, checked: true
      },
      {
        name: 'In Progress', value: DepositStatus.InProgress, checked: true
      },
      {
        name: 'Pending Receipt', value: DepositStatus.PendingReceipt, checked: true
      },
      {
        name: 'Approved', value: DepositStatus.Approved, checked: false
      },
      {
        name: 'Rejected', value: DepositStatus.Rejected, checked: false
      },
      {
        name: 'Pending Callback', value: DepositStatus.PendingCallback, checked: false
      },
      {
        name: 'Failed', value: DepositStatus.Failed, checked: false
      }
    ],
    statusesCrypto: [
      {
        name: 'Approved', value: DepositStatus.Approved, checked: true
      },
      {
        name: 'Rejected', value: DepositStatus.Rejected, checked: false
      },
      {
        name: 'Pending Callback', value: DepositStatus.PendingCallback, checked: false
      },
      {
        name: 'Failed', value: DepositStatus.Failed, checked: false
      }
    ],
    merchantBanks: [],
    merchantBanksCrypto: [],
    paymentMethodsDepositCrypto: this.dropdownHttpService.paymentMethodsDepositCrypto,
    paymentMethodsDeposit: this.dropdownHttpService.paymentMethodsDeposit,
    cryptoTokens: this.dropdownHttpService.cryptoTokens,
    memberBankAccounts: this.dropdownHttpService.memberBankAccount,
    perPage: this.dropdownHttpService.perPage,
    dateType: this.dropdownHttpService.depositDateTypes,
  };
  status = TransactionStatus;
  searchStatus = [DepositStatus.Pending, DepositStatus.InProgress, DepositStatus.PendingReceipt];
  searchStatusCrypto = [DepositStatus.Approved];
  deposits$: Observable<any[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = 'status[0]=0&status[1]=3'; // TODO: To refactor soon
  loggedUser$: Observable<AuthData>;
  currentUserId: number;
  currentUsername: string;
  currentBoCurrencyIds: Array<number>;
  ranges: any;

  dropdownSettings = {};
  bankaccountDropdownSettings = {};
  bankaccountDropdownList = [];
  bankaccountDropdownListCrypto = [];
  bankaccountSelectedItems: any;
  bankaccountSelectedItemsCrypto: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  selectedCurrency = { name: 'all' };

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '2.1';
  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'payment_method': 'desc',
    'status': 'desc',
    'member_group': 'desc',
    'currency': 'desc',
    'amount': 'desc',
    'processing_fee': 'desc',
    'confirmed_amount': 'desc',
    'merchant_bank_account': 'desc',
    'expiry_date': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',
    'cr_token': 'desc',
    'cr_exchange_rate': 'desc',
    'cr_amount': 'desc',
    'cr_processing_fee': 'desc',
    'cr_confirmed_amount': 'desc',
    'pg_reference_id': 'desc'
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  selectedItems = [];
  originalParams = '';
  receiptList = [];
  bankSelectedItems: any;
  bankSelectedItemsCrypto: any;
  autoRefresh: boolean = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;
  button_loading = false;
  messages$ = this.depositDataService.messages$;
  messages2$ = new Subject<string[]>();
  access$ = this.authHttpService.getUserAccess(2, 'Deposits');

  accountTypeDropdownSettings = {};
  accountTypeDropdownList = [];
  accountTypeSelectedItems = [];

  // ws/echo related
  echo: Echo;
  editDepositRoom = null;
  editDepositRoomId = '';
  currentOperatorId = JSON.parse(localStorage.getItem('user_data')).id;
  currentOperatorUsername = JSON.parse(localStorage.getItem('user_data')).username;

  // permissions
  canViewDeposits: boolean;
  canViewMemberDialog: boolean;
  canCreateDeposit:boolean;
  canViewDepositDetails:boolean;
  canViewReceipts:boolean;
  canExportDeposits:boolean;
  canViewDepositEventHistory:boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  private datePickerSubscription = new Subscription();


  constructor(
    private loadingBar: LoadingBarService,
    private store: Store<AppState>,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private depositEntityService: DepositEntityService,
    private depositDataService: DepositDataService,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private memberDataService: MemberDataService,
    private bankReceiptDataService: BankReceiptDataService,
    private eventEmitterService: EventEmitterService,
    private merchantBankHttpService: MerchantBankHttpService,
    private lightbox: Lightbox,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
    private translateService: TranslateService,
  ) {
    const user_token = JSON.parse(localStorage.getItem('user_token'));
    const SocketIoClient = require('socket.io-client');
    const socketHost: string = environment.socketHost;

    try {
      this.echo = new Echo({
        broadcaster: 'socket.io',
        client: SocketIoClient,
        host: socketHost,
        auth: {
          headers: {
              'access-token': user_token.access_token,
              'token-selector': user_token.plaintext_token,
              'X-User-Model': 'bo'
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  ngOnInit() {
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));

    this.formInit();

    //If accessed from Telemarketer Report
    if (localStorage.getItem('usernameFromTelemarketerReport') !== null) {
      this.form.patchValue({
        ...this.form,
        username: localStorage.getItem('usernameFromTelemarketerReport'),
        status: 1
      });
      this.searchStatus = [1];
      this.dropdown.statuses[0].checked = false;
      this.dropdown.statuses[1].checked = false;
      this.dropdown.statuses[3].checked = true;
      this.selectedItems.push({
        username: localStorage.getItem('usernameFromTelemarketerReport')
      });
      localStorage.removeItem('usernameFromTelemarketerReport');
    }

    // If redirect from Notification (Unusual callback)
    if(this.type == 'banking' && localStorage.getItem('NotificationUnusualCallback')) {
      this.form.patchValue({
        status: null
      });
      this.searchStatus = [];
      this.dropdown.statuses[0].checked = false;
      this.dropdown.statuses[1].checked = false;
      this.dropdown.statuses[2].checked = false;
      localStorage.removeItem('NotificationUnusualCallback');
    }

    this.onSubmit(true);
    this.originalParams = this.params;
    const loggedUserStoreSub = this.store.pipe(select(loggedUser)).subscribe((res) => {
      this.currentUserId = res.id;
      this.currentUsername = res.username;
      this.currentBoCurrencyIds = res.bo_currency_ids;
    });
    this.pagination = this.depositDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;

    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.setCurrencyDropdown();

    this.merchantBankAccounts = Object.values(this.merchantBankAccounts);
    this.setMerchantAccountList();

    this.bankaccountDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'name',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false
    };

    if( this.isTypeCrypto() ) {
      this.dropdownHttpService.merchantBanksCrypto.subscribe(res => {
        this.dropdown.merchantBanksCrypto = res;
        this.dropdown.merchantBanksCrypto.unshift({
          id: 'all',
          name: 'All'
        });
        this.bankSelectedItemsCrypto = [this.dropdown.merchantBanksCrypto.find(v => v.id === 'all')];
      });
    } else {
      this.dropdownHttpService.merchantBanks.subscribe(res => {
        this.dropdown.merchantBanks = res;
        this.dropdown.merchantBanks.unshift({
          id: 'all',
          name: 'All'
        });
        this.bankSelectedItems = [this.dropdown.merchantBanks.find(v => v.id === 'all')];
      });
    }    

    this.updateTransaction();

    this.accountTypeDropdownList = this.dropdownHttpService.accountTypesLists;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];

    this.accountTypeDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    const apSub = this.appPermissionService.getAppPermissions().subscribe((appPermissions) => {

      this.canViewMemberDialog = this.isTypeCrypto() ? 
        appPermissions.view_crypto_deposits_member_dialog : 
        appPermissions.view_banking_deposits_member_dialog;
      this.canCreateDeposit = this.isTypeCrypto() ? 
        false : 
        appPermissions.create_deposit;
      this.canViewDepositDetails = this.isTypeCrypto() ? 
        appPermissions.view_crypto_deposit_details : 
        appPermissions.view_deposit_details;
      this.canViewReceipts = this.isTypeCrypto() ? 
        false : 
        appPermissions.view_deposit_receipts;
      this.canExportDeposits = this.isTypeCrypto() ? 
        appPermissions.export_crypto_deposit_list : 
        appPermissions.export_deposit_list;
      this.canViewDepositEventHistory = this.isTypeCrypto() ? 
        false : 
        appPermissions.view_deposit_event_history;

      this.cdr.detectChanges();

    });

    this.subscriptions.add(loggedUserStoreSub);
    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
    this.bankReceiptDataService.selectedReceipt$.complete();
    this.echo.disconnect();
  }

  openReceipt(row: any) {
    let targeted_receipt = row.receipts[0];

    if (targeted_receipt['content'].indexOf('.pdf') >= 0) {
      this.dialog.open(PdfDialogComponent, {
        width: '800px',
        data: {
          pdfSrc: targeted_receipt['content']
        },
      });
    } else {
      this.receiptList = [];
      this.receiptList.push({
        src: targeted_receipt['content'],
        caption: (row['username'] + ' deposit receipt').toLocaleUpperCase(),
        thumb: targeted_receipt['content']
      });

      this.lightbox.open(this.receiptList, 0, { centerVertically: true, disableScrolling: true, fitImageInViewPort: true, fadeDuration: 0.01, resizeDuration: 0.01 });
    }
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenSelect() {
    of(null).pipe(
      delay(0), tap(() => this.filterInput.first.nativeElement.focus()
      )).subscribe();
  }

  onOpenDialog(type: string, id?: number) {
    if (id) {
      if (type === 'member-information') {
        if (!this.canViewMemberDialog) {
          this.messages2$.next(['You do not have permission to view member dialog']);
          return;
        }

        this.subscription = this.memberDataService.getById(id, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&additional_info=1`).pipe(
          tap((res) => {
            if (res) {
              this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' }, '1500px');
            }
          })
        ).subscribe();
      } else if (type === 'show-remarks') {
        this.openDialogBy(ShowRemarkHistoryComponent, { mode: type, id, remarkType: 'deposit' });
      } else if (type === 'show-logs') {
        this.subscription = this.depositEntityService.getByKey(id).subscribe((res) => {
          this.openDialogBy(DepositLogWrapperComponent, { deposit: res, mode: type }, '1500px');
        });
      } else {

        this.subscription = this.depositEntityService.getByKey(id).subscribe((res) => {
          const showDialog = (takeover = false) => {
            if ((res.bo_user_id <= 0 || res.status === DepositStatus.Pending || res.status === DepositStatus.InProgress) && res.status !== DepositStatus.Rejected) {
              const data = {
                id: res.id,
                status: 3,
                takeover: takeover,
                edit: true
              };
              this.depositEntityService.update(data).subscribe(() => {
                this.depositEntityService.getByKey(res.id).subscribe((res2) => {
                  // Pass latest deposit data to dialog if there is update on deposit row
                  // Also may wait deposit log generated first before dialog open so that deposit event history tab can get latest log data
                  const dialogRef = this.openDialogBy(DepositDetailsComponent, { deposit: res2, mode: 'edit', newHandler: this.currentOperatorUsername, is_crypto: res.is_crypto }, '90%');
                  this.depositSingleActiveSessionWebsocket(res2.id, dialogRef);
                });
              });
            } else {
              const dialogRef = this.openDialogBy(DepositDetailsComponent, { deposit: res, mode: 'edit', newHandler: this.currentOperatorUsername, is_crypto: res.is_crypto }, '90%');
              this.depositSingleActiveSessionWebsocket(res.id, dialogRef);
            }
          };
          if (
            res.bo_user_id > 0 &&
            (res.bo_user_id !== this.currentUserId) &&
            (res.status === DepositStatus.Pending || res.status === DepositStatus.InProgress || res.status === DepositStatus.PendingReceipt)
          ) {
            Swal.fire({
              title: 'Someone is on the same deposit row!',
              text: 'Do you want to take over?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes',
              reverseButtons: true,
            }).then((resp) => {
              if (resp.value) {
                return showDialog(true);
              }
            });
          } else {
            return showDialog();
          }
        });
      }
    } else {
      this.openDialogBy(DepositDetailsComponent, { mode: 'create', is_crypto: this.isTypeCrypto() ? '1' : '0' });
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.deposits$ = this.depositEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.depositDataService.pagination;
        this.eventEmitterService.updateDepositTabBadge.emit({'isTypeCrypto': this.isTypeCrypto(), 'total': this.pagination.total});
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onSearchStatus(event: any, id: number, ind: number) {
    if( this.isTypeCrypto() ) {
      const position = this.searchStatusCrypto.indexOf(id);
      if (event.target.checked) {
        this.searchStatusCrypto.push(id);
        this.dropdown.statusesCrypto[ind].checked = true;
      } else {
        this.searchStatusCrypto.splice(position, 1);
        this.dropdown.statusesCrypto[ind].checked = false;
      }
      this.form.patchValue({ status: this.searchStatusCrypto });
    } else {
      const position = this.searchStatus.indexOf(id);
      if (event.target.checked) {
        this.searchStatus.push(id);
        this.dropdown.statuses[ind].checked = true;
      } else {
        this.searchStatus.splice(position, 1);
        this.dropdown.statuses[ind].checked = false;
      }
      this.form.patchValue({ status: this.searchStatus });
    }
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.eventEmitterService.onClearMerchantAccountSearch();
    this.searchStatus = [DepositStatus.Pending, DepositStatus.InProgress, DepositStatus.PendingReceipt];
    this.searchStatusCrypto = [DepositStatus.Approved];
    this.bankaccountSelectedItems = [this.bankaccountDropdownList.find(v => v.id === 'all')];
    this.bankaccountSelectedItemsCrypto = [this.bankaccountDropdownListCrypto.find(v => v.id === 'all')];
    this.bankSelectedItems = [this.dropdown.merchantBanks.find(v => v.id === 'all')];
    this.bankSelectedItemsCrypto = [this.dropdown.merchantBanksCrypto.find(v => v.id === 'all')];
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    this.form.patchValue({
      id: null,
      username: null,
      name: null,
      currency_id: 'all',
      status: this.isTypeCrypto() ? this.searchStatusCrypto : this.searchStatus,
      payment_method: 'all',
      member_group: 'all',
      merchant_bank: 'all',
      merchant_bank_account: 'all',
      start_date: null,
      end_date: null,
      defaultDate: null,
      token: null,
      amount: null,
      amount_type: 'Crypto',
      date_type: this.dropdown.dateType[0].value,
      unusual_callback: true,
      pg_reference_id: null,
      account_type_condition: 'Or',
      account_type: [1, 2, 3]
    });
    this.dropdown.statuses.forEach((child) => {
      if (child.name !== 'In Progress' && child.name !== 'Pending' && child.name !== 'Pending Receipt') {
        child.checked = false;
      } else {
        child.checked = true;
      }
    });
    this.dropdown.statusesCrypto.forEach((child) => {
      if (child.name !== 'Approved') {
        child.checked = false;
      } else {
        child.checked = true;
      }
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        if(data['account_type']){
          data['account_type'] = this.form.controls['account_type'].value.toString();
        }

        const statusParams = this.generateStatusParams();
        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = paramsRef.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
        this.loadingBar.start();
        return this.deposits$ = this.depositEntityService.getWithQuery(`?${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.depositDataService.pagination;
            this.eventEmitterService.updateDepositTabBadge.emit({'isTypeCrypto': this.isTypeCrypto(), 'total': this.pagination.total});
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onExport() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.button_loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        data['paginate'] = false;
        data['timezone'] = this.timezone;
        if(data['account_type']){
          data['account_type'] = this.form.controls['account_type'].value.toString();
        }
        const statusParams = this.generateStatusParams();
        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = paramsRef.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
        this.loadingBar.start();
        return this.depositDataService.exportDeposit(`?${this.params}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.messages$ = this.depositDataService.messages$;
            this.loadingBar.complete();
            this.button_loading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.button_loading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  onCurrencyChange() {
    this.selectedCurrency = this.dropdown.currencies.find(x => x.id == this.form.value.currency_id);
    if (this.selectedCurrency === undefined) {
      this.selectedCurrency = { name: 'all' };
      this.setMerchantAccountList();
    } else {
      this.setMerchantAccountList(this.form.value.currency_id);
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private updateTransaction() {
    const listener = (newData) => {
      const newDeposit = {
        ...newData,
        member_id: newData.member_account_id
      };

      const dataUpdate = (unshift = true, res: any) => {
        if (unshift){
          res.unshift(newDeposit);
          this.pagination = {
            ...this.pagination,
            from: 1,
            to: this.pagination.to + 1,
            total: this.pagination.total + 1,
          };
        }else{
          res.pop();
          this.pagination = {
            ...this.pagination,
            to: res.length
          };
        }
        this.dataLength = res.length;
        this.eventEmitterService.updateDepositTabBadge.emit({'isTypeCrypto': this.isTypeCrypto(), 'total': this.pagination.total});
        this.cdr.detectChanges();
      };

      const data = {
        ...this.form.value,
        settings_currency_id: this.form.value.currency_id,
        payment_method_id: this.form.value.payment_method,
        group_id: this.form.value.member_group
      };
      Object.keys(data).forEach((key) => (
        data[key] == null ||
        data[key] === '' ||
        data[key] === 'all' ||
        data[key] < 1 || 
        key === 'defaultDate' ||
        key === 'status' ||
        key === 'date_type' ||
        key === 'amount_type'
      ) && delete data[key]);

      const toFilter = () => {
        let isFiltered = false;
        Object.keys(data).forEach(key => {
          if (key === 'end_date' || key === 'start_date'){
            var startDate = moment(data.start_date).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
                endDate = moment(data.end_date).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
                date = moment(newDeposit.created_at).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
            if (!moment(date).isBetween(startDate, endDate)){
              return isFiltered = true;
            }
          } else if (key === 'username' || key === 'name'){
            if (!(newDeposit[key].toString()).includes(data[key].toString())) {
              return isFiltered = true;
            }
          }else{
            // TODO: commenting this will let listener ignore all filters other than if else-if filters above,
            //       table will proceed to add/update data
            // if (newDeposit[key].toString() !== data[key].toString()) {
            //   return isFiltered = true;
            // }
          }
        });
        return isFiltered;
      }

      if (this.page === 1 && this.searchStatus.includes(DepositStatus.Pending) && newDeposit.type === 'new'){
        this.deposits$.subscribe(res => {
          const isFiltered = toFilter();

          setTimeout(() => {
            if (!isFiltered){
              dataUpdate(true, res);
              if (res.length > this.pageSize ) {
                dataUpdate(false, res);
              }
            }
          }, 1500);
        });
      }

      if (newDeposit.type !== 'new'){
        this.deposits$.subscribe(res => {
          const objIndex = res.findIndex((obj => obj.id === newDeposit.id));
          if (objIndex !== null && objIndex !== undefined){
            res[objIndex] = {
              ...res[objIndex],
              ...newDeposit
            };
            const isFiltered = toFilter();
            if ((objIndex >= 0) && (isFiltered || !this.searchStatus.includes(+newDeposit.status) === true)){
              res.splice(objIndex, 1);
              this.pagination = {
                ...this.pagination,
                from: res.length === 0 ? 0 : 1,
                to: res.length === 0 ? 0 : this.pagination.to - 1,
                total: res.length === 0 ? 0 : this.pagination.total - 1,
              };
              this.dataLength = res.length;
            }

            if (!isFiltered && this.page === 1 && this.searchStatus.includes(+newDeposit.status) && objIndex < 0){
              dataUpdate(true, res);
            }
            this.cdr.detectChanges();
          }
        });
      }
    };

    const depositChannel = this.echo.channel('deposit-channel');
    const currencyIds = this.currentBoCurrencyIds;

    currencyIds.forEach(currencyId => {
      depositChannel.listen(`.DepositEvent-${currencyId}`, listener);
    });
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private generateStatusParams() {
    let params = this.isTypeCrypto() ? {
      newStatusParams: Object.keys(this.searchStatusCrypto).map(key => `status[${key}]=${this.searchStatusCrypto[key]}`).join('&'),
      oldStatusParams: 'status=' + Object.keys(this.searchStatusCrypto).map(key => this.searchStatusCrypto[key]).join(','),
    } : {
      newStatusParams: Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&'),
      oldStatusParams: 'status=' + Object.keys(this.searchStatus).map(key => this.searchStatus[key]).join(','),
    }
    return params;
  }

  private openDialogBy(componentRef: any, data?: { id?: any, deposit?: any, mode?: any, member?: any, newHandler?: string, remarkType?: any, is_crypto?: any }, dimension?: string) {
    const dialogRef = this.dialog.open(componentRef, {
      width: dimension ? dimension : '800px',
      height: data.mode === 'member-information' ? '80vh' : 'auto',
      data: {
        id: data.id,
        deposit: data.deposit,
        mode: data.mode,
        member: data.member,
        newHandler: data.newHandler,
        remarkType: data.remarkType,
        is_crypto: data.is_crypto
      },
      autoFocus: data.mode === false
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (this.editDepositRoom) {
        this.leaveDepositSingleActiveSessionRoom();
        this.editDepositRoom = null;
        this.editDepositRoomId = '';
      }

      sessionStorage.removeItem('bank_receipt_id');
    });

    return dialogRef;
  }

  depositSingleActiveSessionWebsocket(depositId, dialogRef) {
    if (!this.echo) {
      return;
    }

    this.editDepositRoomId = `deposit-room.${depositId}`;
    const room = this.echo.join(this.editDepositRoomId);

    const fireSessionExpiredSwal = (text: string) => {
      Swal.fire({
        title: "Action Failed",
        text,
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then(() => {
        dialogRef.close(true);
      });
    };

    this.editDepositRoom = room
      .here((users) => {})
      .joining((user) => {
        const otherOperatorTakeover = user.id !== this.currentOperatorId;

        if (otherOperatorTakeover) {
          fireSessionExpiredSwal(
            "This deposit has been taken over by another operator"
          );
          return;
        }

        // same operator opens same deposit on another tab
        fireSessionExpiredSwal("Edit deposit is opened on another tab");
      })
      .leaving((user) => {});
  }

  leaveDepositSingleActiveSessionRoom() {
    if (!this.editDepositRoomId) {
      console.error('Room not found');
      return;
    }

    this.echo.leave(this.editDepositRoomId);
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      username: new FormControl(null),
      name: new FormControl(null),
      currency_id: new FormControl('all'),
      status: new FormControl(this.isTypeCrypto() ? this.searchStatusCrypto : this.searchStatus),
      payment_method: new FormControl('all'),
      member_group: new FormControl('all'),
      merchant_bank: new FormControl('all'),
      merchant_bank_account: new FormControl('all'),
      pg_reference_id: new FormControl(null),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      date_type: new FormControl(this.dropdown.dateType[0].value),
      is_crypto: new FormControl(this.isTypeCrypto() ? '1' : '0'),
      amount_type: new FormControl('Crypto'),
      amount: new FormControl(null),
      token: new FormControl(null),
      unusual_callback: new FormControl(true),
      account_type_condition: new FormControl('Or'),
      account_type: new FormControl([1, 2, 3])
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate' && formData[key] !== false) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private reload(refresh: boolean) {
    if (!refresh) {
      this.onViewPageBy(this.page).subscribe();
    }
  }

  isTypeCrypto() {
    return this.type === 'crypto';
  }

  setMerchantAccountList(currency?) {
    let list = this.merchantBankAccounts;
    if( currency !== undefined ) {
      list = list.filter(v => v.currency_id == currency || v.id === 'all');
    }

    if( this.isTypeCrypto() ) {
      this.bankaccountDropdownListCrypto = list;
      this.bankaccountSelectedItemsCrypto = [list.find(v => v.id === 'all')];
    } else {
      this.bankaccountDropdownList = list;
      this.bankaccountSelectedItems = [list.find(v => v.id === 'all')];
    }
  }

  getLighterColor(hex: string, percent: number = 30): string {
    if (!hex) return 'inherit'; // Default to white if no color is provided
    hex = hex.replace('#', '');
  
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
  
    // Increase each channel value to make it lighter (but not exceed 255)
    r = Math.min(255, r + (255 - r) * (percent / 100));
    g = Math.min(255, g + (255 - g) * (percent / 100));
    b = Math.min(255, b + (255 - b) * (percent / 100));
  
    return `rgb(${r}, ${g}, ${b})`; // Return the lightened color
  }

}
