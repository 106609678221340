<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Label and Remarks</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <mat-tab-group animationDuration="0ms" #tabGroup [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChange($event)">
        <mat-tab label="Member Info" *ngIf="canViewMemberLabelInfo">
          <kt-member-label-list></kt-member-label-list>
        </mat-tab>
        <mat-tab label="Label Settings" *ngIf="canViewMemberLabelSettings">
          <kt-member-label-settings></kt-member-label-settings>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    </div>
  </div>
</div>