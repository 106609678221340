<div id="kt_content" class="kt-content kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <!-- Filter -->
    <div class="kt-form kt-form--label-right">
      <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1">
            <form class="align-items-center" [formGroup]="form">
              <div class="row mb-2">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>ID:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                    <input type="text" formControlName="id" placeholder="Search" class="form-control">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                    <label>Username:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                    <input type="text" formControlName="username" placeholder="Search" class="form-control">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Name:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="name" placeholder="Search" class="form-control">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Status:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select  class="form-control" formControlName="status">
                    <option value="all">{{ 'All' | translate }}</option>
                    <ng-container *ngFor="let value of dropdown.statuses; let i = index;">
                      <option [value]="i">{{ value | translate }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Currency:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="currency_id" class="form-control" [attr.disabled] = "interactiveInput.disableCurrency ? 'disabled': null">
                    <option value="all"> All </option>
                    <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                        {{ value.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Group:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="group_id" class="form-control" [attr.disabled] = "interactiveInput.disableGroup ? 'disabled': null">
                    <option value="all"> All </option>
                    <option [value]="value.id" *ngFor="let value of memberGroup">
                        {{ value.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Type:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <ngx-dropdown-treeview [config]="config" [items]="treeviewItems" [buttonClass]="'treeview-outline-color'"
                      (selectedChange)="onTreeviewChange($event)">
                  </ngx-dropdown-treeview>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Label:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="label" class="form-control">
                    <option value="all"> All </option>
                    <option [value]="item.name" *ngFor="let item of dropdown.label | async">{{ item.name }}</option>
                </select>
                </div>
              </div>
            </form>
          </div>
      </div>
    </div>

    <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
      <div class="dropdown dropdown-inline">
        <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
        <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
      </div>
      <button class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')" *ngIf="canCreateMemberLabel"><i class="fas fa-plus"></i>Create</button>
    </div>

    <div class="kt-section">
      <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="text-center sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('currency')" [ngClass]="sortingConfig.currency" [class.sort-selected]="sortingSelection.sort_by === 'currency'">Currency</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('group')" [ngClass]="sortingConfig.group" [class.sort-selected]="sortingSelection.sort_by === 'group'">Group</th>
                  <th class="text-center">Label</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                  <th class="text-center" *ngIf="canEditMemberLabel">Actions</th>
                </tr>
              </thead>
              <tbody *ngIf="(members$ | async) as rows">
                <tr *ngFor="let row of rows">
                  <td class="text-center">{{ row.id }}</td>
                  <td [ngClass]="row.username ? 'text-left' : 'text-center'">
                    <ng-container *ngIf="(row.username); else nullTemplate">
                      <ng-container *ngIf="canViewMemberDialog else otherRole">
                        <a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold"
                            (click)="onOpenDialog('member-information', row.id)">
                            {{ row.username }}
                        </a>
                        <i class="fas fa-user-tag" [ngbTooltip]="memberLabelDescription" [style.color]="row.member_label_display_color_code" placement="right" container="body" tooltipClass="member-label-tooltip"></i>
                      </ng-container>
                      <ng-template #otherRole>
                        <span [ngClass]="row.dummy == 1 ? 'text-dummy-unclickable' : 'text-primary'">
                          {{ row.username }}
                        </span>
                        <i class="fas fa-user-tag" [ngbTooltip]="memberLabelDescription" [style.color]="row.member_label_display_color_code" placement="right" container="body" tooltipClass="member-label-tooltip"></i>
                      </ng-template>
                    </ng-container>
                    <ng-template #nullTemplate>
                      -
                    </ng-template>

                    <ng-template #memberLabelDescription>
                      <div class="tooltip-container">
                        <ng-container *ngFor="let label of row.labels">
                          <div *ngIf="label.status == 1" class="tooltip-item text-left">
                            <i class="fas fa-user-tag pr-2" [style.color]="label.display_color_code"></i>
                            <b>{{ label.name }}</b> - {{ label.description }}
                          </div>
                        </ng-container>
                      </div>
                    </ng-template>
                  </td>
                  <td>{{ row.name }}</td>
                  <td class="text-center">{{ row.currency }}</td>
                  <td class="text-center">{{ row.group }}</td>
                  <td>
                    <ng-container *ngFor="let label of row.labels">
                      <div *ngIf="label.status == 1">
                        <i class="fa fa-check-circle" [ngStyle]="{ color: label.display_color_code }"></i> {{ label.name }}
                      </div>
                    </ng-container>
                  </td>
                  <td class="text-center">
                    <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status">{{ row.status_name }}</span>
                  </td>
                  <td>
                    {{ row.updated_by || "System" }}
                    <br />
                    <span placement="top" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                    <ng-template #updatedDateTime>
                        {{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                  </td>
                  <td class="text-center actions-column" *ngIf="canEditMemberLabel">
                    <button matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', null, row)" *ngIf="canEditMemberLabel"><i class="fas fa-cog"></i></button>
                  </td>
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination
                  [pageSize]="pageSize"
                  [(page)]="page"
                  [maxSize]="maxSize"
                  [directionLinks]="true"
                  [boundaryLinks]="true"
                  [rotate]="true"
                  [collectionSize]="pagination.total"
                  (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
                <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                    <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                </select>
                <span class="pagination__desc">
                    Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                </span>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="loadingBar.complete()"></kt-swal-alert>
