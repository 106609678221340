import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GameCategory } from '@core/models/game-category.model';
import { GameProvider } from '@core/models/game-provider.model';
import { Rebate } from '@core/models/rebate.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError, debounceTime, tap } from 'rxjs/operators';
import { GameProviderBlacklistComponent } from './../../../general/promotion-codes/dialogs/game-provider-blacklist/game-provider-blacklist.component';
import { RebateDataService } from './../services/rebate-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-rebate-edit',
  templateUrl: './rebate-edit.component.html',
  styleUrls: ['./rebate-edit.component.scss']
})
export class RebateEditDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  form: FormGroup;
  refreshStatus: boolean;
  messages$ = this.rebateDataService.messages$;
  private subscription = new Subscription();
  private subscriptions = new Subscription();

  buttonLoading = false;

  dropdown = {
    memberGroups: this.dropdownHttpService.groups,
    gameProviders: this.data.mode === 'edit' ? this.gameProviderHttpService.getGameProviders((this.data.rebate.member_groups).map(res => res.category_id)) : of(null),
    gameCategories: this.dropdownHttpService.gameCategories,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    statuses: this.dropdownHttpService.statuses
  }

  memberGroupsDropdownList = [];
  memberGroupsSelectedItems = [];

  gameProvidersDropdownList = [];
  gameProvidersDropdownLoading = false;
  gameProvidersSelectedItems = [];

  categoriesDropdownList  = [];
  categoriesSelectedItems = [];

  currenciesSelectedItems = [];

  dropdownSettings = {
    singleSelection: false,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 'auto',
    primaryKey: 'id',
    labelKey: 'name',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false,
    searchBy: ["name"]
  };

  gameProviderDropdownSettings = {
    singleSelection: false,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 'auto',
    primaryKey: 'id',
    labelKey: 'labelKey',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false
  };

  blacklistedGameProviders$ = this.gameProviderHttpService.blacklistedGameProviders$;
  blacklistedGameProvidersData = null;
  isBlackListFormOpened = false;

  // permissions
  canCreateRebate: boolean;
  canEditRebate: boolean;
  canViewGameProviderBlacklist: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { rebate: Rebate, mode: string },
    private rebateDataService: RebateDataService,
    public dialogRef: MatDialogRef<RebateEditDialogComponent>,
    private dropdownHttpService: DropdownHttpService,
    private gameProviderHttpService: GameProviderHttpService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
      });
    }
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateRebate = appPermissions.create_rebate_settings;
      this.canEditRebate = appPermissions.edit_rebate_settings;
      this.canViewGameProviderBlacklist = appPermissions.rebate_settings_view_game_provider_blacklist;
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    forkJoin(this.dropdown).subscribe((res: any) => {
      this.memberGroupsDropdownList = res.memberGroups;
      this.gameProvidersDropdownList = (this.data.mode === 'edit') ? res.gameProviders.map((res: any) => {
        return {
          id: res.id,
          code: res.code,
          name: res.name,
          labelKey: res.code + " - " + res.name
        }
      }) : [];
      this.categoriesDropdownList = res.gameCategories;

      if(this.data.mode === 'edit') {
        // Pre-selected currencies
        this.dropdown.currencies.forEach(item => { this.data.rebate.member_groups.forEach(r => { if(+r.settings_currency_id === +item.id) { this.currenciesSelectedItems.push(item); }}); });
        this.currenciesSelectedItems = Array.from(new Set(this.currenciesSelectedItems));

        // Pre-selected categories
        this.categoriesDropdownList.forEach(item => { this.data.rebate.member_groups.forEach(r => { if(+r.category_id === +item.id) { this.categoriesSelectedItems.push(item); }}); });
        this.categoriesSelectedItems = Array.from(new Set(this.categoriesSelectedItems));

        // Pre-selected gameProviders
        this.gameProvidersDropdownList.forEach(item => { this.data.rebate.member_groups.forEach(r => { if(+r.game_provider_id === +item.id) { this.gameProvidersSelectedItems.push(item); }}); });
        this.gameProvidersSelectedItems = Array.from(new Set(this.gameProvidersSelectedItems));

        // Pre-selected memberGroups
        this.memberGroupsDropdownList.forEach(item => { this.data.rebate.member_groups.forEach(r => { if(+r.member_group_id === +item.id) { this.memberGroupsSelectedItems.push(item); }}); });
        this.memberGroupsSelectedItems = Array.from(new Set(this.memberGroupsSelectedItems));

        this.form.patchValue({
          currencies: this.currenciesSelectedItems.map(item => item.id),
          categories: this.categoriesSelectedItems.map(item => item.id),
          member_groups: this.memberGroupsSelectedItems.map(item => item.id),
          game_providers: this.gameProvidersSelectedItems.map(item => item.id)
        });

      }
    });

  }

  ngOnDestroy() {
    this.gameProviderHttpService.blacklistedGameProviders$.next(null);
    this.gameProviderHttpService.selectedSubCategories$.next(null);
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onSave(rebate: Rebate, mode?: string) {
    this.onBlacklistedGameProviders();
    const blacklistedGameProvidersData = this.blacklistedGameProvidersData;

    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const data = {
      id: rebate ? rebate.setting.id : null,
      ...this.form.value,
      ...blacklistedGameProvidersData
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.rebateDataService.updateRebate(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        this.refreshStatus = true;
        break;
      case 'create':
        this.subscription = forkJoin([
          this.rebateDataService.add(data).pipe(
            tap((res: any) => {
              this.messages$.next([...res.message]);
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
              throw error;
            })
          ),
          this.rebateDataService.messages$,
        ]).subscribe();
        this.refreshStatus = true;
        break;
    }
  }

  onBlacklistDialog(selectedProviders: GameProvider[]) {
    const data = {
      providers: selectedProviders,
      blacklist_sub_categories: this.data.mode === 'edit' ? this.data.rebate.blacklist_sub_categories : null
    };

    if(this.gameProvidersSelectedItems.length >= 1) {
      this.isBlackListFormOpened = true;
      this.openDialogBy(GameProviderBlacklistComponent, data);
    }
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  getSelectedCategories(selections: GameCategory[]) {

    // Reset
    this.gameProvidersDropdownList = [];
    this.gameProvidersDropdownLoading = true;
    this.subscription.unsubscribe();

    if(selections && selections.length <= 0) {
      this.gameProvidersSelectedItems = [];
      this.form.patchValue({
        game_providers: null
      })
    }

    if(selections !== null && selections.length >= 1) {
      this.categoriesSelectedItems = selections;
      const categoriesParam = selections.map(item => item.id);
      if(categoriesParam && categoriesParam.length >= 1) {
        setTimeout(() => {
          this.subscription = this.gameProviderHttpService.getGameProviders(categoriesParam)
          .pipe(
            debounceTime(2000)
          )
          .subscribe((res: any) => {
            this.gameProvidersDropdownList = res.map((item: any) => {
              return {
                id: item.id,
                code: item.code,
                name: item.name,
                labelKey: item.code + " - " + item.name
              };
            });
            this.gameProvidersDropdownLoading = false;
          });
        }, 1500);
      }
    }
  }

  onBlacklistedGameProviders() {
    this.gameProviderHttpService.blacklistedGameProviders$.subscribe(res => {
      let data: {[k: number]: any[]} = {};
      if (this.isBlackListFormOpened){
        if(res?.game_providers !== null && (res?.game_providers)?.length >= 1) {
            (res?.game_providers.filter((row: any) => row.blacklist_sub_categories !== null)).forEach((item: any) => {
              data = {
                ...data,
                [item.game_provider_id]: item.blacklist_sub_categories
              };
            });
        }
      }else{
        this.gameProvidersSelectedItems.map( res => {
          let list;
          if (this.data.rebate.blacklist_sub_categories.find( x => x['game_provider_id'] === res.id) !== undefined){
            list = this.data.rebate.blacklist_sub_categories.find( x => x['game_provider_id'] === res.id);
            data = {
              ...data,
              [list.game_provider_id]: list['sub_category_name']
            };
          }
        });
      }

      if(Object.keys(data)) {
        const finalData = {
          blacklist_sub_categories: data
        }
        this.blacklistedGameProvidersData = finalData;
      }
    });
  }

  private openDialogBy(componentRef: any, data?: { promotionCurrency?: any, promotionCode?: any, providers?: any[], blacklist_sub_categories?: any[], promotionType?: any}) {
    this.dialog.open(componentRef, {
      width: '1300px',
      data: {
        promotionCurrency: data.promotionCurrency,
        promotionCode: data.promotionCode,
        providers: data.providers,
        blacklist_sub_categories: data.blacklist_sub_categories,
        promotionType: data.promotionType
      }
    });
  }

  private formInit() {
    let name = null;
    let min = null;
    let max = null;
    let percentage = null;
    let stat = 1;
    let memberGroups = null;
    let gameProviders = null;
    let categories = null;
    let currencies = null;
    if (this.data.mode === 'edit') {
      name = this.data.rebate.setting.name;
      min = this.data.rebate.setting.min_rebate_limit;
      max = this.data.rebate.setting.max_rebate_limit;
      percentage = this.data.rebate.setting.percentage;
      stat = this.data.rebate.setting.status;
    }
    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      min_rebate_limit: new FormControl(min, [Validators.required]),
      max_rebate_limit: new FormControl(max, [Validators.required]),
      percentage: new FormControl(percentage, [Validators.required]),
      status: new FormControl(stat),
      member_groups: new FormControl(memberGroups),
      game_providers: new FormControl(gameProviders),
      categories: new FormControl(categories),
      currencies: new FormControl(currencies),
    });
  }

}
