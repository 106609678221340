import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageStatus } from '@core/enums/message-status.enum';
import { AllSmsMessages } from '@core/models/all-sms-messages.model';
import { Member } from '@core/models/member.model';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { exhaustMap, map, tap, catchError } from 'rxjs/operators';
import { MemberInformationDialogComponent } from '../../general/members/dialogs/member-information/member-information.component';
import { AllSmsMessagesDialogComponent } from './dialogs/all-sms-messages-dialog.component';
import { AllSmsMessagesDataService } from './service/all-sms-messages-data.service';
import { AllSmsMessagesEntityService } from './service/all-sms-messages-entity.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import Swal from 'sweetalert2';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { accessSections } from '@core/store/auth/auth.selectors';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-all-sms-messages',
  templateUrl: './all-sms-messages.component.html',
  styleUrls: ['./all-sms-messages.component.scss'],
  animations: [
    trigger('panelState', [
      state('closed', style({ height: '80px', overflow: 'hidden' })),
      state('open', style({ height: '*' })),
      transition('closed <=> open', animate('300ms ease-in-out'))
    ])
  ]
})
export class AllSmsMessagesComponent implements OnInit {

  form: FormGroup;
  allSmsMessages$: Observable<AllSmsMessages[]>;
  messages$ = this.allSmsMessagesDataService.messages$;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };
  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  messageStatus = MessageStatus;
  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '11.1';
  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'title': 'desc',
    'content': 'desc',
    'status': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  buttonLoading = false;
  dataLength: number;

  accountTypeDropdownSettings = {};
  accountTypeDropdownList = [];
  accountTypeSelectedItems = [];

  // permissions
  // allSMSUseNewPermissions: boolean;
  canViewMemberDialog: boolean;
  canSendMessages: boolean;
  canExportMessages: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private datePickerSubscription = new Subscription();

  dateTimeFilterType = [
    {
      key: 'created_at',
      label: 'Created Date'
    },
    {
      key: 'updated_at',
      label: 'Updated Date'
    }
  ]

  constructor(
    public dialog: MatDialog,
    private transactionHttpService: TransactionHttpService,
    private loadingBar: LoadingBarService,
    private allSmsMessagesEntityService: AllSmsMessagesEntityService,
    private allSmsMessagesDataService: AllSmsMessagesDataService,
    private dropdownHttpService: DropdownHttpService,
    public sanitizer: DomSanitizer,
    private authHttpService: AuthHttpService,
    private store: Store<AppState>,
    private appPermissionService: AppPermissionService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.accountTypeDropdownList = this.dropdownHttpService.accountTypesLists;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    
    this.accountTypeDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));
    this.formInit();
    this.pagination = this.allSmsMessagesDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.all_messages_view_member_dialog;
      this.canSendMessages = appPermissions.send_messages;
      this.canExportMessages = appPermissions.export_all_messages;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.allSmsMessages$ = this.allSmsMessagesEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.allSmsMessagesDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onClearDate() {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    this.form.patchValue({
      username: null,
      status: 'all',
      keyword: null,
      start_datetime: null,
      end_datetime: null,
      defaultDate: null,
      account_type_condition: 'Or',
      account_type: [1, 2, 3],
      date_type: this.dateTimeFilterType[0].key
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        if(data['account_type']){
          data['account_type'] = this.form.controls['account_type'].value.toString();
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.allSmsMessages$ = this.allSmsMessagesEntityService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.page = 1;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.allSmsMessagesDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onExport() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.buttonLoading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.allSmsMessagesDataService.export(`?perPage=${this.pageSize}${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.buttonLoading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.buttonLoading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  onOpenDialog(type: string, memberData?: Member) {
    switch (type) {
      case 'member-information':
        if (!this.canViewMemberDialog) {
          Swal.fire('Permission Error', 'You do not have permission to view member dialog', 'error');
          return;
        }

        const member = this.allSmsMessagesDataService.getMember(memberData.id, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&additional_info=1`);
        this.subscription = member.pipe(
          tap((res) => {
            if (res) {
              this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' });
            }
          })
        )
          .subscribe();
        break;

      case 'send-message':
        this.openDialogBy(AllSmsMessagesDialogComponent, {
          member: memberData,
          mode: 'send-message'
        });
        break;
    }

  }

  onContent(content) {
    return this.sanitizer.bypassSecurityTrustHtml(content.replace(/\&lt;/g, '<').replace(/\&gt;/g, '>'));
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }
    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }
  
  stripHTMLTags(text: string){
    return text.replace( /(<([^>]+)>)/ig, '');
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private openDialogBy(componentRef: any, data?: { member?: any, mode?: any, isAdmin?: boolean }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'member-information' ? '1500px' : '800px',
      height: data.mode === 'member-information' ? '80vh' : 'auto',
      data,
      autoFocus: data.mode === 'member-information' ? false : true
    });
    if (data.mode !== 'member-information') {
      dialogRef.afterClosed().subscribe(result => {
        this.reload();
      });
    }
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all' && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      status: new FormControl('all'),
      keyword: new FormControl(null),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      account_type_condition: new FormControl('Or'),
      account_type: new FormControl([1,2,3]),
      date_type: new FormControl(this.dateTimeFilterType[0].key),
    });
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

}
