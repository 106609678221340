<div class="kt-form w-webkit-fill-available" *ngIf="dropdown.locales.length > 0">
    <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
          <form [formGroup]="form">
            <div class="row align-items-center">
              <div class="col-xl-12 order-2 order-xl-1">
                <div class="row mb-3">
                  <!-- Label -->
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Label' | translate }}:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <input type="text" formControlName="label" placeholder="{{ 'Search' | translate }}" class="form-control">
                  </div>

                  <!-- Date Time -->
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>Date/Time:</label>
                  </div>
                  <div class="col-md-5 kt-form__control">
                    <div class="input-group date">
                      <div class="input-group-prepend">
                        <select class="form-control" formControlName="date_type">
                            <ng-container *ngFor="let row of dropdown.dateType">
                                <option [value]="row.value">{{ row.name }}</option>
                            </ng-container>
                        </select>
                      </div>
                      <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" 
                        formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" 
                        [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" 
                        [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />

                        <span class="input-group-append">
                        <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                      </span>
                    </div>
                  </div>

                  <!-- Status -->
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Status' | translate }}:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <select formControlName="status" class="form-control">
                      <option value="all"> {{'All' | translate }} </option>
                      <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">
                        {{ value }}
                      </option>
                    </select>
                  </div>
                  
                </div>
              </div>
            </div>
          </form>
          <div class="kt-section col-12 row d-flex justify-content-between mb-0 mt-1">
            <div class="dropdown dropdown-inline">
              <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
              <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onSubmit(true)"></kt-clear-button>
            </div>
          </div>
        </div>
      </div>

      <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-portlet__body pt-12 pb-0">
            <div class="kt-section">
              <div class="kt-section__content">
                <div class="table-responsive">
                  <table class="table table-bordered table-hover table-striped table-sortable">
                    <tbody *ngIf="vipContentSettings$ as rows">
                      <tr><td colspan="2" class="custom-padding"></td></tr>
                      <ng-container *ngIf="vipContentSettings$ as rows">
                        <tr class="border-bottom-remove ng-tns-c15-1 ng-star-inserted">
                            <td class="question" colspan="2">
                                <div class="main_description">VIP Structure Section</div>
                            </td>
                        </tr>
                        <!-- VIP Structure Section -->
                        <ng-container *ngIf="rows.vip_sections as rows">
                          <tr class="border-top-remove border-bottom-remove" [ngClass]="modeVipSection == 'edit' || modeVipSection == 'create'? 'border-bottom-remove' : ''">
                            <td colspan="2" class="custom-top-border">
                                <button *ngIf="canCreateVIPStructure" class="faq_section_add_button" [disabled]="loading" (click)="expandRowVipSectionCreate('create')" ><i class="fas fa-plus"></i></button>
                              <ng-container *ngFor="let row of rows">
                                <button class="faq_section_button" [disabled]="loading" [ngClass]="vipSection && vipSection.id == row.id ? 'merchant-selected' : row.status == 0 ? 'inactive_button' : ''" (click)="expandRowVipSectionEdit('edit',row)"> {{ row.main_vip_name }}</button>
                              </ng-container>
                            </td>
                          </tr>
                          <tr class="border-top-remove" *ngIf="(modeVipSection == 'edit' && canViewVIPStructureDetails) || (modeVipSection == 'create' && canCreateVIPStructure)">
                            <td colspan="2" class="pr-0 pl-0  custom-top-border" style="border-bottom: 0 !important;">
                              <ng-container *ngIf="!modeVipSection else vipSectionsContent"></ng-container>
                            </td>
                          </tr>
                        </ng-container>
                      </ng-container>
                    </tbody>
                  </table>
                  <table class="table table-bordered table-hover table-striped table-sortable border-btm" *ngIf="canViewVIPContentSettingsList">
                    <thead>
                        <tr>
                          <th width="5" class="sort-enabled pointer" (click)="onSortColumn('vip_content_setting_id')" [ngClass]="sortingConfig.vip_content_setting_id" [class.sort-selected]="sortingSelection.sort_by === 'vip_content_setting_id'">{{ 'Id' | uppercase }}</th>
                          <th width="5" class="pr-0">{{ 'Locale' | uppercase }}</th>
                          <th width="5" class="pl-1 pr-5">
                            <div class="dropdown selected-category-container ml-1" *ngIf="displayLocale != null">
                              <button class="dropdown-toggle d-flex justify-content-center dropdown-box m-auto" type="button" data-toggle="dropdown" style="margin: 0 auto">
                                <p class="mr-1">{{ displayLocale['code'] | uppercase }} </p>
                                <i class="fa fa-sort-down"></i>
                              </button>
                              <ul class="dropdown-menu dropdown-box-open">
                                <li *ngFor="let value of dropdown.locales" class="selected-category-container2 ml-0 text-center" (click)="changeLocale(value)">
                                  {{ value.code | uppercase }}
                                </li>
                              </ul>
                            </div>
                          </th>
                          <th width="200">{{ 'Label' | uppercase }}</th>
                          <th width="5" class="text-center pr-0">{{ 'Value' | uppercase }}</th>
                          <th width="5" class="pl-1 pr-5">
                            <div class="dropdown selected-category-container member-group ml-1" *ngIf="displayGroup != null && dropdown.activeVipMemberGroups && vipContentSettings$">
                              <button class="dropdown-toggle d-flex justify-content-center dropdown-box m-auto" type="button" data-toggle="dropdown" style="margin: 0 auto">
                                <p class="mr-1">{{ displayGroup['member_group_name'] | uppercase }} </p>
                                <i class="fa fa-sort-down"></i>
                              </button>
                              <ul class="dropdown-menu dropdown-box-open">
                                <li *ngFor="let value of dropdown.activeVipMemberGroups" class="selected-category-container2 ml-0 text-center" (click)="changeGroup(value)">
                                  {{ value.member_group_name | uppercase }}
                                </li>
                              </ul>
                            </div>
                          </th>
                          <th class="sort-enabled pointer text-center" (click)="onSortColumn('position')" [ngClass]="sortingConfig.position" [class.sort-selected]="sortingSelection.sort_by === 'position'">{{ 'Position' | uppercase}}</th>
                          <th class="sort-enabled pointer" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">{{ 'Created By' | uppercase}}</th>
                          <th class="sort-enabled pointer" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">{{ 'Updated By' | uppercase}}</th>
                          <th>{{ 'Status' | uppercase }}</th>
                          <th class="text-center pr-13">{{ 'Actions' | uppercase}}</th>
                        </tr>

                        <tr *ngIf="canCreateVIPContentSettings && (!mode || mode != 'create')">
                          <th colspan="100%" class="pr-0 pl-0">
                            <div class="content-container text-center" (click)="expandRowCreate('create')">
                              <span><i class="fas fa-plus mr-2"></i>Create New Content</span>
                            </div>
                          </th>
                        </tr>
                        <tr *ngIf="mode == 'create'">
                          <th colspan="100%" class="pr-0 pl-0">
                            <ng-container *ngIf="!mode else contents"></ng-container>
                          </th>
                        </tr>  
                    </thead>
                    <tbody *ngIf="vipContentSettings$ as contentrows">
                      <ng-container *ngFor="let row of contentrows.vip_contents.rows">
                        <tr>
                          <td [style]="mode == 'edit' && vipContent.vip_content_setting_id == row.vip_content_setting_id ? 'border-bottom: 0 !important;' : ''">{{ row.vip_content_setting_id }}</td>
                          <td [style]="mode == 'edit' && vipContent.vip_content_setting_id == row.vip_content_setting_id ? 'border-bottom: 0 !important;' : ''" colspan="2" width="1" class="pr-5">
                            <div style="width: 100px;">
                              {{ row.locale_list }}
                            </div>
                          </td>
                          <td [style]="mode == 'edit' && vipContent.vip_content_setting_id == row.vip_content_setting_id ? 'border-bottom: 0 !important;' : ''">{{ displayLocaleData(row, 'label') }}</td>
                          <td [style]="mode == 'edit' && vipContent.vip_content_setting_id == row.vip_content_setting_id ? 'border-bottom: 0 !important;' : ''" colspan="2" width="1" class="pr-5">
                            <div style="width: 100px;">
                              <div class="text-center" [innerHTML]="displayGroupValue(row)"></div>
                            </div>
                          </td>
                          <td class="text-center" [style]="mode == 'edit' && vipContent.vip_content_setting_id == row.vip_content_setting_id ? 'border-bottom: 0 !important;' : ''">{{ displayLocaleData(row, 'position') }}</td>
                          <td [style]="mode == 'edit' && vipContent.vip_content_setting_id == row.vip_content_setting_id ? 'border-bottom: 0 !important;' : ''">
                            {{ displayLocaleData(row, 'createdBy') }}<br>
                            <div class="header-hint">
                              <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="tooltip-class">{{ displayLocaleData(row, 'createdAt') != '-' ? (displayLocaleData(row, 'createdAt') | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }}</span>
                              <ng-template #createdDateTime>
                                <ng-container *ngIf="displayLocaleData(row, 'createdAt') != '-'">
                                  {{ (displayLocaleData(row, 'createdAt') | timezoneDate:'YYYY-MM-DD HH:mm:ss') }}
                                </ng-container>
                              </ng-template>
                            </div>
                          </td>
                          <td [style]="mode == 'edit' && vipContent.vip_content_setting_id == row.vip_content_setting_id ? 'border-bottom: 0 !important;' : ''">
                            {{ displayLocaleData(row, 'updatedBy') }}<br>
                            <div class="header-hint">
                              <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="tooltip-class">{{ displayLocaleData(row, 'updatedAt') != '-' ? (displayLocaleData(row, 'updatedAt') | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }}</span>
                              <ng-template #updatedDateTime>
                                <ng-container *ngIf="displayLocaleData(row, 'updatedAt') != '-'">
                                  {{ (displayLocaleData(row, 'updatedAt') | timezoneDate:'YYYY-MM-DD HH:mm:ss') }}
                                </ng-container>
                              </ng-template>
                            </div>
                          </td>
                          <td [style]="mode == 'edit' && vipContent.vip_content_setting_id == row.vip_content_setting_id ? 'border-bottom: 0 !important;' : ''">
                            <label class="switch">
                              <input [disabled]="!canEditVIPContentSettings || !canUpdateVIPContentStatus" type="checkbox" id="togBtn" [checked]="row.locale_data[0].main_status == 1" (change)="onChangeStatus($event, row)">
                              <div class="slider round">
                                <span class="on">ON</span>
                                <span class="off">OFF</span>
                              </div>
                            </label>
                          </td>
                          <td [style]="mode == 'edit' && vipContent.vip_content_setting_id == row.vip_content_setting_id ? 'border-bottom: 0 !important;' : ''" class="text-center actions-column">
                            <button *ngIf="canViewVIPContentSettings" matTooltip="{{ 'View' }}" class="btn btn-elevate btn-sm btn-icon btn-icon-sm" (click)="expandRowEdit('edit', row)"><i class="fas fa-cog"></i></button>
                          </td>
                        </tr>
                        <tr *ngIf="mode == 'edit' && vipContent.vip_content_setting_id == row.vip_content_setting_id" class="border-top-remove">
                          <td colspan="100%" class="pr-0 pl-0 custom-top-border" style="border-bottom: 0 !important;">
                            <ng-container *ngIf="!mode else contents"></ng-container>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                    <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold table-handler"></tbody>
                  </table>
                </div>
                <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined && canViewVIPContentSettingsList">
                  <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                  </ngb-pagination>
                  <div class="kt-pagination__toolbar">
                    <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                      <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                    </select>
                    <span class="pagination__desc">
                      Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

<ng-template #contents>
    <form [formGroup]="formContent">
      <div class="create-content-container" *ngIf="dropdown.locales.length > 0">
        <ng-container *ngIf="checkLoadingPage else loadingPage" >
          <mat-tab-group animationDuration="0ms" class="form-group mb-0 mat-tab-header-pagination-controls-enabled" formGroupName="contents" [selectedIndex]="localeIndex()">          
            <ng-container *ngIf="dropdown.locales.length > 0">
              <mat-tab *ngFor="let value of dropdown.locales; let i = index" [formGroupName]="value.id">
                <ng-template mat-tab-label>
                  <span class="mat-tab">
                    {{ value.code }}
                    <div *ngIf="checkContent(value)" class="icon" (click)="clearContent(value)">
                      <i class="fas fa-times"></i>
                    </div>
                  </span>
                </ng-template>

                <div class="row col-12 p-0 m-0">
                  <div class="form-group col-3 mb-3 pl-0 pr-4">
                    <label>{{ 'Label' }}: <span class="text-danger">*</span></label>
                    <input class="form-control" type="text" formControlName="label">
                  </div>
                  <ng-template #labelToolTip>
                    <div class="text-left">
                      Leave as blank to disable content for this locale
                    </div>
                  </ng-template>
                  <div class="form-group col-2 mb-3 pl-0 pr-4">
                    <label>{{ 'Row Position' }}: <span class="text-danger">*</span></label>
                    <input class="form-control" type="number" min="1" (wheel)="false" step="1" onkeypress="return event.charCode >= 48" formControlName="position">
                  </div>

                  <div class="form-group col-2 mb-3 pl-0 pr-4">
                    <label>{{ 'Value Type' }}: <span class="text-danger">*</span></label>
                    <select class="form-control" formControlName="value_type" (change)="onValueTypeChanged($event.target.value, value.id)">
                        <option value="null" disabled hidden> Please Select </option>
                        <ng-container *ngFor="let row of dropdown.valueType">
                            <option [value]="row.value">{{ row.name }}</option>
                        </ng-container>
                    </select>
                  </div>

                  <div class="form-group col-2 mb-3 pr-4">
                    <label>{{ 'Status' }}: <span class="text-danger">*</span></label>
                    <select formControlName="status" class="form-control">
                      <option value="null" disabled hidden> Please Select </option>
                      <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">
                        {{ value }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group col-3 mb-3 pl-0 pr-0 align-end">
                    <div class="d-flex sync-save-button">
                      <div class="dropdown dropdown-inline">
                        <ng-container *ngIf="canCreateVIPContentSettings && canEditVIPContentSettings">
                          <kt-sync-locale-button
                            [checkupdate] = "checkupdate()"
                            [localeList] = "dropdown.locales"
                            [valueType] = "showContentSettingDetails[value.id]"
                            [settings_locale_id_from]="value.id"
                            (localesSynced)="onLocalesSynced($event)">
                          </kt-sync-locale-button>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="showContentSettingDetails[value.id]">
                  <table class="sub-table table-striped table-border">
                    <thead *ngIf="dropdown.activeVipMemberGroups.length > 0">
                        <tr>
                            <td>Tier Name</td>
                            <td class="value-col">Value <i class="fa fa-info-circle ml-1" placement="right" [ngbTooltip]="valueToolTip" tooltipClass="tooltip-class" container="body"></i></td>
                            <ng-template #valueToolTip>
                              <div class="text-left">
                                When 'Symbol' is selected as the 'Value Type,' a check or cross icon will appear on the front page instead of text. The toggle's enabled state will show a check, while the disabled state will show a cross.
                              </div>
                            </ng-template>
                        </tr>
                    </thead>
                    <ng-container formGroupName="details">
                      <tbody *ngIf="dropdown.activeVipMemberGroups.length > 0">
                        <tr *ngFor="let group of dropdown.activeVipMemberGroups" [formGroupName]="group.member_group_id">
                          <td>{{ group.member_group_name }}</td>
                          <ng-container *ngIf="checkValueType(value.id) == 'Text' else symbolType">
                            <td>
                                <input class="form-control" type="text" formControlName="text_value">
                            </td>
                          </ng-container>
                          <ng-template #symbolType>
                            <td>
                              <label class="switch-symbol">
                                <input type="checkbox" id="togBtn" formControlName="symbol_value">
                                <div class="slider-symbol round">
                                  <span class="on"></span>
                                  <span class="off"></span>
                                </div>
                              </label>
                            </td>
                          </ng-template>
                        </tr>
                      </tbody>
                    </ng-container>
                  </table>
                </ng-container>
              </mat-tab>
            </ng-container>
          </mat-tab-group>
          <div class="modal-footer">
            <table class="info mr-3" *ngIf="mode == 'edit'">
              <tr>
                <td class="header-hint">
                  Updated by: {{ updatedBy ? updatedBy + '&nbsp;' : 'System&nbsp;' }}
                </td>
                <td class="header-hint">
                  <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="tooltip-class">{{ updatedAt | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
                  <ng-template #updatedDateTime>
                    {{ updatedAt | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <td class="header-hint">
                  Created by: {{ createdBy ? createdBy + '&nbsp;' : 'System&nbsp;' }}
                </td>
                <td class="header-hint">
                  <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="tooltip-class">{{ createdAt | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
                  <ng-template #createdDateTime>
                    {{ createdAt | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
                  </ng-template>
                </td>
              </tr>
            </table>
            <button type="button" class="btn btn-danger m-0 mr-2" (click)="mode == 'create' ? expandRowCreate('', true) : expandRowEdit('', null, true)">{{ 'Cancel' }}</button>
            <button *ngIf="(mode == 'create' && canCreateVIPContentSettings) || (mode == 'edit' && canEditVIPContentSettings)" type="button" class="btn btn-success m-0" [disabled]="isEmptyContent || !formContent.valid" (click)="onSave()">{{ mode != null && mode == 'create' ? 'Create' : 'Update' }}</button>
          </div>
        </ng-container>
      </div>
    </form>
    <ng-template #loadingPage>
      <div style="margin: auto;width: fit-content;">
        <div class="spinner-wrapper">
          <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
        </div>
      </div>  
    </ng-template>
</ng-template>

<ng-template #vipSectionsContent>
  <form [formGroup]="formSection">
    <div class="create-content-container">
      <div class="row col-12 p-0 m-0">
        <div class="form-group col-2 mb-3 pl-0">
          <label>{{ 'Display Type' }}: <span class="text-danger">*</span></label>
          <select class="form-control" formControlName="display_type" (change)="onDisplayTypeChanged($event.target.value)">
            <ng-container *ngFor="let row of dropdown.displayType">
                <option value="null" disabled hidden> Please Select </option>
                <option [value]="row.value">{{ row.name }}</option>
            </ng-container>
          </select>
        </div>
        <div class="form-group row col-4 mb-3">
          <label class="col-12 pl-0">{{ 'Main VIP Name ' }} {{ formSection.value.display_type == 1 ? '(Member Group)' : '' }} <span class="text-danger">*</span></label>
          <ng-container *ngIf="formSection.value.display_type == 1; else subMainVipName">
            <div class="col-8 w-100 justify-content-start pr-0 pl-0">
              <kt-dropdown-wo-lazyload [form]="formSection"
                [dropdownList]="memberGroupDropdownList" 
                [selectedItems]="memberGroupSelectedItems" 
                [selectionAttributes]="'id'" [formName]="'member_group_id'" 
                [dropdownSettings]="memberGroupDropdownSettings" 
                (selectedItemsChanged)="onChangeMemberGroup($event)">
              </kt-dropdown-wo-lazyload>
            </div>
            <div class="col-4 d-flex justify-content-start">
              <button type="button" class="btn btn-primary w-100" [disabled]="isDisabledVipName || formSection.value.member_group_id == null" (click)="onOpenDialog('vip_names')"><i class="fas fa-plus"></i>VIP Name</button>
            </div>
          </ng-container>
          <ng-template #subMainVipName>
            <div class="col-8 w-100 justify-content-start pr-0 pl-0">
              <input type="text" formControlName="main_vip_name" class="form-control">
            </div>
            <div class="col-4 d-flex justify-content-start">
              <button type="button" class="btn btn-primary w-100" [disabled]="isDisabledVipName || formSection.value.display_type == null" (click)="onOpenDialog('vip_names')"><i class="fas fa-plus"></i>VIP Name</button>
            </div>
          </ng-template>
        </div>
        <!-- <div class="form-group col-3 mb-3 pl-0" *ngIf="formSection.value.display_type == 1">
          <label>{{ 'Member Group' }}: <span class="text-danger">*</span></label>
          <kt-dropdown-wo-lazyload [form]="form"
            [dropdownList]="memberGroupDropdownList" 
            [selectedItems]="memberGroupSelectedItems" 
            [selectionAttributes]="'id'" [formName]="'member_group_id'" 
            [dropdownSettings]="memberGroupDropdownSettings" 
            (selectedItemsChanged)="onChangeMemberGroup($event)">
          </kt-dropdown-wo-lazyload>
        </div> -->
        <div class="form-group col-3 mb-3 pl-0" >
          <label>{{ 'Position' }}: <span class="text-danger">*</span></label>
          <input type="number" min="1" (wheel)="false" step="1" onkeypress="return event.charCode >= 48" formControlName="position" class="form-control">
        </div>
        <div class="form-group col-3 mb-3 pl-0">
          <label>{{ 'Status' }}: <span class="text-danger">*</span></label>
          <select formControlName="status" class="form-control">
            <option value="null" disabled hidden> Please Select </option>
            <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">
              {{ value }}
            </option>
          </select>
        </div>
      </div>
      <div class="row col-12 p-0 m-0">  
        <div class="form-group col-3 mb-0 pl-0">
          <label>{{ 'Upload Main VIP Icon' }}:</label>
          <label class="btn btn-default btn-sm btn-upload upload-icon" *ngIf="getMediaSource() == null && !isImageUploading">
            <img src="assets/img/upload-icon.png">
            <span>{{ 'Upload' }}</span>
            <input class="form-control" type="file" hidden accept="image/*"
            (change)="onUploadFile($event)" [disabled]="imageLoading">
          </label>
          <div class="spinner-wrapper" *ngIf="isImageUploading">
              <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
          </div>

          <div *ngIf="getMediaSource() != null && !isImageUploading">
            <div class="image-upload">
              <div class="image-wrapper">
                <img class="fas fa-trash position-absolute m-2 trash-icon" src="assets/img/trash-icon.png" (click)="onRemoveFile()">
                <img class="img-fluid preview-image" alt="Image" [src]="getMediaSource()" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>

      <ng-container *ngIf="showAddSubTier">
        <div class="kt-section col-12 row d-flex justify-content-end mb-3 mt-3">
          <button class="btn btn-brand btn-icon-sm" (click)="onVipContentSubAddRow(true)" [disabled]="buttonLoading || loading || validationVipContentSupList() || vipContentSubEdit"><i class="fas fa-plus"></i>Add Sub VIP</button>
        </div>

        <table class="sub-table table-striped">
          <div class="kt-section col-12 mb-0">
            <div class="kt-section__content">
                <div class="table-responsive">
                  <form [formGroup]="vipContentSubArrayForm">
                      <table class="table table-bordered table-hover table-striped">
                        <thead>
                            <tr>
                                <th class="text-center" style="width: 10%">Sub VIP (Member Group)</th>
                                <th class="text-center" style="width: 20%">Position</th>
                                <th class="text-center" style="width: 5%">Status</th>
                                <th class="text-center" style="width: 10%">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                          <ng-container formArrayName="vipContentSub">
                              <ng-container *ngIf="!loading && vipContentSubArr.controls.length > 0">
                                  <ng-container *ngFor="let row of vipContentSubArr.controls; index as i" [formGroupName]="i"> 
                                      <tr [ngClass]="row.enabled ? 'edit-active-row': ''">
                                          <td style="width: 20%">
                                              <div style="width: 35.85%; transform: translate(0px, -18.5px);" [style.z-index]="row.enabled ? 9999 : 1" class="p-0 position-absolute" [ngClass]="{'div-disabled': row.disabled }">
                                                  <kt-dropdown-wo-lazyload
                                                      [form]='vipContentSubArr.controls[i]'
                                                      [dropdownList]='memberGroupListDropdownList[i]'
                                                      [dropdownSettings]='memberGroupListDropdownListSettings'
                                                      [formName]="'member_group_id'"
                                                      [selectionAttributes]="'id'"
                                                      [selectedItems]="memberGroupListSelectedItems[i]">
                                                  </kt-dropdown-wo-lazyload>
                                              </div>
                                          </td>
                                          <td class="text-center align-content-center" style="width: 20%">
                                            <input type="number" min="1" (wheel)="false" step="1" onkeypress="return event.charCode >= 48" formControlName="position" class="form-control" [ngClass]="{'is-invalid': checkValidationVipContentSubPosition(i) && row.controls.position.value != null }">
                                          </td>
                                          <td class="text-center align-content-center" style="width: 5%">
                                            <label class="switch" style="align-self: center;">
                                              <input type="checkbox" formControlName="status">
                                              <div class="slider round" [ngClass]="{'div-disabled-switch': row.disabled }">
                                                  <span class="on">ON</span>
                                                  <span class="off">OFF</span>
                                              </div>
                                          </label>
                                          </td>
                                          <td class="text-center" style="width: 10%">
                                              <button *ngIf="row.value.mode === 'edit'" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="enableVipContentSupEdit(i)" [disabled]="!row.disabled || validationVipContentSupList()"><i class="fas fa-edit"></i></button>
                                              <button matTooltip="Save" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="onVipContentSubSave(row.value, i)" [disabled]="row.disabled || (!row.disabled && validationVipContentSupList(i)) || (checkValidationVipContentSubPosition(i) && row.controls.position.value != null)"><i class="fas fa-save"></i></button>
                                              <button *ngIf="row.enabled" matTooltip="Dismiss" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="onDismissVipContentSubRow(row.value.mode, i)"><i class="fas fa-times"></i></button>
                                          </td>
                                      </tr>
                                  </ng-container>
                              </ng-container>
                            </ng-container>
                          </tbody>
                          <tbody shared-table-handler [loading]="loading" [dataLength]="vipContentSubArr$.length || vipContentSubArr.controls.length >= 1" class="text-gray-600 fw-bold"></tbody>
                      </table>
                  </form>
                </div>
              </div>
          </div>
        </table>
      </ng-container>
      <div class="modal-footer m-0">
        <table class="info mr-3" *ngIf="modeFaqSection == 'edit'">
          <tr class="border-bottom-remove">
            <td class="header-hint">
              Updated by: {{ vipSection.updated_by ? vipSection.updated_by + '&nbsp;' : '-&nbsp;' }}
            </td>
            <td class="header-hint">
              <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="tooltip-class">{{ vipSection.updated_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #updatedDateTime>
                {{ vipSection.updated_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
              </ng-template>
            </td>
          </tr>
          <tr class="border-bottom-remove">
            <td class="header-hint">
              Created by: {{ vipSection.created_by ? vipSection.created_by + '&nbsp;' : '-&nbsp;' }}
            </td>
            <td class="header-hint">
              <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="tooltip-class">{{ vipSection.created_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #createdDateTime>
                {{ vipSection.created_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
              </ng-template>
            </td>
          </tr>
        </table>
        <button type="button" class="btn btn-danger m-0 mr-2" (click)="modeVipSection == 'create' ? expandRowVipSectionCreate('', true) : expandRowVipSectionEdit('', null, true)" [disabled]="validationVipContentSupList()">{{ 'Cancel' | translate }}</button>
        <button *ngIf="(modeVipSection == 'create' && canCreateVIPStructure) || (modeVipSection != 'create' && canEditVIPStructureDetails)" type="button" class="btn btn-success m-0" [disabled]="!formSection.valid || validationVipContentSupList()" (click)="onSaveVipSection()">{{ modeVipSection != null && modeVipSection == 'create' ? 'Create' : 'Update' | translate }}</button>
      </div>
    </div>
  </form>
</ng-template>

<kt-swal-alert [message]="messages$ | async" (confirmed)="reload()"></kt-swal-alert>
