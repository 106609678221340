import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { catchError, finalize } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';
import { Token } from '@core/models/token.model';
import { ip_address } from 'app/app.module';
import { environment } from '@env/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>, private matSnackBar: MatSnackBar) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {

    const apiBase: string = environment.apiBase;
    const isExternal: boolean = request.url.includes('gf.') ? true : request.url.startsWith('//');

    const {
      access_token,
      plaintext_token
    }: Token = JSON.parse(localStorage.getItem('user_token')) || { access_token: undefined, plaintext_token: undefined };

    if (access_token && !isExternal) {
      const apiEndpoint = request?.url.includes("?") ? request?.url.substring(1, request.url.length).split("?")[0] : request?.url.substring(1, request.url.length);
      let params = request.url.includes('livechat') || request.url.includes('operator') || request.url.includes('password') || request.url.includes('deposit/remarks') || request.url.includes('/deposit/receipt/request') || request.url.includes('memberaccountlabelsetting') ? request.url.substring(1) : null;
      const skippedPages = ['file', 'smscampaign', 'telemarketer/importcsv', 'promotion/bulkImportMember', 'telemarketer/importcsvvalidate', 'message/send', 'webpush/send', 'campaign/adcost/importcsv', 'reward/import', 'vipverification/import', params, 'changepassword', 'smsprovider/send'];
      request = request.clone({
        headers: request.headers
          .append('Authorization', `Bearer ${access_token}`)
          .append('access-token', `${access_token}`)
          .append('token-selector', `${plaintext_token}`)
          .append('X-User-Agent', window.navigator.userAgent)
          .append('X-Site-Prefix', environment.sitePrefix)
          .append('x-referrer', location.href),
        //Sanitize request string
        url: this.removeTags(request.url),
        body: skippedPages.includes(apiEndpoint) ? request.body : this.sanitizeObject(request.body)
      }
      );
    }

    if (!access_token && !isExternal) {
      request = request.clone({
        headers: request.headers
          .append('X-User-Agent', window.navigator.userAgent)
      }
      );
    }

    if (!isExternal && ip_address) {
      request = request.clone({
        headers: request.headers
          .append('user-ip', ip_address)
      }
      );
    }

    if (request.url.includes('reportv2')) {
      request = request.clone({
        headers: request.headers
          .append('x-site-ids', '1')
      });
    }

    request = request.clone({
      url: isExternal ? request.url : apiBase + request.url
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
      finalize(() => { })
    );

  }

  //Recursive sanitation method - base case: object is a string to sanitize or another type
  sanitizeObject(object: any) {
    //If API call is POST/PUT then body is not null, else only the request.url has to be sanitized
    if (object !== null) {
      //Cycle through each object attribute
      for (let key in object) {
        if (typeof object[key] === 'string') {
          //If the attribute value is a string, call method to remove tags
          object = {
            ...object,
            [key]: this.removeTags(object[key], key)
          }
        } else if (typeof object[key] === 'object') {
          //If the attribute is another object, method works recursively until it sees a string to sanitize
          object = {
            ...object,
            [key]: this.sanitizeObject(object[key])
          }
        } else {
          object = {
            ...object
          }
        }
      }
    }
    return object;
  }

  removeTags(stringValue: string, key?: string) {
    //If the request body object contains attributes from a content form field, it sends tags to the API as &lt; and &gt;
    let string = stringValue.replace('#', '%23'); // convert hashtag to string
    //Check whether string is from a content form field
    if (key && (key === "content" || key === "question" || key === "answer" || key === "message" || key === "text_content" || key === "description" || key === "text_value" )) {
      string = string.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "");
    } else if (key && (key === "website")) {
      string = string.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
    }else {
      //If not, remove all tags
      string = string.replace(/(<([^>]+)>)/gi, "");
    }
    return string;
  }
}
