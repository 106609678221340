import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, ChangeDetectorRef, Input } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { DepositDataService } from '../../services/deposit-data.service';
import { DepositLog } from '@core/models/deposit-log.model';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionStatus } from '@core/enums/transaction-status.enum';
import { AppPermissionService } from '@core/services/app-permission.service';
import { ViewReceiptComponent } from '../view-receipt/view-receipt.component';

@Component({
  selector: 'kt-deposit-log',
  templateUrl: './deposit-log.component.html',
  styleUrls: ['./deposit-log.component.scss']
})
export class DepositLogComponent implements OnInit, OnDestroy {

  @Input()
  deposit: any;

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  pagination: Pagination;
  private subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  loadMoreButtonLoading = false;
  messages$ = this.depositDataService.messages$;
  loading = false;
  status = TransactionStatus;
  dropdown = {
    perPage: this.dropdownHttpService.perPage,
  };
  pageSize = 6;
  page = 1;
  depositReceipts = [];
  allLogs: any[] = [];

  // permissions
  canViewReceipts: boolean;

  private subscriptions = new Subscription();

  constructor(
    private depositDataService: DepositDataService,
    public dialog: MatDialog,
    public loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.getReceipts();
    this.getTimeline();
    this.pagination = this.depositDataService.pagination;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewReceipts = this.deposit.is_crypto === 1 ?
        false :
        appPermissions.view_deposit_receipts;

      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  private getTimeline(paginate = true, page = 1, pageSize?: number) {
    this.loading = true;
    this.loadingBar.useRef().start();
    pageSize = this.pageSize;
    return this.depositDataService
      .getDepositLog(`?paginate=${paginate}&page=${page}&perPage=${pageSize}&deposit_id=${this.deposit.id}`)
      .pipe(
        tap(res => {
          this.allLogs = res;
          this.pagination = this.depositDataService.pagination;
          this.loadingBar.useRef().complete();
          this.loading = false;
          this.loadMoreButtonLoading = false;
        }),
        catchError((err) => {
          this.loading = false;
          this.loadingBar.useRef().complete();
          return throwError(err);
        })
      ).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { remarks?: any, deposit?: any }) {
    this.dialog.open(componentRef, {
      width: '800px',
      data: {
        remarks: data.remarks,
        deposit: data.deposit,
      }
    });
  }

  getReceipts() {
    this.depositDataService.getReceipt(this.deposit.id).pipe(
      map(res => {
        this.depositReceipts = res;
      })
    ).subscribe();
  }

  openReceipt() {
    let depositData = {
      ...this.deposit
    };
    depositData.receipts = this.depositReceipts;

    this.openDialogBy(ViewReceiptComponent, { deposit: depositData });
  }

  loadMoreLogs(): void {
    this.loadMoreButtonLoading = true;
    this.getTimeline(false);
  }

  getLighterColor(hex: string, percent: number = 30): string {
    if (!hex) return '#f7f8fa'; // Default to white if no color is provided
    hex = hex.replace('#', '');
  
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
  
    // Increase each channel value to make it lighter (but not exceed 255)
    r = Math.min(255, r + (255 - r) * (percent / 100));
    g = Math.min(255, g + (255 - g) * (percent / 100));
    b = Math.min(255, b + (255 - b) * (percent / 100));
  
    return `rgb(${r}, ${g}, ${b})`; // Return the lightened color
  }
}
