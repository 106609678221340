import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { GroupHttpService } from '@core/services/group-http.service';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AllAffiliatesDataService } from '../../services/all-affiliates-data.service';
import { UrlTestFireDialogComponent } from '../url-test-fire/url-test-fire.component';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-all-affiliates-edit',
  templateUrl: './all-affiliates-edit.component.html',
  styleUrls: ['./all-affiliates-edit.component.scss']
})
export class AllAffiliatesEditDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  buttonLoading = false;
  messages$ = this.allAffiliatesDataService.messages$;
  dropdownLoading = false;
  affiliateLoading = false;

  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    groups: [],
    statuses: this.dropdownHttpService.affiliateStatuses
  };
  affiliatesDropdownList = [];
  affiliatesSelectedItems:any = null;
  affiliatesDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'username',
    labelKey: 'username',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false,
    disabled: (this.data.mode === 'edit' && (this.data.affiliate.upline_id !== null || this.data.affiliate.affiliate_group_type == 1))
            // only allow edit Parent if no parent and  FTD affiliate group
  };

  affiliatePostbackVariables = [];

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  // permissions
  canAffiliatePostbackTestFire: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { affiliate: any, mode: string },
    public dialogRef: MatDialogRef<AllAffiliatesEditDialogComponent>,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private dropdownHttpService: DropdownHttpService,
    private groupHttpService: GroupHttpService,
    private authHttpService: AuthHttpService,
    public dialog: MatDialog,
    public appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    if (this.dropdown.currencies.length === 0) {
      this.dropdownHttpService.currencies.subscribe(res => {
        this.dropdown.currencies = res;
      });
    }

    this.allAffiliatesDataService.getAffiliatePostbackVariables().subscribe(res => {
      this.affiliatePostbackVariables = res;
    });

    this.formInit();
    if (this.data.mode === 'edit') {
      this.setGroupDropdown(this.data.affiliate.settings_currency_id);
      this.setParentDropdown(this.data.affiliate.settings_currency_id, this.data.affiliate.id);
    }

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canAffiliatePostbackTestFire = appPermissions.affiliate_postback_test_fire;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onCloseDialog(value = false) {
    this.dialogRef.close(value);
  }

  onSubmit(mode?: string) {
    this.buttonLoading = true;
    const data = {
      currency_id: this.data.mode === 'edit' ? this.data.affiliate.settings_currency_id : null,
      url: this.data.mode === 'create' ? window.location.href : null,
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || (data[key] === '' && key !== 'postback_domain')) && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.allAffiliatesDataService.update(this.data.affiliate.id, data).pipe(
          tap((res: any) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            this.messages$.next([...res.message]);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
        this.subscription = this.allAffiliatesDataService.add(data).pipe(
          tap((res: any) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            this.messages$.next([...res.message]);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
    }
  }

  onSelectCurrency(item: number) {
    this.dropdown.groups = [];
    this.form.patchValue({
      affiliate_group_id: null
    });
    this.setGroupDropdown(item);
    this.setParentDropdown(item); //create
    this.setMobileValidator(item);
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private setGroupDropdown(selectedCurrency?: number) {
    this.dropdownLoading = true;
    const params = `status=1&currency_id=${selectedCurrency}`
    this.groupHttpService.getAffiliateGroups(params).subscribe(res => {
      this.dropdown.groups = res;
      this.dropdownLoading = false;
    });
  }

  private setParentDropdown(selectedCurrency?: number, affiliate_id?: number) {
   this.affiliateLoading = true;
    this.allAffiliatesDataService.getAffiliates(selectedCurrency, affiliate_id).subscribe(
      res => {
        this.affiliatesDropdownList = res;
        this.affiliateLoading = false;
        this.affiliatesSelectedItems = this.affiliatesDropdownList.filter(x => x['id'] == this.data.affiliate.upline_id);
      }
    );
  }

  private formInit() {
    let name = null;
    let username = null;
    let currency = null;
    let affiliateGroup = null;
    let email = null;
    let mobile = null;
    let status = 1;
    let remarks = null;
    let password = null;
    let confirmPassword = null;
    let postbackMethod = 1;
    let postbackDomain = null;
    let ftdPostbackMethod = 1;
    let ftdPostbackDomain = null;
    let upline_id = null;
    let mobilePattern = this.dropdownHttpService.mobileRegex['MY'];

    if (this.data.mode === 'edit') {
      name = this.data.affiliate.name;
      username = this.data.affiliate.username;
      currency = this.data.affiliate.settings_currency_id;
      affiliateGroup = this.data.affiliate.affiliate_group_id;
      email = this.data.affiliate.email;
      mobile = this.data.affiliate.mobile;
      status = this.data.affiliate.status;
      remarks = this.data.affiliate.remarks;
      postbackMethod = this.data.affiliate.postback_method;
      postbackDomain = this.data.affiliate.postback_domain;
      ftdPostbackMethod = this.data.affiliate.ftd_postback_method;
      ftdPostbackDomain = this.data.affiliate.ftd_postback_domain;
      upline_id = this.data.affiliate.upline_id;

      const currencyObj = this.dropdown.currencies.find(currency => currency.id == this.data.affiliate.settings_currency_id);
      const currencyCode = currencyObj.name || 'MYR';
      const countryCode = this.dropdownHttpService.currencyToCountryMap[currencyCode] || this.dropdownHttpService.currencyToCountryMap['MYR'];
      mobilePattern = this.dropdownHttpService.mobileRegex[countryCode];
    }

    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      username: new FormControl({ value: username, disabled: this.data.mode === 'edit' ? true : false }, [Validators.required]),
      currency_id: new FormControl({ value: currency, disabled: this.data.mode === 'edit' ? true : false }, [Validators.required]),
      affiliate_group_id: new FormControl(affiliateGroup, [Validators.required]),
      mobile: new FormControl(mobile, [Validators.required, Validators.pattern(mobilePattern)]),
      email: new FormControl(email, [Validators.email]),
      status: new FormControl(status),
      remarks: new FormControl(remarks),
      postback_method: new FormControl(postbackMethod),
      postback_domain: new FormControl(postbackDomain, Validators.pattern('(https?:\\/\\/)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .\\-=:&?]*\\/?')),
      ftd_postback_method: new FormControl(ftdPostbackMethod),
      ftd_postback_domain: new FormControl(ftdPostbackDomain, Validators.pattern('(https?:\\/\\/)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .\\-=:&?]*\\/?')),
      password: new FormControl(password, this.data.mode === 'create' ? [Validators.required] : null),
      confirm_password: new FormControl(confirmPassword, this.data.mode === 'create' ? [Validators.required] : null),
      upline_id : new FormControl(upline_id)
    });
  }

  onSelectUsableVariable(usableVariable: String, type: String, input: any) {
    if (type == 'postback_domain') {
      this.form.patchValue({
        postback_domain: this.form.value.postback_domain != null ? this.form.value.postback_domain + usableVariable : usableVariable
      });
    } else if (type == 'ftd_postback_domain') {
      this.form.patchValue({
        ftd_postback_domain: this.form.value.ftd_postback_domain != null ? this.form.value.ftd_postback_domain + usableVariable : usableVariable
      });
    }
    input.focus();
  }

  postbackTest(type: 'registration' | 'ftd', value: string) {
    const dialogRef = this.dialog.open(UrlTestFireDialogComponent, {
      width: '50%',
      data: {
        id: this.data.affiliate.id,
        type: type,
        postbackMethod: type == 'registration' ? this.form.get('postback_method').value : this.form.get('ftd_postback_method').value,
        postbackUrl: value,
        affiliatePostbackVariables: this.affiliatePostbackVariables,
      }
    });
  }

  setMobileValidator(currency_id:number) {
    const currency = this.dropdown.currencies.find(currency => currency.id == currency_id);
    const currencyCode = currency.name || 'MYR';
    const countryCode = this.dropdownHttpService.currencyToCountryMap[currencyCode] || this.dropdownHttpService.currencyToCountryMap['MYR'];
    const selectedPattern = this.dropdownHttpService.mobileRegex[countryCode];

    this.form.controls['mobile'].setValidators([Validators.pattern(selectedPattern), Validators.required]);
    this.form.patchValue({
      mobile: null
    })
  }

}
