import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, ChangeDetectorRef, Input } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { Observable, Subscription, of } from 'rxjs';
import { tap, map, exhaustMap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Pagination } from '@core/models/pagination.model';
import { FormGroup, FormControl } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { MemberLabelSetting } from '@core/models/member-label-setting.model';
import { MemberLabelSettingsEditComponent } from './member-label-settings-edit/member-label-settings-edit.component';
import { MemberAccountLabelSettingHttpService } from '@core/services/member-account-label-setting-http.service';

@Component({
  selector: 'kt-member-label-settings',
  templateUrl: './member-label-settings.component.html',
  styleUrls: ['./member-label-settings.component.scss']
})
export class MemberLabelSettingsComponent implements OnInit, OnDestroy {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  private subscription = new Subscription();
  form: FormGroup;
  messages$ = this.memberAccountLabelSettingHttpService.messages$;
  loading = false;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  buttonLoading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;

  memberLabelSettings$: Observable<MemberLabelSetting[]>;

  sortingConfig = {
    'id': 'desc',
    'code': 'desc',
    'name': 'desc',
    'status': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    perPage: this.dropdownHttpService.perPage
};

  // permissions
  canCreateMemberLabelSettings: boolean;
  canEditMemberLabelSettings: boolean;

  private subscriptions = new Subscription();

  constructor(
    private memberAccountLabelSettingHttpService: MemberAccountLabelSettingHttpService,
    public dialog: MatDialog,
    public loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.pagination = this.memberAccountLabelSettingHttpService.pagination;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateMemberLabelSettings = appPermissions.create_label_settings;
      this.canEditMemberLabelSettings = appPermissions.edit_label_settings;
    });

    this.subscriptions.add(apSub);

    this.onSubmit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onOpenDialog(mode: string, id?: number) {
    switch (mode) {
      case 'create':
        this.openDialogBy(MemberLabelSettingsEditComponent, { mode: mode });
        break;
      case 'edit':
        this.memberAccountLabelSettingHttpService.getById(id).subscribe((res: any) => {
          this.openDialogBy(MemberLabelSettingsEditComponent, { mode: mode, labelSetting: res });
        });
        break;
    }
  }

  private openDialogBy(componentRef: any, data?: { mode: any, labelSetting?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        mode: data.mode,
        labelSetting: data.labelSetting,
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true){
        this.onViewPageBy();
      }
    });
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        this.memberLabelSettings$ = this.memberAccountLabelSettingHttpService.getWithQuery(`?perPage=${this.pageSize}${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.pagination = this.memberAccountLabelSettingHttpService.pagination;
            this.dataLength = res.length;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    this.memberLabelSettings$ = this.memberAccountLabelSettingHttpService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.pagination = this.memberAccountLabelSettingHttpService.pagination;
        this.dataLength = res.length;
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.loadingBar.complete();
      })
    );
  }

  public onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      name: null,
      keyword: null,
      status: 1
    });
    this.onSubmit(true);
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(null),
      keyword: new FormControl(null),
      status: new FormControl(1),
    });
  }
}
