import { tap, map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { Injectable } from '@angular/core';

@Injectable()
export class VipFooterSettingsDataService {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(private http: HttpClient) { }
    
  getVIPStructureSection() {
    return this.http.get<ApiResponse>('/vipfootersetting/section').pipe(
      map(res => res.data.rows)
    );
  }

  addSection(data: any) {
    return this.http.post<ApiResponse>(`/vipfootersetting/section`, data).pipe(
      tap(res => this.messages$.next(res.message))
    )
  }

  updateSection(id: number, data: any) {
    return this.http.put<ApiResponse>(`/vipfootersetting/section/${id}`, data).pipe(
      tap(res => this.messages$.next(res.message))
    )
  }

  getWithQuery(pageParam: string): Observable<[]> {
    return this.http.get<ApiResponse>(`/vipfootersetting${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => {

        Object.keys(res.data.vip_contents.rows).forEach(x => {
          let localeList = [];

          Object.keys(res.data.vip_contents.rows[x].details).forEach(y => {
            localeList.push(res.data.vip_contents.rows[x].details[y].locale_name);
          })
          res.data.vip_contents.rows[x].locale_list = localeList.toString().split(',').join(', ');
        })
        return res.data
      })
    );
  }

  add(data: any) {
    return this.http.post<ApiResponse>(`/vipfootersetting`, data).pipe(
      tap(res => this.messages$.next(res.message))
    )
  }

  update(id: number, data: any) {
    return this.http.put<ApiResponse>(`/vipfootersetting/${id}`, data).pipe(
      tap(res => this.messages$.next(res.message))
    )
  }

  updateStatus(param: any) {
    return this.http.put<ApiResponse>(`/vipfootersetting/statusupdate`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.vip_contents.paginations;
    }
  }
}
