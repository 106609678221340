import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Pagination } from '@core/models/pagination.model';
import { TelemarketerReport } from '@core/models/telemarketer-report.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { DepositDetailsComponent } from '../../general/deposits/dialogs/deposit-details/deposit-details.component';
import { DepositEntityService } from '../../general/deposits/services/deposit-entity.service';
import { TelemarketerReportDataService } from './services/telemarketer-report-data.service';
import { TimezoneDatePipe } from '@core/pipes/timezone-date.pipe';
import { Tiers } from '@core/enums/tiers.enum';
import { AppPermissionService } from '@core/services/app-permission.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'telemarketer-report',
  templateUrl: './telemarketer-report.component.html',
  styleUrls: ['./telemarketer-report.component.scss'],
})
export class TelemarketerReportComponent implements OnInit, OnDestroy {
  @ViewChildren('filterInput') filterInput: QueryList<ElementRef>;
  form: FormGroup;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  pagination: Pagination;
  tiers = Tiers;

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  timezoneSelectedItems: any;
  selectedTimezone: any;
  timezoneDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown clear-all-disabled',
    primaryKey: 'offset',
    labelKey: 'offset',
    noDataLabel: '',
    showCheckbox: false
  };
  dropdown = {
    timezones: JSON.parse(localStorage.getItem('timezones')),
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    telemarketer: this.dropdownHttpService.telemarketer,
    perPage: this.dropdownHttpService.perPage,
    sourceTypes: this.dropdownHttpService.sourceTypes,
    statuses: [
      {
        name: 'Expired', value: 2, checked: true
      },
      {
        name: 'Closed', value: 0, checked: true
      },
      {
        name: 'Partially Closed', value: 3, checked: true
      },
      {
        name: 'Failed', value: 4, checked: true
      },
      {
        name: 'Pending Justification', value: 6, checked: true
      },
      {
        name: 'Rejected Justification', value: 7, checked: true
      },
    ],
    tier_statuses: [
      {
        name: 'On Going', value: 0, checked: true
      },
      {
        name: 'Ended', value: 1, checked: true
      },
    ],
  };
  buttonLoading = false;
  messages$ = this.telemarketerReportDataService.messages$;

  telemarketerReport$: Observable<TelemarketerReport[]>;
  params = ``;
  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;
  currentContact = '';
  selectedContactType = 'Mobile';
  searchStatus = [0, 2, 3, 4, 6, 7];
  searchTierStatus = [0, 1];

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  datePickerSubscription: Subscription;
  ranges: any;

  default_sort_by = 'FTD_date';
  default_sort_order = 'asc';

  sortingConfig = {
    'lead_id': 'desc',
    'telemarketer': 'desc',
    'username': 'desc',
    'mobile': 'desc',
    'source_type': 'desc',
    'source_account': 'desc',
    'campaign': 'desc',
    'lead_provision_date': 'desc',
    'FTD_id': 'desc',
    'FTD_date': 'desc',
    'currency': 'desc',
    'FTD_amount': 'desc',
    'first_call_time': 'desc',
    'last_call_time': 'desc',
    'tier': 'desc',
    'tier_status': 'desc',
    'status': 'desc',
    'completed_date': 'desc',
    'updated_at': 'desc',
  };

  sortingSelection = {
    'sort_by': this.default_sort_by,
    'sort_order': this.default_sort_order,
  };

  access$ = this.authHttpService.getUserAccess(13, 'Telemarketer Report');
  isAdmin: boolean = JSON.parse(localStorage.getItem('user_data')).is_admin;

  // permissions
  // telemarketerReportUseNewPermissions: boolean;
  canViewDepositDetails: boolean;
  canExportTelemarketerReport: boolean;

  constructor(
    private telemarketerReportDataService: TelemarketerReportDataService,
    private transactionHttpService: TransactionHttpService,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private router: Router,
    private depositEntityService: DepositEntityService,
    public dialog: MatDialog,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private timeZoneDate: TimezoneDatePipe,
    private cdr: ChangeDetectorRef,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {

    if (this.dropdown.currencies.length === 0) {
      this.dropdownHttpService.currencies.subscribe(res => {
        this.dropdown.currencies = res;
      });
    }

    this.formInit();
    this.ranges = this.transactionHttpService.ranges;
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.selectedTimezone = this.timezone;
    // this.dropdownSettings = {
    //   singleSelection: true,
    //   text: 'Please Select',
    //   enableFilterSelectAll: false,
    //   enableSearchFilter: true,
    //   classes: 'dropdown',
    //   primaryKey: 'username',
    //   labelKey: 'username',
    //   lazyLoading: true,
    //   noDataLabel: '',
    //   showCheckbox: false
    // };

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewDepositDetails = appPermissions.telemarketer_report_view_deposit_details;
      this.canExportTelemarketerReport = appPermissions.export_telemarketer_report;
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }
    this.sortingSelection.sort_by = property;
    this.sortingSelection.sort_order = this.sortingConfig[property];
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date_time: event.startDate !== null ? event.startDate._d : event.startDate,
        end_date_time: event.endDate !== null ? event.endDate._d : event.endDate,
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.selectedContactType = 'Mobile';
    this.searchStatus = [0, 2, 3, 4, 6, 7];
    this.searchTierStatus = [0, 1];
    this.dropdown.tier_statuses.forEach((child) => {
      child.checked = true;
    });
    this.dropdown.statuses.forEach((child) => {
      child.checked = true;
    });
    this.form.patchValue({
      telemarketer: null,
      username: null,
      mobile: null,
      email: null,
      settings_currency_id: 'all',
      source_type: null,
      source_account: null,
      campaign_name: null,
      campaign_code: null,
      tier: 'all',
      date_type: 'lead_provision_date',
      start_date_time: null,
      end_date_time: null,
      defaultDate: null,
      tier_status: this.searchTierStatus,
      lead_status: this.searchStatus,
      timezone: this.timezone,
      dummy: 1
    });
    this.form.controls.source_account.disable();
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        tap((data) => {
          if (data['start_date_time']) {
            data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
            data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          }
          // if (data['start_date_time']) {
          //   data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).format('YYYY-MM-DD HH:mm:ss');
          //   data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).format('YYYY-MM-DD HH:mm:ss');
          // }

          delete (data['timezone']);

          this.params = Object.keys(data)
            .map((key) => key + "=" + data[key])
            .join("&");
          this.generateStatusAndTierStatusParams();
          this.sortingConfig[this.sortingSelection.sort_by] = 'desc';
          this.sortingSelection.sort_by = this.default_sort_by;
          this.sortingSelection.sort_order = this.default_sort_order;
          this.sortingConfig[this.default_sort_by] = this.default_sort_order;
          this.onViewPageBy();
        })
      )
      .subscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : "";
    this.loadingBar.start();
    return (this.telemarketerReport$ = this.telemarketerReportDataService
      .getWithQuery(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`)
      .pipe(
        map(
          (res) => {
            return res.map((report: TelemarketerReport) => ({
              ...report
            }));
          }
        ),
        tap((res) => {
          this.dataLength = res.length;
          this.pagination = this.telemarketerReportDataService.pagination;
          this.loadingBar.complete();
          this.onSearch = true;
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
        }),
        catchError((err) => {
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
          this.loadingBar.complete();
          return throwError(err);
        })
      ));
  }

  calculateTier(amount: number, currency: string): string {
    if (currency == 'IDR') {
      if (amount >= 150000) {
        return 'Tier 3';
      } else if (amount < 150000 && amount >= 100000) {
        return 'Tier 2';
      } else if (amount < 100000 && amount >= 5000) {
        return 'Tier 1';
      } else {
        return 'Tier 0';
      }
    } else {
      if (amount >= 300) {
        return 'Tier 3';
      } else if (amount < 300 && amount >= 200) {
        return 'Tier 2';
      } else if (amount < 200 && amount >= 100) {
        return 'Tier 1';
      } else {
        return 'Tier 0';
      }
    }
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(id: number) {
    if (!this.canViewDepositDetails) {
      Swal.fire('Permission Denied', 'You do not have permission to view deposit details.', 'error');
      return;
    }

    this.subscription = this.depositEntityService.getByKey(id).subscribe((res) => {
      if (res.bo_user_id <= 0 || res.status === 0 || res.status === 3) {
        const data = {
          id: res.id,
          status: 3
        };
        this.depositEntityService.update(data);
      }
      this.openDialogBy(DepositDetailsComponent, { deposit: res, mode: 'edit' });
    });
  }

  onExport() {
    this.buttonLoading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        data['paginate'] = false;

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.generateStatusAndTierStatusParams();
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start(); var filename = 'MarketingReport_' + moment(this.form.get('start_date_time').value).format('YYYYMMDD') + '-' + moment(this.form.get('end_date_time').value).format('YYYYMMDD') + '.xlsx';
        return this.telemarketerReportDataService.exportReport(`?${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.buttonLoading = false;
          }),
          catchError((err) => {
            this.buttonLoading = false;
            this.loadingBar.complete();
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private openDialogBy(componentRef: any, data?: { deposit?: any, mode?: any, timezone?: any }) {
    data['timezone'] = this.form.value.timezone;
    const dialogRef = this.dialog.open(componentRef, {
      width: '1200px',
      data: {
        deposit: data.deposit,
        timezone: data.timezone,
        mode: data.mode
      },
      autoFocus: data.mode === false
    });
    dialogRef.afterClosed().subscribe(res => {
      this.onViewPageBy(this.page);
    });
  }

  private formInit() {
    this.form = new FormGroup({
      telemarketer: new FormControl(null),
      username: new FormControl(null),
      mobile: new FormControl(null),
      email: new FormControl(null),
      settings_currency_id: new FormControl('all'),
      tier: new FormControl('all'),
      date_type: new FormControl('lead_provision_date'),
      start_date_time: new FormControl(null),
      end_date_time: new FormControl(null),
      defaultDate: new FormControl(null),
      tier_status: new FormControl(this.searchTierStatus),
      lead_status: new FormControl(this.searchStatus),
      timezone: new FormControl(this.timezone),
      source_type: new FormControl(null),
      source_account: new FormControl({value: null, disabled :true}),
      campaign_name: new FormControl(null),
      campaign_code: new FormControl(null),
      dummy : new FormControl(1)
    });
  }

  approvedDeposits(username: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/general/deposits`])
    );

    localStorage.setItem('usernameFromTelemarketerReport', username);
    window.open(url, '_blank');
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach((key) => {
      if (
        formData[key] !== '' &&
        formData[key] !== null &&
        formData[key] !== undefined &&
        key !== 'defaultDate' &&
        formData[key] !== 'all'
      ) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }
  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onChangeTimezone(item: any) {
    if (item.length) {
      this.selectedTimezone = item[0]['timezone'];
    }
    else {
      this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.selectedTimezone);
      this.form.patchValue({
        timezone: this.selectedTimezone
      });
    }
  }

  timezoneDateWithTimezone(date: any, timezone: any, format: any) {
    return this.timeZoneDate.transformWithTimezone(date, timezone, format);
  }

  onContactType(label: string) {
    this.selectedContactType = label;
    this.contactChanges();
    this.cdr.detectChanges();
  }

  onContact(event: Event) {
    this.currentContact = (event.target as HTMLInputElement).value;
    this.contactChanges();
    this.cdr.detectChanges();
  }

  private contactChanges() {
    if (this.selectedContactType !== '' || this.selectedContactType !== null) {

      if (this.selectedContactType === 'Mobile') {
        this.form.patchValue({
          [this.selectedContactType.toLocaleLowerCase()]: this.currentContact,
          email: null,
        });
      }
      if (this.selectedContactType === 'Email') {
        this.form.patchValue({
          mobile: null,
          [this.selectedContactType.toLocaleLowerCase()]: this.currentContact,
        });
      }
    }
  }

  onClearDate() {
    if (this.form.value.start_date_time !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  private generateStatusAndTierStatusParams() {
    const statusParams = this.generateStatusParams();
    this.params = this.params.replace(statusParams.oldStatusParams, statusParams.newStatusParams);

    const tierStatusParams = this.generateTierStatusParams();
    this.params = this.params.replace(tierStatusParams.oldStatusParams, tierStatusParams.newStatusParams);
  }

  onChangeTierStatus(event: any, id: number, ind: number) {
    const position = this.searchTierStatus.indexOf(id);
    if (event.target.checked) {
      this.searchTierStatus.push(id);
      this.dropdown.tier_statuses[ind].checked = true;
    } else {
      this.searchTierStatus.splice(position, 1);
      this.dropdown.tier_statuses[ind].checked = false;
    }
    this.form.patchValue({ tier_status: this.searchTierStatus });
  }

  onChangeStatus(event: any, id: number, ind: number) {
    const position = this.searchStatus.indexOf(id);
    if (event.target.checked) {
      this.searchStatus.push(id);
      this.dropdown.statuses[ind].checked = true;
    } else {
      this.searchStatus.splice(position, 1);
      this.dropdown.statuses[ind].checked = false;
    }
    this.form.patchValue({ status: this.searchStatus });
  }

  private generateTierStatusParams() {
    return {
      newStatusParams: Object.keys(this.searchTierStatus).map(key => `tier_status[${key}]=${this.searchTierStatus[key]}`).join('&'),
      oldStatusParams: 'tier_status=' + Object.keys(this.searchTierStatus).map(key => this.searchTierStatus[key]).join(','),
    };
  }

  private generateStatusParams() {
    return {
      newStatusParams: Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&'),
      oldStatusParams: 'lead_status=' + Object.keys(this.searchStatus).map(key => this.searchStatus[key]).join(','),
    };
  }

  onSelectSourceType(event: Event) {
    let selectedSourceType = (event.target as HTMLInputElement).value;
    if (selectedSourceType !== null && selectedSourceType !== 'null' && parseInt(selectedSourceType) != 3) {
      this.form.controls.source_account.enable();
    } else {
      this.form.patchValue({source_account: null});
      this.form.controls.source_account.disable();
    }
  }

  onChangeDummy($event) {
    let isChecked = $event.target.checked ? 1 : 0;
    this.form.patchValue({
      dummy: isChecked
    });
  }
}
