<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-100" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit Member Details' : 'Create Member' }}</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="row">
          <!-- Account Information Section -->
          <div [class]="data.mode === 'create' ? 'col-xl-6 col-lg-6 col-sm-12 col-md-6 p-0' : 'col-xl-4 col-lg-6 col-sm-12 col-md-6 p-0'">
            <section class="col card border-0">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  Account Information
                </div>
                <section class="col-12 p-4">
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Currency <span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <select formControlName="currency_id" class="form-control" (change)="onChangeCurrency($event)">
                        <option [value]="null" [disabled]="true">Please Select</option>
                        <option *ngFor="let value of dropdown.currencies" [value]="value.id">{{ value.name }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Username <span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <input
                        type="text"
                        formControlName="username"
                        (input)="toLowerCaseInput('username', $event); onInput('username', $event)"
                        [ngClass]="{'is-invalid': showValidationErr['username'] && form.controls.username.errors }"
                        class="form-control"
                      >
                    </div>
                  </div>

                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Password <span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <div class="input-group">
                        <input
                          [type]="showPassword ? 'text' : 'password'"
                          formControlName="password"
                          class="form-control"
                          [ngClass]="{'is-invalid': showValidationErr['password'] && form.controls.password.errors }"
                          (input)="onInput('password', $event)"
                        >
                        <div class="input-group-append">
                          <span class="input-group-text" (click)="togglePasswordVisibility()">
                            <i class="fas" [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Member Group <span class="text-danger">*</span></span>
                      <i
                        class="fas fa-exclamation-circle"
                        matTooltip="Create member group adjustment in 8.1 All VIPs."
                        matTooltipHideDelay="0"
                      ></i>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <div class="row px-2">
                        <select class="col form-control" formControlName="member_group_id">
                          <option [value]="null" [disabled]="true">Please Select</option>
                          <option [value]="value.id" *ngFor="let value of dropdown.groups | async">{{ value.name }}</option>
                        </select>
                        <button *ngIf="data.mode === 'edit'" class="col-6 btn btn-primary" (click)="navigateToAllVips()" style="background-color: #0079FE; border-color: #0079FE" type="button">8.1 All VIPs</button>
                      </div>
                    </div>
                  </div>

                  <div class="row border-top border-left" *ngIf="data.mode !== 'create'">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">
                        Dummy Account
                        <i
                          class="fas fa-exclamation-circle"
                          matTooltip="
                            Checked to create the member as a test account.

                            Test accounts are often used for trial purposes or to simulate user experiences without affecting real data.
                          "
                          matTooltipHideDelay="0"
                        ></i>
                      </span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <div class="d-flex align-items-center">
                        <mat-slide-toggle
                          class="modern-slide-toggle"
                          formControlName="dummy"
                          (change)="onSlideToggle($event, 'dummy')"
                          [checked]="data.member && data.member.dummy === 1"
                          [disabled]="disabledDummy">
                        </mat-slide-toggle>
                      </div>
                    </div>
                  </div>

                  <div class="row border-top border-left border-bottom">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Status <span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <select class="form-control" formControlName="status">
                        <option *ngFor="let value of dropdown.statuses; let i = index" [value]="i">{{ value }}</option>
                      </select>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>

          <!-- Member Information Section -->
          <div [class]="data.mode === 'create' ? 'col-xl-6 col-lg-6 col-sm-12 col-md-6 p-0' : 'col-xl-4 col-lg-6 col-sm-12 col-md-6 p-0'">
            <section class="col card border-0">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  Member Information
                </div>
                <section class="col-12 p-4">
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Name <span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <input type="text" formControlName="name" class="form-control" (input)="onInput('name', $event)" [ngClass]="{'is-invalid': showValidationErr['name'] && form.controls.name.errors }">
                      <div class="invalid-feedback" *ngIf="form.get('name')?.errors && form.get('name')?.errors['pattern']">
                        Name should contain only letters
                      </div>
                    </div>
                  </div>

                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Mobile <span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <div class="input-group">
                        <input type="text" formControlName="mobile" class="form-control" (input)="onInput('mobile', $event)" [ngClass]="{'is-invalid': showValidationErr['mobile'] && form.controls.mobile.errors }">
                        <div class="input-group-append" *ngIf="data.mode === 'edit'">
                          <span class="input-group-text">
                            <label class="checkbox checkbox-inline checkbox-primary mb-0">
                              <input matTooltip="Verified" type="checkbox" 
                                     (change)="onCheckbox($event, 'mobile_status')" 
                                     [checked]="data.member && data.member.mobile_status === 1" 
                                     formControlName="mobile_status">
                              <span></span>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Email</span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <div class="input-group">
                        <input
                          type="text"
                          formControlName="email"
                          class="form-control"
                          [ngClass]="{'is-invalid': form.controls.email.errors }"
                          (input)="onEmailInput($event)"
                        >
                        <div class="input-group-append" *ngIf="data.mode === 'edit'">
                          <span class="input-group-text">
                            <label class="checkbox checkbox-inline checkbox-primary mb-0">
                              <input matTooltip="Verified" type="checkbox" 
                                     (change)="onCheckbox($event, 'email_status')" 
                                     [checked]="data.member && data.member.email_status === 1" 
                                     formControlName="email_status">
                              <span></span>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Date of Birth</span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <div class="input-group date" [owlDateTimeTrigger]="dateStartRef">
                        <input class="hidden" [max]="maxDate"  [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack" [ngModelOptions]="{standalone: true}">
                        <input class="form-control" formControlName="date_of_birth" [ngClass]="{'is-invalid': form.controls.date_of_birth.errors }">
                        <div class="input-group-append">
                          <span class="input-group-text pointer-calendar">
                            <i class="far fa-calendar-alt"></i>
                          </span>
                        </div>
                        <owl-date-time #dateStartRef [disabled]="!modify_dob"></owl-date-time>
                      </div>
                    </div>
                  </div>

                  <div class="row border-top border-left border-bottom">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Traffic Source <span class="text-danger">*</span></span>
                    </div>

                    <div class="col-md-8 border-right p-3">
                      <!-- Traffic Source Dropdown -->
                      <select
                        class="form-control mb-2"
                        formControlName="traffic_source"
                        (change)="onTrafficSourceChange($event)"
                      >
                        <option [value]="null" [disabled]="true">Please Select</option>
                        <option [value]="value.id" *ngFor="let value of dropdown.traffic_sources | async">
                          {{ value.name }}
                        </option>
                      </select>

                      <!-- Referral Input -->
                      <div class="input-group mb-2" *ngIf="form.get('traffic_source').value == 1">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="fas fa-user"></i>
                          </span>
                        </div>
                        <input type="text" 
                              class="form-control"
                              [ngClass]="{'is-invalid': form.get('referral_input').errors?.invalid}"
                              formControlName="referral_input"
                              (input)="onValidateTrafficSourceInput($event, 'referral_input')"
                              placeholder="Enter Referral Code or Username">
                          <div class="invalid-feedback" *ngIf="form.get('referral_input').errors?.invalid">
                            {{ form.get('referral_input').errors.invalid }}
                          </div>
                      </div>

                      <!-- Affiliate Inputs -->
                      <ng-container *ngIf="form.get('traffic_source').value == 2 || form.get('traffic_source').value == 3">
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-user"></i>
                            </span>
                          </div>
                          <input type="text" 
                                class="form-control"
                                [ngClass]="{'is-invalid': form.get('affiliate_code').errors?.invalid}"
                                formControlName="affiliate_code"
                                (input)="onValidateTrafficSourceInput($event, 'affiliate_code')"
                                placeholder="Enter Affiliate Code or Username">
                          <div class="invalid-feedback" *ngIf="form.get('affiliate_code').errors?.invalid">
                            {{ form.get('affiliate_code').errors.invalid }}
                          </div>
                        </div>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-globe"></i>
                            </span>
                          </div>
                          <input type="text" 
                                class="form-control"
                                [ngClass]="{'is-invalid': form.get('affiliate_campaign').errors?.invalid}"
                                formControlName="affiliate_campaign"
                                (input)="onValidateTrafficSourceInput($event, 'affiliate_campaign')"
                                placeholder="Enter Affiliate Campaign Code">
                          <div class="invalid-feedback" *ngIf="form.get('affiliate_campaign').errors?.invalid">
                            {{ form.get('affiliate_campaign').errors.invalid }}
                          </div>
                        </div>
                      </ng-container>

                      <!-- Campaign Input -->
                      <div class="input-group mb-2" *ngIf="form.get('traffic_source').value == 4">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="fas fa-globe"></i>
                          </span>
                        </div>
                        <input type="text" 
                              class="form-control"
                              [ngClass]="{'is-invalid': form.get('campaign_code').errors?.invalid}"
                              formControlName="campaign_code"
                              (input)="onValidateTrafficSourceInput($event, 'campaign_code')"
                              placeholder="Enter Campaign Code">
                        <div class="invalid-feedback" *ngIf="form.get('campaign_code').errors?.invalid">
                          {{ form.get('campaign_code').errors.invalid }}
                        </div>
                      </div>

                      <!-- Telemarketer Input -->
                      <div class="input-group mb-2" *ngIf="form.get('traffic_source').value == 5">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="fas fa-user"></i>
                          </span>
                        </div>
                        <input type="text" 
                              class="form-control"
                              [ngClass]="{'is-invalid': form.get('telemarketer_username').errors?.invalid}"
                              formControlName="telemarketer_username"
                              (input)="onValidateTrafficSourceInput($event, 'telemarketer_username')"
                              placeholder="Enter Telemarketer Username">
                        <div class="invalid-feedback" *ngIf="form.get('telemarketer_username').errors?.invalid">
                          {{ form.get('telemarketer_username').errors.invalid }}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>

          <!-- Member Settings Section -->
          <div *ngIf="data.mode !== 'create'" class="col-xl-4 col-lg-6 col-sm-12 col-md-6 p-0">
            <section class="col card border-0">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  Member Settings
                </div>
                <section class="col-12 p-4">
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">SMS Subscription</span>
                    </div>

                    <div class="col-md-8 border-right p-3 sms_sub-container">
                      <mat-slide-toggle
                        class="modern-slide-toggle"
                        formControlName="sms_sub"
                        [checked]="true"
                        [disabled]="true">
                      </mat-slide-toggle>

                      <div *ngIf="form.get('sms_sub')?.value === 1" class="mt-3 w-100 sms-sub-wrap">
                        <kt-dropdown-wo-lazyload
                          [form]="form"
                          [dropdownList]="smsSubscriptionDropdownList"
                          [dropdownSettings]="smsSubscriptionDropdownSettings"
                          [formName]="'sms_sub_options'"
                          [selectionAttributes]="'id'"
                          [selectedItems]="smsSubscriptionSelectedItems"
                        >
                        </kt-dropdown-wo-lazyload>
                      </div>
                    </div>
                  </div>

                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Push Notification</span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <div class="d-flex align-items-center">
                        <mat-slide-toggle
                          class="modern-slide-toggle"
                          formControlName="push_notification"
                          (change)="onSlideToggle($event, 'push_notification')"
                          [checked]="data.member?.push_notification === 1">
                        </mat-slide-toggle>
                      </div>
                    </div>
                  </div>

                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Email Notification</span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <div class="d-flex align-items-center">
                        <mat-slide-toggle
                          class="modern-slide-toggle"
                          formControlName="email_notification"
                          (change)="onSlideToggle($event, 'email_notification')"
                          [checked]="data.member?.email_notification === 1">
                        </mat-slide-toggle>
                      </div>
                    </div>
                  </div>

                  <div class="row border-top border-left border-bottom">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Label and Remarks</span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <button type="button" class="btn manage-label-remarks-btn" (click)="onOpenDialog()">
                        <i class="fas fa-user-cog mr-1"></i> Manage
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()">
          <i class="fas fa-ban"></i> Close
        </button>
        <kt-submit-button *ngIf="data.mode == 'edit' ? (canEditMemberDetailsRestricted || canEditMemberDetails) : canCreateNewMember" 
                         [isDisabled]="!form.valid" 
                         [buttonLoading]="buttonLoading" 
                         (confirmed)="onSave(data.member, data.mode)">
          Submit
        </kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>


<!-- Old member profile, temporarily kept for reference first, may remove later -->
<!-- <div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ data.mode === 'edit' ? 'Member Details' : 'Create Member' }} </h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
            <div>
                <div class="row">
                    <div class="col-md-6">

                      <div class="col-12 form-group row">
                        <label class="col-4 col-form-label"> Username <span class="text-danger">*</span></label>
                        <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" class="col-8 form-control datetime">
                      </div>

                      <div class="col-12 form-group row">
                        <label class="col-4 col-form-label">Currency <span class="text-danger">*</span></label>
                        <select formControlName="currency_id" class="col-8 form-control">
                          <option [value]="null" [disabled]="true">Please Select</option>
                          <option *ngFor="let value of dropdown.currencies" [value]="value.id">{{ value.name }}</option>
                        </select>
                      </div>

                      <div class="col-12 form-group row">
                        <label class="col-4 col-form-label">Mobile <span class="text-danger">*</span></label>
                        <div class="col-8 pl-0 pr-0">
                          <div class="input-group">
                            <input type="text" formControlName="mobile" class="form-control">
                            <div class="input-group-append" *ngIf="data.mode === 'edit'">
                              <span class="input-group-text">
                                <label class="checkbox checkbox-inline checkbox-primary mb-0">
                                  <input matTooltip="Verified" type="checkbox" (change)="onCheckbox($event, 'mobile_status')" [checked]="data.member && data.member.mobile_status === 1 ? true : false" formControlName="mobile_status">
                                  <span></span>
                                </label>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 form-group row">
                        <label class="col-4 col-form-label">Date of Birth</label>
                        <div class="col-8 input-group date" [owlDateTimeTrigger]="dateStartRef" >
                          <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack" [ngModelOptions]="{standalone: true}">
                          <input type="text" formControlName="date_of_birth" class="form-control">
                          <span class="input-group-append">
                              <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                          </span>
                          <owl-date-time #dateStartRef [disabled]="!modify_dob"></owl-date-time>
                        </div>
                      </div>

                      <div class="col-12 form-group row">
                        <label class="col-4 col-form-label">Agent</label>
                        <div class="col-8" style="padding:0px!important; margin:0px!important">
                          <kt-member-dropdown [form]="form"
                          [isAgent]="true" [dropdownSettings]='dropdownSettings' [formName]="'agent_id'"
                          [selectionAttributes]="'id'" [selectedItems]="selectedItems" [agentsAgentOnly]="true"></kt-member-dropdown>
                        </div>
                      </div>

                      <ng-container *ngIf="sqsEnable; else elseTemplate">
                        <div class="col-12 form-group row" *ngIf="data.mode === 'edit'">
                          <label class="col-4 col-form-label">Label and Remarks</label>
                          <div class="col-8" style="padding:0px!important; margin:0px!important">
                            <button type="button" class="btn btn-secondary" (click)="onOpenDialog()">Add</button>
                          </div>
                      </div>
                      </ng-container>
                      <ng-template #elseTemplate>
                        <div class="col-12 form-group row">
                          <label class="col-4 col-form-label">Remarks</label>
                          <input type="text" formControlName="remarks" class="col-8 form-control">
                        </div>
                      </ng-template>      
                    </div>

                    <div class="col-md-6">
                        <div class="col-12 form-group row">
                          <label class="col-4 col-form-label">Name <span class="text-danger">*</span></label>
                          <input type="text" formControlName="name"  class="col-8 form-control">
                        </div>
                        <div class="col-12 form-group row">
                          <label class="col-4 col-form-label">Group <span class="text-danger">*</span></label>
                          <select formControlName="member_group_id" class="col-8 form-control">
                            <option [value]="null" [disabled]="true">Please Select</option>
                            <option [value]="value.id" *ngFor="let value of dropdown.groups | async">
                                {{ value.name }}
                            </option>
                          </select>
                        </div>

                        <div class="col-12 form-group row">
                            <label class="col-4 col-form-label">Email</label>
                            <div class="col-8 pl-0 pr-0">
                              <div class="input-group">
                                <input type="text" formControlName="email" class="form-control">
                                <div class="input-group-append" *ngIf="data.mode === 'edit'">
                                  <span class="input-group-text">
                                    <label class="checkbox checkbox-inline checkbox-primary mb-0">
                                      <input matTooltip="Verified" type="checkbox" (change)="onCheckbox($event, 'email_status')" [checked]="data.member && data.member.email_status === 1 ? true : false" formControlName="email_status">
                                      <span></span>
                                    </label>
                                  </span>
                                </div>
                              </div>
                            </div>
                        </div>

                        <div class="col-12 form-group row" *ngIf="data.mode === 'edit'">
                          <label class="col-4 col-form-label">Status</label>
                          <select class="form-control col-8" formControlName="status">
                            <ng-container *ngFor="let value of dropdown.statuses; let i = index;">
                              <option [value]="i">{{ value }}</option>
                            </ng-container>
                          </select>
                        </div>

                        <div class="col-12 form-group row">
                          <label class="col-4 col-form-label">Referrer</label>
                          <input type="text" formControlName="referrer" class="col-8 form-control" [readonly]="data.member && (data.member.last_deposit !== null || data.member.refer_by_id !== null) ? 'readonly' : ''">
                        </div>

                        <div class="col-12 form-group row" *ngIf="data.mode === 'edit' && !sqsEnable">
                          <label class="col-4 col-form-label">Suspicious</label>
                          <div class="col-8">
                            <input type="checkbox" formControlName="suspicious" (change)="onCheckbox($event, 'suspicious')" [checked]="data.member.suspicious === 1 ? true : false" style="margin: 10px 0 0 -10px !important;">
                          </div>
                        </div>

                        <div class="col-12 form-group row" *ngIf="data.mode === 'edit'">
                          <label class="col-4 col-form-label">SMS Subscription</label>
                          <div class="col-8">
                            <input type="checkbox" formControlName="sms_sub" (change)="onSmsSubscription($event)" [checked]="data.member.sms_sub === 1 ? true : false" style="margin: 10px 0 0 -10px !important;">
                          </div>
                        </div>

                        <div class="col-12 form-group row" *ngIf="canCreateDummyAccount || canEditDummyAccount">
                          <label class="col-4 col-form-label">Dummy<i class="fa fa-info-circle ml-2" placement="top" [ngbTooltip]="linkToolTip" tooltipClass="tooltip-class"></i></label>
                          <div class="col-8">
                            <input type="checkbox" formControlName="dummy" (change)="onCheckbox($event, 'dummy')" [checked]="data.member && data.member.dummy === 1 ? true : false" [attr.disabled]="disabledDummy ? 'disabled' : null " style="margin: 10px 0 0 -10px !important;">
                          </div>
                          <ng-template #linkToolTip>
                            <div class="text-left">
                              Checked to create the member as a test account.<br><br>
                              Test accounts are often used for trial purposes or to simulate user experiences without affecting real data. 
                            </div>
                          </ng-template>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="data.member && data.mode === 'edit'">
                  <hr>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="col-12 form-group row">
                       <label class="col-4">Created At: </label>
                       <span class="col-8 mt-10"> {{ data.member.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }} </span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="col-12 form-group row">
                        <label class="col-4">Last Login: </label>
                        <span class="col-8 mt-10"> {{ data.member.last_login ? (data.member.last_login | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }} </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="col-12 form-group row">
                       <label class="col-4">Last <br />Login IP: </label>
                       <span class="col-8 mt-10"> {{ data.member.last_login_ip ? data.member.last_login_ip : '-'}} </span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="col-12 form-group row">
                       <label class="col-4">Last Login <br />Location: </label>
                       <span class="col-8 mt-10"> {{ data.member.last_login_location ? data.member.last_login_location : '-' }} </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="col-12 form-group row">
                       <label class="col-4">Last Deposit: </label>
                       <span class="col-8 mt-10"> {{ data.member.last_deposit ? (data.member.last_deposit | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }} </span>
                      </div>
                    </div>
                  </div>
                </ng-container>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button *ngIf="data.mode == 'edit' ? (canEditMemberDetailsRestricted || canEditMemberDetails) : canCreateNewMember" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.member, data.mode)"></kt-submit-button>
        </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert> -->
