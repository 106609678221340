import { Component, Inject, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Rebate } from '@core/models/rebate.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import moment from 'moment';
import { forkJoin, Subscription } from 'rxjs';
import { NewRebateReleaseDataService } from '../../services/new-rebate-release-data.service';

@Component({
  selector: 'kt-new-rebate-generate',
  templateUrl: './new-rebate-generate.component.html',
  styleUrls: ['./new-rebate-generate.component.scss'],
})
export class NewRebateGenerateDialogComponent implements OnInit, OnDestroy {
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
  };
  form: FormGroup;
  messages$ = this.rebateReleaseDataService.messages$;
  ranges = this.transactionHttpService.ranges;
  dateTimePickerLocale = { format: 'YYYY-MM-DD', firstDay: 1 };
  private subscription = new Subscription();

  buttonLoading = false;

  dropdownSettings = {
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 200,
    primaryKey: 'id',
    labelKey: 'username',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false,
    searchBy: ["username"],
    autoPosition: false
  };

  currencyId:any;
  checkValidation = false;
  isFilteredDummy = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { rebate: Rebate; mode: string },
    private rebateReleaseDataService: NewRebateReleaseDataService,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    public dialogRef: MatDialogRef<NewRebateGenerateDialogComponent>,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
      });
    }
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onGenerate() {
    this.checkValidation = true;
    if (this.form.valid) {
      this.buttonLoading = true;
      // To set 'Save' button to disable (To prevent call API in multiple times when double click)
      this.form.setErrors({ 'invalid': true });
      const data = {
        ...this.form.value,
        start_datetime: moment(this.form.value.start_datetime)
          // .utc()
          .format('YYYY-MM-DD'),
        end_datetime: moment(this.form.value.end_datetime)
          // .utc()
          .format('YYYY-MM-DD'),
        member_account_id: this.form.value.member_account_id?.length > 0 ? this.form.value.member_account_id : null
      };
      Object.keys(data).forEach(
        (key) =>
          (data[key] == null || data[key] === 'all' || data[key] === '' || key === 'defaultDate') &&
          delete data[key]
      );
      this.subscription = forkJoin([
        this.rebateReleaseDataService.generateRebate(data),
        this.rebateReleaseDataService.messages$,
      ]).subscribe(
        (res) => {
          this.buttonLoading = false;
          this.checkValidation = false;
          // To enable 'Save' button after get response
          this.form.setErrors(null);
        },
        (err) => {
          this.buttonLoading = false;
          this.checkValidation = false;
          // To enable 'Save' button after get response
          this.form.setErrors(null);
        },
        () => { }
      );
      this.messages$.subscribe(() => {
        this.form.patchValue({
          currency_id: 'all',
          start_datetime: null,
          end_datetime: null,
          defaultDate: null,
        });
        this.checkValidation = false;
      });
    }
  }

  onDateRange(event: any, type: string) {
    if (event) {
      this.form.patchValue({
        start_datetime:
          event.startDate !== null ? event.startDate._d : event.startDate,
        end_datetime: event.endDate !== null ? event.endDate._d : event.endDate,
      });
    }
  }

  onClearDate(type: string) {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({
        start_datetime: null,
        end_datetime: null,
        defaultDate: null,
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private formInit() {
    this.form = new FormGroup({
      currency_id: new FormControl('all', [Validators.required]),
      start_datetime: new FormControl(null, [Validators.required]),
      end_datetime: new FormControl(null, [Validators.required]),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      member_account_id: new FormControl(null),
      dummy: new FormControl(1),
    });
  }

  onChangeCurrency(){
    this.currencyId = this.form.value.currency_id;
    this.cdr.detectChanges();
  }

  onChangeDummy($event) {
    this.isFilteredDummy = true;
    let isChecked = $event.target.checked ? 1 : 0;
    this.form.patchValue({
      dummy: isChecked
    });
  }

}
