<div class="row">
  <section class="col-lg-12 card border-0">
    <div class="col-12 p-0 card rounded-0">
      <div class="card-header" [style.backgroundColor]="getLighterColor(withdraw.member_label_display_color_code, 90)" [ngStyle]="withdraw.member_label_display_color_code ? {'border-bottom': '5px solid ' + withdraw.member_label_display_color_code, 'font-weight': 'bold'} : {}" [style.color]="withdraw.member_label_display_color_code">
        Withdrawal Details
        <ng-container *ngFor="let label of withdraw.labels">
          <span [style.backgroundColor]="label.display_color_code" class="member-label-box" *ngIf="label.status == 1">
            {{ label.name }} 
          </span>
        </ng-container>
      </div>
      <section class="col-12 p-4">
        <div class="row border-top border-left">
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Member ID</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ withdraw.member_id }}</span>
          </div>
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Username</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ withdraw.username }}</span>
          </div>
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Name</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ withdraw.name }}</span>
          </div>
        </div>

        <div class="row border-top border-left">
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Bank Name</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ withdraw.member_bank_name }}</span>
          </div>
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Account Name</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ withdraw.member_account_name }}</span>
          </div>
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Account Number</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ withdraw.member_account_number }}</span>
          </div>
        </div>

        <div class="row border-top border-left">
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Created At</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ withdraw.created_at ? (withdraw.created_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</span>
          </div>
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Confirmed Amount</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ withdraw.confirmed_amount }}</span>
          </div>
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Status</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="withdraw.status === WithdrawStatusEnum.Risky ? 'kt-badge--10' : 'kt-badge--' + withdraw.status"> {{ withdraw.status_name }}</span>
          </div>
        </div>

        <div class="row border-top border-left border-bottom">
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Latest Remark</span>
          </div>
          <div class="col-md-10 border-right p-3">
            <span>{{ withdraw.remarks ? withdraw.remarks : '-' }}</span>
          </div>
        </div>
      </section>
    </div>
  </section>
</div>

<div class="row mt-5">
  <section class="col-lg-12 card border-0">
    <div class="col-12 p-0 card rounded-0" *ngIf="allLogs">
      <div class="card-header">
        Timeline
      </div>
      <div class="row ml-1" *ngFor="let row of allLogs">
        <div class="col-1 p-3">
          {{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }}
        </div>
        <div class="col-11 p-3 timelinediv">
          <!-- The Timeline -->
          <div class="timeline">
            <!-- Item 1 -->
            <div>
              <div class="direction-r">
                <div class="flag-wrapper">
                  <span class="flag" [ngClass]="'border-color--' + row.status">
                    <section class="col-12 p-3">
                      <div class="row border-top border-left">
                        <div class="col-lg-3 col-md-3 card-header border-right p-3">
                          <span class="kt-font-bold">Action</span>
                        </div>
                        <div class="col-md-9 border-right p-3">
                          <span>{{ row.action_name }}</span>
                        </div>
                      </div>

                      <div class="row border-top border-left" *ngIf="row.action == 3 || row.action == 4 || row.action == 8">
                        <div class="col-lg-3 col-md-3 card-header border-right p-3">
                          <span class="kt-font-bold">References</span>
                        </div>
                        <div class="col-md-9 border-right p-3">
                          <div *ngFor="let transaction of row.bank_transaction">
                            <span>{{ transaction.pg_reference_id }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row border-top border-left" *ngIf="row.action == 1 || row.action == 2 || row.action == 3 || row.action == 4 || row.action == 7 || row.action == 8 || row.action == 9">
                        <div class="col-lg-3 col-md-3 card-header border-right p-3">
                          <span class="kt-font-bold">Remark</span>
                        </div>
                        <div class="col-md-9 border-right p-3">
                          <span>{{ row.remarks }}</span>
                        </div>
                      </div>

                      <div class="row border-top border-left border-bottom" *ngIf="row.action != 8">
                        <div class="col-lg-3 col-md-3 card-header border-right p-3">
                          <span class="kt-font-bold">Handler</span>
                        </div>
                        <div class="col-md-9 border-right p-3">
                          <span>{{ row.created_by ? row.created_by : '-' }} <br> {{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</span>
                        </div>
                      </div>

                      <div class="row border-top border-left border-bottom" *ngIf="row.action == 8">
                        <div class="col-lg-3 col-md-3 card-header border-right p-3">
                          <span class="kt-font-bold">Receipt</span>
                        </div>
                        <div class="col-md-3 border-right p-3">
                          <button *ngIf="canViewReceipts" (click)="openReceipt(row)" [disabled]="getAllReceiptImages(row).length < 1" type="button" class="btn btn-primary mr-2">View Receipt</button>
                        </div>
                        <div class="col-lg-3 col-md-3 card-header border-right p-3">
                          <span class="kt-font-bold">Handler</span>
                        </div>
                        <div class="col-md-3 border-right p-3">
                          <span class="text-right"> {{ row.created_by ? row.created_by : '-' }} <br> {{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</span>
                        </div>
                      </div>
                    </section>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-3 text-right" *ngIf="allLogs.length > 0 && pagination?.current_page < pagination?.last_page">
        <button type="button" class="btn btn-loadmore" (click)="loadMoreLogs()"><i [ngClass]="(loadMoreButtonLoading) ? 'spinner-border spinner-border-sm align-middle mr-2' : 'fas fa-ellipsis-h'"></i>Load More</button>
      </div>
    </div>
  </section>
</div>