import { EventEmitterService } from '@core/services/event-emitter.service';
// Angular
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Notification } from '@core/models/notification.model';
import { NotificationHttpService } from '@core/services/notification-http.service';
import Echo from 'laravel-echo';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClearCacheDialogComponent } from './dialog/clear-cache.component';
import { ManualSyncDialogComponent } from './dialog/manual-sync/manual-sync.component';
import { DateTime } from 'luxon';
import { environment } from '@env/environment';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { logout, updateAccessSections, updateAppPermissions, updateNavigations, updateUserPermissions } from '@core/store/auth/auth.actions';
import { specialPermissions } from '@core/store/auth/auth.selectors';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit, AfterViewInit {

  notification$: Observable<Notification>;
  hasDeposits: boolean;
  hasWithdrawals: boolean;
  hasMemberPromotions: boolean;
  hasRewards: boolean;
  hasBankBots: boolean;
  hasMemberVerifications: boolean;
  hasTransfers: boolean;
  isAdmin: boolean = JSON.parse(localStorage.getItem('user_data')).is_admin;
  boCurrencyIdsParams: string;
  isOpenNotification: boolean;
  contentHash = '';
  echo: Echo;
  notificationMessages: any;
  notificationSound = localStorage.getItem('notificationSound') !== null ? localStorage.getItem('notificationSound') : 'on';
  withdrawDepositNotif = new Audio('./assets/media/sounds/withdraw_deposit_notification.mp3');
  otherNotif = new Audio('./assets/media/sounds/other_notification.mp3');
  todayDate: any;
  realtime: any;
  tz: any;

  // channels
  rolePermissionsChannel = null;
  updateOperatorChannel = null;

  private subscriptions = new Subscription();

  // permissions
  canClearCache: boolean;
  canManualSync: boolean;
  canUseAffiliateApprovalsShortcut: boolean;
  canUseAffiliateDepositShortcut: boolean;
  canUseAffiliateWithdrawalShortcut: boolean;
  canUseDepositShortcut: boolean;
  canUseWithdrawalShortcut: boolean;
  canUseMemberPromotionShortcut: boolean;
  canViewOwnDownloadsHistory: boolean;
  canViewAllDownloadsHistory: boolean;
  canViewOwnImportHistory: boolean;
  canViewAllImportHistory: boolean;
  canUseTransferShortcut: boolean;

  constructor(
    private notificationHttpService: NotificationHttpService,
    private eventEmitterService: EventEmitterService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private store: Store<AppState>,
    private appPermissionService: AppPermissionService,
  ) {}

  ngOnInit() {
    this.tz = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.onRealTime();

    const permissions = JSON.parse(localStorage.getItem('user_access'));
    // TODO: To revise in the future once supported in API
    // Fixed position for now!
    // this.hasTransfers = permissions[2][3]?.view ?? true;

    this.boCurrencyIdsParams = this.generateBoCurrencyIdsParams();

    this.eventEmitterService.callUpdateTimezone = this.eventEmitterService.updateTimezone.subscribe(() => {
      this.tz = JSON.parse(localStorage.getItem('user_data')).timezone;
      this.onRealTime();
    });

    const userToken = JSON.parse(localStorage.getItem('user_token'));
    const SocketIoClient = require('socket.io-client');
    const socketHost: string = environment.socketHost;

    try {
      this.echo = new Echo({
        broadcaster: 'socket.io',
        client: SocketIoClient,
        host: socketHost,
        auth: {
          headers: {
              'access-token': userToken.access_token,
              'token-selector': userToken.plaintext_token,
              'X-User-Model': 'bo'
          }
        }
      });
    } catch (e) {
      console.log(e);
    }

    this.updateAppRolePermissionsWebsocket();
    this.updateOperatorWebsocket();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canClearCache = appPermissions.clear_cache;
      this.canManualSync = appPermissions.manual_sync;
      this.canUseAffiliateApprovalsShortcut = appPermissions.affiliate_approvals_shortcut;
      this.canUseAffiliateDepositShortcut = appPermissions.affiliate_deposit_shortcut;
      this.canUseAffiliateWithdrawalShortcut = appPermissions.affiliate_withdrawal_shortcut;
      this.canUseDepositShortcut = appPermissions.deposit_shortcut;
      this.canUseWithdrawalShortcut = appPermissions.withdrawal_shortcut;
      this.canUseMemberPromotionShortcut = appPermissions.member_promotion_shortcut;
      this.canViewOwnDownloadsHistory = appPermissions.view_own_downloads_history;
      this.canViewAllDownloadsHistory = appPermissions.view_all_downloads_history;
      this.canViewOwnImportHistory = appPermissions.view_own_import_history;
      this.canViewAllImportHistory = appPermissions.view_all_import_history;
      this.canUseTransferShortcut = appPermissions.transfer_shortcut;
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    this.notificationSub();
  }

  ngOnDestroy() {
    this.echo.disconnect();
    this.subscriptions.unsubscribe();
  }

  onNotificationOnOff(){
    if (this.notificationSound === 'off') {
      this.notificationSound = 'on';
    }else{
      this.notificationSound = 'off';
    }
    localStorage.setItem('notificationSound', this.notificationSound);
  }

  private notificationSub() {
    this.notification$ = this.getNotifications();
    //listen to notification channel
    this.notificationHttpService.notificationCountWebsocket();
    //update view when event broadcast
    this.eventEmitterService.listenNotificationWebsocketVar = this.eventEmitterService.listenNotificationWebsocket.subscribe(() => {
      let prevNotif = JSON.parse(sessionStorage.getItem('prevNotifications'));
      let newNotif = JSON.parse(sessionStorage.getItem('newNotifications'));
      if (prevNotif !== null && this.notificationSound === 'on'){
        if (+newNotif.deposit > +prevNotif.deposit || +newNotif.withdraw > +prevNotif.withdraw){
          this.withdrawDepositNotif.load();
          setTimeout(() => {
            this.withdrawDepositNotif.play();
          }, 500)
        }
        if (+newNotif.memberPromotion > +prevNotif.memberPromotion || +newNotif.notification_count > +prevNotif.notification_count || +newNotif.downloads > +prevNotif.downloads || +newNotif.imports > +prevNotif.imports) {
          this.otherNotif.load();
          setTimeout(() => {
            this.otherNotif.play();
          }, 500)
        }
      }
      sessionStorage.setItem('prevNotifications', JSON.stringify(newNotif))
      this.cdr.detectChanges();
    });
  }

  private generateBoCurrencyIdsParams () {
    var currency_ids = JSON.parse(localStorage.getItem('user_data'))['bo_currency_ids'];
    var params = Object.keys(currency_ids).map(key => `currency_ids[${key}]=${currency_ids[key]}`).join('&')

    return params;
  }

  private getNotifications() {
    return this.notificationHttpService.getNotificationCounter(`?${this.boCurrencyIdsParams}`).pipe(
      tap((res)=> {
        this.notificationMessages = res.notification_messages;
        sessionStorage.setItem('newNotifications', JSON.stringify(res));
      })
    )
  }

  onOpenDialog(type?: string) {
    if (type === 'sync') {
      this.dialog.open(ManualSyncDialogComponent, {
        width: '600px'
      });
    } else {
      this.dialog.open(ClearCacheDialogComponent, {
        width: '600px'
      });
    }
  }

  openNotificationMenu() {
    this.eventEmitterService.onOpenNotificationMenu();
  }

  @HostListener('document:click', ['$event'])
  onNotificationPanel() {
    const notificationElement = document.querySelector('kt-notification .kt-header__topbar-item.show.dropdown') as HTMLElement;

    if (notificationElement !== null) {
      this.isOpenNotification = true;
    } else {
      this.isOpenNotification = false;
    }
    this.contentHash = Math.random().toString(36).substring(7);
  }

  onRedirect(url: string) {
    this.notificationSub();
    this.router.navigateByUrl(url);
  }

  onRealTime(){
    this.realtime = setInterval(() => {
      this.todayDate = DateTime.now().setZone(this.tz).toFormat('yyyy-MM-dd HH:mm:ss (ZZ)');
      this.todayDate = this.todayDate.replace('(', '(GMT ');
      this.cdr.detectChanges();
    }, 1000);
  }

  private updateAppRolePermissionsWebsocket() {
    const userData = JSON.parse(localStorage.getItem('user_data'));

    this.rolePermissionsChannel = this.echo.channel(`update-app-role-permissions-channel.${userData.application_role_id}.${userData.id}`);

    this.rolePermissionsChannel.listen(
      '.UpdateAppRolePermissionsEvent',
      ({ 
        role,
        appPermissions,
        appSections,
        navigations
      }) => {
        if (role.status === 0) {
          this.store.dispatch(logout());
          return;
        }

        const userAppPermissions = appPermissions;
        const userNavigations = navigations;

        localStorage.setItem('user_app_permissions', JSON.stringify(userAppPermissions));
        localStorage.setItem('navigations', JSON.stringify(userNavigations));
        localStorage.setItem('user_app_sections', JSON.stringify(appSections));

        this.store.dispatch(updateAppPermissions({ appPermissions: userAppPermissions }));
        this.store.dispatch(updateNavigations({ navigations: userNavigations }));
      });
  }

  private updateOperatorWebsocket() {
    const userData = JSON.parse(localStorage.getItem('user_data'));
    this.updateOperatorChannel = this.echo.private(`update-operator-channel.${userData.id}`);

    this.updateOperatorChannel.listen(
      '.UpdateOperatorEvent',
      ({
        user,
        navigations,
        appPermissions,
        userPermissions,
        accessSections,
      }) => {
      const newUserData = {
        ...JSON.parse(localStorage.getItem('user_data')), // get from local storage instead of using userData above to always get latest data

        // here only choose several fields that I think important to update.
        // If there's a need to update more/all fields then feel free to update it
        role_id: user.role_id,
        application_role_id: user.application_role_id,
        authorized_role_version: user.authorized_role_version,
        authorizedRole: user.authorizedRole,
      };

      localStorage.setItem('user_data', JSON.stringify(newUserData));
      localStorage.setItem('navigations', JSON.stringify(navigations));
      localStorage.setItem('user_app_permissions', JSON.stringify(appPermissions));
      localStorage.setItem('user_permissions', JSON.stringify(userPermissions));
      localStorage.setItem('user_access', JSON.stringify(accessSections));

      // unsub from update-app-role-permissions-channel if app role id is null
      if (!user.application_role_id && this.rolePermissionsChannel) {
        this.rolePermissionsChannel.unsubscribe();
        this.rolePermissionsChannel = null;
      } else if (user.application_role_id && !this.rolePermissionsChannel) {
        // subscribe back if not null
        this.updateAppRolePermissionsWebsocket();
      }

      this.store.dispatch(updateAccessSections({ accessSections }));
      this.store.dispatch(updateAppPermissions({ appPermissions }));
      this.store.dispatch(updateNavigations({ navigations }));
      this.store.dispatch(updateUserPermissions({ userPermissions }));
    });
  }

  onRedirectToTransferPage() {
    localStorage.setItem('filterPendingTransfer', '1');
    this.router.navigateByUrl('/general/transfers');
  }

}

