import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RoleEnum } from '@core/enums/role.enum';
import { Member } from '@core/models/member.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { MemberDataService } from '../../services/member-data.service';
import { MemberEntityService } from '../../services/member-entity.service';
import { MemberLabelComponent } from '../member-label/member-label.component';
import { environment } from '@env/environment';
import { AppState } from '@store/reducers';
import { Store, select } from '@ngrx/store';
import { specialPermissions } from '@core/store/auth/auth.selectors';
import { AppPermissionService } from '@core/services/app-permission.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { AllLeadsDataService } from '../../../all-leads/services/all-leads-data.service';
import { AllAffiliatesDataService } from '../../../all-affiliates/services/all-affiliates-data.service';
import { TrafficSource } from '@core/enums/traffic-source.enum';
import Swal from 'sweetalert2';
import { CustomValidators } from '@core/validators/custom-validators';
import { AesService } from '@core/services/aes.service';

@Component({
  templateUrl: './member-profile.component.html',
  styleUrls: ['./member-profile.component.scss']
})
export class MemberProfileDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  private validateSubject = new BehaviorSubject<any>(null);
  private emailValidateSubject = new BehaviorSubject<any>(null);

  form: FormGroup;
  username: Member;
  @ViewChild(OwlDateTimeInputDirective) datePicker: OwlDateTimeInputDirective<any>;
  dropdown = {
    statuses: this.dropdownHttpService.memberStatuses,
    groups: this.dropdownHttpService.groups,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    merchantBanks: this.dropdownHttpService.merchantBanks,
    traffic_sources: this.dropdownHttpService.trafficSourceTypes,

    affiliates: this.allAffiliatesDataService.getAffiliates(),
    telemarketerList: this.allLeadsDataService.getTelemarketerList(``),
    campaigns: this.dropdownHttpService.campaigns,
    affiliateCampaigns: this.dropdownHttpService.affiliateCampaigns,
    
  };
  role = RoleEnum;
  buttonLoading = false;
  dropdownSettings = {};

  showPassword = false; // Add this for password toggle functionality

  // whether to show validation err per form input
  showValidationErr = {
    'name': false,
    'mobile': false,
    'password': false,
  };

  userPermissions$ = this.store.pipe(select(specialPermissions));
  canCreateDummyAccount: boolean;
  canEditDummyAccount: boolean;
  canResetPassword: boolean;

  maxDate: Date;

  smsSubscriptionDropdownList = [
    { id: 1, code: 'otp', name: 'OTP', disabled: true },
    { id: 2, code: 'transaction', name: 'Transaction' },
    { id: 3, code: 'marketing', name: 'Marketing' }
  ];

  smsSubscriptionDropdownSettings = {};

  smsSubscriptionSelectedItems = [];

  validatingEmail = false;

  // permissions
  canCreateNewMember: boolean;
  canEditMemberDetailsRestricted: boolean;
  canEditMemberDetails: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private datePickerSubscription = new Subscription();
  messages$ = this.memberDataService.messages$;
  refreshStatus: boolean;
  dateTimeStack = null;

  selectedItems = [];
  modify_dob = true;
  sqsEnable: boolean = environment.sqsEnable;
  disabledDummy = false;

  constructor(
    public dialogRef: MatDialogRef<MemberProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { member: Member, mode: string, isAdmin: boolean },
    public dialog: MatDialog,
    private memberService: MemberEntityService,
    private memberDataService: MemberDataService,
    private dropdownHttpService: DropdownHttpService,
    private datePipe: DatePipe,
    private authHttpService: AuthHttpService,
    private store: Store<AppState>,
    private router: Router,
    private allLeadsDataService: AllLeadsDataService,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private appPermissionService: AppPermissionService,
    private aesService: AesService,
  ) {}

  ngOnInit() {
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
      });
    }

    // Set maximum date to 18 years ago from today
    this.maxDate = new Date();
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);

    this.dropdownSettings = {
      autoPosition: true,
      maxHeight: 150,
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.smsSubscriptionDropdownSettings = {
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: false,
      noDataLabel: '',
      showCheckbox: false,
      disabledField: 'disabled',
    };

    if(this.data.mode === 'edit' && this.data.member.agent_id !== null){
      this.selectedItems.push({id:this.data.member.agent_id, username: this.data.member.agent_username});
    }

    this.formInit(); 

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateNewMember = appPermissions.create_new_member;
      this.canEditMemberDetailsRestricted = appPermissions.edit_member_details_restricted;
      this.canEditMemberDetails = appPermissions.edit_member_details;
      this.canCreateDummyAccount = appPermissions.create_dummy_account;
      this.canEditDummyAccount = appPermissions.edit_dummy_account;
      this.canResetPassword = appPermissions.reset_password;

      if (this.data.mode == 'edit') {
        // Password
        if (!this.canResetPassword) {
          this.form.get('password').disable();
        } else {
          this.form.get('password').enable();
        }

        // DOB
        if (!this.canEditMemberDetails) {
          this.modify_dob = false;

          this.form.get('date_of_birth').disable();
          this.form.get('name').disable();
          this.form.get('email').disable();
          this.form.get('email_status').disable();
          this.form.get('mobile').disable();
          this.form.get('mobile_status').disable();
        } else {
          this.modify_dob = true;

          this.form.get('date_of_birth').enable();
          this.form.get('name').enable();
          this.form.get('email').enable();
          this.form.get('email_status').enable();
          this.form.get('mobile').enable();
          this.form.get('mobile_status').enable();
        }
      }
    });

    this.subscriptions.add(apSub);

    this.initTrafficSourceInputValidator();
    this.initEmailInputValidator();

    if ((this.data.mode == 'edit' && !this.canEditDummyAccount)) {
      this.disabledDummy = true;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
    this.validateSubject.complete();
    this.emailValidateSubject.complete();
  }

  ngAfterViewInit() {
    if ((this.data.isAdmin || this.canEditMemberDetails) || this.data.mode === 'create') {
      // this.modify_dob = true;

      this.datePickerSubscription = forkJoin([
        this.buildDatePicker(0, 'date_of_birth'),
      ]).subscribe();
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close(event);
  }

  handleOnSaveError(error: any) {
    this.buttonLoading = false;
    this.form.setErrors(null);
    Object.keys(this.showValidationErr).forEach(key => {
      this.showValidationErr[key] = false;
    });

    // specifically handle showing errors if backend validation errors exist
    if (error?.errors) {
      Object.keys(error.errors).forEach(key => {
        this.showValidationErr[key] = true;

        this.form.get(key).setErrors({ 'invalid': error.errors[key] });
      });
    }
  }

  onSave(member: Member, mode?: string) {
    this.buttonLoading = true;
    const data = {
      id: member ? member.id : null,
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);

    // encrypt the raw password before sending to api
    if( data.password ) {
      data.password = this.aesService.encryptWithReqSignKey(data.password);
    }

    const createOrUpdateMember = () => {
      switch (mode) {
        case 'edit':
          this.subscription = this.memberService.update(data).pipe(
            tap((res: any) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              this.messages$.next([...res.message]);
            }),
            catchError((error) => {
              this.handleOnSaveError(error);
              throw error;
            })
        ).subscribe();
          break;
        case 'create':
          this.subscription = forkJoin([
            this.memberDataService.add(data).pipe(
              tap((res: any) => {
                this.buttonLoading = false;
                this.form.setErrors(null);
                this.messages$.next([...res.message]);
              }),
              catchError((error) => {
                this.handleOnSaveError(error);
                throw error;
              })
            ),
          ]).subscribe();
          break;
      } 
    }

    this.memberDataService.checkMobileEmailDuplicate({
      email: data.email,
      mobile: data.mobile,
      member_account_id: data.id,
      currency_id: this.form.get('currency_id').value,
    })
      .pipe(
        catchError((error) => {
          this.handleOnSaveError(error);
          throw error;
        })
      )
      .subscribe((res: any) => {
        if (res.success) {
          createOrUpdateMember();
          return;
        }

        Swal.fire({
          title: 'Duplicate Contact Information',
          html: res.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Proceed',
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancel',
          heightAuto: false
        }).then(result => {
          if (result.isConfirmed) {
            createOrUpdateMember();
          } else if (result.isDenied || result.dismiss) {
            this.buttonLoading = false;
          }
        });
      })

    this.refreshStatus = true;
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  onCheckbox(event: Event, formKey: string) {
    this.form.patchValue({
      [formKey]: (event.target as HTMLInputElement).checked ? 1 : 0
    });
  }

  onSlideToggle(event: MatSlideToggleChange, formKey: string) {
    this.form.patchValue({
      [formKey]: +!!event.checked,
    });
  }

  onEllipsis(text: string, limit: number): string {
    if (!text) return '';
    return text.length > limit ? text.substring(0, limit) + '...' : text;
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  onInput(controlName: string, event: Event) {
    this.showValidationErr[controlName] = true;
  }

  onOpenDialog() {
    this.openDialogBy(MemberLabelComponent, { member: this.data.member});
  }

  onTrafficSourceChange(evt) {
    const trafficSource = this.form.get('traffic_source').value;

    if (trafficSource == TrafficSource.AFFILIATE_INTERNAL || trafficSource == TrafficSource.AFFILIATE_EXTERNAL) {
      this.form.patchValue({
        affiliate_code: '',
        affiliate_campaign: '',
        campaign_code: '',
      })
    }
  }

  // push to validateSubject, then validateSubject sub in ngOnInit will call validation API
  onValidateTrafficSourceInput(event: any, control: string) {
    // set error first to disable Submit button
    this.form.get(control).setErrors({});

    this.validateSubject.next({ value: event.target.value, control }); 
  }

  onEmailInput(event) {
    this.emailValidateSubject.next({ value: event.target.value }); 
  }

  navigateToAllVips() {
    this.router.navigate(['/vip/all-vips']);
    this.dialogRef.close();
  }

  private openDialogBy(componentRef: any, data?: { member?: any}) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '1000px',
      data,
    });
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.valueChange.pipe(
      map(res => this.datePipe.transform(res, 'yyyy-MM-dd')),
      tap(date => {
        this.form.patchValue({ [formKey] : date });
      }),
    );
  }

  private formInit() {
    let username = null;
    let currency = null;
    let dateOfBirth = null;
    let name = null;
    let memberGroup = 1; // Normal by default
    let status = 1;
    let mobile = null;
    let mobile_status = 0;
    let email = null;
    let email_status = 0;
    let remarks = null;
    let agent_id = null;
    let referrer = null;
    let traffic_source = null;
    let referral_input = '';
    let affiliate_code = '';
    let affiliate_campaign = '';
    let campaign_code = '';
    let telemarketer_username = '';
    let sms_sub = 1;
    let sms_sub_options = [1];
    let push_notification = 0;
    let email_notification = 0;
    let dummy = 0;
    let countryCode = null;

    let controls: any = {};

    if (this.data.mode === 'edit'){
      if (this.data.member.date_of_birth != null && this.data.member.date_of_birth != '') {
        this.dateTimeStack = new Date(this.data.member.date_of_birth);
      }
      username =  this.data.member.username;
      currency =  this.data.member.settings_currency_id;
      name =  this.data.member.name;
      memberGroup = this.data.member.member_group_id;
      status =  this.data.member.status;
      mobile = this.data.member.mobile;
      mobile_status = this.data.member.mobile_status;
      email = this.data.member.email;
      email_status = this.data.member.email_status;
      remarks =  this.data.member.remarks;
      dateOfBirth = this.data.member.date_of_birth;
      agent_id = this.data.member.agent_id;
      referrer = this.data.member.referrer;
      sms_sub_options = [...this.data.member.sms_sub_options];
      // Ensure '1' is always included
      if (!sms_sub_options.some(a => a == 1)) {
        sms_sub_options.push(1);
      }
      push_notification = this.data.member.push_notification;
      email_notification = this.data.member.email_notification;
      dummy = this.data.member.dummy;
      traffic_source = this.data.member.source_type;
      referral_input = this.data.member.referrer ? this.data.member.referrer + ` (${this.data.member.referral_code})` : '';
      affiliate_code = this.data.member.affiliate_username ? this.data.member.affiliate_username + ` (${this.data.member.affiliate_code})` : '';
      affiliate_campaign = campaign_code = this.data.member.campaign_name ? this.onEllipsis(this.data.member.campaign_name, 20) + ` (${this.data.member.campaign_code})` : '';
      telemarketer_username = this.data.member.telemarketer || '';

      //Unable to change dummy account to normal account
      this.disabledDummy = dummy !== 0 ? true : false;

      this.smsSubscriptionSelectedItems = sms_sub_options.map((id) => this.smsSubscriptionDropdownList.find((option) => option.id == id));

      controls = {
        ...controls,
        mobile_status: new FormControl(mobile_status),
        email_status: new FormControl(email_status),
        suspicious: new FormControl(this.data.member.suspicious),
      };

      countryCode = this.dropdownHttpService.currencyToCountryMap[this.data.member.currency_code] || null;
    }

    const mobilePattern = this.dropdownHttpService.mobileRegex[countryCode] || this.dropdownHttpService.mobileRegex['MY'];

    controls = {
      ...controls,
      username: new FormControl({ value: username, disabled: this.data.mode === 'create' ? false : true }, [Validators.required]),
      password: new FormControl('', [Validators.required,, Validators.minLength(6)]),
      currency_id: new FormControl({ value: currency, disabled: this.data.mode === 'edit' ? true : false }, [Validators.required]),
      date_of_birth: new FormControl(dateOfBirth),
      member_group_id: new FormControl({ value: memberGroup, disabled: this.data.mode === 'edit' ? true : false }, [Validators.required]),
      status: new FormControl({ value: status, disabled: this.data.mode === 'create' ? true : false }),
      name: new FormControl(name, [
        Validators.required,
        Validators.pattern(/^[-\/\p{L}\p{M}\s@]+$/u),
      ]),
      mobile: new FormControl(mobile, [
        Validators.required,
        Validators.pattern(mobilePattern),
        Validators.maxLength(255)
      ]),
      email: new FormControl(email, []), // email validation is done entirely on backend
      remarks: new FormControl(remarks),
      agent_id: new FormControl(agent_id),
      referrer: new FormControl(referrer),
      dummy: new FormControl(dummy),
      sms_sub: new FormControl(sms_sub),
      sms_sub_options: new FormControl(sms_sub_options),
      push_notification: new FormControl(push_notification),
      email_notification: new FormControl(email_notification),
      traffic_source: new FormControl({
        value: traffic_source,
        disabled: this.data.mode === 'edit' ? traffic_source != TrafficSource.ORGANIC : false,
      }, [Validators.required]),
      referral_input: new FormControl({ 
        value: referral_input, 
        disabled: this.data.mode === 'edit' && traffic_source != TrafficSource.ORGANIC,
      },  [
        (control: AbstractControl) => {
          const trafficSource = control.parent?.get('traffic_source')?.value;
          return trafficSource == TrafficSource.REFERRAL ? Validators.required(control) : null;
        }
      ]),
      affiliate_code: new FormControl({ 
        value: affiliate_code, 
        disabled: this.data.mode === 'edit' && traffic_source != TrafficSource.ORGANIC,
      }, [
        (control: AbstractControl) => {
          const trafficSource = control.parent?.get('traffic_source')?.value;
          return (trafficSource == TrafficSource.AFFILIATE_INTERNAL || 
                  trafficSource == TrafficSource.AFFILIATE_EXTERNAL) 
                 ? Validators.required(control) 
                 : null;
        }
      ]),
      affiliate_campaign: new FormControl({ 
        value: affiliate_campaign, 
        disabled: this.data.mode === 'edit' && traffic_source != TrafficSource.ORGANIC,
      }, []),
      campaign_code: new FormControl({ 
        value: campaign_code, 
        disabled: this.data.mode === 'edit' && traffic_source != TrafficSource.ORGANIC,
      }, [
        (control: AbstractControl) => {
          const trafficSource = control.parent?.get('traffic_source')?.value;
          return trafficSource == TrafficSource.ADS ? Validators.required(control) : null;
        }
      ]),
      telemarketer_username: new FormControl({ 
        value: telemarketer_username, 
        disabled: this.data.mode === 'edit' && traffic_source != TrafficSource.ORGANIC,
      }, [
        (control: AbstractControl) => {
          const trafficSource = control.parent?.get('traffic_source')?.value;
          return trafficSource == TrafficSource.TELEMARKETER ? Validators.required(control) : null;
        }
      ]),
    };

    this.form = new FormGroup(controls);

    // Trigger revalidation of traffic source value fields
    this.form.get('traffic_source')?.valueChanges.subscribe(() => {
      ['referral_input', 'affiliate_code', 'affiliate_campaign', 
       'campaign_code', 'telemarketer_username'].forEach(controlName => {
        this.form.get(controlName)?.updateValueAndValidity();
      });
    });

    if (this.data.mode === 'edit') {
      let emailChangesSubscriber = this.form.get('email').valueChanges.subscribe(value => {
        if (value !== this.data.member.email) {
          this.form.controls.email.setValidators(Validators.email);
          setTimeout(() => {
            this.form.controls.email.updateValueAndValidity();
          });
          emailChangesSubscriber.unsubscribe();
        }
      })

      // Password field set to optional for edit mode
      this.form.controls.password.setValidators([Validators.minLength(6)]);
      this.form.controls.password.updateValueAndValidity();
    }
  }
  
  initTrafficSourceInputValidator() {
    // for input validation under "Traffic Source"
    this.validateSubject.pipe(
      distinctUntilChanged(),
      debounceTime(300),
    ).subscribe(data => {
      if (data?.value) {
        this.buttonLoading = true;

        this.memberDataService.validateTrafficSourceInput(
          this.form.get('traffic_source').value,
          {[data.control]: data.value}
        ).subscribe({
          next: (response) => {
            this.buttonLoading = false;

            const formControl = this.form.get(data.control);

            if (!response.success) {
              formControl.setErrors({ invalid: response.message });
            } else {
              formControl.setErrors(null);
            }
          },
          error: (error) => {
            this.buttonLoading = false;
            console.error('Validation error:', error);
          }
        });
      }
    });
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  private initEmailInputValidator() {
    this.emailValidateSubject.pipe(
      distinctUntilChanged(),
      debounceTime(300),
    ).subscribe(data => {
      if (data?.value) {
        this.validatingEmail = true;
        this.memberDataService.validateEmail(
          { email: this.form.get('email').value }
        ).subscribe({
          next: (response) => {
            const formControl = this.form.get('email');
            if (!response.success) {
              formControl.setErrors({ invalid: response.message });
            } else {
              formControl.setErrors(null);
            }
          },
          error: (error) => {
            this.form.get('email').setErrors({ invalid: error.error.message });
          },
          complete: () => {
            this.validatingEmail = false;
          }
        });
      }
    });
  }

  onChangeCurrency(event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedCurrency = selectElement.options[selectElement.selectedIndex].text;

    const countryCode = this.dropdownHttpService.currencyToCountryMap[selectedCurrency] || this.dropdownHttpService.currencyToCountryMap['MYR'];
    const selectedPattern = this.dropdownHttpService.mobileRegex[countryCode];
    
    this.form.controls['mobile'].setValidators([Validators.pattern(selectedPattern), Validators.required]);
    this.form.patchValue({
      mobile: null
    })
  }
}
