import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Member } from '@core/models/member.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TrafficSource, TrafficSourceNames } from '@core/enums/traffic-source.enum';
import { AesService } from '@core/services/aes.service';

@Injectable()
export class MemberHttpService {

  constructor(
    private http: HttpClient, 
    private aesService: AesService,
  ) { }

  resetPassword(member: Member, form: FormGroup) {
    // encrypt the raw password before sending to api
    if( form.value.password ) {
      form.value.password = this.aesService.encryptWithReqSignKey(form.value.password);
    }
    return this.http.post<ApiResponse>(`/member/${member.id}/password`, form.value);
  }

  getAllDropdown(): Observable<Member[]> {
    return this.http.get<ApiResponse>(`/member`).pipe(
      map(res => {
        Object.keys(res.data.rows).forEach(key => {
          res.data.rows[key].custom = res.data.rows[key].id + ' - ' + res.data.rows[key].username.toUpperCase() + ' - ' + res.data.rows[key].name.toUpperCase();
        })
        return res.data.rows}
    ));
  }

  getSourceTypesDropdown(): Observable<any[]> {
    return this.http.get<ApiResponse>(`/sourcetypes`).pipe(
      map(response => {
        // change traffic source name to frontend name
        const transformedTypes = response.data.rows.map(type => ({
            id: type.id,
            name: TrafficSourceNames[type.id]
        }));

        const organicType = transformedTypes.find(t => t.id === TrafficSource.ORGANIC);
        const otherTypes = transformedTypes.filter(t => t.id !== TrafficSource.ORGANIC);

        return organicType ? [organicType, ...otherTypes] : transformedTypes;
      })
    );
  }

}
