<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available"  [formGroup]="form">
        <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : data.mode === 'duplicate' ? 'Duplicate' : 'Create' }} Message Template</h5>
                    <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                    </span>
                </div>
                <div class="modal-body">
                    <table class="col-12 mb-5">
                        <tr class="col-md-12">
                            <th class="col-4">Sections <span class="text-danger">*</span></th>
                            <td class="col-8">
                                <select class="col-12 form-control sectionSelect" formControlName="section" (change)="onGenerate('section')">
                                    <option [value]="null" disabled> Please Select </option>
                                    <ng-container *ngFor="let value of dropdown.section">
                                        <option [value]="value.id" *ngIf="editable ? (value.edit === 'false' ? false : true) : true">
                                        {{ value.name }}
                                        </option>
                                    </ng-container>
                                </select>
                            </td>
                        </tr>
                        <tr class="col-md-12">
                            <th class="col-4">Type <span class="text-danger">*</span></th>
                            <td class="col-8">
                                <select class="col-12 form-control typeSelect" formControlName="type" (change)="onGenerate('type')">
                                    <option [value]="null" disabled> Please Select </option>
                                    <option [value]="value.id" *ngFor="let value of dropdown.type">
                                    {{ value.name }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr class="col-md-12">
                            <th class="col-4">Name <span class="text-danger">*</span></th>
                            <td class="col-8">
                                <input type="text" formControlName="name" class="col-12 form-control" (keyup)="onGenerate('name')">
                            </td>
                        </tr>
                        <tr class="col-md-12 form-group">
                            <th class="col-4">Code <span class="text-danger">*</span></th>
                            <td class="col-8">
                                <input type="text" [value]="code | uppercase" class="col-12 form-control" readonly>
                            </td>
                        </tr>
                        <tr class="col-md-12" *ngIf="data.mode === 'edit'">
                            <th class="col-4">Status <span class="text-danger">*</span></th>
                            <td class="col-8">
                                <select class="col-12 form-control" formControlName="status">
                                    <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">
                                        {{ value }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr class="col-md-12 form-group">
                            <th class="col-4">Sync Content From 
                                <i class="fa fa-info-circle ml-1" placement="bottom" [ngbTooltip]="linkToolTip" tooltipClass="tooltip-class"></i>
                            </th>
                            <ng-template #linkToolTip>
                                <div class="text-left">
                                    Click 'Sync' to copy the message content from 14.1.2 Dialog Popup into the message template. The existing message content will be cleared and replaced.<br />
                                    <b>Mapping:</b><br />
                                    <li><b>Title</b> (from Dialog Popup) → <b>Subject</b> (in Message Template)</li>
                                    <li><b>Content</b> (from Dialog Popup) → <b>Message</b> (in Message Template)</li>
                                </div>
                            </ng-template>
                            <td class="row">
                                <div class="col-10 pr-4">
                                    <kt-dropdown-wo-lazyload
                                        [form]="form" 
                                        [dropdownList]="popupDropdownListArray" 
                                        [dropdownSettings]="popupDropdownSettings" 
                                        [formName]="'dialog_popups'" 
                                        [selectionAttributes]="'id'" 
                                        [selectedItems]="popupSelectedItems"
                                        (selectedItemsChanged)="onSelectedPopupItems($event)"> 
                                    </kt-dropdown-wo-lazyload>
                                </div>
                                <span class="input-group-append" style="transform: translate(-10px, 0px);">
                                    <button type="button" [disabled]="!popupSelectedItems.length || buttonLoading" class="btn btn-warning btn-info" (click)="syncContent()"><i [class]="buttonLoading ? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-sync'"></i>Sync</button>
                                </span>
                            </td>
                        </tr>
                    </table>
                    <div class="row">
                        <mat-tab-group animationDuration="0ms" class="col-12 form-group" formGroupName="details">
                            <ng-container *ngIf="languages.length > 0">
                              <mat-tab *ngFor="let language of languages; let i = index" [formGroupName]="language.id">
                                <ng-template mat-tab-label>
                                  {{language.code}}
                                </ng-template>
                                <input type="hidden" formControlName="settings_locale_id" [value]="language.id" />
                                <input type="hidden" formControlName="id" [value]="data.messageTemplate.message_template.id" *ngIf="data.mode === 'edit'" />
                                <div class="col-12 form-group">
                                  <label class="control-label">Subject <span class="text-danger">*</span></label>
                                  <input type="text" formControlName="subject" class="col-12 form-control">
                                </div>
                                <div class="col-12 form-group">
                                  <label>Message <span class="text-danger">*</span></label>
                                  <ckeditor #editors [config]="editorConfig" [editor]="editor" formControlName="message"></ckeditor>
                                  <div>
                                    <label class="usable-variables-label">Usable variables: </label>
                                    <div *ngFor="let usableVariable of usableVariables; let last = last">
                                        <button class="usable-variables-text" (click)="onSelectUsableVariable(usableVariable, i)">{{ usableVariable }}</button>
                                        <div *ngIf="!last" class="usable-variables-text">,</div>
                                    </div>
                                  </div>
                                </div>
                              </mat-tab>
                            </ng-container>
                        </mat-tab-group>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                    <kt-submit-button [isDisabled]="!form.valid" *ngIf="data.mode == 'create' ? canCreateMessageTemplate : data.mode == 'edit' ? canEditMessageTemplate : canDuplicateMessageTemplate" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.messageTemplate, data.mode)" #focusfield [text]="data.mode == 'duplicate' ? 'Duplicate': 'Submit'"></kt-submit-button>
                </div>
        </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
