import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { MemberLabel } from '@core/models/member-label.model';
import { Pagination } from '@core/models/pagination.model';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class MemberLabelHttpService {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  
  constructor(
    private http: HttpClient
  ) { }

  getWithQuery(params: string): Observable<MemberLabel[]>{
    return this.http.get<ApiResponse>(`/member/labels${params}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  getById(id: number): Observable<MemberLabel[]> {
    return this.http.get<ApiResponse>(`/member/labels/${id}`).pipe(
      map(res => res.data.rows)
    )
  }

  add(label) {
    return this.http.post<ApiResponse>(`/member/labels`, label).pipe(
      tap(res => this.messages$.next(res.message)),
    )
  }

  update(id: number, label) {
    return this.http.put<ApiResponse>(`/member/labels/${id}`, label).pipe(
      tap(res => this.messages$.next(res.message)),
    )
  }

  updateDefaultRemark(labelId: number, data) {
    return this.http.put<ApiResponse>(`/member/labels/${labelId}/remarks`, data).pipe(
      tap(res => this.messages$.next(res.message)),
    )
  }

  getLabelDropdown() {
    return this.http.get<ApiResponse>(`/cmsmemberlabelsdropdown`).pipe(
      map(res => res.data.rows)
    )
  }

  getActiveLabelDropdown() {
    return this.http.get<ApiResponse>(`/cmsmemberlabelsdropdown?status=1`).pipe(
      map(res => res.data.rows)
    )
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }

}
