<div class="row form-group ml-0 mr-0 pr-0 mb-2">
  <div class="col-12 row align-items-center pr-0 mb-4">
    <div class="col-xl-12 order-2 order-xl-1 pl-0 pr-0">
      <form class="row align-items-center" [formGroup]="form">
        <div class="col-12 row mb-2 pr-0">
          <div class="col-md-1 kt-form__label col-form-label">
            <label>Date/Time:</label>
          </div>
          <div class="col-md-5 kt-form__control">
            <div class="input-group date">
              <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
              <span class="input-group-append">
                <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
              </span>
            </div>
          </div>
          <div class="col-md-1 kt-form__label col-form-label">
            <label>Transaction Type:</label>
          </div>
          <div class="col-md-5 kt-form__control">
            <div class="d-flex flex-wrap status-filter-pt">
              <div class="custom-control custom-checkbox mr-3" *ngFor="let item of transactionTypes">
                <input type="checkbox" [checked]="item.checked" (click)="onChangeType($event, item.id)" class="custom-control-input" [id]="item.name">
                <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="kt-section col-12 row d-flex justify-content-between mb-3">
    <div class="dropdown dropdown-inline">
      <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
      <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
    </div>
  </div>
  <span class="pb-1"><strong>Game Wallet Balance:</strong> {{ totalGameBalance | number : '1.2-2' }}</span>
  <div class="table-responsive">
    <table class="table table-bordered table-hover table-striped">
      <thead>
        <tr>
          <th>ID</th>
          <th class="date-column">Created Time</th>
          <th class="date-column">Approved Time</th>
          <th>Fund</th>
          <th>Remarks</th>
          <th>Operator</th>
          <th class="text-center">Amount</th>
          <th class="text-center">Balance</th>
          <th>Refer ID</th>
        </tr>
      </thead>
      <tbody *ngIf="(walletEvents$ | async) as rows">
        <tr *ngFor="let row of rows">
          <td>{{ row.id }}</td>
          <!--Created Time-->
          <td>
            <!-- Void deposit / Void Withdraw / Reject Withdraw -->
            <ng-container *ngIf="row.transaction_type_id == 2 || row.transaction_type_id == 4 ||row.transaction_type_id == 7">
              {{ row.rejected_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
            </ng-container>
            <ng-container *ngIf="row.transaction_type_id != 2 && row.transaction_type_id != 4 && row.transaction_type_id != 7">
              {{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
            </ng-container>
          </td>
          <!--Approved Time-->
          <td>
            <!-- Deposit / Withdraw -->
            <ng-container *ngIf="row.transaction_type_id == 1 || row.transaction_type_id == 3">
              {{ row.approved_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
            </ng-container>
            <!-- Normal -->
            <ng-container *ngIf="row.transaction_type_id == 5 || row.transaction_type_id == 6 || row.transaction_type_id == 14 || row.transaction_type_id == 9">
              {{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
            </ng-container>
          </td>
          <td>{{ row.transaction_type }}</td>
          <td>{{ row.remarks }}</td>
          <td>
            <!-- Deposit / Withdraw -->
            <ng-container *ngIf="row.transaction_type_id == 8 || row.transaction_type_id == 15; else defaultOperator">
              {{ row.updated_by }}
            </ng-container>
            <ng-template #defaultOperator>
              {{ row.operator }}
            </ng-template>
          </td>
          <td [ngClass]="row.amount >= 0 ? 'text-success' : 'text-danger'" class="text-right">{{ row.amount | number : '1.2-2'}}</td>
          <td class="text-right">{{ row.balance | number : '1.2-2'}}</td>
          <td>{{ row.refer_id }}</td>
        </tr>
      </tbody>
      <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
    </table>
  </div>
</div>
<div class="kt-pagination kt-pagination--brand mb-4" *ngIf="pagination !== undefined">
  <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
  </ngb-pagination>
  <div class="kt-pagination__toolbar">
    <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
      <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
    </select>
    <span class="pagination__desc">
      Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
    </span>
  </div>
</div>
