import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { GameAccountMember } from '@core/models/game-account-member.model';
import { GameProvider } from '@core/models/game-provider.model';
import { GameCategory } from '@core/models/game-category.model';
import { Game } from '@core/models/game.model';

@Injectable()
export class GameProviderHttpService {

  blacklistedGameProviders$ = new BehaviorSubject<any>(null);
  selectedSubCategories$ = new BehaviorSubject<any>(null);
  selectionMode: string;

  constructor(private http: HttpClient) { }

  getGameProviders(categories?: number[]): Observable<GameProvider[]> {
    let params = '';
    if(categories !== undefined && categories.length >= 1) {
      categories.forEach((id, index) => params += `&category_id[${index}]=${id}`)
    }
    return this.http.get<ApiResponse>(`/gameprovider?paginate=false${params}`).pipe(
      map(res => res.data.rows)
    );
  }

  getGameProvidersByName(): Observable<GameProvider[]> {
    return this.http.get<ApiResponse>('/gameprovider/providerNames').pipe(
      map(res => res.data)
    );
  }

  getGameProvidersCategory(category: string): Observable<GameProvider[]> {
    return this.http.get<ApiResponse>(`/gameprovider?category_code=${category}&paginate=false`).pipe(
      map(res => res.data.rows)
    );
  }

  getGameProviderBetLimit(game_provider_code?: string, currency_code?: string) {
    return this.http.get<ApiResponse>(`/betlimit?game_provider_code=${game_provider_code}&currency_code=${currency_code}`).pipe(
      map(res => res.data.rows)
    );
  }

  getGameCategories(status: boolean = true): Observable<GameProvider[]> {
    let url = '/gameprovider/gamecategories?paginate=false';
    if (status) {
      url += '&status=1';
    }
    return this.http.get<ApiResponse>(url).pipe(
      map(res => res.data.rows)
    );
  }

  getGameCategory(id: number | string): Observable<GameCategory[]> {
    return this.http.get<ApiResponse>(`/gameprovider/getgamecategory/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  getGameProviderBalance(params: string){
    return this.http.get<ApiResponse>(`/gameprovider/balance${params}`).pipe(
      map(res => res.data.data)
    );
  }

  getGameAccountsOfMember(username: string, settingsCurrencyId: number): Observable<GameAccountMember[]> {
    return this.http.get<ApiResponse>(`/gameprovider/account?username=${username}&currency_id=${settingsCurrencyId}`).pipe(
      map(res => res.data)
    );
  }

  getGameDataBalanceOfMember(memberId: number, gameProviderId: number): Observable<GameAccountMember> {
    return this.getGameProviderBalance(`?member_account_id=${memberId}&game_provider_id=${gameProviderId}`);
  }

  getSubCategories(providerCodes: any[], categoryCode?: string): Observable<any[]> {
    let params = '', categoryParams = '';
    providerCodes.forEach((code: string, index: number) => {
      params += `game_provider_code[${index}]=${code}&`;
    });
    categoryCode !== undefined ? categoryParams = `category_code=${categoryCode}` : '';
    return this.http.get<ApiResponse>(`/gameprovider/subcategories?${params}${categoryParams}`).pipe(
      map(res => res.data.rows)
    );
  }

  getFreeSpinGameProvider(): Observable<GameProvider[]> {
    return this.http.get<ApiResponse>(`/gameprovider/freespingameproviders`).pipe(
      map(res => res.data.rows)
    );
  }

  getFreeSpinGameList(code: string): Observable<Game[]> {
    return this.http.get<ApiResponse>(`/gameprovider/freespingame/${code}`).pipe(
      map(res => res.data.rows)
    );
  }

}
