import { AppPermissions } from "@core/models/app-permissions.model";
import { appPermissions } from "@core/store/auth/auth.selectors";
import { select, Store } from "@ngrx/store";
import { AppState } from "@store/reducers";
import { Observable } from "rxjs";

export class AppPermissionService {
  appPermissions: AppPermissions;

  constructor(private store: Store<AppState>) {}

  getAppPermissions(): Observable<AppPermissions> {
    return this.store.pipe(select(appPermissions));
  }
}
