<!-- <div class="row">
  <section class="col-lg-12 card border-0">
    <div class="col-12 p-0 card rounded-0">
      <div class="card-header">
        Deposit Details
      </div>
      <section class="col-12 p-4">
        <div class="row border-top border-left">
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Member ID</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ deposit.member_id }}</span>
          </div>
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Username</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ deposit.username }}</span>
          </div>
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Name</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ deposit.name }}</span>
          </div>
        </div>

        <div class="row border-top border-left">
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Payment Method</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ deposit.payment_method }}</span>
          </div>
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Confirmed Amount</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ deposit.confirmed_amount }}</span>
          </div>
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Status</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + (deposit.status === 7 ? '5' : deposit.status)"> {{ status[deposit.status] }}</span>
          </div>
        </div>

        <div class="row border-top border-left border-bottom">
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">References</span>
          </div>
          <div class="col-md-10 border-right p-3">
            <span>{{ deposit.pg_reference_id ? deposit.pg_reference_id : '-' }}</span>
          </div>
        </div>

        <div class="row border-top border-left border-bottom">
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Latest Remark</span>
          </div>
          <div class="col-md-10 border-right p-3">
            <span>{{ deposit.remarks ? deposit.remarks : '-' }}</span>
          </div>
        </div>
      </section>
    </div>
  </section>
</div> -->

<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <!-- <div class="kt-portlet__body"> -->
      <!-- Filter -->
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
            <div class="col-xl-12 order-2 order-xl-1">
              <form class="align-items-center" [formGroup]="form">
                <div class="row mb-2">
                  <div class="col-md-1 kt-form__label col-form-label">
                      <label>Label:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                      <input type="text" formControlName="name" placeholder="Search" class="form-control">
                  </div>
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>Keyword:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <input type="text" formControlName="keyword" placeholder="Search" class="form-control">
                  </div>
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>Status:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <select  class="form-control" formControlName="status">
                      <option value="all">{{ 'All' | translate }}</option>
                      <ng-container *ngFor="let value of dropdown.statuses; let i = index;">
                        <option [value]="i">{{ value | translate }}</option>
                      </ng-container>
                  </select>
                  </div>
                </div>
              </form>
            </div>
        </div>
      </div>

      <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
        <div class="dropdown dropdown-inline">
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <button class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')" *ngIf="canCreateMemberLabelSettings"><i class="fas fa-plus"></i>Create</button>
      </div>

      <div class="kt-section">
        <div class="kt-section__content">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-striped table-sortable">
                <thead>
                  <tr>
                      <th class="text-center sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                      <th class="text-center sort-enabled" (click)="onSortColumn('code')" [ngClass]="sortingConfig.code" [class.sort-selected]="sortingSelection.sort_by === 'code'">Code</th>
                      <th class="text-center sort-enabled" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Label</th>
                      <th class="text-center">Description</th>
                      <th class="text-center">Display</th>
                      <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                      <th class="text-center sort-enabled" (click)="onSortColumn('created_at')"[ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                      <th class="text-center sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                      <th class="text-center" *ngIf="canEditMemberLabelSettings">Actions</th>
                  </tr>
                </thead>
                <tbody *ngIf="(memberLabelSettings$ | async) as rows">
                    <tr *ngFor="let row of rows">
                        <td>{{ row.id }}</td>
                        <td>{{ row.code }}</td>
                        <td>{{ row.name }}</td>
                        <td>{{ row.description }}</td>
                        <td class="text-center">
                          <i class="fas fa-user-tag" [style.color]="row.display_color_code"></i>
                        </td>
                        <td class="text-center">
                          <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status">{{ row.status_name }}</span>
                        </td>
                        <td>
                          {{ row.created_by || "System" }}
                          <br />
                          <span placement="top" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                          <ng-template #createdDateTime>
                              {{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                          </ng-template>
                        </td>
                        <td>
                          {{ row.updated_by || "System" }}
                          <br />
                          <span placement="top" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                          <ng-template #updatedDateTime>
                              {{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                          </ng-template>
                        </td>
                        <td class="text-center actions-column" *ngIf="canEditMemberLabelSettings">
                          <button matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id)" *ngIf="canEditMemberLabelSettings"><i class="fas fa-edit"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
              </table>
            </div>
            <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
              <ngb-pagination
                    [pageSize]="pageSize"
                    [(page)]="page"
                    [maxSize]="maxSize"
                    [directionLinks]="true"
                    [boundaryLinks]="true"
                    [rotate]="true"
                    [collectionSize]="pagination.total"
                    (pageChange)="onViewPageBy(page)">
              </ngb-pagination>
              <div class="kt-pagination__toolbar">
                  <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                      <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                  </select>
                  <span class="pagination__desc">
                      Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                  </span>
              </div>
            </div>
        </div>
      </div>
    <!-- </div> -->
  </div>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="loadingBar.complete()"></kt-swal-alert>
