import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, ChangeDetectorRef, Input } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { WithdrawDataService } from '../../services/withdraw-data.service';
import { WithdrawLog } from '@core/models/withdraw-log.model';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionStatus } from '@core/enums/transaction-status.enum';
import { AppPermissionService } from '@core/services/app-permission.service';
import { PdfDialogComponent } from '@shared/pdf-dialog/pdf-dialog.component';
import { Lightbox } from 'ngx-lightbox';
import * as moment from 'moment-timezone';
import { WithdrawStatus } from '@core/enums/withdraw-status.enum';
import { ViewWithdrawalReceiptComponent } from '../view-withdrawal-receipt/view-withdrawal-receipt.component';

@Component({
  selector: 'kt-withdrawal-log',
  templateUrl: './withdrawal-log.component.html',
  styleUrls: ['./withdrawal-log.component.scss']
})
export class WithdrawalLogComponent implements OnInit, OnDestroy {

  @Input()
  withdraw: any;

  WithdrawStatusEnum = WithdrawStatus;
  
  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  pagination: Pagination;
  private subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  loadMoreButtonLoading = false;
  messages$ = this.withdrawDataService.messages$;
  loading = false;
  status = TransactionStatus;
  dropdown = {
    perPage: this.dropdownHttpService.perPage,
  };
  pageSize = 6;
  page = 1;
  depositReceipts = [];
  allLogs: any[] = [];
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  // permissions
  canViewReceipts: boolean;

  private subscriptions = new Subscription();

  constructor(
    private withdrawDataService: WithdrawDataService,
    public dialog: MatDialog,
    public loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
    private lightbox: Lightbox,
  ) { }

  ngOnInit() {
    this.getTimeline();
    this.pagination = this.withdrawDataService.pagination;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewReceipts = this.withdraw.is_crypto === 1 ?
        false :
        appPermissions.view_deposit_receipts;

      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  public getTimeline(paginate = true, page = 1, pageSize?: number) {
    this.allLogs = [];
    this.loading = true;
    this.loadingBar.useRef().start();
    pageSize = this.pageSize;
    return this.withdrawDataService
      .getWithdrawLog(`?paginate=${paginate}&page=${page}&perPage=${pageSize}&withdraw_id=${this.withdraw.id}`)
      .pipe(
        tap(res => {
          this.allLogs = res;
          this.pagination = this.withdrawDataService.pagination;
          this.loadingBar.useRef().complete();
          this.loading = false;
          this.loadMoreButtonLoading = false;
        }),
        catchError((err) => {
          this.loading = false;
          this.loadingBar.useRef().complete();
          return throwError(err);
        })
      ).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { remarks?: any, withdraw?: any, withdrawal?: any }) {
    this.dialog.open(componentRef, {
      width: '800px',
      data: {
        remarks: data.remarks,
        withdraw: data.withdraw,
        withdrawal: data.withdrawal,
      }
    });
  }

  openReceipt(data: any) {
    let receiptImages = this.getAllReceiptImages(data);
    if (receiptImages.length > 0) {
      let dialogData = {
        id: this.withdraw.id,
        receipt: receiptImages,
      };

      this.openDialogBy(ViewWithdrawalReceiptComponent, { withdrawal: dialogData });
    }
  }

  loadMoreLogs(): void {
    this.loadMoreButtonLoading = true;
    this.getTimeline(false);
  }

  getAllReceiptImages(row: any) {
    // Retrieve all the receipt_image for this withdrawal log
    if (row.bank_transaction.length > 0) {
      // Map and filter to get all non-empty `receipt_image` values
      return row.bank_transaction
            .filter((transaction: any) => transaction.receipt_image && transaction.receipt_image.trim() !== '')
            .map((transaction: any) => ({
              bank_transaction_id: transaction.bank_transaction_id,
              bank_transaction_created_at: transaction.bank_transaction_created_at,
              receipt_image: transaction.receipt_image,
            }));
    }
    // Return an empty array if `bank_transaction` is null or not an array
    return [];
  }

  getLighterColor(hex: string, percent: number = 30): string {
    if (!hex) return '#f7f8fa'; // Default to grey if no color is provided
    hex = hex.replace('#', '');
  
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
  
    // Increase each channel value to make it lighter (but not exceed 255)
    r = Math.min(255, r + (255 - r) * (percent / 100));
    g = Math.min(255, g + (255 - g) * (percent / 100));
    b = Math.min(255, b + (255 - b) * (percent / 100));
  
    return `rgb(${r}, ${g}, ${b})`; // Return the lightened color
  }
}
