import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AppPermissionService } from '@core/services/app-permission.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TranslateService } from '@ngx-translate/core';
import { VipContentSettingsDataService } from './services/vip-content-settings-data.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { UploadHttpService } from '@core/services/upload-http.service';
import { Subscription, Observable } from 'rxjs';
import { Pagination } from '@core/models/pagination.model';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { tap, catchError } from 'rxjs/operators';
import { VipSettingsDataService } from '../settings/services/vip-settings-data.service';
import Swal from "sweetalert2";
import * as moment from 'moment-timezone';
import { VIPContentNameDialogComponent } from './dialogs/vip-name-dialog/vip-content-name-dialog.component';

@Component({
  selector: 'kt-vip-content-settings',
  templateUrl: './vip-content-settings.component.html',
  styleUrls: ['./vip-content-settings.component.scss']
})
export class VipContentSettingsComponent implements OnInit {
  form: FormGroup; 
  formSection: FormGroup;
  formContent: FormGroup;
  localeCheckboxForm: FormGroup;
  vipContentSubArrayForm: FormGroup;

  vipContentSubArr$ = [];
  vipContentSettings$: any;
  vipSection: any
  vipContent: any;
  messages$ = this.vipContentSettingsDataService.messages$;

  memberGroupListDropdownList = [];
  memberGroupListDropdownListSettings = {
    autoPosition: true,
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 200, //'auto',
    primaryKey: 'id',
    labelKey: 'code',
    noDataLabel: '',
    showCheckbox: false,
  };
  memberGroupListSelectedItems = [];

  backUpVipContentSubArr: any;
  isOnAddClicked = false;
  checkValidation = false;
  vipSectionSelected = false;

  displayLocale: any;
  displayGroup: any;

  mode: any;
  viewVipContent: any;
  changedObjects: any;
  changedObjects1: any;
  initVipContent: any;
  initVipSection: any;
  modeVipSection: any;
  availableVipContents = [];

  vipContentSettingsData = {
    vip_sections: [],
    vip_contents: [],
  }

  unsaveStatus = true;
  loading = false;
  buttonLoading = true;
  clearBtnLoading = false;
  searchBtnLoading = false;
  checkLoadingPage = false;
  viewMode: any = false;
  showAddSubTier = false;
  isDisabledVipName = false;
  isEmptyContent = true;

  imageLoading = false;
  isImageUploading = false;
  imagePreview = new Array(2);

  dataLength: number;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  backupPageSize = 30;
  backupPage = 1;

  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;

  valueTypes: { [key: string]: string } = {};
  showContentSettingDetails: { [key: string]: boolean } = {};
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  updatedBy: any;
  createdBy: any;
  updatedAt: any;
  createdAt: any;

  sortingConfig = {
    'vip_content_setting_id': 'desc',
    'position': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',
  };

  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    locales: [],
    activeVipMemberGroups: [],
    perPage: this.dropdownHttpService.perPage,
    dateType: this.dropdownHttpService.vipContentSettingDateTypes,
    displayType: this.dropdownHttpService.vipContentSettingDisplayTypes,
    valueType: this.dropdownHttpService.vipContentSettingValueTypes,
    groups: [],
  };

  memberGroupSelectedItems = [];
  memberGroupDropdownList = [];
  memberGroupDropdownSettings = {
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    singleSelection: true,
    classes: 'dropdown',
    primaryKey: 'id',
    labelKey: 'name',
    noDataLabel: '',
    showCheckbox: false,
    maxHeight: 200
};
  
  // permission
  canViewVIPStructureSection: boolean;
  canViewVIPStructureDetails: boolean;
  canCreateVIPStructure: boolean;
  canEditVIPStructureDetails: boolean;
  canViewVIPContentSettingsList: boolean;
  canViewVIPContentSettings: boolean;
  canCreateVIPContentSettings: boolean;
  canEditVIPContentSettings: boolean;
  canUpdateVIPContentStatus: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  private localesSub = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
    private vipContentSettingsDataService: VipContentSettingsDataService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private loadingBar: LoadingBarService,
    private uploadService: UploadHttpService,
    private fb: FormBuilder,
    private transactionHttpService: TransactionHttpService,
    private vipSettingsDataService: VipSettingsDataService,
    private dialog: MatDialog,
  ) { }

  async canDeactivate(): Promise<boolean | Observable<boolean>> {
      // provide component specific logic to decide if component can or can't be deactivated
      if (this.checkupdate()) {
        const result = await Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            return (true);
          } else if (result.isDenied) {
            return (false);
          }
        });
  
        return result;
      }
      return true;
    }

  async ngOnInit() {
    this.dropdownHttpService.groups.subscribe(res => {
      res.map(function (elm) {
        elm['id'] = elm.id;
        elm['name'] = elm.name.toUpperCase();
      });

      this.dropdown.groups = this.dropdown.groups.concat(res);
      this.memberGroupDropdownList = this.memberGroupDropdownList.concat(res);
    });

    await this.setActiveMemberGroupDropdown();

    this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.vipSettingsDataService.locales$.next(res);
      this.dropdown.locales = res;
      this.displayLocale = this.dropdown.locales[0];
      this.formInit();
      this.contentSupInit();
      this.pagination = this.vipContentSettingsDataService.pagination;
      this.onSubmit();
    })).subscribe();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewVIPStructureSection = appPermissions.view_vip_structure_section;
      this.canViewVIPStructureDetails = appPermissions.view_vip_structure_details;
      this.canCreateVIPStructure = appPermissions.create_vip_structure;
      this.canEditVIPStructureDetails = appPermissions.edit_vip_structure_details;
      this.canViewVIPContentSettingsList = appPermissions.view_vip_content_settings_list;
      this.canViewVIPContentSettings = appPermissions.view_vip_content_settings;
      this.canCreateVIPContentSettings = appPermissions.create_vip_content_settings;
      this.canEditVIPContentSettings = appPermissions.edit_vip_content_settings;
      this.canUpdateVIPContentStatus = appPermissions.update_vip_content_status;
    });
  }

  setActiveMemberGroupDropdown():Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dropdownHttpService.activeVipMemberGroups.subscribe(res => {
        this.dropdown.activeVipMemberGroups = res.filter(group => 
          group.member_group_id !== null && group.member_group_name !== null
      );
      this.displayGroup = this.dropdown.activeVipMemberGroups[0] || null;
      resolve();
      });
    });
  }

  reload(clearSearch?: boolean) {
    this.mode = '';
    this.isEmptyContent = true;
    this.onClearContent();
    this.modeVipSection = '';
    this.onClearVipSection();
    this.onSubmit(clearSearch);
    this.initVipContent = JSON.stringify({ ...this.formContent.value });
    this.initVipSection = JSON.stringify({ ...this.formSection.value }) + JSON.stringify({ ...this.vipContentSubArr.value });
    this.buttonLoading = false;
  }
  
  onChangeStatus($event, row: any) {
    const data = {
      vip_content_setting_id: row.vip_content_setting_id,
      main_status: $event.target.checked ? 1 : 0,
    };

    this.subscription = this.vipContentSettingsDataService.updateStatus(data).pipe(
      tap((res: any) => {
        this.messages$.next(res.message);
        this.buttonLoading = false;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        throw error;
      })
    ).subscribe();
  }

  checkReopen(mode: any, row?: any) {
    switch (mode) {
      case 'edit':
        this.expandRowEdit(mode, row)
        break;
      case 'create':
        this.expandRowCreate(mode);
        break;
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onClearDate(type: string) {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({
        start_datetime: null,
        end_datetime: null,
        defaultDate: null,
      });
    }
  }

  localeIndex() {
    const index = this.dropdown.locales.findIndex(item => {
      return item.id == this.displayLocale['id'];
    });

    return index;
  }

  changeLocale(locale: any) {
    this.displayLocale = locale;
    this.form.patchValue({
      settings_locale_id: locale['id']
    })

    const data = {
      ...this.form.value,
    };

    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);

    if (data['defaultDate']) {
      delete data['defaultDate'];
    }

    if (data['start_datetime']) {
      data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
      data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
    }

    this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    this.sortingSelection = {
      'sort_by': 'id',
      'sort_order': 'desc',
    };
  }

  changeGroup(group: any) {
    this.displayGroup = group;
  }

  checkContent(locale: any) {
    let label = this.formContent.value.contents[locale.id].label;
    let position = this.formContent.value.contents[locale.id].position;

    if ((label == null || label == '') && (position == null || position == '')) {
      return false;
    }
    else {
      return true;
    }
  }

  clearContent(locale: any) {
    Swal.fire({
      title: 'Delete Content',
      text: 'Are you sure you want to delete contents for ' + locale.code + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this.formContent.patchValue({
          contents: {
            [locale.id]: {
              label: null,
              position: null,
              value_type: 1,
              status: null,
            }
          }
        });

        // Set default
        this.showContentSettingDetails[locale.id] = true;
        this.valueTypes[locale.id] = 'Text';

        this.dropdown.activeVipMemberGroups.forEach((element2: any) => {
          this.formContent.patchValue({
            contents: {
              [locale.id]: {
                details: {
                  [element2.member_group_id]: {
                    text_value: null,
                    symbol_value: null
                  }
                }
              }
            }
          });
        });

        this.cdr.detectChanges();
      }
    });
  }

  expandRowCreate(mode: any, close?: boolean) {
      let vipContentInput = JSON.stringify({ ...this.formContent.value });
      this.changedObjects = this.initVipContent != vipContentInput ? true : false;
    
      if (close != true && this.changedObjects == false && this.checkupdate() == false) {
        this.dropdown.locales.forEach(locale => {// Set default
          this.showContentSettingDetails[locale.id] = true;
          this.valueTypes[locale.id] = 'Text';
        });

        this.viewMode = null;
        this.modeVipSection = null;
        
        this.changedObjects = false;
        this.initVipContent = null;
        this.mode = mode;
        this.vipSection =null;
        this.onClearVipSection();
  
        this.cdr.detectChanges();
  
        this.onClearContent();
  
        this.checkLoadingPage = false;
  
        setTimeout(() => {
          this.checkLoadingPage = true;
          this.cdr.detectChanges();
        }, 1500);
  
      } else {
        if (this.changedObjects == true || this.checkupdate() == true) {
          Swal.fire({
            title: '<div class="text-center">Unsaved Changes</div>',
            html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
            showDenyButton: true,
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: true,
            reverseButtons: true,
            denyButtonText: this.translateService.instant('Cancel'),
            confirmButtonText: this.translateService.instant('Discard'),
            icon: 'warning',
            customClass: {
              denyButton: 'deny-button',
              confirmButton: 'confirm-button',
            }
          }).then(result => {
            if (result.isConfirmed) {
              this.mode = '';
              this.isEmptyContent = true;
              this.unsaveStatus = true;
              this.modeVipSection = '';
              this.onClearContent();
              this.onClearVipSection();
              // Check if reopen needed
              this.checkReopen(mode);
            }
          });
        }
        else {
          this.mode = '';
          this.isEmptyContent = true;
          this.unsaveStatus = true;
          this.onClearContent();
        }
      }
  
      this.cdr.detectChanges();
    }

  expandRowEdit(mode: any, row?: any, close?: boolean) {
    if (close != true) close = this.vipContent == row ? true : false;
  
    let vipContentInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initVipContent && this.initVipContent != vipContentInput ? true : false;
    if (close != true && this.changedObjects == false && this.checkupdate() == false) {
      this.vipContent = row;

      this.changedObjects = false;
      this.initVipContent = null;
      this.mode = mode;
      this.modeVipSection =null;
      this.onClearVipSection();

      this.viewMode = null;
      this.vipSection = null;

      this.cdr.detectChanges();

      // reset all locales to null
      this.dropdown.locales.forEach((element: any) => {
        this.formContent.patchValue({
          contents: {
            [element.id]: {
              label: null,
              position: null,
              value_type: 1,
              status: null,
            }
          }
        });
        this.showContentSettingDetails[element.id] = true; 
        this.valueTypes[element.id] = 'Text';

        this.dropdown.activeVipMemberGroups.forEach((element2: any) => {
          this.formContent.patchValue({
            contents: {
              [element.id]: {
                details: {
                  [element2.member_group_id]: {
                    text_value: null,
                    symbol_value: null
                  }
                }
              }
            }
          });
        });
      });

      setTimeout(() => {
        this.vipContent.locale_data.forEach((element: any) => {
          this.formContent.patchValue({
            contents: {
              [element.settings_locale_id]: {
                label: element.label,
                position: element.position,
                value_type: element.value_type,
                status: element.status,
              }
            }
          })

          this.valueTypes[element.settings_locale_id] = element.value_type_name;
          this.showContentSettingDetails[element.settings_locale_id] = true;
          
          this.createdBy = element.created_by; 
          this.updatedBy = element.updated_by; 
          this.createdAt = element.created_at; 
          this.updatedAt = element.updated_at; 

          element.details.forEach((value: any) => {
            this.formContent.patchValue({
              contents: {
                [element.settings_locale_id]: {
                  details: {
                    [value.member_group_id]: {
                      text_value: value.text_value,
                      symbol_value: value.symbol_value === "1"
                    }
                  }
                }
              }
            });
          })
        });
        
        this.cdr.detectChanges();
        
        this.initVipContent = JSON.stringify({ ...this.formContent.value });
      }, 500);
      
      this.checkLoadingPage = false;

      setTimeout(() => {
        this.checkLoadingPage = true;
        this.cdr.detectChanges();
      }, 1500);

    } else {
      this.changedObjects = this.initVipContent != JSON.stringify({ ...this.formContent.value }) ? true : false;
      if (this.changedObjects == true || this.checkupdate() == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.mode = '';
            this.isEmptyContent = true;
            this.unsaveStatus = true;
            this.onClearContent();
            this.onClearVipSection();
            
            // Check if reopen needed
            if (this.vipContent == row) {
              this.vipContent = null;
            }
            else {
              this.vipContent = null;
              this.modeVipSection = '';
              this.checkReopen(mode, row);
            }
          }
        });
      }
      else {
        this.mode = '';
        this.isEmptyContent = true;
        this.unsaveStatus = true;
        this.vipContent = null;
        this.onClearContent();
      }
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.vipSection = null;
    this.form.patchValue({
      defaultDate: null,
      start_datetime: null,
      end_datetime: null,
      label: null,
      settings_locale_id: this.dropdown.locales[0].id,
      status: 1,
      date_type: 'created_at'
    });
  }

  onSave() {
    this.buttonLoading = true;

    const data = {
      id: this.vipContent ? this.vipContent.vip_content_setting_id : null,
      ...this.formContent.value,
    };
    let checkcontent = false;

    Object.keys(data['contents']).forEach((key) => {
      if ( (data['contents'][key]['position'] !== null && data['contents'][key]['position'] !=='')) {
        checkcontent = true;
      }
    });

    if(!checkcontent){
      Swal.fire({
        title: '<div class="text-center">Fill in at least one locale content</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        reverseButtons: false,
        denyButtonText: this.translateService.instant('Close'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button'
        }
      }).then(result => {
        this.buttonLoading = false;
      });

    }

    Object.keys(data).forEach((key) => {
      if (data[key] == null || data[key] === '' || data[key] === 'Invalid date') {
        delete data[key];
      }
    });

    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.formContent.setErrors({ 'invalid': true });
    //this.buttonLoading = false;
    if(checkcontent){
      switch (this.mode) {
        case 'edit':
          this.subscription = this.vipContentSettingsDataService.update(this.vipContent.vip_content_setting_id, data).pipe(
            tap((res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
              this.dropdown.locales.forEach(locale => {
                this.showContentSettingDetails[locale.id] = false; // Set default to false
              });
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.formContent.setErrors(null);
              this.formContent.enable();
              throw error;
            })
          ).subscribe();
          break;
        case 'create':
          this.subscription = this.vipContentSettingsDataService.add(data).pipe(
            tap((res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
              this.dropdown.locales.forEach(locale => {
                this.showContentSettingDetails[locale.id] = false; // Set default to false
              });
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.formContent.setErrors(null);
              this.formContent.enable();
              throw error;
            })
          ).subscribe();
          break;
      }
    }
  }

  async onSaveVipSection() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.formSection.setErrors({ 'invalid': true });

    const data = {
      id: this.vipSection ? this.vipSection.id : null,
      ...this.formSection.value,
    };

    let subVipList = [];
    this.vipContentSubArr.controls.forEach((control, index)=> {
      let item = this.filterFormFields(control.value);
      item['status'] = item['status'] ? 1 : 0;
      subVipList.push(item);
    });

    data['details'] = subVipList;

    if (data['display_type'] == 1) {
      // Main
      data['details'] = []; 
    } else {
      delete data['member_group_id'];
    }

    let checkcontent = true;

    Object.keys(data).forEach((key) => {
      if (data[key] == null || data[key] === '' || data[key] === 'Invalid date') {
        delete data[key];
      }
    });

    if(checkcontent){
      switch (this.modeVipSection) {
        case 'edit':
          this.subscription = this.vipContentSettingsDataService.updateSection(this.vipSection.id, data).pipe(
            tap(async(res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
              this.vipSection = null;
              // Retreive active member groups set
              await this.setActiveMemberGroupDropdown();
              this.rebuildFormContent();
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.formContent.setErrors(null);
              this.formContent.enable();
              throw error;
            })
          ).subscribe();
          break;
        case 'create':
          this.subscription = this.vipContentSettingsDataService.addSection(data).pipe(
            tap(async(res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
              this.vipSection = null;
              // Retreive active member groups set
              await this.setActiveMemberGroupDropdown();
              this.rebuildFormContent();
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.formContent.setErrors(null);
              this.formContent.enable();
              throw error;
            })
          ).subscribe();
          break;
      }
    }
  }

  async onSubmit(clearSearch?: boolean) {
    await this.onCheckUnsave();

    if (this.unsaveStatus == true) {
      this.searchBtnLoading = clearSearch ? false : true;
      this.loading = true;

      if (clearSearch) {
        this.onClear();
      }

      const data = {
        ...this.form.value,
      };

      Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);

      if (data['defaultDate']) {
        delete data['defaultDate'];
      }

      if (data['start_datetime']) {
        data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
      }

      this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
      const parameters = this.params ? `&${this.params}` : '';
      this.loadingBar.start();

      this.vipContentSettings$ = null;
      this.vipContentSettingsDataService.getWithQuery(`?perPage=${this.pageSize}${parameters}&${this.generateSortingParam()}`).subscribe(res => {
        if(res){
          this.vipContentSettings$ = res;
        }

        this.mode = null;
        this.modeVipSection = null;
        this.onClearContent();
        this.onClearVipSection();

        this.page = 1;
        this.pagination = this.vipContentSettingsDataService.pagination;
        this.dataLength = res['vip_contents'].rows.length;
        this.vipContentSettingsData.vip_sections = res['vip_sections'];
        this.vipContentSettingsData.vip_contents = res['vip_contents'].rows;
        this.availableVipContents = res['vip_contents'].rows;

        this.loading = false;
        this.buttonLoading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.loadingBar.complete();
        this.cdr.detectChanges();
      })
    }
  }

  private onCheckUnsave() {
    return new Promise<void>((resolve, reject) => {
      let vipContentInput = JSON.stringify({ ...this.formContent.value });
      let vipSectionInput = JSON.stringify({ ...this.formSection.value }) + JSON.stringify({ ...this.vipContentSubArr.value });
      this.changedObjects = this.initVipContent && this.initVipContent != vipContentInput ? true : false;
      this.changedObjects1 = this.initVipSection && this.initVipSection != vipSectionInput ? true : false;

      if (this.changedObjects == true || this.changedObjects1 == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.mode = '';
            this.vipContent = null;
            this.isEmptyContent = true;
            this.unsaveStatus = true;
            this.onClearContent();
            resolve();
          }
          else {
            this.unsaveStatus = false;
            resolve();
          }
        });
      }
      else {
        resolve();
      }
    });
  }

  onClearContent() {
    this.dropdown.locales.forEach((element: any) => {
      this.formContent.patchValue({
        contents: {
          [element.id]: {
            label: null,
            position: null,
            value_type: 1,
            status: null,
          }
        }
      });

      // Set default
      this.showContentSettingDetails[element.id] = true;
      this.valueTypes[element.id] = 'Text';

      this.dropdown.activeVipMemberGroups.forEach((element2: any) => {
        this.formContent.patchValue({
          contents: {
            [element.id]: {
              details: {
                [element2.member_group_id]: {
                  text_value: null,
                  symbol_value: null
                }
              }
            }
          }
        });
      });
    });
    
    this.initVipContent = JSON.stringify({ ...this.formContent.value });
  }

  expandRowVipSectionCreate(mode: any, close?: boolean) {
    let vipSectionInput = JSON.stringify({ ...this.formSection.value }) + JSON.stringify({ ...this.vipContentSubArr.value });
    this.changedObjects = this.initVipSection && this.initVipSection != vipSectionInput ? true : false;

    if (close != true && this.changedObjects == false && this.checkupdate() == false) {
      this.contentSupInit();
      this.resetValue();
      this.viewMode = null;
      this.modeVipSection = null;
      this.showAddSubTier = false;
      this.memberGroupSelectedItems = [];
      this.changedObjects = false;
      this.initVipSection = null;
      this.modeVipSection = mode;
      this.vipSection = null;
      this.mode = null;
      this.onClearContent();

      this.cdr.detectChanges();

      this.onClearVipSection();
    } else {
      if (this.changedObjects == true || this.checkupdate() == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.modeVipSection = '';
            this.vipSection = null;
            this.onClearContent();
            this.onClearVipSection();
            // Check if reopen needed
            this.checkReopenVipSection(mode);
          }
        });
      }
      else {
        this.modeVipSection = '';
        this.onClearVipSection();
      }
    }

    this.cdr.detectChanges();
  }

  expandRowVipSectionEdit(mode: any, row?: any, close?: boolean) {
    this.loading = true;
    this.vipSectionSelected = true;

    if (close != true) close = this.vipSection == row ? true : false;

    let vipSectionInput = JSON.stringify({ ...this.formSection.value }) + JSON.stringify({ ...this.vipContentSubArr.value });
    this.changedObjects = this.initVipSection && this.initVipSection != vipSectionInput ? true : false;
    
    if (close != true && this.changedObjects == false && this.checkupdate() == false) {
      this.isDisabledVipName = true;
      this.contentSupInit();
      this.resetValue();
      this.vipContentSubArr$ = Object.assign([], row.details);

      this.viewMode = null;
      this.modeVipSection = null;

      this.vipSection = row;
      this.changedObjects = false;
      this.initVipSection = null;
      this.modeVipSection = mode;
      this.mode = null;
      this.onClearContent();

      this.formSection.patchValue({
        main_vip_name : this.vipSection.main_vip_name,
        member_group_id : this.vipSection.member_group_id ? this.vipSection.member_group_id : null,
        position: this.vipSection.position,
        display_type: this.vipSection.display_type,
        status: this.vipSection.status,
        main_vip_icon: this.vipSection.main_vip_icon
      }); 

      if (this.vipSection.member_group_id) {
        this.memberGroupSelectedItems = this.memberGroupDropdownList.filter(x => x.id == this.vipSection.member_group_id);
      }

      this.showAddSubTier = this.vipSection.display_type == 1 ? false : true;

      this.vipSection.details.forEach((element: any) => {
        this.formSection.patchValue({
          details: {
            [element.id]: {
              position: null,
              status: null,
              vip_names: [],
            }
          }
        });
      });

      if (this.vipContentSubArr$.length > 0) {
        this.vipContentSubArr$.map(item => {
          this.onVipContentSubAddRow(false, item);
          this.cdr.detectChanges();
        });
      }

      setTimeout(() => {
        this.assignVipContentSubList();
      }, 1000);
      
      
      if (this.formSection.value.display_type == 1) {
        this.vipSettingsDataService.getVIPNameLocales(this.formSection.value.member_group_id).subscribe(res => {
          let vipNameList = res['vip_name'];
          this.formSection.removeControl('vip_names');
          this.formSection.addControl('vip_names', new FormGroup(this.buildVIPName(vipNameList)));
          this.isDisabledVipName = false;
          
          this.initVipSection = JSON.stringify({ ...this.formSection.value }) + JSON.stringify({ ...this.vipContentSubArr.value });
          this.loading = false;
          this.cdr.detectChanges();
        });
      }
      else if (this.formSection.value.display_type == 2) {
        this.vipContentSettingsDataService.getVIPSectionNameLocales(this.vipSection.id).subscribe(res => {
          let vipNameList = res['vip_name'];
          this.formSection.removeControl('vip_names');
          this.formSection.addControl('vip_names', new FormGroup(this.buildVIPName(vipNameList)));
          this.isDisabledVipName = false;
          this.initVipSection = JSON.stringify({ ...this.formSection.value }) + JSON.stringify({ ...this.vipContentSubArr.value });
          this.loading = false;
          this.cdr.detectChanges();
        });
      }
    } else {
      this.loading = false;
      this.changedObjects = this.initVipSection != (JSON.stringify({ ...this.formSection.value }) + JSON.stringify({ ...this.vipContentSubArr.value })) ? true : false;
      if (this.changedObjects == true || this.checkupdate() == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            //this.vipContentSubArr$ = Object.assign([], this.vipSection.details);
            this.modeVipSection = '';
            this.contentSupInit();
            this.resetValue();
            this.onClearVipSection();
            this.onClearContent();
            // Check if reopen needed
            if (this.vipSection == row) {
              this.vipSection = null;
            }
            else {
              this.vipSection = null;
              this.vipContent = null;
              this.checkReopenVipSection(mode, row);
            }
          }
        });
      }
      else {
        this.modeVipSection = '';
        this.vipSection = null;
        this.onClearVipSection();
        this.onClearContent();
      }

      this.loading = false;
      this.cdr.detectChanges();
    }
  }

  checkReopenVipSection(mode: any, row?: any) {
    switch (mode) {
      case 'edit':
        this.expandRowVipSectionEdit(mode, row)
        break;
      case 'create':
        this.expandRowVipSectionCreate(mode);
        break;
    }
  }

  onClearVipSection() {
    this.formSection.patchValue({
      main_vip_name: null,
      position: null,
      display_type: null,
      status: null,
      main_vip_icon: null
    })

    this.dropdown.locales.forEach((element: any) => {
      this.formSection.patchValue({
        details: {
          [element.id]: {
            position: null,
            status: null
          }
        }
      });
    });
    this.initVipSection = JSON.stringify({ ...this.formSection.value }) + JSON.stringify({ ...this.vipContentSubArr.value });
  }

  checkupdate() {
    const vipContentCheck = this.initVipContent !== JSON.stringify({ ...this.formContent.value }) ? true : false;
    const vipSectionCheck = this.initVipSection !== JSON.stringify({ ...this.formSection.value }) + JSON.stringify({ ...this.vipContentSubArr.value }) ? true : false;
    return (vipContentCheck || vipSectionCheck) ? true : false;
  }

  displayGroupValue(row: any) {
    let availableData = this.availableVipContents.filter(x => x.vip_content_setting_id == row.vip_content_setting_id)[0]['locale_data'];
    let localeDetails = availableData.filter(x => x['settings_locale_id'] == this.displayLocale['id']);

    if (localeDetails.length > 0) {
      let valueType = localeDetails[0]['value_type_name'];

      let details = localeDetails.filter(x => x['settings_locale_id'] == this.displayLocale['id'])[0]['details'];

      details = details.filter(x => x['member_group_id'] == this.displayGroup['member_group_id']);

      if (details.length > 0) {
        // Text type
        if (valueType == 'Text') {
          return details[0]['text_value'] ? details[0]['text_value'] : '-';
        } 
        // Symbol Type
        else if (valueType = 'Symbol') {
          let symbolValue = details[0]['symbol_value'];
         
          if (symbolValue == 1) {
            return '<i class="fas fa-check kt-font-info"></i>';
          } else {
            return '<i class="fas fa-times kt-font-danger"></i>';
          }
        } else {
          return '-';
        }
      }
      else {
        return '-';
      }
    } else {
      return '-';
    }
  }

  displayLocaleData(row: any, type: string) {
    let contents = this.availableVipContents.filter(x => x.vip_content_setting_id == row.vip_content_setting_id)[0]['locale_data'];

    contents = contents.filter(x => x['settings_locale_id'] == this.displayLocale['id']);

    if (contents.length > 0) {
      if (type == 'label') {
        return contents[0]['label'] != null ? contents[0]['label'] : '-';
      } else if (type == 'position') {
        return contents[0]['position'] != null ? contents[0]['position'] : '-';
      } else if (type == 'createdBy') {
        return contents[0]['created_by'] != null ? contents[0]['created_by'] : 'System';
      } else if (type == 'updatedBy') {
        return contents[0]['updated_by'] != null ? contents[0]['updated_by'] : 'System';
      } else if (type == 'createdAt') {
        return contents[0]['created_at'] != null ? contents[0]['created_at'] : '-';
      } else if (type == 'updatedAt') {
        return contents[0]['updated_at'] != null ? contents[0]['updated_at'] : '-';
      }
    } else {
        return '-';
    }
  }

  getMediaSource() {
    let media: any;
    media = this.formSection.get(`main_vip_icon`).value;
    return media;
  }
  
  onUploadFile(event: any) {
    this.imageLoading = true;
    this.isImageUploading = true;

    const file: File = event.target.files[0];
    const formData = new FormData();

    if (file !== undefined) {
      formData.append('files', file, file.name);
      formData.append('type', 'main_vip_icon');

      this.uploadService.upload(formData).subscribe(
        res => {
          this.imageLoading = false;
          this.imagePreview = res;

          res.forEach(x => {
            this.formSection.patchValue({
              main_vip_icon: this.imagePreview[0]
            });
          });

          this.formSection.markAllAsTouched();
          this.isImageUploading = false;
          this.cdr.detectChanges();
        },
        err => {
          this.isImageUploading = false;
          this.imageLoading = false;
        }
      );
    } else {
      this.isImageUploading = false;
      this.imageLoading = false;
    }
  }
  
  onRemoveFile() {
    this.formSection.patchValue({
      main_vip_icon: null
    });
    this.imagePreview = null;
  }

  checkBoxChecking(mode: string, localeId: number, memberGroupId: number) {
    if (mode == 'edit') {
      let currentLocaleData =  this.vipContent.locale_data.filter(x => x.settings_locale_id == localeId);

      if (currentLocaleData.length > 0) {
        // Current data initially is Symbol type
        if (currentLocaleData[0].value_type == 2) {
          const details = this.formContent.value.contents[localeId]?.details;
          if (details?.[memberGroupId]) {
            const symbolValue = (details[memberGroupId]?.symbol_value == 0 || details[memberGroupId]?.symbol_value == null) ? false : true;
            return symbolValue;
          }
        } else {
          // Manual change to Symbol type 
          return true;
        }
      } else {
        // Sync locale
        const syncedSymbolValue = this.formContent.value.contents[localeId].details[memberGroupId].symbol_value == 0 || this.formContent.value.contents[localeId].details[memberGroupId].symbol_value == null ? false : true;
        return syncedSymbolValue;
      }

    } 

    return true;
    
  }


  onValueTypeChanged(type: any, localeId: string) {
    this.valueTypes[localeId] = type == 1 ? 'Text' : 'Symbol';

    this.dropdown.activeVipMemberGroups.forEach((element: any) => {
      this.formContent.patchValue({
        contents: {
          [localeId]: {
            details: {
              [element.member_group_id]: {
                text_value: null,
                symbol_value: this.valueTypes[localeId] == 'Symbol' ? true : null
              }
            }
          }
        }
      });
    });
    
    this.showContentSettingDetails[localeId] = true;
  }

  onDisplayTypeChanged(type: any) {
    this.showAddSubTier = type == 1 ? false : true;
    this.memberGroupSelectedItems = [];

    this.formSection.patchValue({
      member_group_id: null,
      main_vip_name: null
    })

    if (this.vipSection && this.formSection.value.display_type == 2) {
      this.vipContentSettingsDataService.getVIPSectionNameLocales(this.vipSection.id).subscribe(res => {
        let vipNameList = res['vip_name'];
        this.formSection.removeControl('vip_names');
        this.formSection.addControl('vip_names', new FormGroup(this.buildVIPName(vipNameList)));
        this.isDisabledVipName = false;
        this.cdr.detectChanges();
      });
    }
    else {
      this.formSection.removeControl('vip_names');
      this.formSection.addControl('vip_names', new FormGroup(this.buildVIPName()));
      this.isDisabledVipName = false;
      this.cdr.detectChanges();
    }
  }

  checkValueType(localeId: string) {
    return this.valueTypes[localeId];
  }

  onChangeMemberGroup(value: any){
    this.isDisabledVipName = true;
    if (value.length > 0) {
      this.formSection.patchValue({
        member_group_id: value[0].id,
        main_vip_name: value[0].name
      })

      if (this.formSection.value.display_type == 1) {
        this.vipSettingsDataService.getVIPNameLocales(value[0].id).subscribe(res => {
          let vipNameList = res['vip_name'];
          // if (this.data.mode == 'edit') {
          //     this.data.vipSettings.vip_name = res['vip_name'];
          // }

          this.formSection.removeControl('vip_names');
          this.formSection.addControl('vip_names', new FormGroup(this.buildVIPName(vipNameList)));
          this.isDisabledVipName = false;
          this.cdr.detectChanges();
        });
      }
    }
    else {
      this.formSection.patchValue({
        member_group_id: null,
        main_vip_name: null,
        vip_names: []
      })
      
      this.formSection.removeControl('vip_names');
      this.cdr.detectChanges();
    }
  }

  onLocalesSynced(res: any) {
    //Duplicate current data to selected locales
    const currentData = Object.values(this.formContent.value.contents).filter(
      x => x['settings_locale_id'] == res['settingsLocaleIdFrom']
    );

    res['selectedLocales'].forEach((element: any) => {
      this.formContent.patchValue({
        contents: {
          [element]: {
            label: currentData[0]['label'],
            position: currentData[0]['position'],
            value_type: currentData[0]['value_type'],
            status: currentData[0]['status'],
          }
        }
      });

      this.valueTypes[element] = currentData[0]['value_type'] == 1 ? 'Text' : 'Symbol';

      Object.keys(currentData[0]['details']).forEach((element2: any) => {
        this.formContent.patchValue({
          contents: {
            [element]: {
              details: {
                [currentData[0]['details'][element2]['member_group_id']]: {
                  member_group_id: currentData[0]['details'][element2]['member_group_id'],
                  text_value: currentData[0]['details'][element2]['text_value'],
                  symbol_value: currentData[0]['details'][element2]['symbol_value']
                }
              }
            }
          }
        });
      });
      this.showContentSettingDetails[element] = true;
    });

    //this.initVipContent = JSON.stringify({ ...this.formContent.value });
    this.cdr.detectChanges();
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  async onSortColumn(property: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      // Reset other columns
      for (const key in this.sortingConfig) {
        if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
          if (key == property) {
            this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
          } else {
            this.sortingConfig[key] = 'desc';
          }
        }
      }

      // User selection
      this.sortingSelection.sort_by = property;

      if (this.sortingSelection.sort_by === property) {
        // Same column
        this.sortingSelection.sort_order = this.sortingConfig[property];
      } else {
        // Switch to other column
        this.sortingConfig[property] = 'asc';
        this.sortingSelection.sort_order = 'asc';
      }
      // Load Data
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
  }

  async onPerPage(size: Event) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.page = 1;
      this.pageSize = +(size.target as HTMLSelectElement).value;
      this.backupPageSize = this.pageSize;
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
    else {
      this.pageSize = this.backupPageSize;
    }
  }

  async onViewPageBy(page = 1, pageSize?: number, params?: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.backupPage = page;
      pageSize = this.pageSize;
      params = this.params ? `&${this.params}` : '';
      this.loading = true;
      this.loadingBar.start();
      this.vipContentSettingsDataService.getWithQuery(`?perPage=${this.pageSize}${params}&${this.generateSortingParam()}`).subscribe(res => {
        if(res){
          this.vipContentSettings$ = res;
        }
        this.pagination = this.vipContentSettingsDataService.pagination;
        this.dataLength = res['vip_contents'].rows.length;
        this.vipContentSettingsData.vip_sections = res['vip_sections'];
        this.vipContentSettingsData.vip_contents = res['vip_contents'].rows;
        this.availableVipContents = res['vip_contents'].rows;

        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.loadingBar.complete();
        this.cdr.detectChanges();
      })
    }
    else {
      this.page = this.backupPage;
    }
  }

  private formInit() {
    // Filter
    this.form = new FormGroup({
      label: new FormControl(null),
      settings_locale_id: new FormControl(this.dropdown.locales[0].id),
      status: new FormControl(1),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null),
      date_type: new FormControl('created_at')
    });

    // Vip Content Settings
    const buildContents = () => {
      let fields = {};
      this.dropdown.locales.forEach((element: any) => {
        const subFields = new FormGroup({
          settings_locale_id: new FormControl(element.id),
          label: new FormControl(null),
          position: new FormControl(null, [Validators.min(1), Validators.max(99)]),
          value_type: new FormControl(1),
          status: new FormControl(null),
          details: new FormGroup(this.buildDetails())
        });
        fields = { ...fields, [element.id]: subFields };
      });
      return fields;
    };

    this.formContent = new FormGroup({
      // Create dialog
      contents: new FormGroup(buildContents()),
    });

    this.formContent.valueChanges.subscribe(data => {
      let isEmpty = false;

      for (const sf of Object.keys(data['contents'])) {
        const content = data['contents'][sf];
    
        // Check if all three fields are null
        const allFieldsNull = (content['label'] === null || content['label'] === '') && (content['position'] === null || content['position'] === '') && (content['status'] === null || content['status'] === '');
    
        // Check if any field is null
        const anyFieldNull = (content['label'] === null || content['label'] === '') || (content['position'] === null || content['position'] === '') || (content['status'] === null || content['status'] === '');
        
        if (allFieldsNull) {
          isEmpty = false;
        } else if (anyFieldNull) {
          isEmpty = true;
          break;
        }
      };
    
      this.isEmptyContent = isEmpty;
    });

    // Vip Structure Section
    const buildSection = () => {
      let fields = {};
        const subFields = new FormGroup({
          member_group_id: new FormControl(null),
          position: new FormControl(null, [Validators.min(1), Validators.max(99)]),
          status: new FormControl(null),
        });
      
      return fields;
    };

    this.formSection = new FormGroup({
      main_vip_name: new FormControl(null, [Validators.required]),
      member_group_id: new FormControl(null),
      position: new FormControl(99, [Validators.min(1), Validators.max(99), Validators.required]),
      display_type: new FormControl(null, [Validators.required]),
      status: new FormControl(null, [Validators.required]),
      main_vip_icon: new FormControl(null),
      details: new FormGroup(buildSection()),
      vip_names: new FormGroup(this.buildVIPName()),
    });
  }

  buildDetails() {
    let fields2 = {};
    this.dropdown.activeVipMemberGroups.forEach((value: any) => {
      const subFields2 = new FormGroup({
        member_group_id: new FormControl(value.member_group_id),
        text_value: new FormControl(null),
        symbol_value: new FormControl(null)
      });
      fields2 = { ...fields2, [value.member_group_id]: subFields2 };
    });
    return fields2;
  };

  private contentSupInit() {
    this.vipContentSubArrayForm = this.fb.group({
      vipContentSub: new FormArray([]),
    });
  }

  get vipContentSubArr(): FormArray {
    if (this.vipContentSubArrayForm) {
      return this.vipContentSubArrayForm.get('vipContentSub') as FormArray;
    }
  }

  private resetValue() {
    this.vipContentSubArr.clear();
    this.backUpVipContentSubArr = [];
    this.memberGroupListDropdownList = [];
    this.memberGroupListSelectedItems = [];
    this.cdr.detectChanges();
  }

  enableVipContentSupEdit(index: number) {
    this.backUpVipContentSubArr = ({ ...this.vipContentSubArr.controls[index].value });
    this.vipContentSubArr.controls.forEach(control => {
      control.disable();
    });
    this.vipContentSubArr.controls[index].enable();
    this.cdr.detectChanges();
  }

  validationVipContentSupList(i?: any) {
    if (i != undefined) {
      const vipContentSubArr = ({ ...this.vipContentSubArr.controls[i].value });
      if (vipContentSubArr.member_group_id !== null && vipContentSubArr.position !== null) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      const statues = this.vipContentSubArr.controls.map(x => x.enabled);
      if (statues.includes(true)) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  onVipContentSubSave(row: any, index?: number,) {
    this.checkValidation = true;
    let refreshList = [];
    this.vipContentSubArr.controls.forEach((control, index) => {
      let item = this.filterFormFields(control.value);
      refreshList.push(item);
    });

    this.resetValue();
    refreshList.forEach((item) => {
      this.onVipContentSubAddRow(false, item);
    });

    this.cdr.detectChanges();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && key !== 'mode') ? fields[key] = formData[key] : key);
    return fields;
  }

  onDismissVipContentSubRow(mode: string, index: number) {
    switch (mode) {
      case 'create':
        this.vipContentSubArr.removeAt(index);
        this.memberGroupListDropdownList.shift();
        this.memberGroupListSelectedItems.shift();
        this.isOnAddClicked = false;
        break;
      case 'edit':
        this.vipContentSubArr.controls[index].patchValue({
          ...this.backUpVipContentSubArr,
        })

        this.memberGroupListSelectedItems[index] = this.dropdown.groups.filter(x => x.id == this.backUpVipContentSubArr.member_group_id);
        this.vipContentSubArr.controls[index].disable();
        break;
    }

    this.cdr.detectChanges();
  }

  assignVipContentSubList() {
    let selectedMemberGroup = [];
    selectedMemberGroup = this.vipContentSubArr.controls.filter(function (obj) {
      return obj.value.member_group_id != null;
    }).map(function (obj) { return obj.value.member_group_id; });

    this.vipContentSubArr.controls.forEach((control, index) => {
      const exist = selectedMemberGroup.indexOf(parseInt(control.value.member_group_id));

      let groupIds = [...selectedMemberGroup];
      if (exist > -1) {
        groupIds.splice(exist, 1);
      }

      this.memberGroupListDropdownList[index] = this.dropdown.groups.filter(x => !groupIds.includes(x.id))
    });
    this.cdr.detectChanges();
  }

  onVipContentSubAddRow(isNew = false, data?: any) {
    this.checkValidation = false;
    let member_group_id = data !== undefined ? data.member_group_id : null;
    let position = data !== undefined ? data.position : null;
    let status = data !== undefined ? (data.status == 1 ? true : false) : true;

    const form = new FormGroup({
      id: new FormControl(data !== undefined ? data.id : null),
      member_group_id: new FormControl(member_group_id, [Validators.required]),
      position: new FormControl(position, [Validators.min(1), Validators.max(99), Validators.required]),
      status: new FormControl(status),
      mode: new FormControl(data !== undefined ? 'edit' : 'create')
    });

    if (isNew) {
      this.vipContentSubArr.controls.slice().reverse().forEach((control, index) => {
        const currentGroup = this.vipContentSubArr.controls[index];
        this.vipContentSubArr.insert(index + 1, currentGroup);
        this.vipContentSubArr.removeAt(index);
      })

      this.vipContentSubArr.insert(0, form);
      this.assignVipContentSubList();
    }
    else {
      this.vipContentSubArr.push(form);
    }

    let indexMemberGroup = this.vipContentSubArr.length - 1;
    
    if (data !== undefined) {
      let memberGroupIds = [];

      if (this.vipSection) {
        memberGroupIds = this.vipSection.details.map(item => {
          return item.member_group_id;
        });
      } else {
        memberGroupIds = this.vipContentSubArr.controls.map(control => {
          return control.value.member_group_id;
        });
      }
      
      this.memberGroupListDropdownList[indexMemberGroup] = this.dropdown.groups.filter(x => !memberGroupIds.includes(x.id));
      this.memberGroupListDropdownList[indexMemberGroup].push(this.dropdown.groups.filter(x => x.id == data.member_group_id)[0]);
      this.memberGroupListDropdownList[indexMemberGroup].sort(function (a, b) {
        return - (a.id - b.id);
      });

      this.memberGroupListSelectedItems[indexMemberGroup] = this.dropdown.groups.filter(x => x.id == data.member_group_id);
      this.vipContentSubArr.controls.forEach(control => {
        control.disable();
      });
    } else {
      this.memberGroupListSelectedItems.unshift([]);
      this.isOnAddClicked = true;
      this.cdr.detectChanges();
    }
  }

  checkValidationVipContentSubPosition(i: any) {
    if (this.vipContentSubArr.controls[i].enabled) {
      const supVipContent = ({...this.vipContentSubArr.controls[i].value});
      const position = this.vipContentSubArr.controls.filter(x => x.value.position == supVipContent.position);
      const enteredPosition = supVipContent.position;

      // Check if position is within valid range
      if (enteredPosition < 1 || enteredPosition > 99) {
        return true;  
      }

      if (position.length > 1) {
        return true;
      }
     
      return false;
      
    }
    else {
      return false;
    }
  }

  rebuildFormContent() {
    // Remove and re-add 'details' controls
    const contentsGroup = this.formContent.get('contents') as FormGroup;

    if (contentsGroup) {
      Object.keys(contentsGroup.controls).forEach((localeId) => {
        const subGroup = contentsGroup.get(localeId) as FormGroup;
        if (subGroup) {
          subGroup.removeControl('details'); // Remove existing 'details'
          subGroup.addControl('details', new FormGroup(this.buildDetails())); // Add new 'details'
        }
      });
    }
  }

  onOpenDialog(dialog: string) {
    this.openDialogBy(VIPContentNameDialogComponent, { vip_names: this.formSection.value.vip_names });
  }

  private openDialogBy(componentRef: any, data?: { vip_names: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        vip_names: data.vip_names
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.formSection.patchValue({
          vip_names: result
        });
      }
    });
  }
  
  buildVIPName(vipNameList?: any) {
    let result = {};
    this.dropdown.locales.forEach((item, index) => {
        const vip_locale_name = vipNameList?.find(x => x.settings_locale_id === item.id)?.name;
        const vipNameGroup = result[item.id] = new FormGroup({
            settings_locale_id: new FormControl(item.id),
            name: new FormControl(vip_locale_name != undefined ? vip_locale_name : null),
        });
        result = { ...result, [item.id]: vipNameGroup };
    });
    return result;
  }
}
 