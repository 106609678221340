import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { Banner } from '@core/models/banner.model';
import { tap, map, catchError } from 'rxjs/operators';
import { BannerDataService } from './services/banner-data.service';
import { Pagination } from '@core/models/pagination.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import Swal from "sweetalert2";
import { UploadHttpService } from '@core/services/upload-http.service';
import { Lightbox } from 'ngx-lightbox';
import { AppPermissionService } from '@core/services/app-permission.service';
@Component({
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})

export class BannerComponent implements OnInit, OnDestroy {

  @HostListener('window:beforeunload', ['$event'])
  confirmLeavingPageBeforeSaving($event) {

    if(this.checkupdate()){
      $event.preventDefault();
      $event.returnValue = true;
      return $event;
    }
  }

  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;

  form: FormGroup;
  formContent: FormGroup;
  messages$ = this.bannerDataService.messages$;

  loading = false;
  searchBtnLoading = false;
  clearBtnLoading = false;
  dataLength = 0;
  params = '';
  buttonLoading = true;

  mode: any;

  banner: any;
  initBanner: any;
  availableBanner = [];
  isEmptyContent = true;

  dateTimeStack = {
    start_datetime: null,
    end_datetime: null,
  };

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  ranges = this.transactionHttpService.ranges;

  banner$: Observable<Banner[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  displayLocale: any;
  backupPageSize = 30;
  backupPage = 1;
  unsaveStatus = true;

  usableLinks= [
    'external',
    'promotion',
    'referral',
    'Launch'
  ];
  variable = null;

  dateTimeFormat = 'yyyy-MM-dd HH:mm:';
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
  changedObjects: any;

  sortingConfig = {
    'id': 'desc',
    'label': 'desc',
    'position': 'desc',
    'start_datetime': 'desc',
    'end_datetime': 'desc',
  };

  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    sessions: this.dropdownHttpService.marqueeSession,
    platforms: this.dropdownHttpService.sitePlatforms,
    locales: [],
    perPage: this.dropdownHttpService.perPage,
  };

  isImageUploading = new Array(2).fill(false);
  imagePreview = new Array(2);
  desktopLoading = false;
  mobileLoading = false;
  bannerImage = [];

  iniRequired = true;

  // permissions
  canCreateNewBanner: boolean;
  canViewBannerDetails: boolean;
  canEditBanner: boolean;
  canUpdateBannerStatus: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  private localesSub = new Subscription();
  private datePickerSubscription = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private loadingBar: LoadingBarService,
    private bannerDataService: BannerDataService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private transactionHttpService: TransactionHttpService,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private uploadService: UploadHttpService,
    private lightbox: Lightbox,
    private appPermissionService: AppPermissionService,
  ) { }

  async canDeactivate(): Promise<boolean | Observable<boolean>> {
    // provide component specific logic to decide if component can or can't be deactivated
    if (this.checkupdate()) {
      const result = await Swal.fire({
        title: '<div class="text-center">Unsaved Changes</div>',
        html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Cancel'),
        confirmButtonText: this.translateService.instant('Discard'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          return (true);
        } else if (result.isDenied) {
          return (false);
        }
      });

      return result;
    }
    return true;
  }

  ngOnInit() {
    this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.dropdown.locales = res;
      this.formInit();

      /**
     * When label form field has value, image field of that locale form will set to required
     */
    this.formContent.get('images').valueChanges.subscribe(images => {
      Object.keys(images).forEach(key => {
        if (images[key].label != null && this.formContent.get('images.'+key+'.image_desktop').validator == null && this.formContent.get('images.'+key+'.image_mobile').validator == null && this.iniRequired) {
          this.form.get('images.'+key+'.image_desktop').setValidators(Validators.required);
          this.form.get('images.'+key+'.image_desktop').updateValueAndValidity();
          this.form.get('images.'+key+'.image_mobile').setValidators(Validators.required);
          this.form.get('images.'+key+'.image_mobile').updateValueAndValidity();
          this.iniRequired = false;
        }
      });
    });

      this.onSubmit();

      this.initBanner = JSON.stringify({ ...this.formContent.value });
    })).subscribe();

    this.dateTimeStack = {
      start_datetime: null,
      end_datetime: null,
    };

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateNewBanner = appPermissions.create_new_banner;
      this.canViewBannerDetails = appPermissions.view_banner_details;
      this.canEditBanner = appPermissions.edit_banner;
      this.canUpdateBannerStatus = appPermissions.update_banner_status;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.localesSub.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, index === 0 ? this.dateTimeFormat + '00' : this.dateTimeFormat + '59')),
      tap(date => {
        this.formContent.patchValue({ [formKey]: date });
      })
    );
  }

  onClear() {
    this.onClearDate();
    this.form.patchValue({
      start_datetime: null,
      end_datetime: null,
      defaultDate: null,
      date_type: 'start_date',
      status: 'all',
      settings_locale_id: 'all',
      label: null,
      session: 'all',
      platform_type_id: 'all',
    })
  }

  onClearContent() {
    this.onClearDateContent();
    this.dateTimeStack = {
      start_datetime: new Date(),
      end_datetime: null,
    };

    this.formContent.patchValue({
      label: null,
      start_datetime: this.datePipe.transform(new Date(), this.dateTimeFormat + '00'),
      end_datetime: null,
      session: 1,
      position: 99,
      status: 1,
      link: null,
      platform_type_id: null
    })

    this.dropdown.locales.forEach((element: any) => {
      this.formContent.patchValue({
        images: {
          [element.id]: {
            image_desktop: null,
            image_mobile: null
          }
        }
      });
    });

    this.initBanner = JSON.stringify({ ...this.formContent.value });
  }

  reload(clearSearch?: boolean) {
    this.isEmptyContent = true;
    this.mode = '';
    this.onClearContent();
    this.onSubmit(clearSearch);
    this.buttonLoading = false;
  }

  async onSubmitClick(clearSearch?: boolean) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {

      this.isEmptyContent = true;
      this.mode = '';
      this.onClearContent();
      this.onSubmit(clearSearch);
    }
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;

    if (clearSearch) {
      this.onClear();
    }

    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.banner$ = this.bannerDataService.getWithQuery(`?${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.displayLocale = this.displayLocale ? this.displayLocale : res.length && this.form.value.settings_locale_id != 'all' ? this.dropdown.locales.filter(x => x.id == this.form.value.settings_locale_id)[0] : this.dropdown.locales[0];
            this.page = 1;
            this.pagination = this.bannerDataService.pagination;
            this.loadingBar.complete();
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.buttonLoading = false;
            this.dataLength = res.length;
            this.availableBanner = res;
            this.cdr.detectChanges();
          })
        );
      }),
    ).subscribe();
  }

  onSave() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
    this.buttonLoading = true;

    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.formContent.setErrors({ 'invalid': true });
    const data = {
      id: this.banner ? this.banner.id : null,
      ...this.formContent.value,
      start_datetime: moment(this.formContent.value.start_datetime).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_datetime: moment(this.formContent.value.end_datetime).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
    };

    Object.keys(data).forEach((key) => {
      if (data[key] == null || data[key] === '' || data[key] === 'Invalid date') {
        delete data[key];
      }
    });

    let checkcontent = false;

    Object.keys(data['images']).forEach((key) => {
      if ((data['images'][key]['image_desktop'] !== null || data['images'][key]['image_mobile'] !== null)) {
        checkcontent = true;
      }
    });

    if (checkcontent) {
      switch (this.mode) {
        case 'edit':
          this.subscription = this.bannerDataService.updateBanner(this.banner.id, data).pipe(
            tap((res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              this.form.enable();
              throw error;
            })
          ).subscribe();
          break;
        case 'create':
          this.subscription = this.bannerDataService.addBanner(data).pipe(
            tap((res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.formContent.setErrors(null);
              this.formContent.enable();
              throw error;
            })
          ).subscribe();
          break;
      }
    } else {
      Swal.fire({
        title: '<div class="text-center">Please upload at least one image for desktop or mobile</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        reverseButtons: false,
        denyButtonText: this.translateService.instant('Close'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button'
        }
      }).then(result => {
        this.buttonLoading = false;
      });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClearDate() {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onClearDateContent() {
    if (this.formContent.value.start_datetime !== null) {
      this.formContent.patchValue({
        start_datetime: null,
        end_datetime: null
      });
    }
  }

  changeLocale(locale: any) {
    this.displayLocale = locale;
  }

  addUsableLink(link: any) {
    switch (link) {
      case 'external':
        this.variable = '/external?link=';
        break;
      case 'promotion':
        this.variable = '/promotion?code=';
        break;
      case 'referral':
        this.variable = '/referral';
        break;
      case 'Launch':
        this.variable = 'Launch|';
        break;
    }

    this.formContent.patchValue({
      link: this.variable
    });
  }

  clearContent(locale: any) {
    Swal.fire({
      title: 'Delete Content',
      text: 'Are you sure you want to delete contents for ' + locale.code + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this.formContent.patchValue({
          images: {
            [locale.id]: {
              image_desktop: null,
              image_mobile: null,
            }
          }
        });

        let images = this.formContent.value.images;
        this.isEmptyContent = true;

        Object.keys(images).forEach((sf) => {
          if (images[sf]['image_desktop'] != null || images[sf]['image_mobile'] != null) {
            this.isEmptyContent = false;
          }
        });

      }
      this.cdr.detectChanges();
    });
  }

  onChangeStatus($event, row: any) {
    const data = {
      id: row.id,
      status: $event.target.checked ? 1 : 0,
    };

    this.subscription = this.bannerDataService.updateStatus(data).pipe(
      tap((res: any) => {
        this.messages$.next(res.message);
        this.buttonLoading = false;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        throw error;
      })
    ).subscribe();
  }

  async onPerPage(size: Event) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.page = 1;
      this.pageSize = +(size.target as HTMLSelectElement).value;
      this.backupPageSize = this.pageSize;
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
    else {
      this.pageSize = this.backupPageSize;
    }
  }

  async onViewPageBy(page = 1, pageSize?: number, params?: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.backupPage = page;
      pageSize = this.pageSize;
      params = this.params ? `&${this.params}` : '';
      this.loading = true;
      this.loadingBar.start();
      return this.banner$ = this.bannerDataService.getWithQuery(`?${this.params}&page=${page}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
        tap(res => {
          this.pagination = this.bannerDataService.pagination;
          this.loading = false;
          this.dataLength = res.length;
          this.availableBanner = res;
          this.loadingBar.complete();
        })
      );
    }
    else {
      this.page = this.backupPage;
    }
  }

  expandRowCreate(mode: any, close?: boolean) {
    let bannerInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initBanner != bannerInput ? true : false;

    if (close != true && this.changedObjects == false) {
      this.banner = null;
      this.changedObjects = false;
      this.initBanner = null;

      this.mode = mode;
      this.cdr.detectChanges();

      this.datePickerSubscription = forkJoin([
        this.buildDatePicker(0, 'start_datetime'),
        this.buildDatePicker(1, 'end_datetime')
      ]).subscribe();

      this.onClearContent();
    } else {
      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {

            this.isEmptyContent = true;
            this.mode = '';
            this.unsaveStatus = true;
            this.onClearContent();

            // Check if reopen needed
            this.checkReopen(mode);
          }
        });
      }
      else {

        this.isEmptyContent = true;
        this.mode = '';
        this.unsaveStatus = true;
        this.onClearContent();
      }
    }

    this.cdr.detectChanges();
  }

  expandRowEdit(mode: any, row?: any, close?: boolean) {
    if (close != true) close = this.banner == row ? true : false;
    let bannerInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initBanner && this.initBanner != bannerInput ? true : false;

    if (close != true && this.changedObjects == false) {
      this.banner = row;
      this.changedObjects = false;
      this.initBanner = null;

      this.mode = mode;

      this.cdr.detectChanges();

      this.dateTimeStack = {
        start_datetime: new Date(new Date(this.banner.start_datetime).getTime() + this.offset - this.clientOffset),
        end_datetime: this.banner.end_datetime ? new Date(new Date(this.banner.end_datetime).getTime() + this.offset - this.clientOffset) : null,
      };

      this.formContent.patchValue({
        label: this.banner.label,
        link: this.banner.link,
        start_datetime: moment(new Date(new Date(this.banner.start_datetime).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss'),
        end_datetime: this.banner.end_datetime ? moment(new Date(new Date(this.banner.end_datetime).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss') : null,
        session: this.banner.session,
        platform_type_id: this.banner.platform_type_id,
        position: this.banner.position,
        status: this.banner.status,
      });

      // reset all locales to null
      this.dropdown.locales.forEach((element: any) => {
        this.formContent.patchValue({
          images: {
            [element.id]: {
              image_desktop: null,
              image_mobile: null
            }
          }
        });
      });


      this.banner.images.forEach((element: any) => {
        this.formContent.patchValue({
          images: {
            [element.settings_locale_id]: {
              image_desktop: element.image_desktop,
              image_mobile: element.image_mobile
            }
          }
        });
      });

      this.datePickerSubscription = forkJoin([
        this.buildDatePicker(0, 'start_datetime'),
        this.buildDatePicker(1, 'end_datetime')
      ]).subscribe();

      this.initBanner = JSON.stringify({ ...this.formContent.value });
    } else {
      this.changedObjects = this.initBanner != JSON.stringify({ ...this.formContent.value }) ? true : false;
      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {

            this.isEmptyContent = true;
            this.mode = '';
            this.unsaveStatus = true;
            this.onClearContent();

            // Check if reopen needed
            if (this.banner == row) {
              this.banner = null;
            }
            else {
              this.banner = null;
              this.checkReopen(mode, row);
            }
          }
        });
      }
      else {

        this.isEmptyContent = true;
        this.mode = '';
        this.unsaveStatus = true;
        this.banner = null;
        this.onClearContent();
      }
    }
  }

  checkReopen(mode: any, row?: any) {
    switch (mode) {
      case 'edit':
        this.expandRowEdit(mode, row)
        break;
      case 'create':
        this.expandRowCreate(mode);
        break;
    }
  }

  localeIndex() {
    const index = this.dropdown.locales.findIndex(item => {
      return item.id == this.displayLocale['id'];
    });

    return index;
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== false) {
        fields[key] = (formData[key] === true ? 1 : formData[key]);
      }
    });
    return fields;
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  async onSortColumn(property: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      // Reset other columns
      for (const key in this.sortingConfig) {
        if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
          if (key == property) {
            this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
          } else {
            this.sortingConfig[key] = 'desc';
          }
        }
      }

      // User selection
      this.sortingSelection.sort_by = property;

      if (this.sortingSelection.sort_by === property) {
        // Same column
        this.sortingSelection.sort_order = this.sortingConfig[property];
      } else {
        // Switch to other column
        this.sortingConfig[property] = 'asc';
        this.sortingSelection.sort_order = 'asc';
      }
      // Load Data
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
  }

  private formInit() {
    const buildContents = () => {
      let fields = {};
      this.dropdown.locales.forEach((element: any) => {
        const subFields = new FormGroup({
          settings_locale_id: new FormControl(element.id),
          image_desktop: new FormControl(null),
          image_mobile: new FormControl(null)
        });
        fields = { ...fields, [element.id]: subFields };
      });
      return fields;
    };

    this.form = new FormGroup({
      // Filter
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null),
      date_type: new FormControl('start_date'),
      status: new FormControl('all'),
      settings_locale_id: new FormControl('all'),
      label: new FormControl(null),
      session: new FormControl('all'),
      platform_type_id: new FormControl('all'),
    });

    this.formContent = new FormGroup({
      // Create dialog
      label: new FormControl(null, [Validators.required]),
      link: new FormControl(null),
      start_datetime: new FormControl(this.dateTimeStack.start_datetime, [Validators.required]),
      end_datetime: new FormControl(this.dateTimeStack.start_datetime),
      session: new FormControl(null, [Validators.required]),
      platform_type_id: new FormControl(null, [Validators.required]),
      position: new FormControl(99, [Validators.min(1), Validators.max(99), Validators.required]),
      status: new FormControl(null, [Validators.required]),
      images: new FormGroup(buildContents()),
    });

    this.formContent.valueChanges.subscribe(data => {
      Object.keys(data['images']).forEach((sf) => {
        if (data['images'][sf]['image_desktop'] != null || data['images'][sf]['image_mobile'] != null) {
          this.isEmptyContent = false;
        }
      })
    })
  }

  checkupdate() {
    return this.initBanner !== JSON.stringify({ ...this.formContent.value }) ? true : false;
  }

  displayImage(row: any, imageType: any) {

    let banner = this.availableBanner.filter(x => x.id == row.id)[0]['images'];

    banner = banner.filter(x => x['locale_name'] == this.displayLocale['code']);

    if (banner.length > 0) {
      if (imageType == 'desktop') {
        return banner[0]['image_desktop'] != null ? banner[0]['image_desktop'] : '';
      } else {
        return banner[0]['image_mobile'] != null ? banner[0]['image_mobile'] : '';
      }
    } else {
      if (imageType == 'desktop') {
        return '';
      } else {
        return  '';
      }
    }
  }

  private buildMediaPropertyBy(localeId: number, imageType: string) {
    let output = {};
    output = { ...output, [imageType]: this.imagePreview[0] };
    output = { ...output, settings_locale_id: localeId };
    return output;
  }

  getMediaSource(localeId: number, imageType: string) {
    let media: any;
    media = this.formContent.get(`images.${localeId}.${imageType}`).value;
    return media;
  }

  onUploadFile(event: any, localeId: number, num: number, name:string) {
    if (name === 'image_desktop') {
      this.desktopLoading = true;
    } else if (name === 'image_mobile') {
      this.mobileLoading = true;
    }

    this.isImageUploading[num] = true;

    const file: File = event.target.files[0];
    const formData = new FormData();

    if (file !== undefined) {
      formData.append('files', file, file.name);
      formData.append('type', 'banners');

      this.uploadService.upload(formData).subscribe(
        res => {
          if (name === 'image_desktop') {
            this.desktopLoading = false;
          } else if (name === 'image_mobile') {
            this.mobileLoading = false;
          }

          this.imagePreview = res;

          res.forEach(x => {
            this.formContent.patchValue({
              images: { [localeId]: this.buildMediaPropertyBy(localeId, name) }
            });
          });
          this.formContent.markAllAsTouched();
          this.isImageUploading[num] = false;
        },
        err => {
          this.isImageUploading[num] = false;
          this.desktopLoading = false;
          this.mobileLoading = false;
        }
      );
    } else {
      this.isImageUploading[num] = false;
      this.desktopLoading = false;
      this.mobileLoading = false;
    }
  }

  onRemoveFile(localeId: number, num: number, name:string) {
    if (name == 'image_desktop') {
      this.formContent.patchValue({
        images: {
          [localeId]: {
            image_desktop: null
          }
        }
      });
    } else {
        this.formContent.patchValue({
          images: {
            [localeId]: {
              image_mobile: null
            }
          }
        });
    }
    this.imagePreview[num] = null;
  }

  onChangeImageValidate(event: any , key, imageType: string){
    let content_type = event.target.value;
    let itemControls = <FormGroup>this.formContent.controls['images'];
    let itemFormGroup = <FormGroup>itemControls.controls[key];

    if(imageType == 'image_desktop' && content_type.length != 0) {

      /// remove validators if image_desktop is not null
      itemFormGroup.controls["image_mobile"].clearValidators();
      itemFormGroup.controls["image_mobile"].reset();
      itemFormGroup.controls["image_mobile"].setErrors(null);
      itemFormGroup.controls["image_mobile"].updateValueAndValidity();

    }else if(imageType == 'image_mobile' && content_type.length != 0){

      /// remove validators if image_mobile is not null
      itemFormGroup.controls["image_desktop"].clearValidators();
      itemFormGroup.controls["image_desktop"].reset();
      itemFormGroup.controls["image_desktop"].setErrors(null);
      itemFormGroup.controls["image_desktop"].updateValueAndValidity();
    }
  }

  openImage(image: any) {
    this.bannerImage = [];
    this.bannerImage.push({
      src: image,
      thumb: image
    });

    this.lightbox.open(this.bannerImage, 0, { centerVertically: true, disableScrolling: true, fitImageInViewPort: true, fadeDuration: 0.01, resizeDuration: 0.01 });
  }

  checkContent(locale: any) {
    let image_desktop = this.formContent.value.images[locale.id].image_desktop;
    let image_mobile = this.formContent.value.images[locale.id].image_mobile;

    if ((image_desktop == null || image_desktop == '') && (image_mobile == null || image_mobile == '')) {
      return false;
    }
    else {
      return true;
    }
  }

  private onCheckUnsave() {
    return new Promise<void>((resolve, reject) => {
      let bannerInput = JSON.stringify({ ...this.formContent.value });
      this.changedObjects = this.initBanner && this.initBanner != bannerInput ? true : false;

      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {

            this.isEmptyContent = true;
            this.mode = '';
            this.banner = null;
            this.unsaveStatus = true;
            this.onClearContent();
            resolve();
          }
          else {
            this.unsaveStatus = false;
            resolve();
          }
        });
      }
      else {
        resolve();
      }
    });
  }
}
