import * as CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AesService {
  constructor() {}

  /**
   * Wrapper for decrypt(), with reqSignKey as secret key.
   *
   * @param encryptedData The encrypted data that contains the encrypted string and IV
   * @returns The decrypted string.
   */
  decryptWithReqSignKey(encryptedData: string | number): string {
    const secretKey = environment.reqSignKey; // Make sure this matches backend APP_REQ_SIGN_KEY

    return this.decrypt(encryptedData, secretKey);
  }

  /**
   * Decrypts data encrypted by CMS using AES CBC mode.
   *
   * @param encryptedData The encrypted data that contains the encrypted string and IV
   * @param key The secret key used for decryption.
   * @returns The decrypted string.
   */
  decrypt(encryptedData: string | number, key: string): string {
    if (!encryptedData || encryptedData == 0 || !key) {
      return '';
    }

    try {
      const encryptedDataWithIV = CryptoJS.enc.Base64.parse(encryptedData);

      // extract the first 16 bytes (128 bits) for the IV
      const iv = CryptoJS.lib.WordArray.create(encryptedDataWithIV.words.slice(0, 4));

      // extract the remaining bytes as the ciphertext
      const encryptedCipherText = CryptoJS.lib.WordArray.create(encryptedDataWithIV.words.slice(4));

      // decrypt the message using AES-256-CBC
      const decrypted = CryptoJS.AES.decrypt(
          { ciphertext: encryptedCipherText },
          CryptoJS.SHA256(key),
          {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          }
      );

      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error("OS decryption failed: ", error);
      return ''; // Return empty string or handle as needed
    }
  }

  /**
   * Wrapper for encrypt(), with reqSignKey as secret key.
   * 
   * @param data The string data to encrypt
   * @returns The encrypted string with IV prepended and Base64 encoded
   */
  encryptWithReqSignKey(data: string): string {
    const secretKey = environment.reqSignKey; // Make sure this matches backend APP_REQ_SIGN_KEY
    return this.encrypt(data, secretKey);
  }

  /**
   * Encrypts data using AES CBC mode, matching CMS encryption format.
   * 
   * @param data The string data to encrypt
   * @param key The secret key used for encryption
   * @returns The encrypted string with IV prepended and Base64 encoded
   */
  encrypt(data: string, key: string): string {
    if (!data || !key) {
      return '';
    }

    try {
      // Generate a random IV
      const iv = CryptoJS.lib.WordArray.random(16); // 16 bytes = 128 bits

      // Encrypt the data using AES-256-CBC
      const encrypted = CryptoJS.AES.encrypt(
        data,
        CryptoJS.SHA256(key),
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }
      );

      // Combine IV and encrypted data
      const ivAndEncrypted = CryptoJS.lib.WordArray.create()
        .concat(iv)
        .concat(encrypted.ciphertext);

      // Return as Base64 string
      return CryptoJS.enc.Base64.stringify(ivAndEncrypted);
    } catch (error) {
      console.error("OS encryption failed: ", error);
      return ''; // Return empty string or handle as needed
    }
  }
}
