<!-- Create Mode -->
<ng-container *ngIf="mode === 'Create'">
  <ng-container *ngTemplateOutlet="formContent"></ng-container>
  <kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
</ng-container>

<!-- Edit Mode -->
<ng-container *ngIf="mode === 'Edit'">
  <div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ 'Send SMS' | translate }}</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body p-5">
        <ng-container *ngTemplateOutlet="formContent"></ng-container>
      </div>
      <div class="modal-footer d-flex justify-content-between w-100">
        <button *ngIf="canCancelScheduledSMS && data.status == 1" class="btn btn-danger" (click)="onCancel()" [disabled]="buttonLoading">
          <i [class]="buttonLoading? 'fas fa-ban fa-spin p-0 mr-2' : 'fa fa-ban'"></i>Cancel
        </button>
        <div class="d-flex ml-auto">
          <button *ngIf="canSendSMSNow && data.status == 1" class="btn btn-primary mr-2" (click)="onSendNow()" [disabled]="buttonLoading">
            <i [class]="buttonLoading? 'fas fa-paper-plane fa-spin p-0 mr-2' : 'fa fa-paper-plane'"></i>Send Now
          </button>
          <button *ngIf="canUpdateSMSSettings && data.status == 1" class="btn btn-success" (click)="onSend()" [disabled]="buttonLoading">
            <i [class]="buttonLoading? 'fas fa-save fa-spin p-0 mr-2' : 'fa fa-save'"></i>Update
          </button>
        </div>
      </div>
    </div>
  </div>
  <kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
</ng-container>

<!-- Form Content (Shared Use) -->
<ng-template #formContent>
  <form class="row align-items-center send-sms-form message-center-form-container" *ngIf="sendSMSForm" [formGroup]="sendSMSForm">

    <!-- Histories button -->
    <div *ngIf="canViewSendSMSHistories && mode === 'Create'" class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group d-flex justify-content-end">
      <button type="button" class="btn btn-light" (click)="onOpenDialog('history')">
        <i class="fas fa-history"></i> Histories
      </button>
    </div>
  
    <!-- Region -->
    <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
      <div class="kt-form__group--inline row">
        <div class="col-2 kt-form__label col-form-label">
          <label>Regions <span class="text-danger">*</span></label>
        </div>
  
        <kt-dropdown-wo-lazyload
          class="col-10"
          [form]='sendSMSForm'
          [dropdownList]='regions'
          [dropdownSettings]='regionsDropdownSettings'
          [formName]="'settings_currency_ids'"
          [selectionAttributes]="'id'"
          [selectedItems]='regionsSelectedItems'
          [ngClass]="{ 'is-invalid': checkValidation && sendSMSForm.controls.settings_currency_ids.errors }"
          (selectedItemsChanged)="onRegionChanged($event)"
        ></kt-dropdown-wo-lazyload>
      </div>
    </div>
  
    <!-- Member Type -->
    <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
      <div class="kt-form__group--inline row">
        <div class="col-2 kt-form__label col-form-label">
          <label>Member Type <span class="text-danger">*</span></label>
        </div>
        <div class="col-10 d-flex align-items-center kt-form__control">
          <input
            type="radio"
            formControlName="member_type"
            [value]="1"
          />
          <span class="pl-2 pr-2">Members</span>
  
          <input
            type="radio"
            formControlName="member_type"
            [value]="2"
          />
          <span class="pl-2 pr-2">Non Members</span>
        </div>
      </div>
    </div>
  
    <!-- Member Group -->
    <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group" *ngIf="isMemberType()">
      <div class="kt-form__group--inline row">
        <div class="col-2 kt-form__label col-form-label">
          <label>Member Group <span class="text-danger">*</span></label>
        </div>
        <div class="col-10 kt-form__control">
          <kt-dropdown-wo-lazyload
            [dropdownList]="memberGroupDropdownList"
            [dropdownSettings]="memberGroupDropdownSettings"
            [form]="sendSMSForm"
            [selectionAttributes]="'id'"
            [formName]="'member_group_ids'"
            [selectedItems]="memberGroupSelectedItems"
            (selectedItemsChanged)="onSelectMemberGroup($event)"
            [ngClass]="{ 'is-invalid': checkValidation && sendSMSForm.controls.member_group_ids.errors }"
          >
          </kt-dropdown-wo-lazyload>
        </div>
      </div>
    </div>
  
    <!-- Members / Recipients (non members) -->
    <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
      <div class="kt-form__group--inline row">
        <div class="col-2 kt-form__label col-form-label">
          <label>{{ isMemberType() ? 'Members' : 'Recipients' }} <span class="text-danger">*</span></label>
        </div>
  
        <div *ngIf="isMemberType()" class="kt-form__control" [ngClass]="{'col-6': mode === 'Create', 'col-4': mode === 'Edit'}">
          <kt-member-dropdown
            class="dropdown-maxheight"
            [form]="sendSMSForm"
            [dropdownSettings]="smsMemberDropdownSettings"
            [formName]="'member_account_ids'"
            [selectionAttributes]="'id'"
            [selectedItems]="selectedMembers"
            [isFilterByMembergroupId]="true"
            [membergroupId]="memberGroupSelectedItems"
            [isFilterByCurrency]="true"
            [currencyId]="regionsSelectedItems"
            [ngClass]="{ 'is-invalid': checkValidation && sendSMSForm.controls.member_account_ids.errors }"
          ></kt-member-dropdown>
          <div class="col mt-2">
            <span class="d-inline-block mr-2">
              <input
                type="checkbox"
                formControlName="send_to_all_members"
                (change)="onSendToAllMembersChange($event)"
              />
              <span class="pl-2 pr-2">Send to All Members</span>
            </span>

            <span class="d-inline-block">
              <input
                type="checkbox"
                formControlName="send_to_verified_mobile_only"
              />
              <span class="pl-2 pr-2">Send to Verified Mobile Only</span>
            </span>
          </div>
        </div>
  
        <div class="kt-form__control" [ngClass]="{'col-2': mode === 'Create', 'col-3': mode === 'Edit'}">
          <a [href]="'assets/' + templateFileName" target="_blank" [download]="templateFileName" class="btn btn-primary download-template w-100">
            <i class="fa fa-file-csv mr-2"></i> {{ 'Download Template' | translate }}
          </a>
        </div>
        <div class="kt-form__control" [ngClass]="{'col-2': mode === 'Create', 'col-3': mode === 'Edit'}">
          <div class="d-flex kt-form__control" [ngClass]="{'disabled': sendSMSForm.get('member_account_ids').value.length > 0}" style="cursor: pointer;">
            <span
              class="col-5 col-form-label btn import-button text-center px-0"
              [ngClass]="(buttonLoading || sendSMSForm.get('member_account_ids').value.length > 0 || sendSMSForm.get('send_to_all_members').value == 1) ? 'disabled' : 'btn-import'"
              (click)="!buttonLoading && sendSMSForm.get('member_account_ids').value.length === 0 ? myFile.click() : null"
            >
              <ng-container>
                <i class="fas fa-file-import"></i> {{ 'Import' | translate }}
                <input type="file" hidden id="sms-file" accept=".csv" (change)="onFileUpload($event)" [disabled]="buttonLoading" #myFile>
              </ng-container>
            </span>
            <div class="col-7 d-flex align-items-center import-button" *ngIf="file || attachedFile" (click)="!buttonLoading && sendSMSForm.get('member_account_ids').value.length === 0 && (sendSMSForm.get('send_to_all_members').value === 0 || sendSMSForm.get('send_to_all_members').value === null) ? myFile.click() : null">
              <span class="text-truncate flex-grow-1">{{ file ? file.name : attachedFile.name }}</span>
              <i class="fa fa-times ml-2" (click)="onRemoveFile()" aria-hidden="true"></i>
            </div>
            <span class="col-7 col-form-label import-button text-center" *ngIf="!file && !attachedFile" (click)="!buttonLoading && sendSMSForm.get('member_account_ids').value.length === 0 && (sendSMSForm.get('send_to_all_members').value === 0 || sendSMSForm.get('send_to_all_members').value === null) ? myFile.click() : null">Choose File</span>
          </div>
          <div class="col-12 text-right mt-2" *ngIf="attachedFile">
            <i class="fas fa-download"></i>&nbsp;&nbsp;<a [href]="attachedFile.url" [download]="attachedFile.name">{{ 'Download Imported File' | translate }}</a>
          </div>
        </div>
  
      </div>
    </div>
  
    <!-- Schedule Date/Time -->
    <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
      <div class="kt-form__group--inline row">
        <div class="col-2 kt-form__label col-form-label">
          <label>Schedule Date/Time </label>
        </div>
        <div class="col-10 kt-form__control date-time-picker-div">
          <ng-container *ngIf="mode === 'Create' || (mode === 'Edit' && data.status == 1); else nonPendingStatus">
            <app-date-time-picker
              [form]="sendSMSForm"
              [hasRanges]="false"
              [singleDatePicker]="true"
              [minDate]="minDate"
              [placeholder]="'Leave empty for immediate send'"
            ></app-date-time-picker>
          </ng-container>
          <ng-template #nonPendingStatus>
            <app-date-time-picker
              [form]="sendSMSForm"
              [hasRanges]="false"
              [singleDatePicker]="true"
              [placeholder]="'Leave empty for immediate send'"
            ></app-date-time-picker>
          </ng-template>
          
        </div>
      </div>
    </div>
  
    <!-- SMS Provider -->
    <div *ngIf="sendSMSForm.get('settings_currency_ids').value?.length > 0" class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
      <div class="kt-form__group--inline row">
        <div class="col-2 kt-form__label col-form-label">
          <label>SMS Provider <span class="text-danger">*</span></label>
        </div>
  
        <div class="col-10 kt-form__control">
          <div class="input-wrapper mb-3" *ngFor="let currency of filteredCurrencies()">
            <span class="currency-symbol">{{ currency.name }}</span>
            <select 
              class="form-control" 
              [value]="getSelectedProviderForCurrency(currency.id)"
              (change)="onProviderSelect($event.target.value, currency.id)"
              [disabled]="!canSendSMS"
              [ngClass]="{'is-invalid': checkValidation && !isProviderSelectedForCurrency(currency.id)}"
            >
              <option [value]="null" [disabled]="true">Please Select</option>
              <ng-container *ngFor="let provider of getFilteredProviders(currency.id)">
                <option [value]="provider.id">{{ provider.fullname }}</option>
              </ng-container>
            </select>
          </div>
          <div *ngIf="checkValidation && !validateProviders()" class="invalid-feedback d-block">
            Please select SMS provider for all selected regions
          </div>
        </div>
      </div>
    </div>
  
    <!-- Message Template -->
    <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
      <div class="kt-form__group--inline row">
        <div class="col-2 kt-form__label col-form-label">
          <label>{{ "Message Template" | translate }}</label>
        </div>
        <div class="col-2 kt-form__control pr-0">
          <kt-dropdown-wo-lazyload
            [form]="sendSMSForm"
            [dropdownList]="messageTemplateSectionDropdownList"
            [dropdownSettings]="messageTemplateSectionDropdownSettings"
            [formName]="'section'"
            [selectionAttributes]="'section'"
            [selectedItems]="selectedMessageTemplateSection"
            (selectedItemsChanged)="onMessageTemplateSectionChanged($event)"
          ></kt-dropdown-wo-lazyload>
        </div>
        <div class="col-6 kt-form__control pl-0">
          <kt-dropdown-wo-lazyload
            [form]="sendSMSForm"
            [dropdownList]="messageTemplateDropdownList"
            [dropdownSettings]="smsMessageTemplateDropdownSettings"
            [formName]="'message_template'"
            [selectionAttributes]="'id'"
            [selectedItems]="selectedMessageTemplate"
            [isSMSMessageTemplate]="true"
            (selectedItemsChanged)="onMessageTemplateChanged($event, 'smsForm')"
          ></kt-dropdown-wo-lazyload>
        </div>
        <div class="col-2 kt-form__control pl-0">
          <button type="button" class="btn btn-brand w-100" (click)="onOpenDialog('message_template')"><i class="fa fa-plus" aria-hidden="true"></i>{{ ('Message Template' | translate) }}</button>
        </div>
      </div>
    </div>
  
    <!-- Message Subject & Body -->
    <ng-container *ngIf="languages.length > 0; else noLanguages" >
      <mat-tab-group animationDuration="0ms" class="col-12 form-group locale-form" formGroupName="details">
        <mat-tab label="{{language.code}}" *ngFor="let language of languages; let i = index" [formGroupName]="language.id" (click)="$event.preventDefault()">
          <div class="col-12 form-group" [ngClass]="{ 'disabled': disableMessageField }">
            <label class="control-label">{{ 'Subject' | translate }} <span class="text-danger">*</span></label>
            <input type="text" formControlName="subject" class="col-12 form-control" (input)="updateDetailsValidators()">
          </div>
          <div class="col-12 form-group" [ngClass]="{ 'disabled': disableMessageField }">
            <label>{{ 'Message' | translate }} <span class="text-danger">*</span></label>
            <ckeditor #editors [config]="editorConfig" [editor]="editor" formControlName="message" (change)="updateDetailsValidators()"></ckeditor>
            <div>
              <label class="usable-variables-label">{{ 'Usable variables' | translate }}: </label>
              <div *ngFor="let usableVariable of usableVariables; let last = last">
                  <button class="usable-variables-text" (click)="onSelectUsableVariable(usableVariable, i)">{{ usableVariable }}</button>
                  <div *ngIf="!last" class="usable-variables-text">,</div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
    <ng-template #noLanguages>
      <div style="min-height: 300px; display: block; opacity: 0;">no language</div>
    </ng-template>
  
    <!-- Send Button -->
    <div *ngIf="mode === 'Create'" class="col-12 form-group d-flex flex-row-reverse mb-0">
      <button *ngIf="canSendSMS"  type="button" [disabled]="buttonLoading || !sendSMSForm.valid" class="btn btn-primary" (click)="onSend()">
        <i [class]="buttonLoading ? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-paper-plane'"></i>Send SMS
      </button>
    </div>
  </form>
</ng-template>