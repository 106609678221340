import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TransactionStatus } from '@core/enums/transaction-status.enum';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { loggedUser } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AppState } from '@store/reducers';
import * as moment from 'moment-timezone';
import { Lightbox } from 'ngx-lightbox';
import { of, Subscription } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AffiliateInformationComponent } from './../all-affiliates/dialogs/affiliate-information/affiliate-information.component';
import { AllAffiliatesDataService } from './../all-affiliates/services/all-affiliates-data.service';
import { AffiliateDepositDetailsComponent } from './dialog/deposit-details.component';
import { AffiliateDepositsDataService } from './services/affiliate-deposits-data.service';
import Echo from 'laravel-echo';
import { environment } from '@env/environment';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-affiliate-deposits',
  templateUrl: './affiliate-deposits.component.html',
  styleUrls: ['./affiliate-deposits.component.scss']
})
export class AffiliateDepositsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    paymentMethodsDeposit: this.dropdownHttpService.paymentMethodsDeposit,
    groups: this.dropdownHttpService.affiliateGroups,
    merchantBanks: [],
    merchantAccounts: [],
    statuses: [
      {
        name: 'Pending', value: 0, checked: true
      },
      {
        name: 'In Progress', value: 3, checked: true
      },
      {
        name: 'Approved', value: 1, checked: false
      },
      {
        name: 'Rejected', value: 2, checked: false
      }
    ],
    perPage: this.dropdownHttpService.perPage
  }
  selectedCurrency = { name: 'all' };
  selectedBank: any;
  selectedMerchantAccount: any;
  bankaccountDropdownSettings = {};
  bankaccountDropdownList = [];
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  ranges: any;
  status = TransactionStatus;
  searchStatus = [0, 3];
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = 'status[0]=0&status[1]=3';
  originalParams = '';
  deposits$ = [];
  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '2.1';
  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'status': 'desc',
    'affiliate_group': 'desc',
    'currency': 'desc',
    'amount': 'desc',
    'processing_fee': 'desc',
    'confirmed_amount': 'desc',
    'merchant_bank_account': 'desc',
    'created_at': 'desc',
    'processing_time': 'desc'
  }
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  receiptList = [];
  currentUserId: number;
  currentUsername: string;
  echo: Echo;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private datePickerSubscription = new Subscription();

  dateTimeFilterType = [
    {
      key: 'created_at',
      label: 'Created Date'
    },
    {
      key: 'updated_at',
      label: 'Updated Date'
    }
  ];
  
  // permissions
  canViewAffiliateDialog: boolean;
  canViewAffiliateDepositDetails: boolean;
  canViewReceipt: boolean;

  constructor(
    private affiliateDepositsDataService: AffiliateDepositsDataService,
    private dropdownHttpService: DropdownHttpService,
    private loadingBar: LoadingBarService,
    private transactionHttpService: TransactionHttpService,
    private lightbox: Lightbox,
    private store: Store<AppState>,
    public dialog: MatDialog,
    private merchantBankHttpService: MerchantBankHttpService,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private authHttpService: AuthHttpService,
    private cdr: ChangeDetectorRef,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) {
    const SocketIoClient = require('socket.io-client');
    const socketHost: string = environment.socketHost;
    try {
      this.echo = new Echo({
        broadcaster: 'socket.io',
        client: SocketIoClient,
        host: socketHost
      });
    } catch (e) {
      console.log(e);
    }
   }

  ngOnInit() {
    this.store.pipe(select(loggedUser)).subscribe((res) => { this.currentUserId = res.id; this.currentUsername = res.username; });
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));
    this.formInit();
    this.setDropdowns();
    this.ranges = this.transactionHttpService.ranges;
    this.onSubmit(true);
    this.originalParams = this.params;
    this.pagination = this.affiliateDepositsDataService.pagination;
    this.updateDepositList();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewAffiliateDialog = appPermissions.affiliate_deposits_view_affiliate_dialog;
      this.canViewAffiliateDepositDetails = appPermissions.view_affiliate_deposit_details;
      this.canViewReceipt = appPermissions.affiliate_deposits_view_receipt;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
    this.echo.disconnect();
  }

  private updateDepositList() {
    const listener = (newDeposit) => {
      var indexOfUpdateItem = this.deposits$.findIndex(item => item.id === newDeposit.id);
      //update approval list only for new incoming approval request
      if (this.searchStatus.includes(newDeposit.status)) {
        if (indexOfUpdateItem === -1) {
          this.pagination.total = this.pagination.total + 1;
          //push new approval request when user on first page
          if (this.pagination.current_page === 1) {
            this.deposits$.unshift(newDeposit)

            //if this page can show more request
            if (this.pagination.to < this.pagination.per_page) {
              this.pagination = {
                ...this.pagination,
                from: 1,
                to: this.pagination.to + 1,
              };
            }
            else {
              //remove last request when this page cannot show more request.
              this.deposits$.splice(this.pagination.per_page, 1)
            }
          }
        }
        else {
          //update relevant deposit rows
          this.deposits$[indexOfUpdateItem] = newDeposit;
        }
      }
      else {
        if (indexOfUpdateItem !== -1) {
          this.deposits$.splice(indexOfUpdateItem, 1);
          this.pagination.total = this.pagination.total - 1;
          if (this.pagination.to <= this.pagination.per_page) {
            this.pagination = {
              ...this.pagination,
              from: 1,
              to: this.pagination.to - 1,
            };
          }
        }
      }
      this.cdr.detectChanges();
    }

    const affiliateDepositChannel = this.echo.channel('affiliate-deposit-channel');
    const currencyIds = JSON.parse(localStorage.getItem('user_data'))['bo_currency_ids'];

    currencyIds.forEach(currencyId => {
      affiliateDepositChannel.listen(`.AffiliateDepositEvent-${currencyId}`, listener);
    });
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.deposits$ = [];
        const statusParams = this.generateStatusParams();
        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = paramsRef.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
        this.loadingBar.start();
        return this.affiliateDepositsDataService.getWithQuery(`?${this.params}&page=${this.page}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.deposits$ = res;
            this.loading = false;
            this.searchBtnLoading = false;
            this.clearBtnLoading = false;
            this.page = 1;
            this.pagination = this.affiliateDepositsDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.pageSize = 30;
    this.searchStatus = [0, 3];
    this.selectedMerchantAccount = [this.bankaccountDropdownList.find(v => v.id === 'all')];
    this.selectedBank = [this.dropdown.merchantBanks.find(v => v.id === 'all')];
    this.form.patchValue({
      id: null,
      username: null,
      name: null,
      currency_id: 'all',
      payment_method: 'all',
      affiliate_group: 'all',
      merchant_bank: 'all',
      merchant_bank_account: 'all',
      start_datetime: null,
      end_datetime: null,
      defaultDate: null,
      status: this.searchStatus,
      date_type: this.dateTimeFilterType[0].key
    });
    this.dropdown.statuses.forEach((child) => {
      if (child.name === 'Pending' || child.name === 'In Progress') {
        child.checked = true;
      } else {
        child.checked = false;
      }
    });
    this.onSubmit(true);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.deposits$ = [];
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.affiliateDepositsDataService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.deposits$ = res;
        this.loading = false;
        this.pagination = this.affiliateDepositsDataService.pagination;
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onCurrencyChange() {
    this.selectedCurrency = this.dropdown.currencies.find(x => x.id == this.form.value.currency_id);
    if (this.selectedCurrency === undefined) {
      this.selectedCurrency = { name: 'all' };
      this.bankaccountDropdownList = this.dropdown.merchantAccounts;
    } else {
      this.bankaccountDropdownList = this.dropdown.merchantAccounts.filter(x => x.settings_currency_id == this.form.value.currency_id);
      this.bankaccountDropdownList.unshift({
        id: 'all',
        name: 'All'
      });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClearDate() {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onSearchStatus(event: any, id: number, ind: number) {
    const position = this.searchStatus.indexOf(id);
    if (event.target.checked) {
      this.searchStatus.push(id);
      this.dropdown.statuses[ind].checked = true;
    } else {
      this.searchStatus.splice(position, 1);
      this.dropdown.statuses[ind].checked = false;
    }
    this.form.patchValue({ status: this.searchStatus });
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenReceipt(row: any) {
    this.receiptList = [];
    this.receiptList.push({
      src: row['receipt_path'],
      caption: (row['username'] + ' deposit receipt').toLocaleUpperCase(),
      thumb: row['receipt_path']
    });

    this.lightbox.open(this.receiptList, 0, { centerVertically: true, disableScrolling: true, fitImageInViewPort: true, fadeDuration: 0.01, resizeDuration: 0.01 });
  }

  onViewInfo(mode: string, row?: any) {
    const affiliate = this.allAffiliatesDataService.getById(row.affiliate_id);
    this.subscription = affiliate.pipe(
      tap((res: any) => {
        this.dialog.open(AffiliateInformationComponent, {
          width: '1500px',
          height: '80vh',
          data: {
            affiliate: res,
            mode: mode
          }
        });
      })
    ).subscribe();
  }

  onOpenDialog(depositRow: any) {
    this.subscription = this.affiliateDepositsDataService.getById(depositRow.id).subscribe((res: any) => {
      const showDialog = (takeover = false) => {
        if ((res.bo_user_id <= 0 || res.status === 0 || res.status === 3) && res.handler !== this.currentUsername) {
          const data = {
            id: res.id,
            status: 3,
            takeover: takeover
          };
          this.affiliateDepositsDataService.updateToNewHolder(data).subscribe();
        }
        this.openDialogBy(AffiliateDepositDetailsComponent, { deposit: res, mode: 'edit', newHandler: takeover }, '1200px');
      };
      if (res.bo_user_id > 0 && (res.bo_user_id !== this.currentUserId) && (res.status === 0 || res.status === 3)) {
        Swal.fire({
          title: 'Someone is on the same deposit row!',
          text: 'Do you want to take over?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          reverseButtons: true,
        }).then((resp) => {
          if (resp.value) {
            return showDialog(true);
          }
        });
      } else {
        return showDialog();
      }
    });
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private openDialogBy(componentRef: any, data?: { deposit?: any, mode?: any, newHandler?: boolean }, dimension?: string) {
    const dialogRef = this.dialog.open(componentRef, {
      width: dimension ? dimension : '800px',
      height: data.mode === 'member-information' ? '80vh' : 'auto',
      data: {
        deposit: data.deposit,
        mode: data.mode,
        newHandler: data.newHandler
      },
      autoFocus: data.mode === false
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page);
      }
    });
  }

  private generateStatusParams() {
    return {
      newStatusParams: Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&'),
      oldStatusParams: 'status=' + Object.keys(this.searchStatus).map(key => this.searchStatus[key]).join(','),
    };
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private setDropdowns() {
    this.setCurrencyDropdown();
    this.bankaccountDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'name',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false
    };
    this.dropdownHttpService.merchantBanks.subscribe(res => {
      this.dropdown.merchantBanks = res;
      this.dropdown.merchantBanks.unshift({
        id: 'all',
        name: 'All'
      });
      this.bankaccountDropdownList = this.dropdown.merchantBanks;
      this.selectedBank = [this.dropdown.merchantBanks.find(v => v.id === 'all')];
    });
    this.merchantBankHttpService.getMerchantBanksAccount().subscribe(
      res => {
        res.map(elm => {
          elm.name = elm.bank_code + ' - ' + elm.account_name + ' - ' + elm.account_number;
        });
        this.dropdown.merchantAccounts = res;
        this.dropdown.merchantAccounts.unshift({
          id: 'all',
          name: 'All'
        });
        this.bankaccountDropdownList = this.dropdown.merchantAccounts;
        this.selectedMerchantAccount = [this.bankaccountDropdownList.find(v => v.id === 'all')];
      }
    );
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      username: new FormControl(null),
      name: new FormControl(null),
      currency_id: new FormControl('all'),
      payment_method: new FormControl('all'),
      affiliate_group: new FormControl('all'),
      merchant_bank: new FormControl(null),
      merchant_bank_account: new FormControl(null),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      status: new FormControl(this.searchStatus),
      date_type: new FormControl(this.dateTimeFilterType[0].key)
    })
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

}
