<div class="row form-group ml-0 mr-0 mb-3 card">
  <div class="col-12 card-header larger-text mb-3">
    <strong>Bank Accounts</strong>
  </div>
  <div class="table-responsive">
    <button *ngIf="canCreateMemberBankAccount" class="btn btn-brand btn-icon-sm float-right mb-3 mr-3" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
    <table class="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Bank Type</th>
            <th>Bank Code</th>
            <th>Bank Name</th>
            <th>Account Name</th>
            <th>Account / Ewallet Number</th>
            <th class="text-center">Status</th>
            <th class="text-center">Verified</th>
            <th class="date-column">Created By</th>
            <th class="date-column">Updated By</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="(memberbankAccount$ | async) as rows">
          <tr *ngFor="let row of rows">
            <td>{{ row.id }}</td>
            <td>{{ row.bank_type_name }}</td>
            <td>{{ row.bank_code }}</td>
            <ng-container *ngIf="row.bank_name === 'PayNow'; else elseOther">
            <td>{{ row.bank_name + ' (' + onPayNowBankName(row) + ')' }}</td>
            <td>{{ row.account_name === '' ? '-' : row.account_name }}</td>
            <td>{{ onPayNowBankNumber(row) }}</td>
            </ng-container>
            <ng-template #elseOther>
              <td>{{ row.bank_name }}</td>
              <td>{{ row.account_name === '' ? '-' : row.account_name }}</td>
              <td>
                {{ row.account_number === '' ? '-' : row.account_number }}
                <!-- <button matTooltip="CCID Lookup" *ngIf="member.currency_code === 'MYR'" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onLookup(row.account_number)"><i class="fas fa-search"></i></button> -->
              </td>
            </ng-template>
            <td class="text-center">
              <div class="btn-group dropdown">
                <button type="button" class="btn btn-pill btn-sm dropdown-base"  [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }} </button>
                <ng-container *ngIf="canEditMemberBankAccount || canUpdateBankAccountStatus">
                  <button  type="button" class="btn btn-pill btn-sm dropdown-toggle" [ngClass]="'kt-badge--' + row.status" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-angle-down"></i>
                  </button>
                  <div class="dropdown-menu">
                    <ng-container *ngFor="let item of status | keyvalue">
                      <ng-container *ngIf="+item.key !== row.status">
                        <button class="dropdown-item" type="button" (click)="onChangeStatus(row)" *ngIf="+item.key >= 0">{{ status[item.key] }}</button>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>

              </div>
            </td>
            <ng-container *ngIf="row.verification_id == null else verificationStatus">
              <td class="text-center">-</td>
            </ng-container>
            <ng-template #verificationStatus>
              <td class="text-center">
                <i class="fa fa-check" aria-hidden="true" style="color: green; cursor: pointer;" *ngIf="row.verification_status == 1" (click)="onOpenDialog('member-bank-account-verification', row)" [class.disabled]="!canViewMemberBankAccountVerification"></i>
                <i class="fa fa-times" aria-hidden="true" style="color: red; cursor: pointer;" *ngIf="row.verification_status == 2" (click)="onOpenDialog('member-bank-account-verification', row)" [class.disabled]="!canViewMemberBankAccountVerification"></i>
                <i class="fa fa-spinner" aria-hidden="true" style="color: blue; cursor: pointer;" *ngIf="row.verification_status == 0 || row.verification_status == 3" (click)="onOpenDialog('member-bank-account-verification', row)" [class.disabled]="!canViewMemberBankAccountVerification"></i>
                <i class="fa fa-question" aria-hidden="true" style="color: #f0ad4e; cursor: pointer;" *ngIf="row.verification_status == 4" (click)="onOpenDialog('member-bank-account-verification', row)" [class.disabled]="!canViewMemberBankAccountVerification"></i>
              </td>
            </ng-template>
            <td>
              {{ row.created_by === null ? 'System' : row.created_by }}<br>
              <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #createdDateTime>
                {{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss'}}
              </ng-template>
            </td>
            <td>
              {{ row.updated_by === null ? 'System' : row.updated_by }}<br>
              <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #updatedDateTime>
                {{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss'}}
              </ng-template>
            </td>
            <td class="text-center">
              <button *ngIf="canEditMemberBankAccount" matTooltip="Edit" class="col-5 btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id)"><i class="fas fa-edit"></i></button>
              <button *ngIf="canReuploadVerificationDocument && (row.verification_status == 2 || row.verification_status == 4) && row.verification_id != null" matTooltip="Reupload Document" class="col-5 btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('reupload-document', row)"><i class="fa fa-upload"></i></button>
            </td>
          </tr>
          <kt-fallback-row [collection]="rows"></kt-fallback-row>
        </tbody>
      </table>
  </div>
</div>
<div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination">
  <ngb-pagination
    [pageSize]="pageSize"
    [(page)]="page"
    [maxSize]="maxSize"
    [directionLinks]="true"
    [boundaryLinks]="true"
    [rotate]="true"
    [collectionSize]="pagination.total"
    (pageChange)="onViewPageBy(page)">
  </ngb-pagination>
  <div class="kt-pagination__toolbar">
    <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
        <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
    </select>
    <span class="pagination__desc">
      Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
    </span>
  </div>
</div>

<!-- with R&P 2.0 permission, uncomment this and replace elem below when R&P 2.0 is ready -->
<div *ngIf="canViewMemberCryptoAccounts" class="row form-group ml-0 mr-0 mb-3 mt-5 card">
  <div class="col-12 card-header larger-text mb-3">
    <strong>Crypto Wallets</strong>
  </div>
  <div class="table-responsive">
    <button class="btn btn-brand btn-icon-sm float-right mb-3 mr-3" (click)="onOpenCryptoDialog('create')" *ngIf="canCreateMemberCryptoAccount && isSuperAdmin"><i class="fas fa-plus"></i>Create</button>
    <table class="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Token</th>
            <th>Network</th>
            <th>Wallet Nickname</th>
            <th>Wallet Address</th>
            <th class="text-center">Status</th>
            <th class="date-column">Created By</th>
            <th class="date-column">Updated By</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="(membercryptoWallet$ | async) as rows">
          <tr *ngFor="let row of rows">
            <td>{{ row.id }}</td>
            <td>{{ row.token }}</td>
            <td>{{ row.network }}</td>
            <td>{{ row.wallet_nickname === '' ? '-' : row.wallet_nickname }}</td>
            <td>
              {{ row.wallet_address === '' ? '-' : row.wallet_address }}
            </td>
            <td class="text-center">
              <div class="btn-group dropdown">
                <button type="button" class="btn btn-pill btn-sm dropdown-base"  [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }} </button>
                <ng-container *ngIf="canUpdateBankCryptoStatus || canEditMemberCryptoAccount">
                  <button type="button" class="btn btn-pill btn-sm dropdown-toggle" [ngClass]="'kt-badge--' + row.status" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-angle-down"></i>
                  </button>
                  <div class="dropdown-menu">
                    <ng-container *ngFor="let item of status | keyvalue">
                      <ng-container *ngIf="+item.key !== row.status">
                        <button class="dropdown-item" type="button" (click)="onChangeCryptoStatus(row)" *ngIf="+item.key >= 0">{{ status[item.key] }}</button>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container> 
              </div>
            </td>
            <td>
              {{ row.created_by === null ? 'System' : row.created_by }}<br>
              <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #createdDateTime>
                {{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss'}}
              </ng-template>
            </td>
            <td>
              {{ row.updated_by === null ? 'System' : row.updated_by }}<br>
              <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #updatedDateTime>
                {{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss'}}
              </ng-template>
            </td>
            <td class="text-center">
            <button *ngIf="canEditMemberCryptoAccount && isSuperAdmin" matTooltip="{{ 'Edit Crypto Wallet' | translate }}" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenCryptoDialog('edit', row.id)"><i class="fas fa-edit"></i></button>
            </td>
          </tr>
          <kt-fallback-row [collection]="rows"></kt-fallback-row>
        </tbody>
      </table>
  </div>
</div>
<div class="kt-pagination kt-pagination--brand mt-2 mb-4" *ngIf="cryptoPagination">
  <ngb-pagination
    [pageSize]="cryptoPageSize"
    [(page)]="cryptoPage"
    [maxSize]="cryptoMaxSize"
    [directionLinks]="true"
    [boundaryLinks]="true"
    [rotate]="true"
    [collectionSize]="cryptoPagination.total"
    (pageChange)="onViewCryptoPageBy(cryptoPage)">
  </ngb-pagination>
  <div class="kt-pagination__toolbar">
    <select (change)="onPerCryptoPage($event)" [(ngModel)]="cryptoPageSize" class="form-control kt-font-brand page-size">
        <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
    </select>
    <span class="pagination__desc">
      Showing {{cryptoPagination.from}} to {{cryptoPagination.to}} of {{ cryptoPagination.total | number : '1.0' }} records
    </span>
  </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
<kt-swal-alert [message]="cryptoMessages$ | async" (confirmed)="onCloseCryptoDialog($event)"></kt-swal-alert>
