import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AppPermissionService } from '@core/services/app-permission.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MemberLabelListComponent } from './../member-label-list/member-label-list.component';
import { MemberLabelSettingsComponent } from './../member-label-settings/member-label-settings.component';
@Component({
  templateUrl: './member-label-remark.component.html',
  styleUrls: ['./member-label-remark.component.scss']
})
export class MemberLabelRemarkDialogComponent implements OnInit, OnDestroy {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  @ViewChild(MemberLabelListComponent) memberLabelListComponent!: MemberLabelListComponent;
  @ViewChild(MemberLabelSettingsComponent) memberLabelSettingsComponent!: MemberLabelSettingsComponent;
  form: FormGroup;

  selectedTabIndex: number = 0;

  buttonLoading = false;

  // permissions
  canViewMemberLabelInfo: boolean;
  canViewMemberLabelSettings: boolean;

  private subscriptions = new Subscription();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MemberLabelRemarkDialogComponent>,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberLabelInfo = appPermissions.view_member_info_list;
      this.canViewMemberLabelSettings = appPermissions.view_label_settings_list;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.memberLabelListComponent.onClear(); // Call getTimeline in kt-member-label-list to get latest member label list
    } else if (event.index === 1) {
      this.memberLabelSettingsComponent.onClear(); // Call getTimeline in kt-member-label-settings to get latest member label setting
    }
  }
}
