import { Component, OnInit, Input, Output, ChangeDetectorRef,EventEmitter } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { tap, catchError, delay } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'kt-sync-locale-button',
  templateUrl: './sync-locale-button.component.html',
  styleUrls: ['./sync-locale-button.component.scss'],
  host: {
    '(document:mousedown)': 'onClickOutside($event)',
  }
})
export class SyncLocaleButtonComponent implements OnInit {

  @ViewChild('syncdrop', null) navdrop: ElementRef; // Angular Version 8
  
  @Input()
  localeList: any;

  @Input()
  settings_locale_id_from: number;

  @Input()
  checkupdate: boolean;

  @Input()
  valueType: boolean;

  firstCheckUpdate = true;

  @Output()
  confirmed = new EventEmitter<boolean>();

  @Output() 
  localesSynced = new EventEmitter<any>();

  dropdown = {
    locales: [],
  };

  form: FormGroup;

  selectedLocales: number[] = [];

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private _eref: ElementRef,
  ) { }

  ngOnInit(): void {
    this.dropdown.locales = this.localeList;
  }

  dropClick() {
    if (this.checkupdate ==  true && this.firstCheckUpdate ==  true) {
      Swal.fire({
        title: '<div class="text-center">This page contains unsaved changes</div>',
        html: '<div class="text-center">' + this.translateService.instant('If you leave this page, changes you made may not be saved') +'</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Go Back'),
        confirmButtonText: this.translateService.instant('Continue'),
        icon:'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {

          this.firstCheckUpdate = this.firstCheckUpdate == true ? false : true;
          this.navdrop.nativeElement.classList.toggle("visibility");

        } else if (result.isDenied) {
        }

      });
    } else {
      this.firstCheckUpdate = this.firstCheckUpdate == true ? false : true;
      this.navdrop.nativeElement.classList.toggle("visibility");
    } 
  }

  onCheckboxChange(event: Event, localeId: number) {
    const checked = (event.target as HTMLInputElement).checked;

    if (checked) {
      this.selectedLocales.push(localeId); 
    } else {
      this.selectedLocales = this.selectedLocales.filter((id) => id !== localeId); 
    }
  }

  syncClick(){
    const data = {
      selectedLocales: this.selectedLocales,
      settingsLocaleIdFrom: this.settings_locale_id_from
    }

    this.localesSynced.emit(data);
    this.dropClick();
  }

  onClickOutside(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      of(null).pipe(
        delay(105), tap(() => this.navdrop.nativeElement.classList.add("visibility")
        )).subscribe();
    }
  }

}
