import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MemberLabelSetting } from '@core/models/member-label-setting.model';

@Injectable()
export class MemberAccountLabelSettingHttpService {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  
  constructor(
    private http: HttpClient
  ) { }

  getWithQuery(params: string): Observable<MemberLabelSetting[]>{
    return this.http.get<ApiResponse>(`/memberaccountlabelsetting${params}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  getById(id: number): Observable<MemberLabelSetting[]> {
    return this.http.get<ApiResponse>(`/memberaccountlabelsetting/${id}`).pipe(
      map(res => res.data.rows)
    )
  }

  add(label) {
    return this.http.post<ApiResponse>(`/memberaccountlabelsetting`, label).pipe(
      tap(res => this.messages$.next(res.message)),
    )
  }

  update(id: number, label) {
    return this.http.put<ApiResponse>(`/memberaccountlabelsetting/${id}`, label).pipe(
      tap(res => this.messages$.next(res.message)),
    )
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }

}
