<form [formGroup]="form">
  <input type="hidden" formControlName="withdraw_id" [value]="id" />
  <div *ngIf="status === 3 || status === 5" class="form-group row">
    <div class="col-4">
      <button *ngIf="canEditWithdrawals" type="button" class="btn btn-primary" [disabled]="newTransaction.length >= 1"  (click)="onAddTransaction()">Add Transaction</button>
    </div>
  </div>

  <ng-container *ngIf="withdrawalBankType == 'crypto'; else nonCryptoArea">
    <ng-container formArrayName="merchant_banks">
      <div class="form-group form-group-md row" *ngIf="banks.controls.length >= 1">
        <div class="col-3">{{ withdrawalBankType == 'crypto' ? 'Payment Method' : 'Payment Gateway' }}</div>
        <div class="pl-0 col-2">Amount</div>
        <div class="pl-0 col-3">Remarks</div>
        <div class="pl-0 col-1">Action</div>
      </div>
      <div class="form-group row align-items-center" *ngFor="let bank of banks.controls; index as i" [formGroupName]="i">
        <div class="col-3 amount-height">
          <kt-dropdown-wo-lazyload
            [form] = 'bank'
            [dropdownList] = 'merchantBanks'
            [dropdownSettings] = 'bankaccountDropdownSettings'
            [formName] = "'id'"
            [selectionAttributes] = "'id'"
            (selectedItemsChanged)="onSelectMerchantBank($event, i)"
            [selectedItems] = 'selectedMerchantBank[i]'
            class="w-100">
        </kt-dropdown-wo-lazyload>
        </div>
        <div class="pl-0 amount-height" [ngClass]="(selectedMerchantBank[i] !== undefined && isWPPSelected) ? 'col-1' : 'col-2'">
          <input type="number" (wheel)="false" class="form-control" maxlength="10" formControlName="amount" (change)="onChangeAmount($event, i)" [readonly]="buttonLoading"/>
          <small class="text-muted form-text red-hint" *ngIf="showMinAmount[i]">
            <i class="fa fa-exclamation-circle"></i> {{ 'Minimum' }} : {{ minAmountText[i] }}
          </small>
          <small class="text-muted form-text red-hint" *ngIf="showMaxAmount[i]">
            <i class="fa fa-exclamation-circle"></i> {{ 'Maximum' }} : {{ maxAmountText[i] }}
          </small>
        </div>
        <div class="pl-0 amount-height col-3">
          <input type="text" class="form-control" formControlName="remarks" [disabled]="selectedMerchantBank[i][0]?.bank_type != 'Payment Gateway'" [readonly]="buttonLoading || disableTransactionInput" />
        </div>
        
        <div class="pl-0 amount-height col-4">
          <div class="dropdown-inline">
            <button type="button" class="btn btn-primary mr-2" (click)="onPay(i)" [disabled]="buttonLoading || (showMinAmount[i] || showMaxAmount[i]) || (cryptoWithdrawalLink && !cryptoWithdrawalLinkClicked) || selectedMerchantBank[i].length <= 0"><i *ngIf="buttonLoading" class="spinner-border spinner-border-sm align-middle mr-2"></i>
              {{ 'Generate Withdrawal Link' }}
            </button>
            <button type="button" class="btn btn-warning" (click)="onRemoveItem(i)" [disabled]="buttonLoading">Remove</button>
          </div>
        </div>
        <div class="col-12 p-4" *ngIf="cryptoWithdrawalLink && !buttonLoading">
          <section class="row border-top border-left">
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
              <span class="kt-font-bold">Coin2Pay Withdrawal Link</span>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12 border-right border-bottom p-3">
              <button type="button" class="btn btn-success mr-2" (click)="onRedirectCryptoWithdrawalUrl()">
                Proceed with Withdrawal
              </button>
            </div>
          </section>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #nonCryptoArea>

    <ng-container formArrayName="merchant_banks" *ngIf="isPaymentGatewaySelected">
      <div class="form-group form-group-md row" *ngIf="banks.controls.length >= 1">
        <div class="col-3">Merchant Bank</div>
        <div class="pl-0" [ngClass]="isWPPSelected ? 'col-1' : 'col-2'">Amount</div>
        <div class="col-1 pl-0" *ngIf="!showPayButton">Processing Fee</div>
        <div class="col-1" *ngIf="isWPPSelected">IFSC Code</div>
        <div class="pl-0" [ngClass]="showPayButton ? 'col-2' : 'col-3'">Remarks</div>
        <!-- <div *ngIf="canUploadWithdrawalReceipt" class="col-2 pl-0">Receipt</div> -->
        <div class="pl-0" [ngClass]="showPayButton ? 'col-2' : 'col-1'">Action</div>
      </div>
      <div class="form-group row align-items-center" *ngFor="let bank of banks.controls; index as i" [formGroupName]="i">
        <div class="col-3 amount-height">
          <kt-dropdown-wo-lazyload
            [form] = 'bank'
            [dropdownList] = 'merchantBanks'
            [dropdownSettings] = 'bankaccountDropdownSettings'
            [formName] = "'id'"
            [selectionAttributes] = "'id'"
            (selectedItemsChanged)="onSelectMerchantBank($event, i)"
            [selectedItems] = 'selectedMerchantBank[i]'
            class="w-100">
        </kt-dropdown-wo-lazyload>
          <!-- <select class="form-control" formControlName="id">
            <option value="null" [disabled]="true">Please Select</option>
            <option *ngFor="let item of merchantBanks" [value]="item.id">{{ item.bank_name }} - {{ item.account_name }} - {{ item.account_number }}</option>
          </select> -->
        </div>
        <div class="pl-0 amount-height" [ngClass]="(selectedMerchantBank[i] !== undefined && isWPPSelected) ? 'col-1' : 'col-2'">
          <input type="number" (wheel)="false" class="form-control" maxlength="10" formControlName="amount" (change)="onChangeAmount($event, i)" />
          <small class="text-muted form-text red-hint" *ngIf="showMinAmount[i]">
            <i class="fa fa-exclamation-circle"></i> {{ 'Minimum' }} : {{ minAmountText[i] }}
          </small>
          <small class="text-muted form-text red-hint" *ngIf="showMaxAmount[i]">
            <i class="fa fa-exclamation-circle"></i> {{ 'Maximum' }} : {{ maxAmountText[i] }}
          </small>
        </div>
        <div class="col-1 pl-0 amount-height" *ngIf="!showPayButton">
          <input type="number" (wheel)="false" class="form-control" maxlength="10" formControlName="processing_fee"/>
        </div>
        <div class="col-1 amount-height" *ngIf="isWPPSelected">
          <input type="text" class="form-control" formControlName="payee_ifsc_code" [disabled]="selectedMerchantBank[i][0]?.bank_code != 'WPP'"/>
        </div>
        <div class="pl-0 amount-height" [ngClass]="showPayButton ? 'col-2' : 'col-3'">
          <input type="text" class="form-control" formControlName="remarks" [disabled]="selectedMerchantBank[i][0]?.bank_type != 'Payment Gateway'"/>
        </div>
        <!-- <div *ngIf="canUploadWithdrawalReceipt" class="col-2 pl-0 d-flex align-items-center receipt-row  amount-height">
          <ng-container *ngIf="newTransaction.value[i].loading; else elseShow">
            <div class="spinner-wrapper">
              <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
            </div>
          </ng-container>
          <ng-template #elseShow>
            <ng-container *ngIf="form.value.merchant_banks[i].receipt_image !== null; else elseUpload">
              <img *ngIf="!newTransaction.value[i].isPDF && newTransaction.value[i].url !== ''" [src]="form.value.merchant_banks[i].receipt_image" (click)="openReceipt(form.value.merchant_banks[i].receipt_image)">
              <iframe *ngIf="newTransaction.value[i].isPDF && newTransaction.value[i].url !== ''" [src]="newTransaction.value[i].url" (click)="openReceipt(form.value.merchant_banks[i].receipt_image)"></iframe>
              <i class="fa fa-times" matTooltip="Remove Receipt" (click)="onRemoveReceipt(i)"></i>
            </ng-container>
            <ng-template #elseUpload>
              <label class="btn btn-default btn-sm float-left m-0 receipt-amt-height">
                <span>Choose file</span>
                <input type="file" hidden accept="image/*, application/pdf" (change)="onUploadFile($event, i)">
              </label>
            </ng-template>
          </ng-template>
        </div> -->
        <div class="pl-0 amount-height" [ngClass]="showPayButton ? 'col-2' : 'col-1'">
          <div class="dropdown-inline">
            <button *ngIf="showPayButton && selectedMerchantBank[i][0]?.bank_type_name == 'Payment Gateway'" type="button" class="btn btn-primary mr-2" (click)="onPay(i)" [disabled]="bankTransactionButtonLoading || (showMinAmount[i] || showMaxAmount[i])"><i *ngIf="bankTransactionButtonLoading" class="spinner-border spinner-border-sm align-middle mr-2"></i>Pay</button>
            <button type="button" class="btn btn-warning" (click)="onRemoveItem(i)" [disabled]="buttonLoading">Remove</button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container formArrayName="merchant_banks" *ngIf="!isPaymentGatewaySelected">
      <ng-container *ngIf="banks.controls.length >= 1">
        <ng-container *ngFor="let bank of banks.controls; index as i" [formGroupName]="i">
          <!-- Header rows -->
          <div class="form-group form-group-md">
            <!-- First row labels -->
            <div class="row mb-1">
              <div class="col-3">Merchant Bank</div>
              <div class="col-2 pl-0">Amount</div>
              <div class="col-3 pl-0">Processing Fee</div>
              <div class="col-2 pl-0" *ngIf="canUploadWithdrawalReceipt">Receipt</div>
              <div class="col-2 pl-0">Action</div>
            </div>

            <!-- Second row labels (Processing Fee sub-labels) -->
            <div class="row mb-2">
              <div class="col-3 offset-5 pl-0">
                <div class="additional-words">
                  <span>Total:</span>
                  <span style="padding-left: 20px;">Company:</span>
                  <span>Player:</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Single row container -->
          <div class="form-group row align-items-center">
            <!-- Merchant bank -->
            <div class="col-3 amount-height">
              <kt-dropdown-wo-lazyload
                [form]='bank'
                [dropdownList]='merchantBanks'
                [dropdownSettings]='bankaccountDropdownSettings'
                [formName]="'id'"
                [selectionAttributes]="'id'"
                (selectedItemsChanged)="onSelectMerchantBank($event, i)"
                [selectedItems]='selectedMerchantBank[i]'
                class="w-100">
              </kt-dropdown-wo-lazyload>
            </div>

            <!-- Amount -->
            <div class="col-2 pl-0 amount-height">
              <input 
                type="number" 
                (wheel)="false" 
                class="form-control" 
                maxlength="10" 
                formControlName="amount" 
                (change)="onChangeAmount($event, i)" 
              />
              <small class="text-muted form-text red-hint" *ngIf="showMinAmount[i]">
                <i class="fa fa-exclamation-circle"></i> Min: {{ minAmountText[i] }}
              </small>
              <small class="text-muted form-text red-hint" *ngIf="showMaxAmount[i]">
                <i class="fa fa-exclamation-circle"></i> Max: {{ maxAmountText[i] }}
              </small>
            </div>

            <!-- Processing Fee - Total -->
            <div class="col-1 pl-0 amount-height">
              <input
                type="number"
                (wheel)="false"
                class="form-control"
                maxlength="10"
                formControlName="total_processing_fee"
                (change)="onChangeTotalProcessingFee($event, i)"
              />
              <small class="text-muted form-text red-hint small-font-size" *ngIf="showTotalProcessingFeeError[i]">
                <i class="fa fa-exclamation-circle"></i> {{ totalProcessingFeeErrorText[i] }}
              </small>
            </div>

            <!-- Processing Fee - Company -->
            <div class="col-1 pl-0 amount-height">
              <input
                type="number"
                (wheel)="false"
                class="form-control"
                maxlength="10"
                formControlName="processing_fee"
                (change)="onChangeProcessingFee(i, $event)"
              />
              <small class="text-muted form-text red-hint small-font-size" *ngIf="showProcessingFeeError[i]">
                <i class="fa fa-exclamation-circle"></i> {{ processingFeeErrorText[i] }}
              </small>
            </div>

            <!-- Processing Fee - Player -->
            <div class="col-1 pl-0 amount-height">
              <input
                type="number"
                (wheel)="false"
                class="form-control"
                maxlength="10"
                formControlName="member_processing_fee"
                (change)="onChangeProcessingFee(i, $event)"
              />
            </div>

            <!-- Receipt -->
            <!-- <div class="col-1 pl-0" *ngIf="canUploadWithdrawalReceipt"> -->
            <div class="col-2 pl-0 d-flex align-items-center receipt-row amount-height" *ngIf="canUploadWithdrawalReceipt">
              <ng-container *ngIf="newTransaction.value[i].loading; else elseShow">
                <div class="spinner-wrapper">
                  <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
                </div>
              </ng-container>
              <ng-template #elseShow>
                <ng-container *ngIf="form.value.merchant_banks[i].receipt_image !== null; else elseUpload">
                  <img *ngIf="!newTransaction.value[i].isPDF && newTransaction.value[i].url !== ''" [src]="form.value.merchant_banks[i].receipt_image" (click)="openReceipt(form.value.merchant_banks[i].receipt_image)">
                  <iframe *ngIf="newTransaction.value[i].isPDF && newTransaction.value[i].url !== ''" [src]="newTransaction.value[i].url" (click)="openReceipt(form.value.merchant_banks[i].receipt_image)"></iframe>
                  <i class="fa fa-times" matTooltip="Remove Receipt" (click)="onRemoveReceipt(i)"></i>
                </ng-container>
                <ng-template #elseUpload>
                  <label class="btn btn-default btn-sm float-left m-0 receipt-amt-height w-100">
                    <span>Choose file</span>
                    <input type="file" hidden accept="image/*, application/pdf" (change)="onUploadFile($event, i)">
                  </label>
                </ng-template>
              </ng-template>
            </div>

            <!-- Action -->
            <div class="col-2 pl-0 amount-height">
              <div class="dropdown-inline">
                <button *ngIf="canEditWithdrawals" type="button" class="btn btn-success mr-2" (click)="onApproveBankTransaction(i)" [disabled]="bankTransactionButtonLoading">
                  <i *ngIf="bankTransactionButtonLoading" class="spinner-border spinner-border-sm align-middle mr-2"></i>
                  Approve
                </button>
                <button *ngIf="!bankTransactionButtonLoading" type="button" class="btn btn-warning" (click)="onRemoveItem(i)" [disabled]="buttonLoading">
                  Remove
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

  </ng-template>

</form>

<div class="table-responsive">
<table class="table table-bordered table-hover table-striped mt-5" *ngIf="bankTransactions">
  <thead>
    <ng-container *ngIf="withdrawalBankType != 'crypto'">
      <tr>
        <th>ID</th>
        <th>Bank</th>
        <th class="text-center">Amount</th>
        <th class="text-center min-w-150">Processing Fee</th>
        <th class="text-center">Receipt</th>
        <th class="text-center">Status</th>
        <th>References</th>
        <th>Remarks</th>
        <th class="date-column">Created By</th>
        <th class="date-column">Updated By</th>
      </tr>
    </ng-container>
    <ng-container *ngIf="withdrawalBankType == 'crypto'">
      <tr>
        <th rowspan="2">ID</th>
        <th rowspan="2">Payment Gateway</th>
        <th colspan="2" class="text-center">Local</th>
        <th colspan="2" class="text-center">Crypto</th>
        <th rowspan="2">Exchange Rate</th>
        <th rowspan="2">Status</th>
        <th rowspan="2">References</th>
        <th rowspan="2">Remarks</th>
        <th rowspan="2">Created By</th>
        <th rowspan="2">Updated By</th>
      </tr>
      <tr>
        <th class="text-center">Amount</th>
        <th class="text-center">Processing Fee</th>
        <th class="text-center">Amount</th>
        <th class="text-center">Processing Fee</th>
      </tr>
    </ng-container>
  </thead>
  <tbody>
    <tr *ngFor="let row of bankTransactions, index as i">
      <td class="text-center">
        <div class="d-flex flex-column align-items-center">
          <span>{{ row.id }}</span>
          <!-- Revert -->
          <button
            *ngIf="
              canRevertPayoutTransaction &&
              (
                withdrawalDetails.status == WithdrawStatusEnum.IncompletePayout &&
                row.bank_type == BankTypeEnum.PaymentGateway &&
                row.status === BankTransactionStatusEnum.Rejected
              )
            "
            matTooltip="Revert"
            [disabled]="isBankTransactionActionBtnClicked[i]"
            class="btn btn-elevate btn-sm btn-icon btn-icon-sm shadow-none"
            (click)="onUpdateBankTransactionStatus(row.id, i, 'revert')"
          >
            <i [class]="isBankTransactionActionBtnClicked[i] ? 'fas fa-circle-notch fa-spin' : 'fa fa-undo-alt'"></i>
          </button>

          <!-- Void -->
          <button
            *ngIf="
              (
                (row.bank_type == BankTypeEnum.PaymentGateway && canVoidPayoutTransactionPG) ||
                (row.bank_type == BankTypeEnum.Bank && canVoidPayoutTransactionBankTransfer)
              ) && row.status === BankTransactionStatusEnum.Approved
            "
            matTooltip="Void"
            [disabled]="isBankTransactionActionBtnClicked[i]"
            class="btn btn-elevate btn-sm btn-icon btn-icon-sm shadow-none"
            (click)="onUpdateBankTransactionStatus(row.id, i, 'void')"
          >
            <i [class]="isBankTransactionActionBtnClicked[i] ? 'fas fa-circle-notch fa-spin' : 'fa fa-ban'"></i>
          </button>

          <!-- Force Approve -->
          <button
            *ngIf="
              canForceApproveTransaction
              && row.status === BankTransactionStatusEnum.Rejected
              && row.bank_type == BankTypeEnum.PaymentGateway
            "
            matTooltip="Approve"
            [disabled]="isBankTransactionActionBtnClicked[i]"
            class="btn btn-elevate btn-sm btn-icon btn-icon-sm shadow-none"
            (click)="onUpdateBankTransactionStatus(row.id, i, 'force-approve')"
          >
            <i [class]="isBankTransactionActionBtnClicked[i] ? 'fas fa-circle-notch fa-spin' : 'fa fa-check'"></i>
          </button>

          <!-- sync -->
          <button
            *ngIf="canSyncPayoutTransaction && row.bank_type == BankTypeEnum.PaymentGateway && row.status === BankTransactionStatusEnum.Pending"
            matTooltip="Sync"
            [disabled]="isBankTransactionActionBtnClicked[i]"
            class="btn btn-elevate btn-sm btn-icon btn-icon-sm shadow-none"
            (click)="onCheckPayoutStatus(row.id, i)"
          >
            <i [class]="isBankTransactionActionBtnClicked[i] ? 'fas fa-circle-notch fa-spin' : 'fas fa-sync'"></i>
          </button>
        </div>
      </td>
      <td>{{ row.merchant_bank_account }}</td>
      <td class="text-right">{{ canShowAmount(row.amount, '1.2-2') }}</td>
      <td>
        <div class="processing-fee-row">
            <span class="processing-fee-label"><b>Player</b>: </span>
            <span class="processing-fee-amount"><b>{{ canShowAmount(row.member_processing_fee, '1.2-2') }}</b></span>
        </div>
        <div class="processing-fee-row">
            <span class="processing-fee-label">Company: </span>
            <span class="processing-fee-amount">{{ canShowAmount(row.processing_fee, '1.2-2') }}</span>
        </div>
        <div class="processing-fee-row">
            <span class="processing-fee-label">Total: </span>
            <span class="processing-fee-amount">{{ canShowAmount((+row.member_processing_fee + +row.processing_fee), '1.2-2') }}</span>
        </div>
      </td>
      <ng-container *ngIf="withdrawalBankType == 'crypto'">
        <td class="text-right">{{ canShowAmount(row.cr_amount, '1.6-6') }}</td>
        <td>
          <div class="processing-fee-row">
              <span class="processing-fee-label"><b>Player</b>: </span>
              <span class="processing-fee-amount"><b>{{ canShowAmount(row.cr_member_processing_fee, '1.6-6') }}</b></span>
          </div>
          <div class="processing-fee-row">
              <span class="processing-fee-label">Company: </span>
              <span class="processing-fee-amount">{{ canShowAmount(row.cr_processing_fee, '1.6-6') }}</span>
          </div>
          <div class="processing-fee-row">
              <span class="processing-fee-label">Total: </span>
              <span class="processing-fee-amount">{{ canShowAmount((+row.cr_member_processing_fee + +row.cr_processing_fee), '1.6-6') }}</span>
          </div>
        </td>
        <td class="text-right">{{ row.cr_exchange_rate | number : '1.3-3' }}</td>
      </ng-container>
      <ng-container *ngIf="withdrawalBankType != 'crypto'">
        <td class="text-center">
          <ng-container *ngIf="row.receipt_image !== null && row.receipt_image !== ''; else elseTemplate">
              <i class="fas fa-receipt view-receipt" matTooltip="View Receipt" (click)="openReceipt(row.receipt_image)"></i>
          </ng-container>
          <ng-template #elseTemplate>
            -
          </ng-template>
        </td>
      </ng-container>
      <td class="text-center">
        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + (row.status === 0 ? '2' : row.status === 2 ? '0' : row.status)"> {{ row.status_name }}</span>
      </td>
      <td>{{ row.pg_reference_id ? row.pg_reference_id : '-' }}</td>
      <td style="max-width: 15vw;" [ngClass]="row.transaction_remarks ? 'text-left' : 'text-center'">
          <ng-container *ngIf="(row.transaction_remarks); else nullTemplate">
              <a class="text-primary font-weight-bold" (click)="onOpenDialog('show-remarks', row.id)">
                  {{ row.transaction_remarks }}
              </a>
          </ng-container>
          <ng-template #nullTemplate>
              -
          </ng-template>
      </td>
      <td>
        {{ row.created_by ? row.created_by : 'System' }}<br>
        <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
        <ng-template #createdDateTime>
          {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
        </ng-template>
      </td>
      <td>
        {{ row.updated_by ? row.updated_by : 'System' }}<br>
        <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
        <ng-template #updatedDateTime>
          {{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
        </ng-template>
      </td>
    </tr>
  </tbody>
    <tbody shared-table-handler [loading]="loading" [dataLength]="bankTransactions.length" class="text-gray-600 fw-bold"></tbody>
</table>
</div>
<kt-swal-alert [message]="messages2$ | async"></kt-swal-alert>
