<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Campaign:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <input type="text" formControlName="campaign_name" placeholder="Search"
                                        class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <select formControlName="currency_id" class="form-control">
                                        <option value="0"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Account Type:</label>
                                  </div>
                                  <div class="col-md-3 kt-form__control">
                                    <kt-dropdown-wo-lazyload 
                                        class="dropdown-maxheight col-10" 
                                        style="padding:0" 
                                        [form]='form'
                                        [dropdownList]='accountTypeDropdownList' 
                                        [dropdownSettings]='accountTypeDropdownSettings'
                                        [formName]="'dummy'" 
                                        [selectionAttributes]="'id'"
                                        [selectedItems]='accountTypeSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                  </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control ">
                                    <div class="input-group date">
                                        <input type="text" class="form-control" placeholder="Search"
                                            (change)="onDateRange($event)" formControlName="defaultDate"
                                            [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true"
                                            [ranges]="ranges" [showCustomRangeLabel]="true"
                                            [locale]="dateTimePickerLocale" [timePicker24Hour]="true"
                                            [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                                    </div>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Timezone' }}:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <kt-dropdown-wo-lazyload
                                        #timezone
                                        (click)="onChangeTimezone(timezone.selectedItems)"
                                        [form] = 'form'
                                        [dropdownList] = 'dropdown.timezones'
                                        [dropdownSettings] = 'timezoneDropdownSettings'
                                        [formName] = "'timezone'"
                                        [selectionAttributes] = "'timezone'"
                                        [selectedItems] = 'timezoneSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Action Button -->
            <div class="kt-section col-12 row mb-3 mt-2 d-flex justify-content-between">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canExportSMSRecoveryReport" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button" [disabled]="button_loading">
                    <i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export
                </button>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('campaign_id')" [ngClass]="sortingConfig.campaign_id" [class.sort-selected]="sortingSelection.sort_by === 'campaign_id'">Campaign ID</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('campaign_name')" [ngClass]="sortingConfig.campaign_name" [class.sort-selected]="sortingSelection.sort_by === 'campaign_name'">Campaign Name </th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('total_deposit')" [ngClass]="sortingConfig.total_deposit" [class.sort-selected]="sortingSelection.sort_by === 'total_deposit'">Total Deposit Amount</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('deposit')" [ngClass]="sortingConfig.deposit" [class.sort-selected]="sortingSelection.sort_by === 'deposit'">Total Deposit Count</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('depositor')" [ngClass]="sortingConfig.depositor" [class.sort-selected]="sortingSelection.sort_by === 'depositor'">Total Depositors</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('avg_deposit')" [ngClass]="sortingConfig.avg_deposit" [class.sort-selected]="sortingSelection.sort_by === 'avg_deposit'">Avg. Deposit</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('player_assigned')" [ngClass]="sortingConfig.player_assigned" [class.sort-selected]="sortingSelection.sort_by === 'player_assigned'">Total Players Assigned</th>
                                    <th class="date-column text-center sort-enabled" (click)="onSortColumn('last_player_assigned_time')" [ngClass]="sortingConfig.last_player_assigned_time" [class.sort-selected]="sortingSelection.sort_by === 'last_player_assigned_time'">Last Player Assign Time</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('reward_name')" [ngClass]="sortingConfig.reward_name" [class.sort-selected]="sortingSelection.sort_by === 'reward_name'">Reward Name</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('redeemed')" [ngClass]="sortingConfig.redeemed" [class.sort-selected]="sortingSelection.sort_by === 'redeemed'">Redeemed</th>
                                    <th class="date-column text-center sort-enabled" (click)="onSortColumn('last_redeemed_time')" [ngClass]="sortingConfig.last_redeemed_time" [class.sort-selected]="sortingSelection.sort_by === 'last_redeemed_time'">Last Redeem Time</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('redeem')" [ngClass]="sortingConfig.redeem" [class.sort-selected]="sortingSelection.sort_by === 'redeem'">Redeem %</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('ad_cost')" [ngClass]="sortingConfig.ad_cost" [class.sort-selected]="sortingSelection.sort_by === 'ad_cost'">SMS Cost</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('ad_cost_per_depositor')" [ngClass]="sortingConfig.ad_cost_per_depositor" [class.sort-selected]="sortingSelection.sort_by === 'ad_cost_per_depositor'">SMS Cost per Depositor</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('total_ngr')" [ngClass]="sortingConfig.total_ngr" [class.sort-selected]="sortingSelection.sort_by === 'total_ngr'">Total NGR</th>
                                </tr>
                            </thead>

                            <tbody *ngIf="smsRecovery$ as rows">
                                <tr *ngFor="let row of rows">
                                    <td class="text-center"><a class="text-primary" (click)="onOpenDialog(row.campaign_id)">{{ row.campaign_id}}</a></td>
                                    <td class="text-center">{{ row.campaign_name }}</td>
                                    <td class="text-right">{{ row.total_deposit | number : '1.2-2' }}</td>
                                   <td class="text-right">{{ row.deposit | number : '1.0-0' }}</td>
                                      <td class="text-right">{{ row.depositor | number : '1.0-0' }}</td>
                                    <td class="text-right">{{ row.avg_deposit | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ row.player_assigned | number: '1.0-0' }}</td>
                                    <td class="text-center">{{ row.last_player_assigned_time === null ? '-' : (row.last_player_assigned_time | timezoneDate: 'YYYY-MM-DD HH:mm') }}</td>
                                   <td class="text-center">{{ row.reward_name === null ? '-' : (row.reward_name) }}</td>
                                   <td class="text-right">{{ row.redeemed == 'null' ? '-' : row.redeemed | number: '1.0-0'}}</td>
                                    <td class="text-center">{{ row.last_redeemed_time | timezoneDate: 'YYYY-MM-DD HH:mm'  }}</td>
                                    <td class="text-right">{{ row.redeem }}</td>
                                    <td class="text-right">{{ row.ad_cost | number: '1.2-2' }}</td>
                                    <td class="text-right">{{ row.ad_cost_per_depositor | number: '1.2-2' }}</td>
                                    <td class="text-right">{{ row.total_ngr | number: '1.2-2'}}</td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="smsRecovery$" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <!-- Pagination -->
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize"
                            [directionLinks]="true" [boundaryLinks]="true" [rotate]="true"
                            [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize"
                                class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of
                                {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
