// Angular
import {
  ElementRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

// Service
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from '@angular/platform-browser';
import { Lightbox } from 'ngx-lightbox';
import { ImagePreviewDialogComponent } from './image-preview/image-preview.component';

@Component({
  selector: "app-transaction-view-receipt",
  templateUrl: "./view-transaction-receipt.component.html",
  styleUrls: ["./view-transaction-receipt.component.scss"],
  providers: [NgbCarouselConfig],
})
export class ViewTransactionReceiptComponent implements OnInit, OnDestroy {
  @ViewChildren("focusfield") focusfield: QueryList<ElementRef>;

  private subscription = new Subscription();

  loading = false;
  remarkLength = 0;
  buttonLoading = false;
  receipts = [];
  receiptImage = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bankTransaction: any},
    public dialogRef: MatDialogRef<ViewTransactionReceiptComponent>,
    config: NgbCarouselConfig,
    private sanitizer: DomSanitizer,
    private lightbox: Lightbox,
    public dialog: MatDialog
  ) {
    config.interval = 0;
    config.keyboard = true;
    config.pauseOnHover = true;
    config.showNavigationIndicators = false;
  }

  ngOnInit() {
    if ([1, 2, 7].includes(this.data.bankTransaction.transaction_type_id) && this.data.bankTransaction.deposit_details.length != 0) {
      this.receipts = this.data.bankTransaction.deposit_details.map(receipt => {
        return {
          receipt_image: this.checkMedia(receipt.receipt_image) == 'pdf' ? this.sanitizer.bypassSecurityTrustResourceUrl(receipt.receipt_image) : receipt.receipt_image,
          isPdf : this.checkMedia(receipt.receipt_image) == 'pdf' ? true : false
        }
      });

    } else if ([3, 4, 8].includes(this.data.bankTransaction.transaction_type_id) && this.data.bankTransaction.receipt_image !== '' && this.data.bankTransaction.receipt_image !== null && this.data.bankTransaction.receipt_image !== undefined) {
      let image = this.data.bankTransaction.receipt_image;
      let isPdf = false;
      if (this.checkMedia(this.data.bankTransaction.receipt_image) == 'pdf') {
        image = this.sanitizer.bypassSecurityTrustResourceUrl(image);
        isPdf = true;
      }
      this.receipts.push({receipt_image: image, isPdf: isPdf});
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  checkMedia(url: string) {
    const imageExtensions = [".gif", ".jpg", ".jpeg", ".png"]; // you can add more extensions
    const pdfExtensions = [".pdf"]; // you can add more extensions

    const inc = (ext: string) => url.includes(ext);

    if (imageExtensions.some(inc)) {
      return 'image';
    }

    if (pdfExtensions.some(inc)) {
      return 'pdf';
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  openImage(image: string) {
    this.dialog.open(ImagePreviewDialogComponent, {
      data: { image },
      panelClass: 'custom-dialog-container'
    });
  }
}
