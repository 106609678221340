import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Operator } from '@core/models/operator.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { forkJoin, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { OperatorDataService } from '../services/operator-data.service';
import { OperatorEntityService } from './../services/operator-entity.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { loggedUser } from '@core/store/auth/auth.selectors';
import { AppPermissionService } from '@core/services/app-permission.service';
import { SalesConversionReportDetailsDialogComponent } from '@views/pages/apps/reports/sales-conversion-report/sales-conversion-report-details/sales-conversion-report-details.component';
import { REGEX_PATTERNS } from '@utils/regex.constants';
import { AesService } from '@core/services/aes.service';


@Component({
  selector: 'kt-dialogs',
  templateUrl: './operator-profile.component.html',
  styleUrls: ['./operator-profile.component.scss']
})
export class OperatorDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    statuses:  this.dropdownHttpService.statuses,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    roles: this.dropdownHttpService.roles,
    timezones: JSON.parse(localStorage.getItem('timezones'))
  };
  applicationRolesDropdownSettings = {};
  currenciesDropdownSettings = {};
  statusesDropdownSettings = {};
  timezonesDropdownSettings = {};
  applicationRolesDropdownList = [];
  statusesDropdownList = this.dropdownHttpService.statuses;
  mobileAccessDropDownList = this.dropdownHttpService.abledDisabled;
  emailAccessDropDownList = this.dropdownHttpService.abledDisabled;
  twoFADropDownList = this.dropdownHttpService.abledDisabled;
  applicationRolesSelectedItems: any = [];
  currenciesSelectedItems = [];
  statusesSelectedItems: any;
  timezonesSelectedItems: any;
  loading = false;
  buttonLoading = false;
  telemarketer = false;
  defaultTimezone: any;

  // permissions
  canCreateOperator: boolean;
  canEditOperator: boolean;
  canResetOperatorPassword: boolean;
  canEditPhoneNumberAccess: boolean;
  canEditEmailAccess: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  messages$ = this.operatorDataService.messages$;
  passwordInput = {
    isMasked: true,
    type: null,
    icon: null,
    isValidPassword: true
  };
  passwordAccordiaInput = {
    isMasked: true,
    type: null,
    icon: null,
    isValidPassword: true
  };
  refreshStatus: boolean;
  authUser = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { operator: Operator, mode: string },
    public dialogRef: MatDialogRef<OperatorDialogComponent>,
    private operatorService: OperatorEntityService,
    private operatorDataService: OperatorDataService,
    private dropdownHttpService: DropdownHttpService,
    private cdr: ChangeDetectorRef,
    private authHttpService: AuthHttpService,
    private store: Store<AppState>,
    private appPermissionService: AppPermissionService,
    private aesService: AesService,
    ) {}

  async ngOnInit() {
    this.store.pipe(select(loggedUser)).subscribe((row: any) => {
      this.authUser = row.id;
    });
    this.onPasswordMask();
    this.onPasswordAccordiaMask();
    this.formInit();
    if (this.data.mode !== 'reset'){
      this.setCurrency();

      Object.keys(this.dropdownHttpService.statuses).forEach((key) => {
          if (this.data.operator){
            if ( +key === this.data.operator.status ){
              this.statusesSelectedItems = [{
                name: this.dropdownHttpService.statuses[key],
                id: key
              }];
            }
          }
        }
      );

      this.dropdownHttpService.merchantSites.subscribe( res => {
          this.defaultTimezone = res;

          if (this.data.operator && (this.data.operator.timezone !== null) ){
            this.timezonesSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.data.operator.timezone);
          } else {
            this.timezonesSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.defaultTimezone[0].default_timezone);
            this.form.controls['timezone'].patchValue(
              this.defaultTimezone[0].default_timezone
            );
          }
        }
      );

      this.applicationRolesDropdownSettings = {
        singleSelection: true,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        primaryKey: 'name',
        labelKey: 'name',
        lazyLoading: true,
        noDataLabel: '',
        showCheckbox: false,
        searchBy: ['name']
      };

      this.currenciesDropdownSettings = {
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        primaryKey: 'name',
        labelKey: 'name',
        lazyLoading: true,
        noDataLabel: '',
        showCheckbox: false
      };

      this.statusesDropdownSettings = {
        singleSelection: true,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        position: 'bottom',
        maxHeight: 200,
        primaryKey: 'name',
        labelKey: 'name',
        lazyLoading: true,
        noDataLabel: '',
        showCheckbox: false
      };

      this.timezonesDropdownSettings = {
        singleSelection: true,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        position: 'bottom',
        maxHeight: 200,
        primaryKey: 'timezone',
        labelKey: 'offset',
        lazyLoading: true,
        noDataLabel: '',
        showCheckbox: false
      };
    }

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateOperator = appPermissions.create_operator;
      this.canEditOperator = appPermissions.edit_operator;
      this.canResetOperatorPassword = appPermissions.reset_operator_password;
      this.canEditPhoneNumberAccess = appPermissions.edit_phone_number_access;
      this.canEditEmailAccess = appPermissions.edit_email_access;

      if (this.data.mode === 'edit') {
        if (this.canResetOperatorPassword) {
          this.form.get('password').enable();
          this.form.get('force_change_password').enable();
        } else {
          this.form.get('password').disable();
          this.form.get('force_change_password').disable();
        }
      }

      // for phone access
      if (this.canEditPhoneNumberAccess) {
        this.form.get('view_masked_mobile').enable();
      } else {
        this.form.get('view_masked_mobile').disable();
      }

      // for email access
      if (this.canEditEmailAccess) {
        this.form.get('view_masked_email').enable();
      } else {
        this.form.get('view_masked_email').disable();
      }

      // re-fetch application roles dropdown list
      this.fetchApplicationRolesDropdownList();
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  fetchApplicationRolesDropdownList() {
    const sub = this.dropdownHttpService.applicationRoles.subscribe(
      res => {
        this.applicationRolesDropdownList = res;

        if (this.data.operator) {
          this.applicationRolesSelectedItems = res.filter(v => v.id === this.data.operator.application_role_id);

          if (this.applicationRolesSelectedItems?.length === 0) {
            this.form?.patchValue({
              application_role_id: null
            });
          }
        }
      }
    );

    this.subscriptions.add(sub);
  }

  onSave(operator: Operator, mode?: string) {
    delete this.form.value.role_id_details;
    this.buttonLoading = true;
    const data = {
      ...this.form.getRawValue(),
      force_change_password: Number(this.form.value.force_change_password),
      status: this.data.mode === 'create' ? 1 : this.form.value.status,
      id: operator ? operator.id : null,
      view_masked_mobile: this.form.value.view_masked_mobile ? this.form.value.view_masked_mobile : (this.data.mode === 'edit' ? this.data.operator.view_masked_mobile : 0),
      view_masked_email: this.form.value.view_masked_email ? this.form.value.view_masked_email : (this.data.mode === 'edit' ? this.data.operator.view_masked_email : 0)
    };
    if( data.password ) {
      // encrypt the raw password before sending to api
      data.password = this.aesService.encryptWithReqSignKey(data.password);
    }
    Object.keys(data).forEach((key) => ((key != 'application_role_id' && data[key] == null) || data[key] === '') && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.operatorService.update(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
            this.onRefresh();
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        this.refreshStatus = true;
        break;
      case 'create':
        this.subscription = forkJoin([
          this.operatorDataService.add(data).pipe(
            tap((res: any) => {
              this.form.setErrors(null);
              this.buttonLoading = false;
              this.onRefresh();
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          ),
          this.operatorDataService.messages$,
        ]).subscribe();
        this.refreshStatus = true;
        break;
      case 'reset':
        this.subscription = forkJoin([
          this.operatorDataService.resetPassword(data).pipe(
            tap((res: any) => {
              this.form.setErrors(null);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          ),
          this.operatorDataService.messages$,
        ]).subscribe();
        this.refreshStatus = true;
        break;
    }
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  onPasswordMask() {
    this.passwordInput.isMasked = !this.passwordInput.isMasked;
    this.passwordInput.type = this.passwordInput.isMasked ? 'text' : 'password';
    this.passwordInput.icon = this.passwordInput.isMasked ? 'fas fa-eye' : 'fas fa-eye-slash';
  }

  onPasswordAccordiaMask() {
    this.passwordAccordiaInput.isMasked = !this.passwordAccordiaInput.isMasked;
    this.passwordAccordiaInput.type = this.passwordAccordiaInput.isMasked ? 'text' : 'password';
    this.passwordAccordiaInput.icon = this.passwordAccordiaInput.isMasked ? 'fas fa-eye' : 'fas fa-eye-slash';
  }

  onValidPassword() {
    // at least 8 characters long, contain uppercase and lowercase letters, a number, and a special character available on a standard keyboard
    this.passwordInput.isValidPassword = this.form.value.password.length <= 0 || REGEX_PATTERNS.password.test(this.form.value.password);
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private setCurrency(){
    const selectCurrency = () => {
      if (this.data.operator){
        Object.keys(this.data.operator.currencies).forEach((key) =>
        this.currenciesSelectedItems[key] = {
          name: this.data.operator.currencies[key].code,
          id: this.data.operator.currencies[key].id
        }
        );
      }
    };

    if(this.dropdown.currencies.length === 0){
        this.dropdownHttpService.currencies.subscribe( res => {
            this.dropdown.currencies = res;
            selectCurrency();
        });
    }else{
        selectCurrency();
    }
  }

  private formInit() {
    if (this.data.mode !== 'reset'){
      let roleId = null;
      let applicationRoleId = null;
      let userName = null;
      let name = null;
      const password = null;
      let settingsCurrencyId = null;
      let status = 1;
      let accordia_username = null;
      let accordia_password = null;
      let accordia_ext = null;
      let timezone = null;
      let mobileAccess = 0;
      let emailAccess = 0;
      let twoFA = 0;
      let force_change_password = Boolean(0);
      if (this.data.mode === 'edit') {
        let currentCurrencyIds = [];
        this.data.operator.currencies.map(row => {
          Object.keys(row).forEach(key => {
            if (key !== 'code'){
              currentCurrencyIds = [...currentCurrencyIds, row[key]];
            }
          });
        });
        applicationRoleId = this.data.operator.application_role_id;
        if(this.data.operator.role_name?.toLowerCase() == 'telemarketer' || this.data.operator.application_role_name?.toLowerCase() == 'telemarketer'){
          this.telemarketer = true;
        }
        userName = this.data.operator.username;
        name = this.data.operator.name;
        settingsCurrencyId = currentCurrencyIds;
        status = this.data.operator.status;
        mobileAccess = this.data.operator.view_masked_mobile;
        emailAccess = this.data.operator.view_masked_email;
        accordia_username = this.data.operator.accordia_username;
        accordia_password = this.data.operator.accordia_password;
        accordia_ext = this.data.operator.accordia_ext;
        timezone = this.data.operator.timezone;
        twoFA = this.data.operator.two_factor_authentication;
        force_change_password = Boolean(this.data.operator.force_change_password);
      }
      this.form = new FormGroup({
        application_role_id: new FormControl(applicationRoleId, [Validators.required]),
        role_id_details: new FormControl(this.data.operator),
        username: new FormControl({value: userName, disabled: this.data.mode === 'edit' ? true : false}, [Validators.required, Validators.minLength(4), Validators.pattern('[a-z0-9\-_]+')]),
        name: new FormControl(name, [Validators.required]),
        password: new FormControl(password, Validators.compose(this.data.mode === 'create' ? [Validators.required, Validators.minLength(8)] : null)),
        currency_ids: new FormControl(settingsCurrencyId, [Validators.required]),
        status: new FormControl({ value: status, disabled: this.data.mode === 'create' ? true : false }),
        accordia_username: new FormControl(accordia_username),
        accordia_password: new FormControl(accordia_password),
        accordia_ext: new FormControl(accordia_ext),
        timezone: new FormControl(timezone, [Validators.required]),

        // for both fields below need to be disabled initially then change to enable/disable accordingly in getAppPermissions() due to weird form control behavior
        view_masked_mobile: new FormControl({ value: mobileAccess, disabled: true }, [Validators.required]),
        view_masked_email: new FormControl({ value: emailAccess, disabled: true }, [Validators.required]),

        two_factor_authentication: new FormControl({ value: twoFA, disabled: (this.data.operator ? (this.data.operator.id === this.authUser) : false) }, [Validators.required]),
        force_change_password: new FormControl(force_change_password)
      });
    }else{
      this.form = new FormGroup({
        username: new FormControl(this.data.operator.username, [Validators.required]),
        email: new FormControl(null, [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
      });
    }
  }

}
