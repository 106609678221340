import { Component, OnInit, ChangeDetectorRef, QueryList , ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { AppPermissionService } from '@core/services/app-permission.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TranslateService } from '@ngx-translate/core';
import { VipFooterSettingsDataService } from './services/vip-footer-settings-data.service';
import { UploadHttpService } from '@core/services/upload-http.service';
import { Subscription } from 'rxjs';
import { Pagination } from '@core/models/pagination.model';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { tap, catchError } from 'rxjs/operators';
import Swal from "sweetalert2";
import { TransactionHttpService } from '@core/services/transaction-http.service';
import * as moment from 'moment-timezone';
import { EditorService } from '@core/services/editor.service';
import * as Editor from '@core/../../assets/js/global/integration/plugins/ckeditor';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
@Component({
  selector: 'kt-vip-footer-settings',
  templateUrl: './vip-footer-settings.component.html',
  styleUrls: ['./vip-footer-settings.component.scss']
})
export class VipFooterSettingsComponent implements OnInit {
  form: FormGroup; 
  formSection: FormGroup;
  formContent: FormGroup;
  localeCheckboxForm: FormGroup;

  vipFooterSettings: any;
  vipSection: any
  vipFooter: any;
  messages$ = this.vipFooterSettingsDataService.messages$;

  displayLocale: any;
  displayGroup: any;

  mode: any;
  viewVipFooter: any;
  changedObjects: any;
  changedObjects1: any;
  initVipFooter: any;
  initVipSection: any;
  modeVipSection: any;
  availableVipFooters = [];

  vipFooterSettingsData = {
    vip_sections: [],
    vip_contents: [],
  }

  backUpVipImageSubArr: any;
  checkValidation = false;
  isOnAddClicked = false;

  unsaveStatus = true;
  loading = false;
  buttonLoading = true;
  clearBtnLoading = false;
  searchBtnLoading = false;
  checkLoadingPage = false;
  viewMode: any = false;

  imageLoading = false;
  isImageUploading = false;
  isImageUploadingIndex = null;
  isImageUploadingLocale = null;
  imagePreview = [];

  dataLength: number;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  backupPageSize = 30;
  backupPage = 1;
  
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    locales: [],
    perPage: this.dropdownHttpService.perPage,
    dateType: this.dropdownHttpService.vipFooterSettingDateTypes,
    valueType: this.dropdownHttpService.vipFooterSettingValueTypes,
  };

  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  
  sortingConfig = {
    'id': 'desc',
    'position': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',
  };

  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  // Begin: CKEditor Part
  public editor = Editor;
  editorConfig = this.editorService.config2;
  @ViewChildren('editors') editorComponent:QueryList<CKEditorComponent>;

  usableVariables = [
    ':brandprefix',
    ':brandname',
  ];

  // permission
  canViewVIPFooterSection: boolean;
  canViewVIPFooterDetails: boolean;
  canCreateVIPFooter: boolean;
  canEditVIPFooterDetails: boolean;
  canViewFooterVIPContentSettingsList: boolean;
  canViewFooterVIPContentSettings: boolean;
  canCreateFooterVIPContentSettings: boolean;
  canEditFooterVIPContentSettings: boolean;
  canUpdateVIPContentStatus: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  private localesSub = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
    private vipFooterSettingsDataService: VipFooterSettingsDataService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private loadingBar: LoadingBarService,
    private uploadService: UploadHttpService,
    private transactionHttpService: TransactionHttpService,
    private editorService: EditorService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {

    this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.dropdown.locales = res;
      this.displayLocale = this.dropdown.locales[0];
      this.formInit();
      this.ranges = this.transactionHttpService.ranges;

      const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
        this.canViewVIPFooterSection = appPermissions.view_vip_footer_section;
        this.canViewVIPFooterDetails = appPermissions.view_vip_footer_section_details;
        this.canCreateVIPFooter = appPermissions.create_vip_footer_section;
        this.canEditVIPFooterDetails = appPermissions.edit_vip_footer_section_details;
        this.canViewFooterVIPContentSettingsList = appPermissions.view_vip_footer_content_list;
        this.canViewFooterVIPContentSettings = appPermissions.view_vip_footer_content_settings;
        this.canCreateFooterVIPContentSettings = appPermissions.create_vip_footer_content_settings;
        this.canEditFooterVIPContentSettings = appPermissions.edit_vip_footer_content_settings;
        this.canUpdateVIPContentStatus = appPermissions.update_vip_footer_content_status;
      });
  
      this.subscriptions.add(apSub);
      this.onSubmit();
    })).subscribe();
  }

  reload(clearSearch?: boolean) {
    this.mode = '';
    this.onClearContent();
    this.modeVipSection = '';
    this.onClearVipSection();
    this.onSubmit(clearSearch);
    this.initVipFooter = JSON.stringify({ ...this.formContent.value  });
    this.initVipSection = JSON.stringify({ ...this.formSection.value });
    this.buttonLoading = false;
  }
  
  onChangeStatus($event, row: any) {
    this.formSection.patchValue({
      status: $event.target.checked ? 1 : 0
    });
  }

  onChangeStatusFooter($event, row: any) {
    const data = {
      id: row.id,
      status: $event.target.checked ? 1 : 0,
    };
    this.buttonLoading = true;
    this.loading = true;

    this.subscription = this.vipFooterSettingsDataService.updateStatus(data).pipe(
      tap((res: any) => {
        this.messages$.next(res.message);
        this.buttonLoading = false;
        this.loading = false;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.loading = false;
        throw error;
      })
    ).subscribe();
  }

  checkReopen(mode: any, row?: any) {
    switch (mode) {
      case 'edit':
        this.expandRowEdit(mode, row)
        break;
      case 'create':
        this.expandRowCreate(mode);
        break;
    }
  }

  onDateRange(event: any, type: string) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClearDate(type: string) {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({
        start_datetime: null,
        end_datetime: null,
        defaultDate: null,
      });
    }
  }

  localeIndex() {
    const index = this.dropdown.locales.findIndex(item => {
      return item.id == this.displayLocale['id'];
    });

    return index;
  }

  checkContent(locale: any) {
   
    if(this.formContent.value.value_type == 1 || this.formContent.value.value_type == 3){
      let question =  this.formContent.value.details[locale.id].question ?  this.formContent.value.details[locale.id].question : null;
      let answer = this.formContent.value.details[locale.id].answer ?  this.formContent.value.details[locale.id].answer : null;
      let text_value = this.formContent.value.details[locale.id].text_value ?  this.formContent.value.details[locale.id].text_value : null;
      if ((question == null || question == '') && (answer == null || answer == '') && (text_value == null || text_value == '')) {
        return false;
      }
      else {
        return true;
      }
    }else if(this.formContent.value.value_type == 2){
      let images =  this.formContent.value.details[locale.id].images ?  this.formContent.value.details[locale.id].images : [];
      if(images.length < 1 || images == undefined){
        return false;
      } else {
        return true;
      }
    }

  }

  expandRowCreate(mode: any, close?: boolean) {
      let vipFooterInput = JSON.stringify({ ...this.formContent.value });
      this.changedObjects = this.initVipFooter != vipFooterInput ? true : false;
  
      if (close != true && this.changedObjects == false && this.checkupdate() == false) {
      
        this.viewMode = null;
        this.modeVipSection = null;
        this.changedObjects = false;
        this.initVipFooter = null;
        this.mode = mode;
        this.onClearVipSection();
  
        this.cdr.detectChanges();
  
        this.onClearContent();
  
        this.checkLoadingPage = false;
  
        setTimeout(() => {
          this.checkLoadingPage = true;
          this.cdr.detectChanges();
        }, 1500);
  
      } else {
        if (this.changedObjects == true || this.checkupdate() == true) {
          Swal.fire({
            title: '<div class="text-center">Unsaved Changes</div>',
            html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
            showDenyButton: true,
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: true,
            reverseButtons: true,
            denyButtonText: this.translateService.instant('Cancel'),
            confirmButtonText: this.translateService.instant('Discard'),
            icon: 'warning',
            customClass: {
              denyButton: 'deny-button',
              confirmButton: 'confirm-button',
            }
          }).then(result => {
            if (result.isConfirmed) {
              this.mode = '';
              this.unsaveStatus = true;
              this.modeVipSection = '';
              this.onClearContent();
              this.onClearVipSection();
              // Check if reopen needed
              this.checkReopen(mode);
            }
          });
        }
        else {
          this.mode = '';
          this.unsaveStatus = true;
          this.onClearContent();
        }
      }
  
      this.cdr.detectChanges();
    }

  expandRowEdit(mode: any, row?: any, close?: boolean) {
    if (close != true) close = this.vipFooter == row ? true : false;
  
    let vipFooterInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initVipFooter && this.initVipFooter != vipFooterInput ? true : false;
    if (close != true && this.changedObjects == false && this.checkupdate() == false) {
      this.vipFooter = row;

      this.changedObjects = false;
      this.initVipFooter = null;
      this.mode = mode;
      this.modeVipSection =null;
      this.onClearVipSection();

      this.viewMode = null;
      this.modeVipSection = null;

      this.cdr.detectChanges();

      this.formContent.patchValue({
        label: this.vipFooter.label,
        vip_footer_sections_id: this.vipFooter.vip_footer_sections_id,
        position: this.vipFooter.position,
        value_type: this.vipFooter.value_type
      });

      // reset all locales to null
      this.dropdown.locales.forEach((element: any) => {
        this.formContent.patchValue({
          details: {
            [element.id]: {
              question: null,
              answer: null,
              text_value: null
            }
          }
        });
      });

      setTimeout(() => {
        this.vipFooter.details.forEach((element: any) => {
          this.formContent.patchValue({
            details: {
              [element.settings_locale_id]: {
                question: element.question ? element.question : null,
                answer: element.answer ? element.answer : null,
                text_value: element.text_value ? element.text_value : null
              }
            }
          })

          if(element.image_details && element.image_details.length > 0){
            element.image_details.forEach((image: any) => {
              this.addImages(element.settings_locale_id,image)
            })
          }

        });
        
        this.cdr.detectChanges();
        this.initVipFooter = JSON.stringify({ ...this.formContent.value });
      }, 500);
      
      this.checkLoadingPage = false;

      setTimeout(() => {
        this.checkLoadingPage = true;
        this.cdr.detectChanges();
      }, 1500);

    } else {
      this.changedObjects = this.initVipFooter != JSON.stringify({ ...this.formContent.value }) ? true : false;
      if (this.changedObjects == true || this.checkupdate() == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.mode = '';
            this.unsaveStatus = true;
            this.onClearContent();
            this.onClearVipSection();
            
            // Check if reopen needed
            if (this.vipFooter == row) {
              this.vipFooter = null;
            }
            else {
              this.vipFooter = null;
              this.modeVipSection = '';
              this.checkReopen(mode, row);
            }
          }
        });
      }
      else {
        this.mode = '';
        this.unsaveStatus = true;
        this.vipFooter = null;
        this.onClearContent();
      }
    }
  }

  onClear() {
    this.clearBtnLoading = true;

    this.form.patchValue({
      label: null,
      vip_footer_sections_id: 'all',
      status: 1,
      start_datetime: null,
      end_datetime: null,
      defaultDate: null,
      date_type: this.dropdown.dateType[0].value
    });
  }

  onSave() {
    this.buttonLoading = true;

    const data = {
      id: this.vipFooter ? this.vipFooter.id : null,
      ...this.formContent.value,
    };

    let keepcheck = true;
    let fillInTextNUll = true;
    let fillInQuestionNull = true;
    let fillInAnswerNUll = true;
    let locale = null;

    if(this.formContent.value.value_type == 3){
      Object.keys(data['details']).forEach((key) => {
        if(keepcheck){
          if( (data['details'][key]['question'] == null || data['details'][key]['question'] =='') &&
          (data['details'][key]['answer'] !== null && data['details'][key]['answer'] !=='')
          ){
            fillInQuestionNull = false;
            keepcheck = false;
            locale = this.dropdown.locales.filter(x => x.id == key)[0]
          }else if( (data['details'][key]['question'] !== null && data['details'][key]['question'] !=='') &&
                    (data['details'][key]['answer'] == null || data['details'][key]['answer'] =='')
          ){
            fillInAnswerNUll = false;
            keepcheck = false;
            locale = this.dropdown.locales.filter(x => x.id == key)[0]
          }
        }
      });
    }

    if(!fillInQuestionNull || !fillInAnswerNUll || !fillInTextNUll){
    
          let message = null;
    
          if(!fillInQuestionNull){
            message ="Please Fill in Question for locale "+locale['code'];
          }else if(!fillInAnswerNUll){
            message ="Please Fill in Answer for locale "+locale['code'];
          }
    
          Swal.fire({
            title: '<div class="text-center">'+message+'</div>',
            showDenyButton: true,
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            reverseButtons: false,
            denyButtonText: this.translateService.instant('Close'),
            icon: 'warning',
            customClass: {
              denyButton: 'deny-button'
            }
          }).then(result => {
            this.buttonLoading = false;
          });
    
          return false;
    }

    // check least one locale content
    let checkcontent = false;
 
    Object.keys(data['details']).forEach((key) => {
      if(this.formContent.value.value_type == 1){
        if( (data['details'][key]['text_value'] !== null && data['details'][key]['text_value'] !=='')){
          checkcontent = true;
        }
      }else if(this.formContent.value.value_type == 3){
        if( (data['details'][key]['question'] !== null && data['details'][key]['question'] !=='') ||
        (data['details'][key]['answer'] !== null && data['details'][key]['answer'] !=='')
        ){
          checkcontent = true;
        }
      }else if(this.formContent.value.value_type == 2){
        if( (data['details'][key]['images']).length > 0){
          checkcontent = true;
        }
      }
    });

    if(!checkcontent){
      Swal.fire({
        title: '<div class="text-center">Fill in at least one locale content</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        reverseButtons: false,
        denyButtonText: this.translateService.instant('Close'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button'
        }
      }).then(result => {
        this.buttonLoading = false;
      });

    }

    Object.keys(data).forEach((key) => {
      if (data[key] == null || data[key] === '' || data[key] === 'Invalid date') {
        delete data[key];
      }
    });

    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.formContent.setErrors({ 'invalid': true });

    //this.buttonLoading = false;
    if(checkcontent){
      switch (this.mode) {
        case 'edit':
          this.subscription = this.vipFooterSettingsDataService.update(this.vipFooter.id, data).pipe(
            tap((res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.formContent.setErrors(null);
              this.formContent.enable();
              throw error;
            })
          ).subscribe();
          break;
        case 'create':
          this.subscription = this.vipFooterSettingsDataService.add(data).pipe(
            tap((res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.formContent.setErrors(null);
              this.formContent.enable();
              throw error;
            })
          ).subscribe();
          break;
      }
    }
  }

  onSaveVipSection() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.formSection.setErrors({ 'invalid': true });

    const data = {
      id: this.vipSection ? this.vipSection.id : null,
      ...this.formSection.value,
    };

    let checkcontent = true;
    // Object.keys(data['details']).forEach((key) => {
    //   if(data['details'][key]['name'] !== null && data['details'][key]['name'] !== ''){
    //     checkcontent = true;
    //   }
    // });

    if(checkcontent){
      switch (this.modeVipSection) {
        case 'edit':
          this.subscription = this.vipFooterSettingsDataService.updateSection(this.vipSection.id, data).pipe(
            tap((res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.formContent.setErrors(null);
              this.formContent.enable();
              throw error;
            })
          ).subscribe();
          break;
        case 'create':
          data['status'] = 1;
          this.subscription = this.vipFooterSettingsDataService.addSection(data).pipe(
            tap((res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.formContent.setErrors(null);
              this.formContent.enable();
              throw error;
            })
          ).subscribe();
          break;
      }
    }

  }

  async onSubmit(clearSearch?: boolean) {
    await this.onCheckUnsave();

    if (this.unsaveStatus == true) {
      this.searchBtnLoading = clearSearch ? false : true;
      this.loading = true;

      if (clearSearch) {
        this.onClear();
      }

      const data = {
        ...this.form.value,
      };

      if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
      }      

      delete data['defaultDate'];

      Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);

      this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
      const parameters = this.params ? `&${this.params}` : '';
      this.loadingBar.start();

      this.vipFooterSettingsDataService.getWithQuery(`?perPage=${this.pageSize}${parameters}&${this.generateSortingParam()}`).subscribe(res => {
        if(res){
          this.vipFooterSettings = res;
        }

        this.mode = null;
        this.modeVipSection = null;
        this.onClearContent();
        this.onClearVipSection();

        this.page = 1;
        this.pagination = this.vipFooterSettingsDataService.pagination;
        this.dataLength = res['vip_contents'].rows.length;
        this.vipFooterSettingsData.vip_sections = res['vip_sections'];
        this.vipFooterSettingsData.vip_contents = res['vip_contents'].rows;
        this.availableVipFooters = res['vip_contents'].rows;

        this.loading = false;
        this.buttonLoading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.loadingBar.complete();
        this.cdr.detectChanges();
      })
    }
  }

  private onCheckUnsave() {
    return new Promise<void>((resolve, reject) => {
      let vipFooterInput = JSON.stringify({ ...this.formContent.value });
      let vipSectionInput = JSON.stringify({ ...this.formSection.value });
      this.changedObjects = this.initVipFooter && this.initVipFooter != vipFooterInput ? true : false;
      this.changedObjects1 = this.initVipSection && this.initVipSection != vipSectionInput ? true : false;

      if (this.changedObjects == true || this.changedObjects1 == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.mode = '';
            this.vipFooter = null;
            this.unsaveStatus = true;
            this.onClearContent();
            resolve();
          }
          else {
            this.unsaveStatus = false;
            resolve();
          }
        });
      }
      else {
        resolve();
      }
    });
  }

  onClearContent() {
    this.formContent.patchValue({
      label: null,
      vip_footer_sections_id: null,
      position: null,
      value_type: 1
    });

    this.dropdown.locales.forEach((element: any) => {
      this.formContent.patchValue({
        details: {
          [element.id]: {
            question: null,
            answer: null,
            text_value: null
          }
        }
      });
      this.imagesList(element.id).clear();
    });
    
    this.initVipFooter = JSON.stringify({ ...this.formContent.value });
  }

    clearContent(locale: any) {
      Swal.fire({
        title: 'Delete Content',
        text: 'Are you sure you want to delete contents for ' + locale.code + '?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((response) => {
        if (response.isConfirmed) {
          if(this.formContent.value.value_type == 1 || this.formContent.value.value_type == 3){
            this.formContent.patchValue({
              details: {
                [locale.id]: {
                  question: null,
                  answer: null,
                  text_value:null
                }
              }
            });
          }else{
            this.imagesList(locale.id).clear();
          }
        }
        this.cdr.detectChanges();
      });
    }

  expandRowVipSectionCreate(mode: any, close?: boolean) {
    let vipSectionInput = JSON.stringify({ ...this.formSection.value });
    this.changedObjects = this.initVipSection != vipSectionInput ? true : false;

    if (close != true && this.changedObjects == false && this.checkupdate() == false) {

      this.viewMode = null;
      this.modeVipSection = null;

      this.changedObjects = false;
      this.initVipSection = null;
      this.modeVipSection = mode;
      this.vipSection = null;
      this.mode =null;
      this.onClearContent();

      this.cdr.detectChanges();

      this.onClearVipSection();
      this.formSection.enable();
    } else {
      if (this.changedObjects == true || this.checkupdate() == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.modeVipSection = '';
            this.onClearContent();
            this.onClearVipSection();
            // Check if reopen needed
            this.checkReopenVipSection(mode);
          }
        });
      }
      else {
        this.modeVipSection = '';
        this.onClearVipSection();
      }
    }

    this.cdr.detectChanges();
  }

  expandRowVipSectionEdit(mode: any, row?: any, close?: boolean) {
    this.loading = true;
    if (close != true) close = this.vipSection == row ? true : false;
    let vipSectionInput = JSON.stringify({ ...this.formSection.value });
    this.changedObjects = this.initVipSection && this.initVipSection != vipSectionInput ? true : false;

    if (close != true && this.changedObjects == false && this.checkupdate() == false) {

      this.viewMode = null;
      this.modeVipSection = null;

      this.vipSection = row;
      this.changedObjects = false;
      this.initVipSection = null;
      this.modeVipSection = mode;
      this.mode = null;
      this.onClearContent();

      this.cdr.detectChanges();

      this.formSection.patchValue({
        footer_section_name : this.vipSection.footer_section_name,
        position: this.vipSection.position,
        status: this.vipSection.status
      });

      // reset all locales to null
      this.dropdown.locales.forEach((element: any) => {
        this.formSection.patchValue({
          details: {
            [element.id]: {
              name: null
            }
          }
        });
      });

      setTimeout(() => {
        this.vipSection.details.forEach((element: any) => {
          this.formSection.patchValue({
            details: {
              [element.settings_locale_id]: {
                name: element.name,
              }
            }
          });
        });

        this.loading = false;
        this.cdr.detectChanges();

        this.initVipSection = JSON.stringify({ ...this.formSection.value });
      }, 500);

      if (!this.canEditVIPFooterDetails) {
        this.formSection.disable();
      }
      else {
        this.formSection.enable();
      }
     
      this.cdr.detectChanges();
    } else {
      this.loading = false;
      this.changedObjects = this.initVipSection != JSON.stringify({ ...this.formSection.value }) ? true : false;
      if (this.changedObjects == true || this.checkupdate() == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.modeVipSection = '';
            this.onClearVipSection();
            this.onClearContent();
            // Check if reopen needed
            if (this.vipSection == row) {
              this.vipSection = null;
            }
            else {
              this.vipSection = null;
              this.vipFooter = null;
              this.checkReopenVipSection(mode, row);
            }
          }
        });
      }
      else {
        this.modeVipSection = '';
        this.vipSection = null;
        this.onClearVipSection();
        this.onClearContent();
      }
    }
  }

  checkReopenVipSection(mode: any, row?: any) {
    switch (mode) {
      case 'edit':
        this.expandRowVipSectionEdit(mode, row)
        break;
      case 'create':
        this.expandRowVipSectionCreate(mode);
        break;
    }
  }

  onClearVipSection() {
    this.formSection.patchValue({
      footer_section_name: null,
      position: null,
      status: null
    })

    this.dropdown.locales.forEach((element: any) => {
      this.formSection.patchValue({
        details: {
          [element.id]: {
            name: null
          }
        }
      });
    });
    this.initVipSection = JSON.stringify({ ...this.formSection.value });
  }

  checkupdate() {
    const vipFooterCheck = this.initVipFooter !== JSON.stringify({ ...this.formContent.value }) ? true : false;
    const vipSectionCheck =this.initVipSection !== JSON.stringify({ ...this.formSection.value }) ? true : false;
    return (vipFooterCheck || vipSectionCheck) ? true : false;
  }

  displayLocaleData(row: any, type: string) {
    let contents = this.availableVipFooters.filter(x => x.vip_content_setting_id == row.vip_content_setting_id)[0]['locale_data'];

    contents = contents.filter(x => x['settings_locale_id'] == this.displayLocale['id']);

    if (contents.length > 0) {
      if (type == 'label') {
        return contents[0]['label'] != null ? contents[0]['label'] : '-';
      } else if (type == 'position') {
        return contents[0]['position'] != null ? contents[0]['position'] : '-';
      } else if (type == 'createdBy') {
        return contents[0]['created_by'] != null ? contents[0]['created_by'] : 'System';
      } else if (type == 'updatedBy') {
        return contents[0]['updated_by'] != null ? contents[0]['updated_by'] : 'System';
      } else if (type == 'createdAt') {
        return contents[0]['created_at'] != null ? contents[0]['created_at'] : '-';
      } else if (type == 'updatedAt') {
        return contents[0]['updated_at'] != null ? contents[0]['updated_at'] : '-';
      }
    } else {
        return '-';
    }
  }

  checkBoxChecking(localeId: number, memberGroupId: number) {

    if (this.formContent.value.contents?.[localeId]) {
      const details = this.formContent.value.contents[localeId]?.details;

      if (details?.[memberGroupId]) {
          const symbolValue = (details[memberGroupId]?.symbol_value == 0 || details[memberGroupId]?.symbol_value == null) ? false : true;

          return symbolValue;
      }
    }

    return false;
  }

  private formInit() {
    // Filter
    this.form = new FormGroup({
      label: new FormControl(null),
      vip_footer_sections_id: new FormControl('all'),
      status: new FormControl(1),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null),
      date_type: new FormControl('created_at')
    });

    // Vip Footer Settings
    const buildDetails = () => {
      let fields = {};
      this.dropdown.locales.forEach((element: any) => {
        const subFields = new FormGroup({
          settings_locale_id: new FormControl(element.id),
          question: new FormControl(null),
          answer: new FormControl(null),
          text_value: new FormControl(null),
          images: new FormArray([])
        });
        fields = { ...fields, [element.id]: subFields };
      });
      return fields;
    };

    this.formContent = new FormGroup({
      // Create dialog
      vip_footer_sections_id: new FormControl(null,[Validators.required]),
      label: new FormControl(null, [Validators.required]),
      position: new FormControl(null,[Validators.required]),
      value_type: new FormControl(1,[Validators.required]),
      details: new FormGroup(buildDetails()),
    });

    // Vip footer Section
    const buildSection = () => {
      let fields = {};
      this.dropdown.locales.forEach((element: any) => {
        const subFields = new FormGroup({
          settings_locale_id: new FormControl(element.id),
          name: new FormControl(null),
        });
        fields = { ...fields, [element.id]: subFields };
      });
      return fields;
    };

    this.formSection = new FormGroup({
      footer_section_name: new FormControl(null, [Validators.required]),
      position: new FormControl(99, [Validators.min(1), Validators.max(99), Validators.required]),
      status: new FormControl(1),
      details: new FormGroup(buildSection())
    });
  }

  ngOnDestroy() {
    this.localesSub.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  checkSectionContentVIP(locale: any) {
    let display_title =  this.formSection.value.details[locale.id].name ?  this.formSection.value.details[locale.id].name : null;

    if ((display_title == null || display_title == '') && (display_title == null || display_title == '')) {
      return false;
    }
    else {
      return true;
    }
  }

  clearSectionContentVIP(locale: any) {
    Swal.fire({
      title: 'Delete Section Content',
      text: 'Are you sure you want to delete section contents for ' + locale.code + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this.formSection.patchValue({
          details: {
            [locale.id]: {
              name: null,
            }
          }
        });

      }
      this.cdr.detectChanges();
    });
  }  
  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  addTextVariable(variable: any, id: any) {
    let content = this.formContent.value.details[id]['text_value'] ? this.formContent.value.details[id]['text_value'] : '';
    this.formContent.patchValue({
      details: {
        [id]: {
          text_value: content + variable,
        }
      }
    });
  }

  addQuestionVariable(variable: any, id: any) {
    let content = this.formContent.value.details[id]['question'] ? this.formContent.value.details[id]['question'] : '';
    this.formContent.patchValue({
      details: {
        [id]: {
          question: content + variable,
        }
      }
    });
  }

  addAnswerVariable(variable: any, id: any) {
    let content = this.formContent.value.details[id]['answer'] ? this.formContent.value.details[id]['answer'] : '';
    this.formContent.patchValue({
      details: {
        [id]: {
          answer: content + variable,
        }
      }
    });
  }


  imagesList(setting_local_id: number): FormArray {
    return this.formContent.get('details.'+setting_local_id+'.images') as FormArray;
  }

  addImages(setting_local_id: number,data?: any) {
    this.imagesList(setting_local_id).push(this.newImage(data ? data : null));
  }

  removeImages(setting_local_id: number, imageIndex: number) {
    this.imagesList(setting_local_id).removeAt(imageIndex);
  }

  newImage(data?: any): FormGroup {
    return this.fb.group({
      image_path: data && data?.image_path ? data.image_path : null,
      image_position: data && data?.image_position ? data.image_position : null,
      image_lable: data && data?.image_lable ? data.image_lable : null,
      image_text: data && data?.image_text ? data.image_text : null,
    });
  }

  getMediaSource(setting_local_id: number, imageIndex: number) {
    let media: any;
    media = this.formContent.get('details.'+setting_local_id+'.images.'+imageIndex).value.image_path;
    return media;
  }

  onRemoveFile(setting_local_id: number, imageIndex: number) {
    this.formContent.get('details.'+setting_local_id+'.images.'+imageIndex+'.image_path').setValue(null);
  }

  onUploadFile(event: any,setting_local_id: number, imageIndex: number) {
    this.imageLoading = true;
    this.isImageUploading = true;
    this.isImageUploadingLocale = setting_local_id;
    this.isImageUploadingIndex = imageIndex;


    const file: File = event.target.files[0];
    const formData = new FormData();

    if (file !== undefined) {
      formData.append('files', file, file.name);
      formData.append('type', 'uploads');

      this.uploadService.upload(formData).subscribe(
        res => {
          this.imageLoading = false; 

          res.forEach(x => {
            this.formContent.get('details.'+setting_local_id+'.images.'+imageIndex+'.image_path').setValue(res[0]);
          });

          this.formContent.markAllAsTouched();
          this.isImageUploading = false;
          this.isImageUploadingLocale = null;
          this.isImageUploadingIndex = null;
          this.cdr.detectChanges();
        },
        err => {
          this.isImageUploading = false;
          this.imageLoading = false;
          this.isImageUploadingLocale = null;
          this.isImageUploadingIndex = null;
        }
      );
    } else {
      this.isImageUploading = false;
      this.imageLoading = false;
      this.isImageUploadingLocale = null;
      this.isImageUploadingIndex = null;
    }
  }

  async onSortColumn(property: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      // Reset other columns
      for (const key in this.sortingConfig) {
        if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
          if (key == property) {
            this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
          } else {
            this.sortingConfig[key] = 'desc';
          }
        }
      }

      // User selection
      this.sortingSelection.sort_by = property;

      if (this.sortingSelection.sort_by === property) {
        // Same column
        this.sortingSelection.sort_order = this.sortingConfig[property];
      } else {
        // Switch to other column
        this.sortingConfig[property] = 'asc';
        this.sortingSelection.sort_order = 'asc';
      }
      // Load Data
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
  }

  async onViewPageBy(page = 1, pageSize?: number, params?: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.backupPage = page;
      pageSize = this.pageSize;
      params = this.params ? `&${this.params}` : '';
      this.loading = true;
      this.loadingBar.start();
      this.vipFooterSettingsDataService.getWithQuery(`?perPage=${this.pageSize}${params}&${this.generateSortingParam()}`).subscribe(res => {
        if(res){
          this.vipFooterSettings = res;
        }
        this.pagination = this.vipFooterSettingsDataService.pagination;
        this.dataLength = res['vip_contents'].rows.length;
        this.vipFooterSettingsData.vip_sections = res['vip_sections'];
        this.vipFooterSettingsData.vip_contents = res['vip_contents'].rows;
        this.availableVipFooters = res['vip_contents'].rows;

        this.loading = false;
        this.buttonLoading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.loadingBar.complete();
        this.cdr.detectChanges();
      })
    }
    else {
      this.page = this.backupPage;
    }
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onLocalesSynced(res: any) {
    //Duplicate current data to selected locales
    const currentData = Object.values(this.formContent.value.details).filter(
      x => x['settings_locale_id'] == res['settingsLocaleIdFrom']
    );
    
    res['selectedLocales'].forEach((element: any) => {
      this.formContent.patchValue({
        details: {
          [element]: {
            question: currentData[0]['question'] ? currentData[0]['question'] : null,
            answer: currentData[0]['answer'] ? currentData[0]['answer'] : null,
            text_value: currentData[0]['text_value'] ? currentData[0]['text_value'] : null
          }
        }
      });

      if(currentData[0]['images'].length > 0){
        currentData[0]['images'].forEach((image: any) => {
          this.addImages(element,image)
        });
      }

    });

    // this.initVipFooter = JSON.stringify({ ...this.formContent.value });
    this.cdr.detectChanges();
  }

  checkImageArray(){
    var noimage = false;
    if(this.formContent.value.value_type == 2){
      this.dropdown.locales.forEach((element: any) => {
        if(this.formContent.value.details[element.id].images.length > 0){
          (this.formContent.value.details[element.id].images).forEach(element => {
            if(element.image_path == null || !element.image_path ) noimage = true;
            if(noimage == true) return noimage;
          });
        }
      });
    }
    return noimage;
  }

  onChangeContentPosition($event) {
    if ($event.target.value && $event.target.value > 99) {
      this.formContent.patchValue({
        position: 99
      });
    }

    if ($event.target.value && $event.target.value == 0) {
      this.formContent.patchValue({
        position: 1
      });
    }
  }

  onChangeSectionPosition($event) {
    if ($event.target.value && $event.target.value > 99) {
      this.formSection.patchValue({
        position: 99
      });
    }

    if ($event.target.value && $event.target.value == 0) {
      this.formSection.patchValue({
        position: 1
      });
    }
  }
}
 