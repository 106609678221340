import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, Input, ViewChild } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { Observable, Subscription, of } from 'rxjs';
import { tap, map, exhaustMap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Pagination } from '@core/models/pagination.model';
import { FormGroup, FormControl } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { DropdownTreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { Dropdown } from '@core/models/dropdown.model';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { environment } from '@env/environment';
import Swal from 'sweetalert2';
import * as moment from 'moment-timezone';
import { Member } from '@core/models/member.model';
import { MemberEntityService } from '../../services/member-entity.service';
import { MemberDataService } from '../../services/member-data.service';
import { MemberLabelEditComponent } from '../member-label/member-label-edit/member-label-edit.component';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { MemberInformationDialogComponent } from '../member-information/member-information.component';
import { MemberLabelComponent } from '../member-label/member-label.component';
import Echo from 'laravel-echo';

@Component({
  selector: 'kt-member-label-list',
  templateUrl: './member-label-list.component.html',
  styleUrls: ['./member-label-list.component.scss']
})
export class MemberLabelListComponent implements OnInit, OnDestroy {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  @ViewChild(DropdownTreeviewComponent, { static: false }) treeView: DropdownTreeviewComponent;
  private subscription = new Subscription();
  form: FormGroup;
  messages$ = this.memberDataService.messages$;
  loading = false;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  buttonLoading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;
  sqsEnable: boolean = environment.sqsEnable;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  echo: Echo;

  members$: Observable<Member[]>;

  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'name': 'desc',
    'currency': 'desc',
    'group': 'desc',
    'status': 'desc',
    'updated_at': 'desc',
  };
  sortingSelection = {
    'sort_by': 'updated_at',
    'sort_order': 'desc',
  };

  dropdown = {
    groups: this.dropdownHttpService.groups,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    label: this.dropdownHttpService.activeMemberLabels,
    statuses: this.dropdownHttpService.memberStatuses,
    perPage: this.dropdownHttpService.perPage
  };

  depositTreeview = new TreeviewItem({
    text: 'Deposit',
    value: null,
    children: [
      {
        text: 'Depositor',
        value: 1,
      },
      {
        text: 'Non-Depositor',
        value: 2,
      }
    ]
  });

  accountTypeTreeview = new TreeviewItem({
    text: 'Account Type',
    value: null,
    children: [
      {
        text: 'Normal',
        value: 0,
      },
      {
        text: this.sqsEnable == true ? 'With-Label' : 'Suspicious',
        value: 1,
      },
      {
        text: 'Dummy',
        value: 2,
      }
    ]
  });

  mobileVerificationTreeview = new TreeviewItem({
    text: 'Mobile Verification',
    value: null,
    children: [
      {
        text: 'Verified',
        value: 1,
      },
      {
        text: 'Unverified',
        value: 0,
      }
    ]
  });

  emailVerificationTreeview = new TreeviewItem({
    text: 'Email Verification',
    value: null,
    children: [
      {
        text: 'Verified',
        value: 1,
      },
      {
        text: 'Unverified',
        value: 0,
      }
    ]
  });
  
  treeviewItems: TreeviewItem[];
  values: number[];
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400
  });

  interactiveInput = {
    disableUsername: false,
    disableName: false,
    disableBankAcc: false,
    disableID: false,
    disableAgent: false,
    disableStatus: false,
    disableCurrency: false,
    disableGroup: false,
    disableContact: false,
    disableDate: false,
    disableAffiliate: false,
  }

  memberGroup: Dropdown[];

  // permissions
  canViewMemberDialog: boolean;
  canCreateMemberLabel: boolean;
  canEditMemberLabel: boolean;

  private subscriptions = new Subscription();

  constructor(
    private memberService: MemberEntityService,
    private memberDataService: MemberDataService,
    public dialog: MatDialog,
    public loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
    private transactionHttpService: TransactionHttpService,
  ) { 
    const SocketIoClient = require('socket.io-client');
    const socketHost: string = environment.socketHost;
    try {
      this.echo = new Echo({
        broadcaster: 'socket.io',
        client: SocketIoClient,
        host: socketHost
      });
    } catch (e) {
      console.log(e);
    }
   }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.treeviewItems = [this.depositTreeview, this.accountTypeTreeview, this.mobileVerificationTreeview, this.emailVerificationTreeview];
    this.formInit();
    this.memberGroupsInit();
    this.pagination = this.memberDataService.pagination;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.member_label_view_member_dialog;
      this.canCreateMemberLabel = appPermissions.create_member_label;
      this.canEditMemberLabel = appPermissions.edit_member_label;
    });

    this.subscriptions.add(apSub);

    // Member account label dropdown listener
    const listener = (res) => {
      // Filter only Active member account label settings into dropdown
      var activeMemberLabels = res.rows.filter(item => item.status == 1);
      this.dropdown.label = of(activeMemberLabels);

      // If current label dropdown selected option no longer in dropdown list, set the form value to 'all'
      const selectedValue = this.form.value.label;
      const existsInDropdown = activeMemberLabels.find(item => item.name === selectedValue);
      if (!existsInDropdown) {
        this.form.patchValue({
          label: 'all'
        });
      }
    };
    this.echo.channel('update-member-account-label-dropdown-channel').listen('.UpdateMemberAccountLabelDropdownEvent', listener);

    this.onSubmit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onOpenDialog(mode: string, id?: number, row?: any) {
    switch (mode) {
      case 'create':
        this.openDialogBy(MemberLabelEditComponent, { mode: mode});
        break;
      case 'edit':
        this.openDialogBy(MemberLabelComponent, { mode: 'member-label', member: row});
        break;
      case 'member-information':
        if (!this.canViewMemberDialog) {
          Swal.fire('Permission Error', 'You do not have permission to view member information', 'error');
          return;
        }
  
        this.subscription = this.memberDataService.getById(id, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}
        &end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&additional_info=1`).pipe(
          tap((res) => {
            if (res) {
              this.openDialogBy(MemberInformationDialogComponent, { mode: mode, member: res });
            }
          })
        ).subscribe();
        break;
    }
  }

  private openDialogBy(componentRef: any, data?: { mode: any, labelSetting?: any, member?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'member-information' ? '1500px' : data.mode == 'member-label' ? '1000px' : '800px',
      height: data.mode === 'member-information' ? '80vh' : 'auto',
      data: {
        mode: data.mode,
        labelSetting: data.labelSetting,
        member: data.member,
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true){
        this.onViewPageBy();
      }
    });
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        this.members$ = this.memberService.getWithQuery(`?perPage=${this.pageSize}${parameters}&${this.generateSortingParam()}&has_label=true`).pipe(
          tap(res => {
            this.pagination = this.memberDataService.pagination;
            this.dataLength = res.length;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    this.members$ = this.memberService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}&has_label=true`).pipe(
      tap(res => {
        this.pagination = this.memberDataService.pagination;
        this.dataLength = res.length;
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.loadingBar.complete();
      })
    );
  }

  public onClear() {
    this.clearBtnLoading = true;
    this.interactiveInput.disableID = false;
    this.interactiveInput.disableStatus = false;
    this.interactiveInput.disableUsername = false;
    this.interactiveInput.disableName = false;
    this.interactiveInput.disableCurrency = false;
    this.interactiveInput.disableBankAcc = false;
    this.interactiveInput.disableGroup = false;
    this.interactiveInput.disableAgent = false;
    this.interactiveInput.disableContact = false;
    this.interactiveInput.disableDate = false;
    this.form.patchValue({
      id: null,
      username: null,
      name: null,
      status: 1,
      currency_id: 'all',
      group_id: 'all',
      label: 'all',
      'type[deposit_type][]': null,
      'type[account_type][]': null,
      'type[account_type][1]': null,
      'type[mobile_verification][]': null,
      'type[email_verification][]': null,
    });

    // reset type dropdown
    this.treeviewItems.forEach(item => {
      item.checked = true; // Check all items
      if (item.children) {
        item.children.forEach(child => child.checked = true);
      }
    });
    this.treeviewItems = [...this.treeviewItems]; // Force change detection for Angular to update the UI

    // reset sort
    this.sortingSelection = {
      'sort_by': 'id',
      'sort_order': 'desc',
    };
    this.onSubmit(true);
  }

  onTreeviewChange(event: Event) {
    // for deposit
    if (this.treeView.items[0]['internalChecked']) {
      this.form.patchValue({
        'type[deposit_type][]': null
      });
    } else {
      if (this.treeView.items[0]['internalChildren'][0].internalChecked) {
        this.form.patchValue({
          'type[deposit_type][]': this.treeView.items[0]['internalChildren'][0].value
        });
      }

      if (this.treeView.items[0]['internalChildren'][1].internalChecked) {
        this.form.patchValue({
          'type[deposit_type][]': this.treeView.items[0]['internalChildren'][1].value
        });
      }

      if (!this.treeView.items[0]['internalChildren'][0].internalChecked && !this.treeView.items[0]['internalChildren'][1].internalChecked) {
        this.form.patchValue({
          'type[deposit_type][]': null
        });
      }
    }

    // for account type
    if (this.treeView.items[1]['internalChecked']) {
      this.form.patchValue({
        'type[account_type][]': null,
        'type[account_type][1]': null,
      });
    } else {
      if (this.treeView.items[1]['internalChildren'][0].internalChecked) {
        this.form.patchValue({
          'type[account_type][]': this.treeView.items[1]['internalChildren'][0].value,
          'type[account_type][1]': null,
        });
      }

      if (this.treeView.items[1]['internalChildren'][1].internalChecked) {
        this.form.patchValue({
          'type[account_type][]': this.treeView.items[1]['internalChildren'][1].value,
          'type[account_type][1]': null,
        });
      }

      if (this.treeView.items[1]['internalChildren'][2].internalChecked) {
        this.form.patchValue({
          'type[account_type][]': this.treeView.items[1]['internalChildren'][2].value,
          'type[account_type][1]': null,
        });
      }

      if (this.treeView.items[1]['internalChildren'][0].internalChecked && this.treeView.items[1]['internalChildren'][1].internalChecked) {
        this.form.patchValue({
          'type[account_type][]': this.treeView.items[1]['internalChildren'][0].value,
          'type[account_type][1]': this.treeView.items[1]['internalChildren'][1].value,
        });
      }

      if (this.treeView.items[1]['internalChildren'][0].internalChecked && this.treeView.items[1]['internalChildren'][2].internalChecked) {
        this.form.patchValue({
          'type[account_type][]': this.treeView.items[1]['internalChildren'][0].value,
          'type[account_type][1]': this.treeView.items[1]['internalChildren'][2].value,
        });
      }

      if (this.treeView.items[1]['internalChildren'][1].internalChecked && this.treeView.items[1]['internalChildren'][2].internalChecked) {
        this.form.patchValue({
          'type[account_type][]': this.treeView.items[1]['internalChildren'][1].value,
          'type[account_type][1]': this.treeView.items[1]['internalChildren'][2].value,
        });
      }

      if (!this.treeView.items[1]['internalChildren'][0].internalChecked && !this.treeView.items[1]['internalChildren'][1].internalChecked && !this.treeView.items[1]['internalChildren'][2].internalChecked) {
        this.form.patchValue({
          'type[account_type][]': null,
          'type[account_type][1]': null,
        });
      }
    }

    // for mobile
    if (this.treeView.items[2]['internalChecked']) {
      this.form.patchValue({
        'type[mobile_verification][]': null
      });
    } else {
      if (this.treeView.items[2]['internalChildren'][0].internalChecked) {
        this.form.patchValue({
          'type[mobile_verification][]': this.treeView.items[2]['internalChildren'][0].value
        });
      }

      if (this.treeView.items[2]['internalChildren'][1].internalChecked) {
        this.form.patchValue({
          'type[mobile_verification][]': this.treeView.items[2]['internalChildren'][1].value
        });
      }

      if (!this.treeView.items[2]['internalChildren'][0].internalChecked && !this.treeView.items[2]['internalChildren'][1].internalChecked) {
        this.form.patchValue({
          'type[mobile_verification][]': null
        });
      }
    }

    // for email
    if (this.treeView.items[3]['internalChecked']) {
      this.form.patchValue({
        'type[email_verification][]': null
      });
    } else {
      if (this.treeView.items[3]['internalChildren'][0].internalChecked) {
        this.form.patchValue({
          'type[email_verification][]': this.treeView.items[3]['internalChildren'][0].value
        });
      }

      if (this.treeView.items[3]['internalChildren'][1].internalChecked) {
        this.form.patchValue({
          'type[email_verification][]': this.treeView.items[3]['internalChildren'][1].value
        });
      }

      if (!this.treeView.items[3]['internalChildren'][0].internalChecked && !this.treeView.items[3]['internalChildren'][1].internalChecked) {
        this.form.patchValue({
          'type[email_verification][]': null
        });
      }
    }
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      username: new FormControl(null),
      name: new FormControl(null),
      status: new FormControl(1),
      currency_id: new FormControl('all'),
      group_id: new FormControl('all'),
      label: new FormControl('all'),
      'type[deposit_type][]': new FormControl(null),
      'type[account_type][]': new FormControl(null),
      'type[account_type][1]': new FormControl(null),
      'type[mobile_verification][]': new FormControl(null),
      'type[email_verification][]': new FormControl(null),
    });
  }

  private memberGroupsInit() {
    this.dropdown.groups.pipe(
      tap(groups => this.memberGroup = groups)
    ).subscribe();
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }
}
