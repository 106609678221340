<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ data.mode | titlecase }} Label Settings</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <div class="col-12 form-group row">
                        <label class="col-4 col-form-label">Code <span class="text-danger">*</span></label>
                        <input type="text" formControlName="code" class="col-8 form-control" (input)="onCodeInput($event)" placeholder="Enter Label Code">
                    </div>
                    <div class="col-12 form-group row">
                        <label class="col-4 col-form-label">Name <span class="text-danger">*</span></label>
                        <input type="text" formControlName="name" class="col-8 form-control" placeholder="Enter Label Name">
                    </div>
                    <div class="col-12 form-group row">
                        <label class="col-4 col-form-label">Description <span class="text-danger">*</span></label>
                        <textarea rows="5" class="col-8 form-control" formControlName="description" placeholder="Enter Label Description"></textarea>
                    </div>
                    <div class="col-12 form-group row">
                        <label class="col-4 col-form-label">Display Color <span class="text-danger">*</span></label>
                        <div class="col-8 position-relative p-0">
                            <div class="color-box" [style.background]="displayColor"></div>
                            <input 
                              [value]="displayColor"
                              [cpCancelButton]="false"
                              [cpPosition]="'bottom'"
                              [cpOutputFormat]="'hex'"
                              [(colorPicker)]="displayColor"
                              (colorPickerChange)="onColorChange($event)"
                              formControlName="display_color_code"
                              class="form-control color-input" 
                              placeholder="Enter Color Code"
                              readonly 
                            />
                        </div>
                    </div>
                    <div class="col-12 form-group row">
                        <label class="col-4 col-form-label">Risk Score <span class="text-danger">*</span></label>
                        <input type="number" formControlName="risk_score" class="col-8 form-control" step="1" oninput="this.value = this.value === '' ? '' : Math.max(1, Math.min(99, Math.floor(this.value)))" placeholder="Enter Risk Score (1 = Least Risk, 99 = Most Risk)">
                    </div>
                    <div class="col-12 form-group row">
                        <label class="col-4 col-form-label">Status <span class="text-danger">*</span></label>
                        <select class="col-8 form-control" formControlName="status">
                            <option value="null" disabled> Please Select </option>
                            <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">{{ value }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button [buttonLoading]="buttonLoading" [isDisabled]="form.invalid" (confirmed)="onSave()"></kt-submit-button>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
