import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MaintenanceHoursStatus } from '@core/enums/maintenance-hours-status.enum';
import { MaintenanceHours } from '@core/models/maintenance-hours.model';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { environment } from '@env/environment';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { MaintenanceHoursEditDialogComponent } from './dialogs/maintenance-hours-edit.component';
import { MaintenanceHoursDataService } from './services/maintenance-hours-data.service';
import { MaintenanceHoursEntityService } from './services/maintenance-hours-entity.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-maintenance-hours',
  templateUrl: './maintenance-hours.component.html',
  styleUrls: ['./maintenance-hours.component.scss']
})
export class MaintenanceHoursComponent implements OnInit, OnDestroy {

  form: FormGroup;

  //Using status enum - API might refine to return maintenance statuses
  maintenanceHoursStatus = MaintenanceHoursStatus;
  maintenanceHours$: Observable<MaintenanceHours[]>;

  object = Object;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  messages$ = this.maintenanceHoursDataService.messages$;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage,
    categories: this.dropdownHttpService.gameCategories
  };
  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  signUpUrl = environment.signUpUrl;
  gameProviders: any;
  dataLength = 0;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;

  // permissions
  canCreateGameProviderMaintenanceHour: boolean;
  canEditGameProviderMaintenanceHour: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private datePickerSubscription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private maintenanceHoursDataService: MaintenanceHoursDataService,
    private maintenanceHoursEntityService: MaintenanceHoursEntityService,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private dialog: MatDialog,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
    ) {
    this.dropdownHttpService.gameProviders.subscribe(res => {
      this.gameProviders = res;
    });
  }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.reload();
    this.formInit();
    this.pagination = this.maintenanceHoursDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateGameProviderMaintenanceHour = appPermissions.create_game_provider_maintenance_hour;
      this.canEditGameProviderMaintenanceHour = appPermissions.edit_game_provider_maintenance_hour;
    });

    this.subscription.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
    sessionStorage.setItem('recent_page', null);
  }

  onClearDate() {
    if (this.form.value.start_date !== null){
      this.form.patchValue({defaultDate: null});
    }
  }

  onDateRange(event: any) {
    if (event){
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.maintenanceHours$ = this.maintenanceHoursEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.dataLength = res.length;
        this.loading = false;
        this.pagination = this.maintenanceHoursDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event){
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      status: 'all',
      start_date: null,
      end_date: null,
      category_code: 'all',
      code: 'all',
      currency_id: 'all',
      defaultDate: null,
      date_type: 'start_date'
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.maintenanceHours$ = this.maintenanceHoursEntityService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.dataLength = res.length;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.page = 1;
            this.pagination = this.maintenanceHoursDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all' && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      status: new FormControl('all'),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      date_type: new FormControl('start_date'),
      category_code: new FormControl('all'),
      code: new FormControl('all'),
      currency_id: new FormControl('all'),
      defaultDate: new FormControl(null) // Do not remove: For Clearing The Range
    });
  }

  onOpenDialog(formMode: string, data?: any) {
    let gameProvider = null;
    let maintenanceId = null;
    let currency_id = [];
    if (formMode === 'edit'){
      gameProvider = this.gameProviders.find(i => i.name === data.name);
      maintenanceId = data !== undefined ? data.id : null;
      let split_currency = data.currency.split(', ');
      split_currency.map( currencyCode => {
        currency_id.push(this.dropdown.currencies.find(x => x.name === currencyCode).id);
      });
    };
    const gameProviderData = {
      game_provider_id: gameProvider !== null ? gameProvider.id : null,
      maintenance_id: maintenanceId,
      game_provider_code: gameProvider !== null ? gameProvider.code : null,
      category_code: data !== undefined ? data.category : null,
      maintain_from: data !== undefined ? data.maintain_from : '',
      maintain_to: data !== undefined ? data.maintain_to : '',
      remarks: data !== undefined ? data.remarks : null,
      currency_id: currency_id,
    };
    this.openDialogBy(MaintenanceHoursEditDialogComponent, { gameProvider: gameProviderData, mode: 'maintenance', maintenanceMode: formMode });
  }

  private openDialogBy(componentRef: any, data: { gameProvider?: any, mode: string, maintenanceMode: string }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        gameProvider: data.gameProvider,
        mode: data.mode,
        maintenanceMode: data.maintenanceMode
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  getColorStatusCode(status: string) {
    switch (status) {
      case 'FINISHED': {
        return 1;
      }
      case 'ONGOING': {
        return 5;
      }
      case 'UPCOMING': {
        return 3;
      }
    }
  }

}
