<div class="kt-form w-webkit-fill-available" *ngIf="dropdown.locales.length > 0">
    <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">

      <!-- Filter -->
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
          <form [formGroup]="form">
            <div class="row align-items-center">
              <div class="col-xl-12 order-2 order-xl-1">
                <div class="row mb-3">
                  <!-- Label -->
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Label' | translate }}:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <input type="text" formControlName="label" placeholder="{{ 'Search' | translate }}" class="form-control">
                  </div>

                  <!-- Footer Section -->
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'VIP Footer Section' | translate }}:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <select formControlName="vip_footer_sections_id" class="form-control">
                      <option value="all"> {{'All' | translate }} </option>
                      <ng-container *ngIf="vipFooterSettings  as rows">
                        <ng-container *ngIf="rows.vip_sections as rows">
                          <ng-container *ngFor="let row of rows">
                            <option *ngIf="row.status == 1" [value]="row.id">
                              {{ row.footer_section_name }}
                            </option>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </select>
                  </div>

                  <!-- Status -->
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Status' | translate }}:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <select formControlName="status" class="form-control">
                      <option value="all"> {{'All' | translate }} </option>
                      <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">
                        {{ value }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">  
                  <!-- Date Time -->
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>Date/Time:</label>
                  </div>
                  <div class="col-md-5 kt-form__control">
                    <div class="input-group" width="70%">
                      <div class="input-group-prepend">
                        <select class="form-control" formControlName="date_type">
                            <ng-container *ngFor="let row of dropdown.dateType">
                                <option [value]="row.value">{{ row.name }}</option>
                            </ng-container>
                        </select>
                      </div>
                      <input type="text" class="form-control" placeholder="Search"
                        (change)="onDateRange($event)" formControlName="defaultDate"
                        [timePicker]="true" [timePickerSeconds]="true"
                        [alwaysShowCalendars]="true" [ranges]="ranges"
                        [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                        [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                        ngxDaterangepickerMd/>
                      <span class="input-group-append">
                        <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                      </span>
                    </div>
                  </div>    
                </div>
              </div>
            </div>
          </form>
          <div class="kt-section col-12 row d-flex justify-content-between mb-0 mt-1">
            <div class="dropdown dropdown-inline">
              <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
              <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onSubmit(true)"></kt-clear-button>
            </div>
          </div>
        </div>
      </div>

      <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-portlet__body pb-0">
            <div class="kt-section">
              <div class="kt-section__content">
                <div class="table-responsive">
                  <table class="table table-bordered table-hover table-striped table-sortable">
                    <tbody>
                      <tr class="border-bottom-remove ng-tns-c15-1 ng-star-inserted">
                        <td class="question" colspan="2">
                            <div class="main_description">VIP Footer Section</div>
                        </td>
                      </tr>
                      <ng-container *ngIf="vipFooterSettings as rows">
                        <!-- VIP Footer Section -->
                        <ng-container *ngIf="rows.vip_sections as rows">
                          <tr class="border-top-remove border-bottom-remove" [ngClass]="modeVipSection == 'edit' || modeVipSection == 'create'? 'border-bottom-remove' : ''">
                            <td colspan="2" class="custom-top-border">
                              <button *ngIf="canCreateVIPFooter" class="faq_section_add_button" [disabled]="loading" (click)="expandRowVipSectionCreate('create')" ><i class="fas fa-plus"></i></button>
                              <ng-container *ngFor="let row of rows">
                                <button class="faq_section_button" [disabled]="loading" [ngClass]="canViewVIPFooterDetails && vipSection && vipSection.id == row.id ? 'merchant-selected' : row.status == 0 ? 'inactive_button' : ''" (click)="expandRowVipSectionEdit('edit',row)"> {{ row.footer_section_name }}</button>
                              </ng-container>
                            </td>
                          </tr>
                          <ng-container *ngIf="canViewVIPFooterDetails">
                            <tr class="border-top-remove" *ngIf="modeVipSection == 'edit' || modeVipSection == 'create'">
                              <td colspan="2" class="pr-0 pl-0  custom-top-border" style="border-bottom: 0 !important;">
                                <ng-container *ngIf="!modeVipSection else vipSectionsContent"></ng-container>
                              </td>
                            </tr>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </tbody>
                  </table>
                  <!-- VIP Footer Content -->
                  <table *ngIf="canViewFooterVIPContentSettingsList" class="table table-bordered table-hover table-striped table-sortable border-btm">
                    <thead>
                        <tr>
                          <th width="5" class="sort-enabled pointer" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">{{ 'Id' | uppercase }}</th>
                          <th width="5" class="pr-0">{{ 'Locale' | uppercase }}</th>
                          <th>{{ 'Label' | uppercase }}</th>
                          <th>{{ 'Type' | uppercase }}</th>
                          <th>{{ 'Section' | uppercase }}</th>
                          <th class="sort-enabled pointer text-center" (click)="onSortColumn('position')" [ngClass]="sortingConfig.position" [class.sort-selected]="sortingSelection.sort_by === 'position'">{{ 'Position' | uppercase}}</th>
                          <th class="sort-enabled pointer" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">{{ 'Created By' | uppercase}}</th>
                          <th class="sort-enabled pointer" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">{{ 'Updated By' | uppercase}}</th>
                          <th>{{ 'Status' | uppercase }}</th>
                          <th class="text-center pr-13">{{ 'Actions' | uppercase}}</th>
                        </tr>

                        <tr *ngIf="canCreateFooterVIPContentSettings && (!mode || mode != 'create')">
                          <th colspan="100%" class="pr-0 pl-0">
                            <div class="content-container text-center" (click)="expandRowCreate('create')">
                              <span><i class="fas fa-plus mr-2"></i>Create New Content</span>
                            </div>
                          </th>
                        </tr>
                        <tr *ngIf="mode == 'create'">
                          <th colspan="100%" class="pr-0 pl-0">
                            <ng-container *ngIf="!mode else vipFooterContents"></ng-container>
                          </th>
                        </tr>  
                    </thead>
                    <tbody *ngIf="vipFooterSettings as contentrows">
                      <ng-container *ngFor="let row of contentrows.vip_contents.rows">
                        <tr>
                          <td [style]="mode == 'edit' && vipFooter.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.id }}</td>
                          <td [style]="mode == 'edit' && vipFooter.id == row.id ? 'border-bottom: 0 !important;' : ''" class="pr-5">
                            <div style="width: 100px;">
                              {{ row.locale_list }}
                            </div>
                          </td>
                          <td [style]="mode == 'edit' && vipFooter.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.label }}</td>
                          <td [style]="mode == 'edit' && vipFooter.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.value_type_name }}</td>
                          <td [style]="mode == 'edit' && vipFooter.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.footer_section_name }}</td>
                          <td class="text-center" [style]="mode == 'edit' && vipFooter.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.position }}</td>
                          <td [style]="mode == 'edit' && vipFooter.id == row.id ? 'border-bottom: 0 !important;' : ''">
                            {{ row.created_by ? row.created_by : 'System' }}<br>
                            <div class="header-hint">
                              <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="tooltip-class">{{ row.created_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
                              <ng-template #createdDateTime>
                                {{ row.created_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
                              </ng-template>
                            </div>
                          </td>
                          <td [style]="mode == 'edit' && vipFooter.id == row.id ? 'border-bottom: 0 !important;' : ''">
                            {{ row.updated_by ? row.updated_by : 'System' }}<br>
                            <div class="header-hint">
                              <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="tooltip-class">{{ row.updated_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
                              <ng-template #updatedDateTime>
                                {{ row.updated_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
                              </ng-template>
                            </div>
                          </td>
                          <td [style]="mode == 'edit' && vipFooter.id == row.id ? 'border-bottom: 0 !important;' : ''">
                            <label class="switch">
                              <input [disabled]="!canEditVIPContentSettings && !canUpdateVIPContentStatus" type="checkbox" id="togBtn" [checked]="row.status == 1" (change)="onChangeStatusFooter($event, row)">
                              <div class="slider round">
                                <span class="on">ON</span>
                                <span class="off">OFF</span>
                              </div>
                            </label>
                          </td>
                          <td [style]="mode == 'edit' && vipFooter.id == row.id ? 'border-bottom: 0 !important;' : ''" class="text-center actions-column">
                            <button *ngIf="canViewFooterVIPContentSettings" matTooltip="{{ 'View' }}" class="btn btn-elevate btn-sm btn-icon btn-icon-sm" (click)="expandRowEdit('edit', row)"><i class="fas fa-cog"></i></button>
                          </td>
                        </tr>
                        <tr *ngIf="mode == 'edit' && vipFooter.id == row.id" class="border-top-remove">
                          <td colspan="100%" class="pr-0 pl-0 custom-top-border" style="border-bottom: 0 !important;">
                            <ng-container *ngIf="!mode else vipFooterContents"></ng-container>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                    <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold table-handler"></tbody>
                  </table>
                </div>
                <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined && canViewFooterVIPContentSettingsList">
                  <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                  </ngb-pagination>
                  <div class="kt-pagination__toolbar">
                    <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                      <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                    </select>
                    <span class="pagination__desc">
                      Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

<ng-template #vipSectionsContent>
  <form [formGroup]="formSection">
    <div class="create-content-container" *ngIf="dropdown.locales.length > 0 && canViewVIPFooterDetails">
      <div class="row col-12 p-0 m-0">
        <div class="form-group col-3 mb-0">
          <label>{{ 'VIP Footer Tab Name' | translate }}: <span class="text-danger">*</span></label>
          <input type="text" formControlName="footer_section_name" class="form-control" [disabled]="!canEditVIPFooterDetails">
        </div>
        <div class="form-group col-3 mb-3" >
          <label>{{ 'Position' | translate }}: <span class="text-danger">*</span></label>
          <input type="number" min="1" (wheel)="false" step="1" onkeypress="return event.charCode >= 48" formControlName="position" class="form-control" [disabled]="!canEditVIPFooterDetails" (input)="onChangeSectionPosition($event)">
        </div>
        <div  class="form-group col-3 mb-3" *ngIf="modeVipSection == 'edit' ">
          <label class="d-block">{{ 'Status' | translate }}: <span class="text-danger">*</span></label>
          <label class="switch">
            <input [disabled]="!canEditVIPFooterDetails" type="checkbox" id="togBtn" [checked]="formSection.value.status == 1" (change)="onChangeStatus($event)">
            <div class="slider round">
              <span class="on">ON</span>
              <span class="off">OFF</span>
            </div>
          </label>
        </div>
      </div>
      <hr>
      <mat-tab-group animationDuration="0ms" class="form-group mb-0 mat-tab-header-pagination-controls-enabled"  class="form-group m-0" formGroupName="details" [selectedIndex]="localeIndex()">
        <ng-container *ngIf="dropdown.locales.length > 0">
          <mat-tab *ngFor="let value of dropdown.locales; let i = index" [formGroupName]="value.id">
            <ng-template mat-tab-label>
              <span class="mat-tab">
                {{ value.code }}
                <div *ngIf="checkSectionContentVIP(value)" class="icon" (click)="clearSectionContentVIP(value)">
                  <i class="fas fa-times"></i>
                </div>
              </span>
            </ng-template>
            <div class="row col-12 p-0 m-0">
              <div class="form-group col-12 mb-0 p-0">
                <label>{{ 'Display Text' | translate }}:</label>
                <input type="text" formControlName="name" class="form-control" [disabled]="!canEditVIPFooterDetails">
              </div>
            </div>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
      <div class="modal-footer">
        <table class="info mr-3" *ngIf="modeVipSection == 'edit'">
          <tr class="border-bottom-remove">
            <td class="header-hint">
              Updated by: {{ vipSection.updated_by ? vipSection.updated_by + '&nbsp;' : '-&nbsp;' }}
            </td>
            <td class="header-hint">
              <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="tooltip-class">{{ vipSection.updated_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #updatedDateTime>
                {{ vipSection.updated_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
              </ng-template>
            </td>
          </tr>
          <tr class="border-bottom-remove">
            <td class="header-hint">
              Created by: {{ vipSection.created_by ? vipSection.created_by + '&nbsp;' : '-&nbsp;' }}
            </td>
            <td class="header-hint">
              <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="tooltip-class">{{ vipSection.created_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #createdDateTime>
                {{ vipSection.created_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
              </ng-template>
            </td>
          </tr>
        </table>
        <button type="button" class="btn btn-danger m-0 mr-2" (click)="modeVipSection == 'create' ? expandRowVipSectionCreate('', true) : expandRowVipSectionEdit('', null, true)">{{ 'Cancel' | translate }}</button>
        <button *ngIf="(modeVipSection == 'create' && canCreateVIPFooter) || modeVipSection != 'create'" type="button" class="btn btn-success m-0" [disabled]="!formSection.valid" (click)="onSaveVipSection()">{{ modeVipSection != null && modeVipSection == 'create' ? 'Create' : 'Update' | translate }}</button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #vipFooterContents>
  <form [formGroup]="formContent">
    <div class="create-content-container content" *ngIf="dropdown.locales.length > 0">
      <div class="row col-12 p-0 m-0">
        <div class="form-group col-3 mb-0">
          <label>{{ 'Label' | translate }}: <span class="text-danger">*</span></label>
          <input type="text" formControlName="label" class="form-control">
        </div>
        <div class="form-group col-3 mb-0">
          <label>{{ 'VIP Footer Section' | translate }}: <span class="text-danger">*</span></label>
          <select formControlName="vip_footer_sections_id" class="form-control">
            <ng-container *ngIf="vipFooterSettings  as rows">
              <ng-container *ngIf="rows.vip_sections as rows">
                <option value="null" disabled hidden>{{ 'Please Select' | translate }}</option>
                <ng-container *ngFor="let row of rows">
                  <option [value]="row.id">
                    {{ row.footer_section_name }}
                  </option>
                </ng-container>
              </ng-container>
            </ng-container>
          </select>
        </div>
        <div class="form-group col-3 mb-0">
          <label>{{ 'Value Type' | translate }}: <span class="text-danger">*</span></label>
          <select class="form-control" formControlName="value_type">
            <ng-container *ngFor="let row of dropdown.valueType">
                <option [value]="row.value">{{ row.name }}</option>
            </ng-container>
        </select>
        </div>
        <div class="form-group col-3 mb-3" >
          <label>{{ 'Position' | translate }}: <span class="text-danger">*</span></label>
          <input type="number" min="1" max="99" (wheel)="false" step="1" onkeypress="return event.charCode >= 48" formControlName="position" class="form-control" (input)="onChangeContentPosition($event)">
        </div>
      </div>
      <hr>
      <ng-container *ngIf="checkLoadingPage else loadingPage" >
        <mat-tab-group animationDuration="0ms" class="form-group mb-0 mat-tab-header-pagination-controls-enabled pb-50"  class="form-group m-0" formGroupName="details" [selectedIndex]="localeIndex()">
          <ng-container *ngIf="dropdown.locales.length > 0">
            <mat-tab *ngFor="let value of dropdown.locales; let i = index" [formGroupName]="value.id">
              <ng-template mat-tab-label>
                <span class="mat-tab">
                  {{ value.code }}
                  <div *ngIf="checkContent(value)" class="icon" (click)="clearContent(value)">
                    <i class="fas fa-times"></i>
                  </div>
                </span>
              </ng-template>
              <div class="row col-12 p-0 m-0" *ngIf="formContent.value.value_type == 1">
                <div class="form-group col-12 mb-0 p-0">
                  <div class="col-12 d-flex justify-content-between p-0 align-items-center">
                    <label>{{ 'Text' | translate }}: <span class="text-danger">*</span></label>
                      <div class="d-flex sync-save-button mb-3">
                        <div class="dropdown dropdown-inline">
                          <ng-container *ngIf="canCreateFooterVIPContentSettings && canEditFooterVIPContentSettings">
                            <kt-sync-locale-button
                              [checkupdate] = "checkupdate()"
                              [localeList] = "dropdown.locales"
                              [valueType] = "formContent.value.value_type"
                              [settings_locale_id_from]="value.id"
                              (localesSynced)="onLocalesSynced($event)">
                            </kt-sync-locale-button>
                          </ng-container>
                        </div>
                      </div>
                  </div>
                  <ckeditor #editors [config]="editorConfig" [editor]="editor" formControlName="text_value" class="ck-style custom-minheight"></ckeditor>
                </div>
              </div>
              <div class="col-12 p-0 mb-3" *ngIf="formContent.value.value_type == 1">
                <span class="header-hint">Usable variables:
                  <div *ngFor="let usableVariable of usableVariables; let last = last" class="p-inline">
                    <p class="p-inline" (click)="addTextVariable(usableVariable, value.id)">{{ usableVariable }}</p>
                    <div *ngIf="!last" class="p-inline">
                      <p class="p-inline p-display">, </p>
                    </div>
                  </div>
                </span>
              </div>
              <div class="row col-12 p-0 m-0" *ngIf="formContent.value.value_type && formContent.value.value_type == 3 ">
                <div class="form-group col-12 mb-0 p-0">
                  <div class="col-12 d-flex justify-content-between p-0 align-items-center">
                    <label>{{ 'Question' | translate }}: <span class="text-danger">*</span></label>
                      <div class="d-flex sync-save-button mb-3">
                        <div class="dropdown dropdown-inline">
                          <ng-container *ngIf="canCreateFooterVIPContentSettings && canEditFooterVIPContentSettings">
                            <kt-sync-locale-button
                              [checkupdate] = "checkupdate()"
                              [localeList] = "dropdown.locales"
                              [valueType] = "formContent.value.value_type"
                              [settings_locale_id_from]="value.id"
                              (localesSynced)="onLocalesSynced($event)">
                            </kt-sync-locale-button>
                          </ng-container>
                        </div>
                      </div>
                  </div>
                  <input type="text" formControlName="question" class="form-control">
                </div>
              </div>
              <div class="col-12 p-0 mb-3" *ngIf="formContent.value.value_type && formContent.value.value_type == 3">
                <span class="header-hint">Usable variables:
                  <div *ngFor="let usableVariable of usableVariables; let last = last" class="p-inline">
                    <p class="p-inline" (click)="addQuestionVariable(usableVariable, value.id)">{{ usableVariable }}</p>
                    <div *ngIf="!last" class="p-inline">
                      <p class="p-inline p-display">, </p>
                    </div>
                  </div>
                </span>
              </div>
              <div class="row col-12 p-0 m-0" *ngIf="formContent.value.value_type && formContent.value.value_type == 3">
                <div class="form-group col-12 mb-0 p-0">
                  <label>{{ 'Answer' | translate }}: <span class="text-danger">*</span></label>
                  <ckeditor #editors [config]="editorConfig" [editor]="editor" formControlName="answer" class="ck-style custom-minheight"></ckeditor>
                </div>
              </div>
              <div class="col-12 p-0 mb-3" *ngIf="formContent.value.value_type && formContent.value.value_type == 3">
                <span class="header-hint">Usable variables:
                  <div *ngFor="let usableVariable of usableVariables; let last = last" class="p-inline">
                    <p class="p-inline" (click)="addAnswerVariable(usableVariable, value.id)">{{ usableVariable }}</p>
                    <div *ngIf="!last" class="p-inline">
                      <p class="p-inline p-display">, </p>
                    </div>
                  </div>
                </span>
              </div>
              <div class="row col-12 p-0 m-0" *ngIf="formContent.value.value_type && formContent.value.value_type == 2">
                <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
                  <label class="d-inline-block">{{ 'Image Table' | translate }}: <span class="text-danger">*</span></label>
                  <span>
                    <button class="btn btn-brand btn-icon-sm mr-2" (click)="addImages(value.id)" [disabled]="buttonLoading || loading"><i [class]="buttonLoading || loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-plus'"></i>Add Content</button>
                    <div class="dropdown dropdown-inline">
                      <ng-container *ngIf="canCreateFooterVIPContentSettings && canEditFooterVIPContentSettings">
                        <kt-sync-locale-button
                          [checkupdate] = "checkupdate()"
                          [localeList] = "dropdown.locales"
                          [valueType] = "formContent.value.value_type"
                          [settings_locale_id_from]="value.id"
                          (localesSynced)="onLocalesSynced($event)">
                        </kt-sync-locale-button>
                      </ng-container>
                    </div>
                  </span>
                </div>

                <div class="kt-section col-12 mb-0">
                  <div class="kt-section__content">
                      <div class="table-responsive">
                        <table class="table table-hover table-striped sub-table">
                          <thead>
                              <tr>
                                  <th class="custom-th">Image <span class="text-danger">*</span></th>
                                  <th class="custom-th">Image Label</th>
                                  <th class="custom-th">Image Text</th>
                                  <th class="custom-th">Image Position</th>
                                  <th class="custom-th">Actions</th>
                              </tr>
                          </thead>
                          <tbody>
                            <ng-container formArrayName="images">
                              <ng-container *ngFor="let row of imagesList(value.id).controls; index as i" [formGroupName]="i">
                                <tr>
                                  <td class="align-content-center custom-th">
                                    <label class="btn btn-default btn-sm btn-upload upload-icon border-danger" *ngIf="getMediaSource(value.id,i) == null && !isImageUploading">
                                      <img src="assets/img/upload-icon.png">
                                      <span>{{ 'Upload' }}</span>
                                      <input class="form-control" type="file" hidden accept="image/*"
                                      (change)="onUploadFile($event,value.id,i)" [disabled]="imageLoading">
                                    </label>
                                    <div class="spinner-wrapper" *ngIf="isImageUploading && isImageUploadingLocale == value.id && isImageUploadingIndex == i">
                                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                                    </div>
                                    <div *ngIf="getMediaSource(value.id,i) != null">
                                      <div class="image-upload">
                                        <div class="image-wrapper">
                                          <img *ngIf="!isImageUploading" class="fas fa-trash position-absolute m-2 trash-icon" src="assets/img/trash-icon.png" (click)="onRemoveFile(value.id,i)">
                                          <img class="img-fluid preview-image" alt="Image" [src]="getMediaSource(value.id,i)" />
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="align-content-center custom-th">
                                    <input type="text" formControlName="image_lable" class="form-control">
                                  </td>
                                  <td class="align-content-center custom-th">
                                    <input type="text" formControlName="image_text" class="form-control">
                                  </td>
                                  <td class="align-content-center custom-th">
                                    <input type="number" min="1" (wheel)="false" step="1" formControlName="image_position" class="form-control" [ngClass]="{'is-invalid': row.image_position != null }">
                                  </td>
                                  <td class="align-content-center custom-th">
                                    <button  matTooltip="Dismiss" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="removeImages(value.id,i)"><i class="fas fa-times" style="color: red"></i></button>
                                  </td>
                                </tr>
                              </ng-container>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>
                </div>
              </div>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
      </ng-container>
      <div class="modal-footer">
        <table class="info mr-3" *ngIf="mode == 'edit'">
          <tr class="border-bottom-remove">
            <td class="header-hint">
              Updated by: {{ vipFooter.updated_by ? vipFooter.updated_by + '&nbsp;' : '-&nbsp;' }}
            </td>
            <td class="header-hint">
              <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="tooltip-class">{{ vipFooter.updated_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #updatedDateTime>
                {{ vipFooter.updated_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
              </ng-template>
            </td>
          </tr>
          <tr class="border-bottom-remove">
            <td class="header-hint">
              Created by: {{ vipFooter.created_by ? vipFooter.created_by + '&nbsp;' : '-&nbsp;' }}
            </td>
            <td class="header-hint">
              <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="tooltip-class">{{ vipFooter.created_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #createdDateTime>
                {{ vipFooter.created_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
              </ng-template>
            </td>
          </tr>
        </table>
        <button type="button" class="btn btn-danger m-0 mr-2" (click)="mode == 'create' ? expandRowCreate('', true) : expandRowEdit('', null, true)">{{ 'Cancel' | translate }}</button>
        <button *ngIf="(mode == 'create' && canCreateFooterVIPContentSettings) || (mode == 'edit' && canEditFooterVIPContentSettings)" type="button" class="btn btn-success m-0" [disabled]="!formContent.valid || checkImageArray()" (click)="onSave()">{{ mode != null && mode == 'create' ? 'Create' : 'Update' | translate }}</button>
      </div>
    </div>
  </form>
  <ng-template #loadingPage>
    <div style="margin: auto;width: fit-content;">
      <div class="spinner-wrapper">
        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
      </div>
    </div>  
  </ng-template>
</ng-template>

<kt-swal-alert [message]="messages$ | async" (confirmed)="reload()"></kt-swal-alert>
