<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <!-- Header -->
        <div class="modal-header">
            <h5 class="modal-title">Quick Link Settings | {{ data.gameProvider.name }} ({{ data.gameProvider.code}})</h5>
            <span class="modal-x-button" (click)="onCloseDialog()"><i class="fa fa-times" aria-hidden="true"></i></span>
        </div>
        <div class="modal-body">
            <div class="spinner-wrapper d-flex justify-content-center mb-4" *ngIf="PromoLoading">
                <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
            </div>
            <div class="tab-container">
                <div class="row">
                    <ng-container *ngIf="!PromoLoading">
                        <div class="col-2">
                            <div class="col-12 form-group row promo-list" (click)="!isSelectedPromo('promotions') && !promoCode_buttonLoading && !TableLoading && !rebates_tableLoading && onSelectPromotion('promotions')" [class.selected]="isSelectedPromo('promotions')">
                                <a matTooltipPosition="after">Promotion Codes</a>
                                    <i class="fas fa-circle-notch fa-spin p-0" *ngIf="promoCode_buttonLoading"></i>
                                    <button matTooltip="Save" class="btn btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onSave(false, 'promotions')" [disabled]="promoCode_changes.length === 0 || rebates_buttonLoading || TableLoading" *ngIf="promoCode_changes.length > 0 && !promoCode_buttonLoading && canEditQuickLinkSetting"><i class="fas fa-save fa-lg"></i></button>
                            </div>
                            <div class="col-12 form-group row promo-list" (click)="!isSelectedPromo('rebates') && !rebates_buttonLoading && !TableLoading && !promoCode_tableLoading && onSelectPromotion('rebates')" [class.selected]="isSelectedPromo('rebates')">
                                <a matTooltipPosition="after">Rebate Settings</a>
                                    <i class="fas fa-circle-notch fa-spin p-0" *ngIf="rebates_buttonLoading"></i>
                                    <button matTooltip="Save" class="btn btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onSave(false, 'rebates')" [disabled]="rebates_changes.length === 0 || promoCode_buttonLoading || TableLoading" *ngIf="rebates_changes.length > 0 && !rebates_buttonLoading && canEditQuickLinkSetting"><i class="fas fa-save fa-lg"></i></button>
                            </div>
                        </div>
                            <div class="col-10">
                                <div class="spinner-wrapper d-flex justify-content-center mb-4" *ngIf="TableLoading">
                                    <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                                </div>
                                <ng-container *ngIf="!TableLoading">
                                    <div class="kt-section">
                                        <div *ngIf="selectedPromo === 'promotions'" class="kt-section__content">
                                            <div class="spinner-wrapper d-flex justify-content-center mb-4" *ngIf="FilterLoading">
                                                <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                                            </div>
                                            <div class="row align-items-center filter-section" *ngIf="!FilterLoading && !promoCode_FilterHide">
                                                <div class="col-xl-12 order-2 order-xl-1 pr-0">
                                                    <form class="row align-items-center" [formGroup]="promoCode_form">
                                                        <div class="col-12 row mb-2 pr-0">
                                                            <div class="col-md-1 kt-form__label col-form-label">
                                                                <label>{{ 'Promotion' | translate }}:</label>
                                                            </div>
                                                            <div class="col-md-5 kt-form__control">
                                                                <!-- Current version of ng-boostrap is ^7.0.0, but the popupClass that enable to add necessary CSS to achieve scrollable dropdown required version 9.1.0 -->
                                                                <!-- Feel free to uncomment it to achieve scrollable dropdown when the version fuilfilled requirement -->
                                                                <!-- <input type="text" class="form-control" formControlName="name" [ngbTypeahead]="searchPromotions" [resultFormatter]="formatPromotion" [inputFormatter]="formatPromotion" (selectItem)="onSelectItem($event.item)" [popupClass]="'custom-typeahead-popup'"> -->
                                                                <input type="text" class="form-control" formControlName="name" [ngbTypeahead]="searchPromotions" [resultFormatter]="formatPromotion" [inputFormatter]="formatPromotion" (selectItem)="onSelectItem($event.item)">
                                                            </div>
                                                            <div class="col-md-1 kt-form__label col-form-label">
                                                                <label>{{ 'Status' | translate }}:</label>
                                                            </div>
                                                            <div class="col-md-5 kt-form__control">
                                                                <select  class="form-control" formControlName="status">
                                                                    <option value="all">{{ 'All' | translate }}</option>
                                                                    <ng-container *ngFor="let value of dropdown.statuses; let i = index;">
                                                                        <option [value]="i" [selected]="value === 'Active'">{{ value | translate }}</option>
                                                                    </ng-container>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 row mb-2 pr-0">
                                                            <div class="col-md-1 kt-form__label col-form-label">
                                                                <label>{{ 'Currency' | translate }}:</label>
                                                            </div>
                                                            <div class="col-md-5 kt-form__control">
                                                                <kt-dropdown-wo-lazyload #currencySelect [form]='promoCode_form' [dropdownList]='currencyDropdownList'
                                                                [dropdownSettings]='currencyDropdownSettings' [formName]="'currency_id'"
                                                                [selectionAttributes]="'id'" [selectedItems]='promoCode_currencySelectedItems' (selectedItemsChanged)="onChangeCurrency(currencySelect.selectedItems)">
                                                                </kt-dropdown-wo-lazyload>
                                                            </div>
                                                            <div class="col-md-1 kt-form__label col-form-label">
                                                                <label>{{ 'Game Category' | translate }}</label>
                                                            </div>
                                                            <div class="col-md-5 kt-form__control">
                                                                <kt-dropdown-wo-lazyload #categoriesSelect [form]='promoCode_form' [dropdownList]='categoryDropdownList'
                                                                [dropdownSettings]='categoryDropdownSettings' [formName]="'category_id'"
                                                                [selectionAttributes]="'id'" [selectedItems]='promoCode_categorySelectedItems' (selectedItemsChanged)="onChangeCategories(categoriesSelect.selectedItems)">
                                                                </kt-dropdown-wo-lazyload>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 row mb-2 pr-0">
                                                            <div class="col-md-1 kt-form__label col-form-label">
                                                                <label>{{ 'Target Type' | translate }}:</label>
                                                            </div>
                                                            <div class="col-md-5 kt-form__control">
                                                                <select formControlName="target_type" class="form-control">
                                                                    <option value="all">{{ 'All' | translate }}</option>
                                                                    <option [value]="row.id" *ngFor="let row of dropdown.supported_target_type">
                                                                        {{ row.name === 'Winloss' ? 'Winover' : row.name | titlecase | translate }}
                                                                    </option>
                                                                </select>
                                                                <span style="color: red" *ngIf="data.gameProvider.supported_target_type === 2">* This game provider only support <b>turnover</b> promotion</span>
                                                                <span style="color: red" *ngIf="data.gameProvider.supported_target_type === 3">* This game provider only support <b>winover</b> promotion</span>
                                                            </div>
                                                            <div class="col-md-1 kt-form__label col-form-label">
                                                                <label>{{ 'Bonus Type' | translate }}:</label>
                                                            </div>
                                                            <div class="col-md-5 kt-form__control">
                                                                <select formControlName="promo_type" class="form-control">
                                                                    <option value="all">{{ 'All' | translate }}</option>
                                                                    <option [value]="row.id" *ngFor="let row of dropdown.promoTypes | async">
                                                                        {{ row.name | titlecase | translate }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                                                <div class="dropdown dropdown-inline">
                                                    <kt-search-button [loading]="promoCode_searchBtnLoading" [disabledButton]="promoCode_loading" (search)="onSubmit('promotions', false, true)" *ngIf="!promoCode_FilterHide"></kt-search-button>
                                                    <kt-clear-button [loading]="promoCode_clearBtnLoading" [disabledButton]="promoCode_loading" (clear)="onClear()" *ngIf="!promoCode_FilterHide"></kt-clear-button>
                                                </div>
                                                <div class="dropdown dropdown-inline">
                                                    <button class="btn btn-filter btn-icon-sm" (click)="onHideFilter()"><i class="fas fa-light fa-filter"></i>{{ promoCode_FilterHide? 'Show' : 'Hide' | translate }}</button>
                                                </div>
                                            </div>
                                            <div class="table-responsive" [ngStyle]="{'max-height.px': tableMaxHeight}" (scroll)="onScroll($event)">
                                                <table class="table table-bordered table-hover table-striped table-sortable" *ngIf="!promoCode_tableLoading">
                                                    <thead>    
                                                        <tr class="stickyth">
                                                            <th colspan="10" style="background: #D0D4F5;">
                                                                    {{ AssignedPromotion?.length }} / {{ promotion?.length }} item(s) selected
                                                            </th>
                                                        </tr>
                                                        <th class="text-center stickyth"><input class="form-checkbox" type="checkbox" [disabled]="promoCode_loading || promoCode_searchBtnLoading || promotion.length == 0" [(ngModel)]="promoCode_toggleAll" [ngModelOptions]="{standalone: true}" (click)="checkboxToggleAll()"></th>
                                                        <th class="text-right stickyth sort-enabled" [ngClass]="getSortClass('id')" (click)="onsortColumn('id')">ID</th>
                                                        <th class="text-center stickyth sort-enabled" [ngClass]="getSortClass('category')" (click)="onsortColumn('category')">Category</th>
                                                        <th class="text-left stickyth sort-enabled" [ngClass]="getSortClass('code')" (click)="onsortColumn('code')">Code</th>
                                                        <th class="text-left stickyth sort-enabled" [ngClass]="getSortClass('name')" (click)="onsortColumn('name')">Promo Name</th>
                                                        <th class="text-center stickyth sort-enabled" [ngClass]="getSortClass('currencies')" (click)="onsortColumn('currencies')">Currencies</th>
                                                        <th class="text-left stickyth sort-enabled" [ngClass]="getSortClass('promo_type')" (click)="onsortColumn('promo_type')">Promotion Type</th>
                                                        <th class="text-left stickyth sort-enabled" [ngClass]="getSortClass('target_type')" (click)="onsortColumn('target_type')">Target Type</th>
                                                        <th class="text-center stickyth sort-enabled" [ngClass]="getSortClass('status')" (click)="onsortColumn('status')">Status</th>
                                                        <th class="text-left stickyth sort-enabled" [ngClass]="getSortClass('updated_at')" (click)="onsortColumn('updated_at')">Updated By</th>
                                                    </thead>
                                                    <tbody>
                                                            <tr *ngFor="let row of promotion">
                                                                <td class="text-center" [formGroup]="promoCode_checkboxForm"><input class="form-checkbox" type="checkbox" [formControlName]="row.id"></td>
                                                                <td class="text-right">{{row.id}}</td>
                                                                <td class="text-center">{{ row.category }}</td>
                                                                <td class="text-left">{{ row.code }}</td>
                                                                <td class="text-left">{{ row.name }}</td>
                                                                <td class="text-center">{{ row.currencies }}</td>
                                                                <td class="text-left">{{ row.bonus_type }}</td>
                                                                <td class="text-left" [formGroup]="promoCode_targetTypeForm">
                                                                    <select [formControlName]="row.id" [ngClass]="{'error': promoCode_targetTypeForm.get('' + row.id).enabled && promoCode_targetTypeForm.get('' + row.id).value === null}">
                                                                        <option value="" disabled [selected]="promoCode_targetTypeForm.get('' + row.id).value === null">Please Select</option>
                                                                        <option *ngFor="let targetType of dropdown.supported_target_type"
                                                                                [ngValue]="targetType.id">
                                                                          {{ targetType.name }}
                                                                        </option>
                                                                    </select>
                                                                </td>
                                                                <td class="text-center">
                                                                    <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ promoCode_status[row.status] | translate }}</span>
                                                                </td>
                                                                <td class="text-left">
                                                                    {{ row.updated_by ? row.updated_by : 'System' }} <br />
                                                                    <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                                                    <ng-template #updatedDateTime>
                                                                        {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                                                    </ng-template>
                                                                </td>
                                                            </tr>
                                                    </tbody>
                                                </table>
                                                <div *ngIf="promoCode_loading" style="text-align: center;">Loading more...</div><br>
                                                <div class="spinner-wrapper d-flex justify-content-center mb-4" *ngIf="promoCode_loading || promoCode_tableLoading">
                                                    <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                                                </div>
                                                <div *ngIf="!promoCode_loading && !promoCode_tableLoading" style="text-align: center;">No more data available</div>
                                            </div>
                                        </div>
                                        <div *ngIf="selectedPromo === 'rebates'" class="kt-section__content">
                                            <div class="spinner-wrapper d-flex justify-content-center mb-4" *ngIf="FilterLoading">
                                                <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                                            </div>
                                            <div class="row align-items-center filter-section" *ngIf="!FilterLoading && !rebates_FilterHide">
                                                <div class="col-xl-12 order-2 order-xl-1 pr-0">
                                                    <form class="row align-items-center" [formGroup]="rebates_form">
                                                        <div class="col-12 row mb-2 pr-0">
                                                            <div class="col-md-1 kt-form__label col-form-label">
                                                                <label>{{ 'Currencies' | translate }}:</label>
                                                            </div>
                                                            <div class="col-md-5 kt-form__control">
                                                                <kt-dropdown-wo-lazyload #currencySelect [form]='rebates_form' [dropdownList]='currencyDropdownList'
                                                                [dropdownSettings]='currencyDropdownSettings' [formName]="'currency_id'"
                                                                [selectionAttributes]="'id'" [selectedItems]='rebates_currencySelectedItems' (selectedItemsChanged)="onChangeCurrency(currencySelect.selectedItems)">
                                                                </kt-dropdown-wo-lazyload>
                                                            </div>
                                                            <div class="col-md-1 kt-form__label col-form-label">
                                                                <label>{{ 'Game Category' | translate }}</label>
                                                            </div>
                                                            <div class="col-md-5 kt-form__control">
                                                                <kt-dropdown-wo-lazyload #categoriesSelect [form]='rebates_form' [dropdownList]='categoryDropdownList'
                                                                [dropdownSettings]='categoryDropdownSettings' [formName]="'category_id'"
                                                                [selectionAttributes]="'id'" [selectedItems]='rebates_categorySelectedItems' (selectedItemsChanged)="onChangeCategories(categoriesSelect.selectedItems)">
                                                                </kt-dropdown-wo-lazyload>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 row mb-2 pr-0">
                                                            <div class="col-md-1 kt-form__label col-form-label">
                                                                <label>{{ 'Rebate Name' | translate }}:</label>
                                                            </div>
                                                            <div class="col-md-5 kt-form__control">
                                                                <!-- Current version of ng-boostrap is ^7.0.0, but the popupClass that enable to add necessary CSS to achieve scrollable dropdown required version 9.1.0 -->
                                                                <!-- Feel free to uncomment it to achieve scrollable dropdown when the version fuilfilled requirement -->
                                                                <!-- <input type="text" class="form-control" formControlName="name" [ngbTypeahead]="searchRebates" [resultFormatter]="formatRebates" [inputFormatter]="formatRebates" [popupClass]="'custom-typeahead-popup'"> -->
                                                                <input type="text" class="form-control" formControlName="name" [ngbTypeahead]="searchRebates" [resultFormatter]="formatRebates" [inputFormatter]="formatRebates">
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                                                <div class="dropdown dropdown-inline">
                                                    <kt-search-button [loading]="rebates_searchBtnLoading" [disabledButton]="rebates_loading" (search)="onSubmit('rebates', false, true)" *ngIf="!rebates_FilterHide"></kt-search-button>
                                                    <kt-clear-button [loading]="rebates_clearBtnLoading" [disabledButton]="rebates_loading" (clear)="onClear()" *ngIf="!rebates_FilterHide"></kt-clear-button>
                                                </div>
                                                <div class="dropdown dropdown-inline">
                                                    <button class="btn btn-filter btn-icon-sm" (click)="onHideFilter()"><i class="fas fa-light fa-filter"></i>{{ rebates_FilterHide? 'Show' : 'Hide' | translate }}</button>
                                                </div>
                                            </div>
                                            <div class="table-responsive" [ngStyle]="{'max-height.px': tableMaxHeight}" (scroll)="onScroll($event)">
                                                <table class="table table-bordered table-hover table-striped table-sortable" *ngIf="!rebates_tableLoading">
                                                    <thead>
                                                        <tr class="stickyth"> 
                                                            <th colspan="11" style="background: #D0D4F5;">
                                                                    {{ AssignedRebate?.length }} / {{ rebates?.length }} item(s) selected
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <thead>
                                                        <th class="text-center stickyth"><input class="form-checkbox" type="checkbox" [disabled]="rebates_loading || rebates_searchBtnLoading || rebates.length == 0" [(ngModel)]="rebates_toggleAll" [ngModelOptions]="{standalone: true}" (click)="checkboxToggleAll()"></th>
                                                        <th class="text-right stickyth sort-enabled" [ngClass]="getSortClass('id')" (click)="onsortColumn('id')">ID</th>
                                                        <th class="text-center stickyth sort-enabled" [ngClass]="getSortClass('category_names')" (click)="onsortColumn('category_names')">Category</th>
                                                        <th class="text-left stickyth sort-enabled" [ngClass]="getSortClass('name')" (click)="onsortColumn('name')">Rebate Name</th>
                                                        <th class="text-left stickyth sort-enabled" [ngClass]="getSortClass('member_group_name')" (click)="onsortColumn('member_group_name')">Member Group</th>
                                                        <th class="text-center stickyth sort-enabled" [ngClass]="getSortClass('currency_names')" (click)="onsortColumn('currency_names')">Currency</th>
                                                        <th class="text-right stickyth sort-enabled" [ngClass]="getSortClass('percentage')" (click)="onsortColumn('percentage')">Rebate Percentage</th>
                                                        <th class="text-right stickyth sort-enabled" [ngClass]="getSortClass('min_rebate_limit')" (click)="onsortColumn('min_rebate_limit')">Min Rebate Limit</th>
                                                        <th class="text-right stickyth sort-enabled" [ngClass]="getSortClass('max_rebate_limit')" (click)="onsortColumn('max_rebate_limit')">Max Rebate Limit</th>
                                                        <th class="text-left stickyth sort-enabled" [ngClass]="getSortClass('updated_at')" (click)="onsortColumn('updated_at')">Updated By</th>
                                                    </thead>
                                                    <tbody>
                                                            <tr *ngFor="let row of rebates">
                                                                <td class="text-center" [formGroup]="rebates_checkboxForm"><input class="form-checkbox" type="checkbox" [formControlName]="row.id"></td>
                                                                <td class="text-right">{{row.id}}</td>
                                                                <td class="text-center">{{ row.category_names }}</td>
                                                                <td class="text-left">{{ row.name }}</td>
                                                                <td class="text-left">{{ row.member_group_name ? row.member_group_name : 'Not available' }}</td>
                                                                <td class="text-center">{{ row.currency_names }}</td>
                                                                <td class="text-right">{{ row.percentage }}</td>
                                                                <td class="text-right">{{ row.min_rebate_limit}}</td>
                                                                <td class="text-right">{{ row.max_rebate_limit}}</td>
                                                                <td class="text-left">
                                                                    {{ row.updated_by_username ? row.updated_by_username : 'System' }} <br />
                                                                    <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                                                    <ng-template #updatedDateTime>
                                                                        {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                                                    </ng-template>
                                                                </td>
                                                            </tr>
                                                    </tbody>
                                                </table>
                                                <div *ngIf="rebates_loading" style="text-align: center;">Loading more...</div><br>
                                                <div class="spinner-wrapper d-flex justify-content-center mb-4" *ngIf="rebates_loading || rebates_tableLoading">
                                                    <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                                                </div>
                                                <div *ngIf="!rebates_loading && !rebates_tableLoading" style="text-align: center;">No more data available</div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="modal-footer" *ngIf="!TableLoading && !FilterLoading">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()" [disabled]="buttonLoading"><i class="fas fa-ban"></i>{{ 'Close' | translate }}</button>
            <button *ngIf="canEditQuickLinkSetting" class="btn btn-brand" (click)="onSave(true)" [disabled]="(promoCode_changes.length === 0 && rebates_changes.length === 0) || promoCode_buttonLoading || rebates_buttonLoading"><i [class]="buttonLoading ? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>Save All</button>
        </div>
    </div>
</div>