import { HttpClient } from "@angular/common/http";
import { AffiliateMember } from "@core/models/affiliate-member.model";
import { AllAffiliates } from "@core/models/all-affiliates.model";
import { ApiResponse } from "@core/models/api-response.model";
import { Pagination } from "@core/models/pagination.model";
import { TestFirePostbackLogs } from "@core/models/test-fire-postback-logs.model";
import { Observable, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { AesService } from '@core/services/aes.service';

export class AllAffiliatesDataService {

    pagination: Pagination;
    messages$ = new Subject<any[]>();

    constructor(
        private http: HttpClient,
        private aesService: AesService,
    ) { }

    getWithQuery(pageParam?: string): Observable<AllAffiliates[]> {
        return this.http.get<ApiResponse>(`/affiliate${pageParam}`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }

    getById(id: number): Observable<AllAffiliates[]> {
        return this.http.get<ApiResponse>(`/affiliate/${id}`).pipe(
            map(res => res.data.rows)
        );
    }

    getRemarks(params: string): Observable<any> {
        return this.http.get<ApiResponse>(`/affiliate/remarks/${params}`).pipe(
          tap(res => this.paginationInit(res)),
          map(res => res.data)
        );
      }

    getAffiliates(currency_id?: number, affiliate_id?: number) {
        const params = [];
        let queryString = '';

        if (currency_id !== null && currency_id !== undefined) {
            params.push(`settings_currency_id=${currency_id}`);
        }

        if (affiliate_id !== null && affiliate_id !== undefined) {
            params.push(`affiliate_id=${affiliate_id}`);
        }

        if (params.length > 0) {
           queryString = '?' + params.join('&');
        }

        return this.http.get<ApiResponse>(`/affiliatedropdown${queryString}&paginate=false`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }

    exportAllAffiliates(pageParam) {
      return this.http.get<ApiResponse>(`/affiliate/export${pageParam}`).pipe(
        tap(res => this.messages$.next(res.message))
      );
    }

    add(affiliate: AllAffiliates) {
        // encrypt the raw password before sending to api
        affiliate.password = this.aesService.encryptWithReqSignKey(affiliate.password);
        affiliate.confirm_password = this.aesService.encryptWithReqSignKey(affiliate.confirm_password);
        return this.http.post<ApiResponse>('/affiliate', affiliate).pipe(
            tap(res => this.messages$.next(res.message))
        )
    }

    update(id: number, affiliate: AllAffiliates) {
        // encrypt the raw password before sending to api
        if( affiliate.password ) {
            affiliate.password = this.aesService.encryptWithReqSignKey(affiliate.password);
        }
        
        return this.http.put<ApiResponse>(`/affiliate/${id}`, affiliate).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    suspend(data: any) {
        return this.http.put<ApiResponse>(`/affiliate/statusupdate`, data).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    changePassword(data: any) {
        // encrypt the raw password before sending to api
        if(data.password) {
            data.password = this.aesService.encryptWithReqSignKey(data.password);
        }
        return this.http.put<ApiResponse>(`/affiliate/changepassword`, data).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    adjustGroup(data: any) {
        return this.http.put<ApiResponse>(`/affiliate/changegroup`, data).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    getInfo(pageParam: string): Observable<AllAffiliates[]> {
        return this.http.get<ApiResponse>(`/affiliate/info${pageParam}`).pipe(
            map(res => res.data.rows)
        );
    }

    getCommInfo(pageParam: string): Observable<AllAffiliates[]> {
        return this.http.get<ApiResponse>(`/affiliate/comminfo${pageParam}`).pipe(
            map(res => res.data.rows)
        );
    }

    getMembers(pageParam: string): Observable<AffiliateMember[]> {
        return this.http.get<ApiResponse>(`/affiliate/member${pageParam}`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }

    getProviderSummary(pageParam: string): Observable<AllAffiliates[]> {
        return this.http.get<ApiResponse>(`/affiliate/providersummary${pageParam}`).pipe(
            map(res => res.data.rows)
        );
    }

    shadowLogin(id) {
        return this.http.get<ApiResponse>(`/affiliate/shadowLogin?affiliate_id=${id}`).pipe(
            map(res => res.data.rows.login_url)
        );
    }

    testPostback(data: { affiliate_id: number, url: string, postback_domain: string }) {

        return this.http.post<ApiResponse>(`/affiliate/testpostback`, data).pipe(
            map(res => res.message)
        );
    }

    getLogsWithQuery(pageParam: string): Observable<TestFirePostbackLogs[]> {
      return this.http.get<ApiResponse>(`/affiliate/testfirepostbacklog${pageParam}`).pipe(
          tap(res => this.paginationInit(res)),
          map(res => res.data.rows)
      );
    }

    exportTestFireLog(pageParam) {
      return this.http.get<ApiResponse>(`/affiliate/exporttestfirepostbacklog${pageParam}`).pipe(
        tap(res => this.messages$.next(res.message))
      );
    }

    getAffiliatePostbackVariables() {
      return this.http.get<ApiResponse>(`/affiliatepostbackvariables`).pipe(
        map(res => res.data)
      );
    }

    private paginationInit(res: any) {
        if (res) {
            this.pagination = res.data.paginations;
        }
    }

}
