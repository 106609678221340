import { trigger, state, style, transition, animate } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MessageTemplateEditComponent } from '../dialogs/message-template-edit.component';
import { MessageTemplateEntityService } from '../services/message-template-entity.service';
import { MessageTemplateDataService } from '../services/message-template-data.service';
import { map, exhaustMap, tap } from 'rxjs/operators';
import { MessageTemplate } from '@core/models/message-template.model';
import { Observable, of, Subscription } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppPermissionService } from '@core/services/app-permission.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import * as moment from 'moment-timezone';
import { EventEmitterService } from '@core/services/event-emitter.service';

@Component({
  selector: 'app-message-template-general',
  templateUrl: './message-template-general.component.html',
  styleUrls: ['./message-template-general.component.scss'],
  animations: [
    trigger('panelState', [
      state('closed', style({ height: '80px', overflow: 'hidden' })),
      state('open', style({ height: '*' })),
      transition('closed <=> open', animate('300ms ease-in-out'))
    ])
  ]
})
export class MessageTemplateGeneralComponent implements OnInit, OnDestroy {

  form: FormGroup;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';

  messageTemplates$: Observable<MessageTemplate[]>;
  dropdown = {
    statuses: [
      {
        name: 'Active', value: 1, checked: true
      },
      {
        name: 'Inactive', value: 0, checked: true
      },
    ],
    locales: [],
    perPage: this.dropdownHttpService.perPage,
    type: this.dropdownHttpService.messageTemplateType,
    section: this.dropdownHttpService.messageTemplateSection(),
  };
  searchLocale = [];
  searchType = [1, 2, 3];
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;
  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  searchStatus = [0, 1];
  localeDropdownList = [];
  localeSelectedItems = [];
  localeDropdownSettings = {
    singleSelection: false,
    labelKey: 'code',
    enableSearchFilter: true,
  }
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  // permissions
  canCreateMessageTemplate: boolean;
  canViewMessageTemplate: boolean;
  canEditMessageTemplate: boolean;
  canDuplicateMessageTemplate: boolean;

  private localesSub = new Subscription();
  private subscription = new Subscription();
  private refreshSubcription = new Subscription();
  private subscriptions = new Subscription();
  constructor(
    private dropdownHttpService: DropdownHttpService,
    private messageTemplateDataService: MessageTemplateDataService,
    private messageTemplateEntityService: MessageTemplateEntityService,
    public loadingBar: LoadingBarService,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    private appPermissionService: AppPermissionService,
    private transactionHttpService: TransactionHttpService,
    private eventEmitterService: EventEmitterService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.pagination = this.messageTemplateDataService.pagination;
    this.setLocales();
    this.ranges = this.transactionHttpService.ranges;
    this.dropdownHttpService.locales.subscribe(res => {
      this.localeDropdownList = res;
    });

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateMessageTemplate = appPermissions.create_message_template;
      this.canViewMessageTemplate = appPermissions.view_message_template;
      this.canEditMessageTemplate = appPermissions.edit_message_template;
      this.canDuplicateMessageTemplate = appPermissions.duplicate_message_template;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
    this.localesSub.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.messageTemplates$ = this.messageTemplateEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.messageTemplateDataService.pagination;
        this.loading = false;
        this.dataLength = res.length;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        const localeCheckboxParams = this.generateCheckboxParams('locale');
        const typeCheckboxParams = this.generateCheckboxParams('type');
        const statusParams = this.generateStatusParams();
        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = paramsRef.replace(localeCheckboxParams.oldCheckboxParams, localeCheckboxParams.newCheckboxParams);
        this.params = this.params.replace(typeCheckboxParams.oldCheckboxParams, typeCheckboxParams.newCheckboxParams);
        this.params = this.params.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
        this.loadingBar.start();
        return this.messageTemplates$ = this.messageTemplateEntityService.getWithQuery(`?${this.params}&perPage=${this.pageSize}`).pipe(
          tap(res => {
            this.page = 1;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.messageTemplateDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMerchantAccountSearch();
    this.searchType = [1, 2, 3];
    this.searchLocale = [];
    this.searchStatus = [0, 1];
    this.dropdown.type.forEach(val => {
      val.checked = true;
    });
    this.dropdown.locales.forEach(val => {
      this.searchLocale.push(val.id);
    });
    this.dropdown.statuses.forEach((val) => {
      val.checked = true
    });
    this.form.patchValue({
      code: null,
      message: null,
      datetime_type: 'created_at',
      start_date: null,
      end_date: null,
      defaultDate: null,
      section: 'all',
      type: this.searchType,
      status: this.searchStatus,
      locale: this.searchLocale
    })
    this.onSubmit(true);
  }

  onCheckBoxFilter(event: any, id: number, type: string, ind?: number) {
    if (type === 'type') {
      const position = this.searchType.indexOf(id);
      if (event.target.checked) {
        this.searchType.push(id);
        this.dropdown.type[ind].checked = true;
      } else {
        this.searchType.splice(position, 1);
        this.dropdown.type[ind].checked = false;
      }
      this.form.patchValue({ type: this.searchType });
    }else if (type === 'status'){
      const position = this.searchStatus.indexOf(id);
      if (event.target.checked) {
        this.searchStatus.push(id);
        this.dropdown.statuses[ind].checked = true;
      } else {
        this.searchStatus.splice(position, 1);
        this.dropdown.statuses[ind].checked = false;
      }
      this.form.patchValue({ status: this.searchStatus });
    }
  }

  onOpenDialog(type: string, row?: any) {
    if (row) {
      this.messageTemplateDataService.getById(row.id).subscribe(res => {
        let dataMode = type == 'duplicate' || type == 'edit' ? 'edit' : 'create';
        this.dropdownHttpService.messageTemplateSection(dataMode).subscribe(res1 => {
          this.openDialogBy(MessageTemplateEditComponent, { messageTemplate: res, mode: type, section: res1});
        });
        
      });
    } else {
      this.dropdownHttpService.messageTemplateSection('create').subscribe(res => {
        this.openDialogBy(MessageTemplateEditComponent, { mode: type, section: res });
      });
    }
  }

  onContent(content) {
    return this.sanitizer.bypassSecurityTrustHtml(content.replace(/\&lt;/g, '<').replace(/\&gt;/g, '>'));
  }

  private openDialogBy(componentRef: any, data?: { messageTemplate?: any, mode?: any, section?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        messageTemplate: data.messageTemplate,
        mode: data.mode,
        section: data.section
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private setLocales() {
    this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.messageTemplateDataService.locales$.next(res);
      res.map(row => {
        this.searchLocale.push(row.id);
        this.dropdown.locales.push({
          id: row.id,
          name: row.name,
          code: row.code,
        });
      });
      this.onSubmit();
    })).subscribe();
  }

  private generateCheckboxParams(type: string) {
    if (type === 'locale') {
      return {
        newCheckboxParams: Object.keys(this.searchLocale).map(key => `locale[${key}]=${this.searchLocale[key]}`).join('&'),
        oldCheckboxParams: 'locale=' + Object.keys(this.searchLocale).map(key => this.searchLocale[key]).join(','),
      };
    } else {
      return {
        newCheckboxParams: Object.keys(this.searchType).map(key => `type[${key}]=${this.searchType[key]}`).join('&'),
        oldCheckboxParams: 'type=' + Object.keys(this.searchType).map(key => this.searchType[key]).join(','),
      };
    }
  }

  private generateStatusParams() {
    return {
      newStatusParams: Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&'),
      oldStatusParams: 'status=' + Object.keys(this.searchStatus).map(key => this.searchStatus[key]).join(','),
    };
  }

  private filterFormFields(formData: any) {
    if (formData) {
      formData.type = formData.type.length > 0 ? formData.type : null;
      formData.locale = formData.locale.length > 0 ? formData.locale : null;
      const fields = {};
      Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate') ? fields[key] = formData[key] : key);
      return fields;
    }
  }

  private formInit() {
    this.form = new FormGroup({
      code: new FormControl(null),
      message: new FormControl(null),
      datetime_type: new FormControl('created_at'),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null),
      section: new FormControl('all'),
      type: new FormControl(this.searchType),
      status: new FormControl(this.searchStatus),
      locale: new FormControl(this.searchLocale)
    });
  }

  onSelectedLocaleChanges(event) {
    this.searchLocale = [];
    event.forEach((locale) => {
      this.searchLocale.push(locale.id);
    });
  }

}
