import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AllSubAffiliateReport } from '@core/models/all-sub-affiliate-report.model';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { AllAffiliateMemberDialogComponent } from './../../../../../shared/all-affiliate-member-dialog/all-affiliate-member-dialog.component';
import { AffiliateInformationComponent } from './../../general/all-affiliates/dialogs/affiliate-information/affiliate-information.component';
import { AllAffiliatesDataService } from './../../general/all-affiliates/services/all-affiliates-data.service';
import { AllSubAffiliateReportDataService } from './services/all-sub-affiliate-report-data.service';
import { AllSubAffiliateReportEntityService } from './services/all-sub-affiliate-report-entity.service';
import { TimezoneDatePipe } from '@core/pipes/timezone-date.pipe';
import { AppPermissionService } from '@core/services/app-permission.service';
import Swal from 'sweetalert2';
import { DateTime } from 'luxon';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'kt-all-sub-affiliate-report',
  templateUrl: './all-sub-affiliate-report.component.html',
  styleUrls: ['./all-sub-affiliate-report.component.scss']
})
export class AllSubAffiliateReportComponent implements OnInit, OnDestroy {

  form: FormGroup;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  from = this.transactionHttpService.getYesterday().from;
  to = this.transactionHttpService.getToday().to;
  pagination: Pagination;

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  timezoneSelectedItems: any;
  selectedTimezone: any;
  timezoneDropdownSettings = {
    singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown clear-all-disabled',
      primaryKey: 'offset',
      labelKey: 'offset',
      noDataLabel: '',
      showCheckbox: false
  };
  params = ``;
  dropdown = {
    timezones: JSON.parse(localStorage.getItem('timezones')),
    perPage: this.dropdownHttpService.perPage
  };

  allSubAff$: Observable<AllSubAffiliateReport[]>;
  ranges = this.transactionHttpService.ranges;

  button_loading = false;
  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  dataLength: number;

  accountTypeDropdownSettings = {};
  accountTypeDropdownList = [];
  accountTypeSelectedItems = [];

  messages$ = this.allSubAffiliateReportDataService.messages$;
  access$ = this.authHttpService.getUserAccess(5, 'All Sub-Affiliates Report');

  private datePickerSubscription = new Subscription();
  private subscription = new Subscription();
  private subscriptions = new Subscription();

  // permissions
  canExportAllSubAffiliatesReport: boolean;
  canViewSubAffiliateInformation: boolean;
  canViewAllSubAffiliateMember: boolean;

  lastBetLogSyncAt = null;

  constructor(
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private loadingBar: LoadingBarService,
    private allSubAffiliateReportDataService: AllSubAffiliateReportDataService,
    private allSubAffiliateReportEntityService: AllSubAffiliateReportEntityService,
    public dialog: MatDialog,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private authHttpService: AuthHttpService,
    private timeZoneDate: TimezoneDatePipe,
    private appPermissionService: AppPermissionService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.selectedTimezone = this.timezone;

    this.accountTypeDropdownList = [
      { id: 0, name: 'Normal'},
      { id: 1, name: 'Dummy'}
    ];

    this.accountTypeSelectedItems = [
      { id: 0, name: 'Normal'},
      { id: 1, name: 'Dummy'}
    ];

    this.accountTypeDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canExportAllSubAffiliatesReport = appPermissions.export_all_sub_affiliates_report;
      this.canViewSubAffiliateInformation = appPermissions.view_sub_affiliate_information;
      this.canViewAllSubAffiliateMember = appPermissions.view_all_sub_affiliate_member;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.allSubAff$ = this.allSubAffiliateReportEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap((res: any) => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.allSubAffiliateReportDataService.pagination;
        this.getLastBetLogSyncAt();
        this.loadingBar.complete();
      }),
      catchError((err) => {
        this.getLastBetLogSyncAt();
        this.loadingBar.complete();
        return throwError(err);
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;

    this.accountTypeSelectedItems = [
      { id: 0, name: 'Normal'},
      { id: 1, name: 'Dummy'}
    ];

    this.form.patchValue({
      parent_username: null,
      start_date_time: this.from,
      end_date_time: this.to,
      timezone: this.timezone,
      defaultDate: {
        startDate: this.from,
        endDate: this.to
      },
      dummy: null
    });
    this.allSubAff$ = undefined;
    this.dataLength = undefined;
    this.pageSize = 30;
    this.page = 1;
    this.pagination = undefined;
    this.clearBtnLoading = false;
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onSubmit() {
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        delete (data['timezone']);

        if (data['dummy']) {
          if (data['dummy'].length == 2) {
            delete (data['dummy']);
          }
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        this.loading = true;
        return this.allSubAff$ = this.allSubAffiliateReportEntityService.getWithQuery(`?${this.params}&perPage=${this.pageSize}`).pipe(
          tap((res: any) => {
            this.page = 1;
            this.dataLength = res.length;
            this.pagination = this.allSubAffiliateReportDataService.pagination;
            this.loadingBar.complete();
            this.onSearch = true;
            this.loading = false;
            this.getLastBetLogSyncAt();
          }),
          catchError((err) => {
            this.loading = false;
            this.loadingBar.complete();
            this.getLastBetLogSyncAt();
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  onExport() {
    this.button_loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        if (data['dummy']) {
          if (data['dummy'].length == 2) {
            delete (data['dummy']);
          }
        }

        data['paginate'] = false;

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start();

        return this.allSubAffiliateReportDataService.exportReport(`?${parameters}`).pipe(
          tap(res => {
            this.messages$ = this.allSubAffiliateReportDataService.messages$;
            this.loadingBar.complete();
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.button_loading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  onViewInfo(mode: string, row?: any) {
    if (!this.canViewSubAffiliateInformation) {
      Swal.fire('Error', 'You do not have permission to view sub-affiliate information', 'error');
      return;
    }

    const affiliate = this.allAffiliatesDataService.getById(row.affiliate_id);
    this.subscription = affiliate.pipe(
      tap((res: any) => {
        this.dialog.open(AffiliateInformationComponent, {
          width: '1500px',
          height: '80vh',
          data: {
            affiliate: res,
            mode: mode
          }
        });
      })
    ).subscribe();
  }

  onOpenDialog(subAffiliate: any) {
    if (!this.canViewAllSubAffiliateMember) {
      Swal.fire('Error', 'You do not have permission to view sub-affiliates members', 'error');
      return;
    }

    this.openDialogBy(AllAffiliateMemberDialogComponent, { subAffiliate: subAffiliate });
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private openDialogBy(componentRef: any, data?: { subAffiliate?: any }) {
    this.dialog.open(componentRef, {
      width: '90vw',
      data: {
        subAffiliate: data.subAffiliate,
        params: this.form.value
      }
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && key !== 'key' && key !== 'key_search') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      parent_username: new FormControl(null), // Default
      start_date_time: new FormControl(this.from),
      end_date_time: new FormControl(this.to),
      timezone: new FormControl(this.timezone),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to
      }), // Do not remove: For Clearing The Range
      dummy: new FormControl(null)
    });
  }

  onChangeTimezone(item: any) {
    if (item.length) {
      this.selectedTimezone = item[0]['timezone'];
    }
    else {
      this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.selectedTimezone);
      this.form.patchValue({
        timezone: this.selectedTimezone
      });
    }
  }

  timezoneDateWithTimezone(date: any, timezone: any, format: any) {
    return this.timeZoneDate.transformWithTimezone(date, timezone, format);
  }

  getLastBetLogSyncAt(){
    var userTimeZone = JSON.parse(localStorage.getItem('user_data')).timezone;
    var timeInterved = 5; // 5 minutes 
    const d = new Date();
    let minutes = d.getMinutes();
    const intMinutes =  minutes/timeInterved;
    const roundedMinute = (minutes/timeInterved).toFixed(0);
    var minus;
    if( Number(intMinutes) < Number(roundedMinute) || Number(intMinutes) == Number(roundedMinute)){
      if(Number(roundedMinute) > 0) minus = 1;
      else minus = -1;
    }
    else if( Number(intMinutes) > Number(roundedMinute)){
      minus = 0;
    }
    var  minutesConverted5min = ( Number(roundedMinute) - minus )  * timeInterved;
    var newMinutes;
    if(minutesConverted5min < 10){
      newMinutes = '0'+minutesConverted5min.toString();
    }else{
      newMinutes = minutesConverted5min.toString();
    }

    var timeNow =  DateTime.now().setZone(userTimeZone).toFormat('yyyy-MM-dd HH:'+newMinutes+':00 (ZZ)'); 
    this.lastBetLogSyncAt = timeNow.replace('(', '(GMT ');
  }
}
