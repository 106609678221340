import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MemberType } from '@core/enums/member-type.enum';
import { Status } from '@core/enums/status.enum';
import { TrafficSource } from '@core/enums/traffic-source.enum';
import { AuthData } from '@core/models/auth-data';
import { Dropdown } from '@core/models/dropdown.model';
import { Group } from '@core/models/group.model';
import { Member } from '@core/models/member.model';
import { Pagination } from '@core/models/pagination.model';
import { SpecialPermissions } from '@core/models/special-permissions.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { loggedUser, specialPermissions } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AppState } from '@store/reducers';
import * as moment from 'moment-timezone';
import { DropdownTreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { interval, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, exhaustMap, map, tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { MemberRemarksListComponent } from './../../../../../shared/member-remarks-list/member-remarks-list.component';
import { TransactionWalletDialogComponent } from './../transactions/dialogs/transaction-wallet/transaction-wallet.component';
import { MemberBlacklistDialogComponent } from './dialogs/member-blacklist/member-blacklist.component';
import { MemberDeductDialogComponent } from './dialogs/member-deduct/member-deduct.component';
import { MemberInformationDialogComponent } from './dialogs/member-information/member-information.component';
import { MemberPasswordDialogComponent } from './dialogs/member-password/member-password.component';
import { MemberProfileDialogComponent } from './dialogs/member-profile/member-profile.component';
import { MemberRewardsDialogComponent } from './dialogs/member-rewards/member-rewards.component';
import { MemberLabelComponent } from './dialogs/member-label/member-label.component';
import { MemberTopupDialogComponent } from './dialogs/member-topup/member-topup.component';
import { MemberDataService } from './services/member-data.service';
import { MemberEntityService } from './services/member-entity.service';
import { environment } from '@env/environment';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { MemberPromotionEligibleDialogComponent } from './dialogs/member-promotion-eligible/member-promotion-eligible.component';
import { AppPermissions } from '@core/models/app-permissions.model';
import { AppPermissionService } from '@core/services/app-permission.service';
import { CampaignType } from '@core/enums/campaign-type.enum';
import { MemberLabelRemarkDialogComponent } from './dialogs/member-label-remark/member-label-remark.component';

@Component({
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('textArea', { read: ElementRef }) textArea: ElementRef;
  @ViewChild(DropdownTreeviewComponent, { static: false }) treeView: DropdownTreeviewComponent;
  form: FormGroup;

  readonly TrafficSource = TrafficSource;
  trafficSourcePlaceholder: string = 'Search';

  dropdown = {
    groups: this.dropdownHttpService.groups,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    statuses: this.dropdownHttpService.memberStatuses,
    perPage: this.dropdownHttpService.perPage,
    label: this.dropdownHttpService.memberLabels,
    campaigns: this.dropdownHttpService.campaigns,
    affiliateCampaigns: this.dropdownHttpService.affiliateCampaigns,

    traffic_sources: this.dropdownHttpService.trafficSourceTypes,
    campaigns_type: [
      CampaignType.NORMAL,
      CampaignType.AFFILIATE
    ],
    others: [
      { id: 'ga_username', label: 'Game Username' },
      { id: 'bank_account', label: 'Bank Account' },
      { id: 'registration_link', label: 'Registration Link' },
      { id: 'ip', label: 'IP' }
    ],
  };
  messages$ = this.memberDataService.messages$;
  messages2$ = new Subject<string[]>();
  dropdownSettings = {};
  status = Status;
  memberType = MemberType;
  members$: Observable<Member[]>;

  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  buttonLoading = false;
  ranges = this.transactionHttpService.ranges;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  isAdminUser = JSON.parse(localStorage.getItem('user_data')).is_admin;
  // userPermissionsShadow = this.isAdminUser === 1 ? true : (localStorage.getItem('user_permissions') !== null ? JSON.parse(localStorage.getItem('user_permissions')).shadow_login : true);
  dataLength: number;
  sqsEnable: boolean = environment.sqsEnable;

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '1.1';
  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'name': 'desc',
    'agents_username': 'desc',
    'currency': 'desc',
    'group': 'desc',
    'status': 'desc',
    'registration_created_at': 'desc',
    'last_login': 'desc',
    'affiliate_username': 'desc',
    'source_type': 'desc',
    'updated_by': 'desc'
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  depositTreeview = new TreeviewItem({
    text: 'Deposit',
    value: null,
    children: [
      {
        text: 'Depositor',
        value: 1,
      },
      {
        text: 'Non-Depositor',
        value: 2,
      }
    ]
  });

  accountTypeTreeview = new TreeviewItem({
    text: 'Account Type',
    value: null,
    children: [
      {
        text: 'Normal',
        value: 0,
      },
      {
        text: this.sqsEnable == true ? 'With-Label' : 'Suspicious',
        value: 1,
      },
      {
        text: 'Dummy',
        value: 2,
      }
    ]
  });

  mobileVerificationTreeview = new TreeviewItem({
    text: 'Mobile Verification',
    value: null,
    children: [
      {
        text: 'Verified',
        value: 1,
      },
      {
        text: 'Unverified',
        value: 0,
      }
    ]
  });

  emailVerificationTreeview = new TreeviewItem({
    text: 'Email Verification',
    value: null,
    children: [
      {
        text: 'Verified',
        value: 1,
      },
      {
        text: 'Unverified',
        value: 0,
      }
    ]
  });

  treeviewItems: TreeviewItem[];
  values: number[];
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400
  });

  interactiveInput = {
    disableUsername: false,
    disableName: false,
    disableBankAcc: false,
    disableID: false,
    disableAgent: false,
    disableStatus: false,
    disableCurrency: false,
    disableGroup: false,
    disableContact: false,
    disableDate: false,
    disableAffiliate: false,
  }

  campaignsList: Dropdown[] = [];
  affiliateCampaignsList: Dropdown[] = [];

  // isAdjustWalletAllowed = this.isAdminUser === 1 ? true : (localStorage.getItem('user_permissions') !== null ? JSON.parse(localStorage.getItem('user_permissions')).adjust_wallet : true);
  // isResetPwdAllowed = this.isAdminUser === 1 ? true : (localStorage.getItem('user_permissions') !== null ? JSON.parse(localStorage.getItem('user_permissions')).reset_password : false);

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  private isAdminSubcription = new Subscription();
  memberGroup: Dropdown[];
  private isAdmin: any;

  loggedUserPermission$: Observable<SpecialPermissions>;
  loggedUser$: Observable<AuthData>;

  // permissions
  // allMembersUseNewPermissions: boolean;
  canViewMemberDialog: boolean;
  canCreateMember: boolean;
  canEditMemberDetailsRestricted: boolean;
  canEditMemberDetails: boolean;
  canResetPassword: boolean;
  canUpdateMemberStatus: boolean;
  canAdjustWallet: boolean;
  canAssignRewards: boolean;
  canShadowLogin: boolean;
  canForceLogout: boolean;
  canExportMemberList: boolean;
  canCheckPromotionEligibility: boolean;
  canViewMemberLabelInfo: boolean;
  canViewMemberLabelSettings: boolean;

  currentContact = '';
  selectedContactType = 'Mobile';
  access$ = this.authHttpService.getUserAccess(1, 'All Members');
  userPermissions$ = this.store.pipe(select(specialPermissions));

  dateTimeFilterType = [
    {
      key: 'registration_date',
      label: 'Registration Date'
    },
    {
      key: 'last_login_date',
      label: 'Last Login Date'
    },
    {
      key: 'first_deposit_date',
      label: 'First Deposit Date'
    },
    {
      key: 'first_withdrawal_date',
      label: 'First Withdrawal Date'
    },
    {
      key: 'updated_date',
      label: 'Updated Date'
    }
  ]

  constructor(
    public loadingBar: LoadingBarService,
    private transactionHttpService: TransactionHttpService,
    public dialog: MatDialog,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private memberService: MemberEntityService,
    private memberDataService: MemberDataService,
    private dropdownHttpService: DropdownHttpService,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.treeviewItems = [this.depositTreeview, this.accountTypeTreeview, this.mobileVerificationTreeview, this.emailVerificationTreeview];
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));

    const campaignsSub = this.dropdown.campaigns.subscribe(
      campaigns => {
        this.campaignsList = campaigns;
      }
    );

    const affiliateCampaignsSub = this.dropdown.affiliateCampaigns.subscribe(
      affiliateCampaigns => {
        this.affiliateCampaignsList = affiliateCampaigns;
      }
    );

    this.subscriptions.add(campaignsSub);
    this.subscriptions.add(affiliateCampaignsSub);

    this.formInit();
    this.memberGroupsInit();
    this.pagination = this.memberDataService.pagination;
    this.isAdminInit();
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
    this.loggedUserPermission$ = this.store.pipe(select(specialPermissions));
    this.loggedUser$ = this.store.pipe(select(loggedUser));

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.all_members_view_member_dialog;
      this.canCreateMember = appPermissions.create_new_member;
      this.canEditMemberDetailsRestricted = appPermissions.edit_member_details_restricted;
      this.canEditMemberDetails = appPermissions.edit_member_details;
      this.canResetPassword = appPermissions.reset_password;
      this.canUpdateMemberStatus = appPermissions.update_member_status;
      this.canAdjustWallet = appPermissions.all_members_adjust_wallet;
      this.canAssignRewards = appPermissions.all_members_assign_rewards;
      this.canShadowLogin = appPermissions.shadow_login;
      this.canForceLogout = appPermissions.force_logout;
      this.canExportMemberList = appPermissions.export_member_list;
      this.canCheckPromotionEligibility = appPermissions.all_members_check_promotion_eligibility;
      this.canViewMemberLabelInfo = appPermissions.view_member_info_list;
      this.canViewMemberLabelSettings = appPermissions.view_label_settings_list;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
    this.isAdminSubcription.unsubscribe();
  }

  onTreeviewChange(event: Event) {
    // for deposit
    if (this.treeView.items[0]['internalChecked']) {
      this.form.patchValue({
        'type[deposit_type][]': null
      });
    } else {
      if (this.treeView.items[0]['internalChildren'][0].internalChecked) {
        this.form.patchValue({
          'type[deposit_type][]': this.treeView.items[0]['internalChildren'][0].value
        });
      }

      if (this.treeView.items[0]['internalChildren'][1].internalChecked) {
        this.form.patchValue({
          'type[deposit_type][]': this.treeView.items[0]['internalChildren'][1].value
        });
      }

      if (!this.treeView.items[0]['internalChildren'][0].internalChecked && !this.treeView.items[0]['internalChildren'][1].internalChecked) {
        this.form.patchValue({
          'type[deposit_type][]': null
        });
      }
    }

    // for account type
    if (this.treeView.items[1]['internalChecked']) {
      this.form.patchValue({
        'type[account_type][]': null,
        'type[account_type][1]': null,
      });
    } else {
      if (this.treeView.items[1]['internalChildren'][0].internalChecked) {
        this.form.patchValue({
          'type[account_type][]': this.treeView.items[1]['internalChildren'][0].value,
          'type[account_type][1]': null,
        });
      }

      if (this.treeView.items[1]['internalChildren'][1].internalChecked) {
        this.form.patchValue({
          'type[account_type][]': this.treeView.items[1]['internalChildren'][1].value,
          'type[account_type][1]': null,
        });
      }

      if (this.treeView.items[1]['internalChildren'][2].internalChecked) {
        this.form.patchValue({
          'type[account_type][]': this.treeView.items[1]['internalChildren'][2].value,
          'type[account_type][1]': null,
        });
      }

      if (this.treeView.items[1]['internalChildren'][0].internalChecked && this.treeView.items[1]['internalChildren'][1].internalChecked) {
        this.form.patchValue({
          'type[account_type][]': this.treeView.items[1]['internalChildren'][0].value,
          'type[account_type][1]': this.treeView.items[1]['internalChildren'][1].value,
        });
      }

      if (this.treeView.items[1]['internalChildren'][0].internalChecked && this.treeView.items[1]['internalChildren'][2].internalChecked) {
        this.form.patchValue({
          'type[account_type][]': this.treeView.items[1]['internalChildren'][0].value,
          'type[account_type][1]': this.treeView.items[1]['internalChildren'][2].value,
        });
      }

      if (this.treeView.items[1]['internalChildren'][1].internalChecked && this.treeView.items[1]['internalChildren'][2].internalChecked) {
        this.form.patchValue({
          'type[account_type][]': this.treeView.items[1]['internalChildren'][1].value,
          'type[account_type][1]': this.treeView.items[1]['internalChildren'][2].value,
        });
      }

      if (!this.treeView.items[1]['internalChildren'][0].internalChecked && !this.treeView.items[1]['internalChildren'][1].internalChecked && !this.treeView.items[1]['internalChildren'][2].internalChecked) {
        this.form.patchValue({
          'type[account_type][]': null,
          'type[account_type][1]': null,
        });
      }
    }

    // for mobile
    if (this.treeView.items[2]['internalChecked']) {
      this.form.patchValue({
        'type[mobile_verification][]': null
      });
    } else {
      if (this.treeView.items[2]['internalChildren'][0].internalChecked) {
        this.form.patchValue({
          'type[mobile_verification][]': this.treeView.items[2]['internalChildren'][0].value
        });
      }

      if (this.treeView.items[2]['internalChildren'][1].internalChecked) {
        this.form.patchValue({
          'type[mobile_verification][]': this.treeView.items[2]['internalChildren'][1].value
        });
      }

      if (!this.treeView.items[2]['internalChildren'][0].internalChecked && !this.treeView.items[2]['internalChildren'][1].internalChecked) {
        this.form.patchValue({
          'type[mobile_verification][]': null
        });
      }
    }

    // for email
    if (this.treeView.items[3]['internalChecked']) {
      this.form.patchValue({
        'type[email_verification][]': null
      });
    } else {
      if (this.treeView.items[3]['internalChildren'][0].internalChecked) {
        this.form.patchValue({
          'type[email_verification][]': this.treeView.items[3]['internalChildren'][0].value
        });
      }

      if (this.treeView.items[3]['internalChildren'][1].internalChecked) {
        this.form.patchValue({
          'type[email_verification][]': this.treeView.items[3]['internalChildren'][1].value
        });
      }

      if (!this.treeView.items[3]['internalChildren'][0].internalChecked && !this.treeView.items[3]['internalChildren'][1].internalChecked) {
        this.form.patchValue({
          'type[email_verification][]': null
        });
      }
    }
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onChangeStatus(newStatus: number, memberId: number) {
    this.subscription = this.memberDataService.updateStatus(memberId, newStatus).subscribe(() => {
      this.messages$ = this.memberDataService.messages$;
      this.onSubmit();
    });
  }

  getUrl(site: string) {
    let url = new URL(site);
    return url.host;
  }

  urlIsValid(url: string) {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  }

  onOpenDialog(type: string, memberId?: number) {
    const isAdmin = this.isAdmin;
    if (memberId) {
      if (type === 'member-information') {

        if (!this.canViewMemberDialog) {
          this.messages2$.next(['You do not have permission to view member dialog']);
          return;
        }

        const member = this.memberDataService.getById(memberId, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&additional_info=1`);
        this.subscription = member.pipe(
          tap((res) => {
            if (res) {
              this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' });
            }
          })
        )
          .subscribe();
      } else {
        const member = this.memberService.getByKey(memberId);
        this.subscription = member.pipe(
          tap((res) => {
            if (res) {
              switch (type) {
                case 'resetPassword':
                  this.openDialogBy(MemberPasswordDialogComponent, { member: res });
                  break;
                case 'edit':
                  this.openDialogBy(MemberProfileDialogComponent, { member: res, mode: 'edit', isAdmin });
                  break;
                case 'blacklist':
                  this.openDialogBy(MemberBlacklistDialogComponent, { member });
                  break;
                case 'deduct':
                  this.openDialogBy(MemberDeductDialogComponent, { member });
                  break;
                case 'topup':
                  this.openDialogBy(MemberTopupDialogComponent, { member });
                  break;
                case 'adjust':
                  this.openDialogBy(TransactionWalletDialogComponent, { member: res });
                  break;
                case 'assignRewards':
                  this.openDialogBy(MemberRewardsDialogComponent, { member: res, mode: 'member_reward' });
                  break;
                case 'promoEligible':
                  this.openDialogBy(MemberPromotionEligibleDialogComponent, { member: res, mode: 'promo-eligible' });
                  break;
              }
            }
          })
        )
          .subscribe();
      }
    } else {
      if (type == 'memberLabelRemark') {
        this.openDialogBy(MemberLabelRemarkDialogComponent, { mode: 'member-label-remark' });
      } else {
        this.openDialogBy(MemberProfileDialogComponent, { mode: 'create' });
      }
    }
  }

  onViewMoreRemarks(row: any, sqsEnable?: boolean) {
    if (sqsEnable) {
      this.dialog.open(MemberLabelComponent, {
        width: '1000px',
        data: {
          member: row
        }
      });
    } else {
      this.memberDataService.getRemarksHistory(row.id).subscribe(res => {
        this.dialog.open(MemberRemarksListComponent, {
          width: '800px',
          data: {
            remarks: res
          }
        });
      });
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.members$ = this.memberService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap((res) => {
        this.pagination = this.memberDataService.pagination;
        this.dataLength = res.length;
        this.loading = false;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onExport() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.buttonLoading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        data['paginate'] = false;
        data['timezone'] = this.timezone;

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start();
        const noDeposit = this.form.value.no_deposit ? ' Without Deposit ' : ' ';
        const dateTitle = this.form.get('start_datetime').value !== null ?
          '(' + this.convertDateTime(this.form.get('start_datetime').value) + ' To ' +
          this.convertDateTime(this.form.get('end_datetime').value) + ')' : '';
        const filename = `All Member${noDeposit}Report${dateTitle}.xlsx`;
        return this.memberDataService.export(`?${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.buttonLoading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.buttonLoading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  getMemberGroupName(id: number) {
    if (this.memberGroup) {
      return (this.memberGroup.find(row => row.id === id) as Group)?.name ?? '-';
    }
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  onClear() {
    this.interactiveInput.disableID = false;
    this.interactiveInput.disableStatus = false;
    this.interactiveInput.disableUsername = false;
    this.interactiveInput.disableName = false;
    this.interactiveInput.disableCurrency = false;
    this.interactiveInput.disableBankAcc = false;
    this.interactiveInput.disableGroup = false;
    this.interactiveInput.disableAgent = false;
    this.interactiveInput.disableContact = false;
    this.interactiveInput.disableDate = false;
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.onClearDate();
    this.selectedContactType = 'Mobile';
    this.form.patchValue({
      username: null,
      name: null,
      id: null,
      mobile: null,
      email: null,
      status: 'all',
      group_id: 'all',
      currency_id: 'all',
      bank_account_number: null,
      no_deposit: null,
      suspicious: 'all',
      start_datetime: null,
      end_datetime: null,
      defaultDate: null,
      agent: null,
      affiliate_username: null,
      label: 'all',
      traffic_source_type: '',
      traffic_source: '',
      campaign_type: CampaignType.NORMAL,
      campaign_value: '',
      others: '',
      others_value: '',
      datetime_type: this.dateTimeFilterType[0].key,
      'type[deposit_type][]': null,
      'type[account_type][]': null,
      'type[account_type][1]': null,
      'type[mobile_verification][]': null,
      'type[email_verification][]': null,
    });

    this.form.get('traffic_source').disable();

    // reset sort
    this.sortingSelection = {
      'sort_by': 'id',
      'sort_order': 'desc',
    };

    // reset type dropdown
    this.treeviewItems.forEach(item => {
      item.checked = true; // Check all items
      if (item.children) {
        item.children.forEach(child => child.checked = true);
      }
    });
    this.treeviewItems = [...this.treeviewItems]; // Force change detection for Angular to update the UI

    this.onSubmit(true);
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null ? event.startDate._d : event.startDate,
        end_datetime: event.endDate !== null ? event.endDate._d : event.endDate
      });
    }
  }

  onInteractiveSearchFilter(event: any) {
    let inputName = event.target.getAttribute('formControlName');
    let inputValue = event.target.value;

    if (inputName === 'agent' || inputName === 'affiliate_username') {
      if (inputValue != '') {
        this.interactiveInput.disableBankAcc = true;
        this.interactiveInput.disableID = true;
        this.interactiveInput.disableName = true;
        this.interactiveInput.disableUsername = true;

        this.form.patchValue({
          username: null,
          name: null,
          id: null,
          bank_account_number: null,
        })
      } else {
        this.interactiveInput.disableBankAcc = false;
        this.interactiveInput.disableID = false;
        this.interactiveInput.disableName = false;
        this.interactiveInput.disableUsername = false;
      }
    }

    if (inputName === 'bank_account_number') {
      if (inputValue != '') {
        this.interactiveInput.disableID = true;
        this.interactiveInput.disableStatus = true;
        this.interactiveInput.disableUsername = true;
        this.interactiveInput.disableName = true;
        this.interactiveInput.disableCurrency = true;
        this.interactiveInput.disableGroup = true;
        this.interactiveInput.disableAgent = true;
        this.interactiveInput.disableContact = true;
        this.interactiveInput.disableDate = true;

        (<HTMLInputElement>document.getElementById('contactRefValue')).value = "";

        this.form.patchValue({
          username: null,
          name: null,
          id: null,
          mobile: null,
          email: null,
          status: 'all',
          group_id: 'all',
          currency_id: 'all',
          start_datetime: null,
          end_datetime: null,
          defaultDate: null,
          agent: null
        })
      } else {
        this.interactiveInput.disableID = false;
        this.interactiveInput.disableStatus = false;
        this.interactiveInput.disableUsername = false;
        this.interactiveInput.disableName = false;
        this.interactiveInput.disableCurrency = false;
        this.interactiveInput.disableGroup = false;
        this.interactiveInput.disableAgent = false;
        this.interactiveInput.disableContact = false;
        this.interactiveInput.disableDate = false;
      }
    }

    if (inputName === 'id') {
      if (inputValue != '') {
        this.interactiveInput.disableBankAcc = true;
        this.interactiveInput.disableStatus = true;
        this.interactiveInput.disableUsername = true;
        this.interactiveInput.disableName = true;
        this.interactiveInput.disableCurrency = true;
        this.interactiveInput.disableGroup = true;
        this.interactiveInput.disableAgent = true;
        this.interactiveInput.disableContact = true;
        this.interactiveInput.disableDate = true;

        (<HTMLInputElement>document.getElementById('contactRefValue')).value = "";

        this.form.patchValue({
          username: null,
          name: null,
          mobile: null,
          email: null,
          status: 'all',
          group_id: 'all',
          currency_id: 'all',
          bank_account_number: null,
          start_datetime: null,
          end_datetime: null,
          defaultDate: null,
          agent: null
        })
      } else {
        this.interactiveInput.disableStatus = false;
        this.interactiveInput.disableUsername = false;
        this.interactiveInput.disableName = false;
        this.interactiveInput.disableCurrency = false;
        this.interactiveInput.disableBankAcc = false;
        this.interactiveInput.disableGroup = false;
        this.interactiveInput.disableAgent = false;
        this.interactiveInput.disableContact = false;
        this.interactiveInput.disableDate = false;
      }
    }

    if (inputName === 'username' || inputName === 'name') {
      if (inputValue != '') {
        this.interactiveInput.disableID = true;
        this.interactiveInput.disableAgent = true;
        this.interactiveInput.disableBankAcc = true;

        (<HTMLInputElement>document.getElementById('contactRefValue')).value = "";

        this.form.patchValue({
          id: null,
          agent: null,
          bank_account_number: null
        })
      } else if ((this.form.value.name == '' || this.form.value.name == null) && (this.form.value.username == '' || this.form.value.username == null)) {
        this.interactiveInput.disableID = false;
        this.interactiveInput.disableAgent = false;
        this.interactiveInput.disableBankAcc = false;
      }
    }
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map((formValue) => {
        // Handle campaign value formatting before pass to backend
        if (formValue.campaign_value) {
          if (formValue.campaign_value.code !== undefined && formValue.campaign_value.name !== undefined) {
            formValue.campaign_code = formValue.campaign_value.code;
            formValue.campaign_name = formValue.campaign_value.name;
            delete formValue.campaign_value;
          }
        }
        return this.filterFormFields(formValue);
      }),
      exhaustMap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.members$ = this.memberService.getWithQuery(`?${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.page = 1;
            this.pagination = this.memberDataService.pagination;
            this.loadingBar.complete();
            this.onSearch = true;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
          })
        );
      }),
      catchError((err) => {
        this.loadingBar.complete();
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        throw err;
      })
    ).subscribe();
  }

  onShadowLogin(id: number, usename: string) {
    Swal.fire({
      title: `Shadow login as ${usename}`,
      text: `Your session will expire after 15 minutes`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.memberDataService.shadowLogin(id).subscribe(url => {
          if (url !== null) {
            if (!(url).match(/^https?:\/\//i)) {
              url = 'https://' + url;
            }
            window.open(url, "_blank");
          }
        });
      }
    });
  }

  onForceLogout(id: number, usename: string) {
    Swal.fire({
      title: `Are you sure you want to logout ${usename}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.loadingBar.start();
        this.memberDataService.forceLogout(id).subscribe(() => {
          this.messages$ = this.memberDataService.messages$;
        });
      }
    });
  }

  onContactType(label: string) {
    this.selectedContactType = label;
    this.contactChanges();
    this.cdr.detectChanges();
  }

  onContact(event: Event) {
    this.currentContact = (event.target as HTMLInputElement).value;
    this.contactChanges();
    this.cdr.detectChanges();
  }

  onEllipsis(value: string, maxlength = 9) {
    let result = '';
    if (value.length > maxlength) {
      for (let i = 0; i < (maxlength - 1); i++) {
        result += value.split('')[i];
      }
      result += '...';
    } else {
      result = value;
    }
    return result;
  }

  hasActiveLabel(member) {
    if (typeof member.labels === 'string' && member.labels != '') {
      return true;
    }

    return member.labels?.some(label => label.status == 1);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  getTrafficSourcePlaceholder(sourceType: any): string {
    const type = Number(sourceType);

    if (type === TrafficSource.REFERRAL || 
        type === TrafficSource.AFFILIATE_INTERNAL || 
        type === TrafficSource.AFFILIATE_EXTERNAL
    ) {
        return 'Username or Code';
    } else if (type === TrafficSource.ADS) {
        return 'Code';
    } else if (type === TrafficSource.TELEMARKETER) {
        return 'Username';
    } else {
        return 'Search';
    }
  }

  filterCampaign(term: string): any[] {
    const campaigns = this.form.get('campaign_type').value === CampaignType.NORMAL
      ? this.campaignsList 
      : this.affiliateCampaignsList;

    if (campaigns) {
      return campaigns.filter(
        campaign => campaign.code.toLowerCase().includes(term.toLowerCase()) || 
                   campaign.name.toLowerCase().includes(term.toLowerCase())
      );
    }
    return [];
  }

  searchCampaign = (text$: Observable<string>): Observable<any[]> => {
    return text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(term => term.trim() === '' ? [] : this.filterCampaign(term))
    );
  }

  formatCampaign(campaign: any): string {
    if (!campaign) return '';
    return `${campaign.name} (${campaign.code})`;
  }

  hasSmsSubOption(row, option: string): boolean {
    return row.sms_sub_options.find(smsSubOption => smsSubOption == option);
  }

  public autoGrow() {
    const textArea = this.textArea.nativeElement;
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

  private contactChanges() {
    if (this.selectedContactType !== '' || this.selectedContactType !== null) {

      if (this.selectedContactType === 'Mobile') {
        this.form.patchValue({
          [this.selectedContactType.toLocaleLowerCase()]: this.currentContact,
          email: null,
        });
      }
      if (this.selectedContactType === 'Email') {
        this.form.patchValue({
          mobile: null,
          [this.selectedContactType.toLocaleLowerCase()]: this.currentContact,
        });
      }
    }
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private convertDateTime(dateTime: any) {
    return moment(dateTime).tz(this.timezone, true).utc().format('YYYY-MM-DD');
  }

  private isAdminInit() {
    this.isAdminSubcription = this.store.pipe(
      select(loggedUser),
      map(user => user.is_admin),
      tap(rights => this.isAdmin = rights)
    ).subscribe();
  }

  private memberGroupsInit() {
    this.dropdown.groups.pipe(
      tap(groups => this.memberGroup = groups)
    ).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { member?: any, mode?: any, isAdmin?: boolean }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: 
        data.mode === 'member-information' || data.mode === 'promo-eligible' || data.mode === 'create' || data.mode === 'member-label-remark'
        ? '1500px'
        : data.mode === 'edit' 
        ? '1550px'
        : '800px',
      height: data.mode === 'member-information' || data.mode == 'member-label-remark' ? '80vh' : 'auto',
      data,
      autoFocus: data.mode === 'member-information' ? false : true
    });
    if (data.mode !== 'member-information') {
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true && (data.mode === 'promo-eligible' || data.mode === 'create' || data.mode === 'edit')) {
          this.onViewPageBy(this.page).subscribe();
        }
      });
    }
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      name: new FormControl(null),
      id: new FormControl(null),
      mobile: new FormControl(null),
      email: new FormControl(null),
      status: new FormControl('all'),
      group_id: new FormControl('all'),
      currency_id: new FormControl('all'),
      bank_account_number: new FormControl(null),
      no_deposit: new FormControl(null),
      suspicious: new FormControl('all'),
      datetime_type: new FormControl(this.dateTimeFilterType[0].key),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null),
      agent: new FormControl(null),
      affiliate_username: new FormControl(null),
      label: new FormControl('all'),
      'type[deposit_type][]': new FormControl(null),
      'type[account_type][]': new FormControl(null),
      'type[account_type][1]': new FormControl(null),
      'type[mobile_verification][]': new FormControl(null),
      'type[email_verification][]': new FormControl(null),

      traffic_source_type: new FormControl(''),
      traffic_source: new FormControl({ value: '', disabled: true }), // Initially disabled
      campaign_type: new FormControl(CampaignType.NORMAL),
      campaign_value: new FormControl(''),
      others: new FormControl(''),
      others_value: new FormControl(''),
    });

    const campaignTypeSub = this.form.get('campaign_type').valueChanges.subscribe(() => {
      this.form.get('campaign_value').setValue(''); // Reset campaign value when type changes
    });

    const trafficSourceSub = this.form.get('traffic_source_type').valueChanges.subscribe(value => {
      const trafficSourceControl = this.form.get('traffic_source');
      if (value == TrafficSource.ORGANIC) {
          trafficSourceControl.disable();
          trafficSourceControl.setValue('');
      } else {
          trafficSourceControl.enable();
      }
      this.trafficSourcePlaceholder = this.getTrafficSourcePlaceholder(value);
    });

    this.subscriptions.add(campaignTypeSub);
    this.subscriptions.add(trafficSourceSub);
  }

  private refreshEvery(seconds: number) {
    const refreshSource = interval(seconds * 1000);
    refreshSource.subscribe(() => {
      setTimeout(() => {
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
          this.reload();
        }
      });
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== false) {
        fields[key] = formData[key] === true ? 1 : formData[key];
      }
    });
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

}
