import { Component, Inject, OnInit, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { catchError, tap } from 'rxjs/operators';
import { MemberAccountLabelSettingHttpService } from '@core/services/member-account-label-setting-http.service';
import { MemberLabelSetting } from '@core/models/member-label-setting.model';

@Component({
  selector: 'kt-member-label-settings-edit',
  templateUrl: './member-label-settings-edit.component.html',
  styleUrls: ['./member-label-settings-edit.component.scss']
})
export class MemberLabelSettingsEditComponent implements OnInit, AfterViewInit  {

  form: FormGroup;
  buttonLoading = false;
  messages$ = this.memberAccountLabelSettingHttpService.messages$;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
  }

  displayColor = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, labelSetting: MemberLabelSetting},
    public dialogRef: MatDialogRef<MemberLabelSettingsEditComponent>,
    private memberAccountLabelSettingHttpService: MemberAccountLabelSettingHttpService,
    private dropdownHttpService: DropdownHttpService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.displayColor = this.data.mode == 'edit' ? this.data.labelSetting.display_color_code : '';
  }

  ngAfterViewInit() {
  }

  onCloseDialog(value = false) {
    this.dialogRef.close(value);
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const data = {
      ...this.form.value,
      code: this.form.get('code').value,
      display_color_code: this.displayColor,
      status: this.form.get('status').value
    };

    switch (this.data.mode) {
      case 'create':
        this.memberAccountLabelSettingHttpService.add(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
      case 'edit':
        this.memberAccountLabelSettingHttpService.update(this.data.labelSetting.id, data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
    }
  }

  private formInit() {
    this.form = new FormGroup({
      code: new FormControl({ value: this.data.mode === 'edit' ? this.data.labelSetting.code : null, disabled: this.data.mode === 'edit' }, [Validators.required]),
      name: new FormControl(this.data.mode === 'edit' ? this.data.labelSetting.name : null, [Validators.required]),
      description: new FormControl(this.data.mode === 'edit' ? this.data.labelSetting.description : null, [Validators.required]),
      display_color_code: new FormControl(this.data.mode === 'edit' ? this.data.labelSetting.display_color_code : null, [Validators.required, Validators.pattern(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)]),
      risk_score: new FormControl(this.data.mode === 'edit' ? this.data.labelSetting.risk_score : null, [Validators.required, Validators.min(1), Validators.max(99)]),
      status: new FormControl({ value: this.data.mode === 'edit' ? this.data.labelSetting.status : 1, disabled: this.data.mode === 'create' }, [Validators.required])
    });

    this.form.get('display_color_code')?.valueChanges.subscribe(value => {
      this.displayColor = value;
    });
  }

  onColorChange(color: string) {
    this.form.get('display_color_code')?.setValue(color);
  }

  onCodeInput(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.toUpperCase(); // Ensures immediate visual update
    this.form.get('code')?.setValue(input.value, { emitEvent: false }); // Updates FormControl value
  }
}
