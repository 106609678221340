import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { forkJoin, Subscription } from 'rxjs';
import * as moment from 'moment-timezone';
import { CommissionReleasesDataService } from '../../services/commission-releases-data.service';

@Component({
  selector: 'kt-generate-commission',
  templateUrl: './generate-commission.component.html',
  styleUrls: ['./generate-commission.component.scss']
})
export class GenerateCommissionComponent implements OnInit {
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
  };
  form: FormGroup;
  ranges = this.transactionHttpService.ranges;
  dateTimePickerLocale = {format: 'YYYY-MM-DD HH:mm:ss', firstDay: 1};
  private subscription = new Subscription();
  buttonLoading = false;
  messages$ = this.commissionReleasesDataService.messages$;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  dropdownSettings = {
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'id',
    labelKey: 'username',
    noDataLabel: '',
    showCheckbox: false,
    autoPosition: false,
    position: 'bottom',
  };

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    public dialogRef: MatDialogRef<GenerateCommissionComponent>,
    private commissionReleasesDataService: CommissionReleasesDataService,
  ) { }

  ngOnInit() {
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
      });
    }
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onGenerate() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.buttonLoading = true;
    // To set 'Save' button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });
    const data = {
      ...this.form.value,
      start_date_time: moment(this.form.value.start_date_time).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date_time: moment(this.form.value.end_date_time).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')
    };
    Object.keys(data).forEach(
      (key) =>
        (data[key] == null || data[key] === '' || key === 'defaultDate') &&
        delete data[key]
    );
    this.subscription = forkJoin([
      this.commissionReleasesDataService.generateCommission(data),
      this.commissionReleasesDataService.messages$,
    ]).subscribe(
      (res) => {
        this.buttonLoading = false;
        // To enable 'Save' button after get response
        this.form.setErrors(null);
      },
      (err) => {
        this.buttonLoading = false;
        // To enable 'Save' button after get response
        this.form.setErrors(null);
      },
      () => {}
    );
    this.messages$.subscribe(() => {
      this.form.patchValue({
        currency_id: 'all',
        start_date_time: null,
        end_date_time: null,
        defaultDate: null,
      });
    });
  }

  onDateRange(event: any, type: string) {
    if (event) {
      this.form.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClearDate(type: string) {
    if (this.form.value.start_date_time !== null) {
      this.form.patchValue({
        start_date_time: null,
        end_date_time: null,
        defaultDate: null,
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private formInit() {
    this.form = new FormGroup({
      currency_id: new FormControl('all', [Validators.required]),
      start_date_time: new FormControl(null, [Validators.required]),
      end_date_time: new FormControl(null, [Validators.required]),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      dummy: new FormControl(1),
    });
  }

  onChangeDummy($event) {
    let isChecked = $event.target.checked ? 1 : 0;
    this.form.patchValue({
      dummy: isChecked
    });
  }

}
