
<button (click)="dropClick()" [disabled]="!valueType" type="button" class="btn btn-warning mr-2"><i class='fas fa-sync'></i>{{ 'Sync' }}</button>
<div role="tablist" #syncdrop id="syncdrop" class='visibility sync-button'>
    
    <div class="kt-form">
        <h4 class="kt-font-bold text-dark mb-4">{{ 'Sync to' }}
            <i class="fa fa-exclamation-circle sync-tooltip text-secondary ml-2" placement="bottom"
            ngbTooltip="{{ 'The Sync button can synchronize content table values from the current locale to the selected locales.' }}">
            </i>
        </h4>

        <!-- Locale -->
        <div class="sync-list">
            <ng-container *ngIf="dropdown.locales.length > 0">
                <ng-container  *ngFor="let value of dropdown.locales" >
                    <ng-container *ngIf="settings_locale_id_from != value.id">
                        <div class="select-locale">
                            <input type="checkbox" [checked]="selectedLocales.includes(value.id)" (change)="onCheckboxChange($event, value.id)">
                            <p>{{ value.code }}</p>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>

        <!-- Sticky Footer -->
        <div class="sync-footer">
            <button type="button" class="btn btn-warning d-flex" (click)="syncClick()">
                <i class="fas fa-sync"></i> 
                {{ 'Sync' }}
            </button>
        </div>
    </div>
</div>