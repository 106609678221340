<div
  class="modal-dialog view-receipt-modal modal-dialog-scrollable m-0 scrollable-dialog"
>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">View Receipt ({{ data.bankTransaction.refer_id }})</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <ngb-carousel *ngIf="this.receipts.length > 0">
        <ng-template
          ngbSlide
          *ngFor="let receipt of this.receipts; let i = index">
          <ng-container *ngIf="receipt.isPdf; else img">
            <iframe [src]="receipt.receipt_image"></iframe>
          </ng-container>
          <ng-template #img>
            <div class="picsum-img-wrapper">
              <div class="img" style="min-height: inherit" (click)="openImage(receipt.receipt_image)">
                <object
                  [data]="receipt.receipt_image | safe : 'resourceUrl'"
                  [ngStyle]="{
                    height: 'auto',
                    'max-height': '95%',
                    'max-width': '100%'
                  }"
                ></object>
              </div>
            </div>
          </ng-template>
        </ng-template>
      </ngb-carousel>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="onCloseDialog($event)"
      >
        <i class="fas fa-ban"></i>Close
      </button>
    </div>
  </div>
</div>
