import { AuthHttpService } from '@core/services/auth-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { GameProviderReportDetailsComponent } from './dialogs/game-provider-report-details.component';
import { FormGroup, FormControl } from '@angular/forms';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap, map, exhaustMap, catchError } from 'rxjs/operators';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { GameProviderReportDataService } from './services/game-provider-report-data.service';
import { GameProviderReportEntityService } from './services/game-provider-report-entity.service';
import { Pagination } from '@core/models/pagination.model';
import { GameProviderReport } from '@core/models/game-provider-report.model';
import { Observable, BehaviorSubject, Subscription, of, throwError } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment-timezone';
import { GameProviderDailyReportEntityService } from './services/game-provider-daily-report-entity.service';
import { GameProviderDailyReportDataService } from './services/game-provider-daily-report-data.service';
import { GameProviderDailyReport } from '@core/models/game-provider-daily-report.model';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import Swal from 'sweetalert2';
import { DateTime } from 'luxon';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-game-provider-report',
  templateUrl: './game-provider-report.component.html',
  styleUrls: ['./game-provider-report.component.scss']
})
export class GameProviderReportComponent implements OnInit, OnDestroy {

  gameProviderReport$: Observable<GameProviderReport[]>;
  gameProviderDailyReport$: Observable<GameProviderDailyReport[]>;
  dataSub$ = new BehaviorSubject<any>(null);

  from = this.transactionHttpService.getYesterday().from;
  to = this.transactionHttpService.getToday().to;

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  timezoneSelectedItems: any;
  selectedTimezone: any;
  timezoneDropdownSettings = {
    singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown clear-all-disabled',
      primaryKey: 'offset',
      labelKey: 'offset',
      noDataLabel: '',
      showCheckbox: false
  };
  daily = false;
  form: FormGroup;
  dropdown = {
    timezones: JSON.parse(localStorage.getItem('timezones')),
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    gameProviders: this.dropdownHttpService.gameProviders,
    perPage: this.dropdownHttpService.perPage
  };
  gameProviderCode = 0; // Default

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;

  params = ``;
  ranges: any;

  accountTypeDropdownSettings = {};
  accountTypeDropdownList = [];
  accountTypeSelectedItems = [];
  
  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private datePickerSubscription = new Subscription();

  submitted = {
    currencyID: 0,
    startDateTime: null,
    endDateTime: null,
  };

  button_loading = false;
  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;
  access$ = this.authHttpService.getUserAccess(5, 'Game Provider Report');
  messages$ = this.gameProviderReportDataService.messages$;
  messages2$ = this.gameProviderDailyReportDataService.messages$;

  // permissions
  canExportGameProviderReport: boolean;
  canViewGameProviderBreakdown: boolean;

  lastBetLogSyncAt = null;

  constructor(
    private gameProviderReportEntityService: GameProviderReportEntityService,
    private gameProviderReportDataService: GameProviderReportDataService,
    private gameProviderDailyReportEntityService: GameProviderDailyReportEntityService,
    private gameProviderDailyReportDataService: GameProviderDailyReportDataService,
    private appPermissionService: AppPermissionService,

    private transactionHttpService: TransactionHttpService,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private dialog: MatDialog,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.pagination = this.gameProviderReportDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    this.timezoneSelectedItems = [this.dropdown.timezones.find(x => x.timezone == this.timezone)];
    this.selectedTimezone = this.timezone;

    this.accountTypeDropdownList = [
      { id: 0, name: 'Normal'},
      { id: 1, name: 'Dummy'}
    ];

    this.accountTypeSelectedItems = [
      { id: 0, name: 'Normal'},
      { id: 1, name: 'Dummy'}
    ];

    this.accountTypeDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canExportGameProviderReport = appPermissions.export_game_provider_report;
      this.canViewGameProviderBreakdown = appPermissions.view_game_provider_breakdown;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loading = true;
    if (this.form.get('daily').value) {
      return this.gameProviderDailyReport$ = this.gameProviderDailyReportEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
        tap(res => {
          this.loading = false;
          this.dataLength = res.length;
          this.pagination = this.gameProviderDailyReportDataService.pagination;
        })
      );
    } else {
      return this.gameProviderReport$ = this.gameProviderReportEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
        tap(res => {
          this.loading = false;
          this.dataLength = res.length;
          this.pagination = this.gameProviderReportDataService.pagination;
        })
      );
    }
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(data: any) {
    if (!this.canViewGameProviderBreakdown) {
      Swal.fire({
        icon: 'error',
        title: 'Permission Error',
        text: 'You do not have permission to view game provider breakdown'
      });
      return;
    }

    this.dropdown.gameProviders.subscribe((res) => {
      const game_provider = res.find(((x) => { return x.code === data.game_provider }));
      const param = {
        category_code: game_provider.categories[0].code,
        game_provider_id: game_provider.id,
        game_provider_code: game_provider.code,
        game_provider_name: game_provider.name,
        currency_id: this.submitted.currencyID,
        start_date_time: this.daily ? moment(data.summary_date + ' 00:00:00').toLocaleString() : this.submitted.startDateTime,
        end_date_time: this.daily ? moment(data.summary_date + ' 23:59:59').toLocaleString() : this.submitted.endDateTime,
        timezone: this.form.value.timezone,
        daily: this.daily,
        viewType: 'dialog',
        dummy: this.form.value.dummy
      }
      this.openDialogBy(GameProviderReportDetailsComponent, { form: this.form, param: param });
    });

  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    let default_currency_id: any = null;
    if (this.dropdown.currencies.length > 0) {
      default_currency_id = Object.values(this.dropdown.currencies)[0]['id'];
    }

    this.accountTypeSelectedItems = [
      { id: 0, name: 'Normal'},
      { id: 1, name: 'Dummy'}
    ];

    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.form.patchValue({
      game_provider_code: 0,
      currency_id: default_currency_id,
      start_date_time: this.from,
      end_date_time: this.to,
      daily: 0,
      timezone: this.timezone,
      defaultDate: {
        startDate: this.from,
        endDate: this.to
      },
      dummy: null
    });
    this.timezoneSelectedItems = [this.dropdown.timezones.find(x => x.timezone == this.timezone)];
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    // To use on openDialog
    this.submitted.currencyID = this.form.value.currency_id;
    this.submitted.startDateTime = moment(this.form.value.start_date_time).toLocaleString();
    this.submitted.endDateTime = moment(this.form.value.end_date_time).toLocaleString();
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['currency_id'] === undefined || data['currency_id'] === null || data['currency_id'] === 0) {
          if (this.dropdown.currencies.length === 0) {
            this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          }
          if (this.dropdown.currencies.length > 0) {
            data['currency_id'] = Object.values(this.dropdown.currencies)[0]['id'];
            this.form.patchValue({ currency_id: data['currency_id'] });
          }
        }
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        delete (data['timezone']);

        if (data['dummy']) {
          if (data['dummy'].length == 2) {
            delete (data['dummy']);
          }
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        if (this.form.get('daily').value) {
          return this.gameProviderDailyReport$ = this.gameProviderDailyReportEntityService.getWithQuery(`?${this.params}&perPage=${this.pageSize}`).pipe(
            tap(res => {
              this.dataLength = res.length;
              this.pagination = this.gameProviderDailyReportDataService.pagination;
              this.loadingBar.complete();
              this.onSearch = true;
              this.loading = false;
              this.clearBtnLoading = false;
              this.searchBtnLoading = false;
              this.getLastBetLogSyncAt();
            }),
            catchError((err) => {
              this.loading = false;
              this.clearBtnLoading = false;
              this.searchBtnLoading = false;
              this.loadingBar.complete();
              this.getLastBetLogSyncAt();
              return throwError(err);
            })
          );
        } else {
          return this.gameProviderReport$ = this.gameProviderReportEntityService.getWithQuery(`?${this.params}&perPage=${this.pageSize}`).pipe(
            tap(res => {
              this.pagination = this.gameProviderReportDataService.pagination;
              this.loadingBar.complete();
              this.onSearch = true;
              this.loading = false;
              this.clearBtnLoading = false;
              this.searchBtnLoading = false;
              this.dataLength = res.length;
              this.getLastBetLogSyncAt();
            }),
            catchError((err) => {
              this.loading = false;
              this.clearBtnLoading = false;
              this.searchBtnLoading = false;
              this.loadingBar.complete();
              this.getLastBetLogSyncAt();
              return throwError(err);
            })
          );
        }
      }),
    ).subscribe();
  }

  onExport() {
    this.button_loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['currency_id'] === undefined || data['currency_id'] === null || data['currency_id'] === 0) {
          if (this.dropdown.currencies.length > 0) {
            data['currency_id'] = Object.values(this.dropdown.currencies)[0]['id'];
            this.form.patchValue({ currency_id: data['currency_id'] });
          }
        }
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        delete (data['timezone']);

        if (data['dummy']) {
          if (data['dummy'].length == 2) {
            delete (data['dummy']);
          }
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start();
        var filename = (this.form.get('game_provider_code').value !== 0 ? this.form.get('game_provider_code').value + '_' : '') + (this.form.get('daily').value ? 'GameProviderDailyReport_' : 'GameProviderReport_') + moment(this.form.get('start_date_time').value).format('YYYYMMDD') + '-' + moment(this.form.get('end_date_time').value).format('YYYYMMDD') + '.xlsx';
        if (this.form.get('daily').value) {
          return this.gameProviderDailyReportDataService.exportReport(`?${parameters}`).pipe(
            tap(res => {
              this.loadingBar.complete();
              this.button_loading = false;
            }),
            catchError((err) => {
              this.button_loading = false;
              this.loadingBar.complete();
              return throwError(err);
            })
          );
        } else {
          return this.gameProviderReportDataService.exportReport(`?${parameters}`).pipe(
            tap(res => {
              this.loadingBar.complete();
              this.button_loading = false;
            }),
            catchError((err) => {
              this.button_loading = false;
              this.loadingBar.complete();
              return throwError(err);
            })
          );
        }
      }),
    ).subscribe();
  }

  checkDaily() {
    if (this.daily) {
      this.daily = false;
      this.form.patchValue({
        game_provider_code: 0
      });
      this.gameProviderReport$ = null;
    } else {
      this.daily = true;
      this.form.patchValue({
        game_provider_code: 'KISS'
      });
      this.gameProviderDailyReport$ = null;
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private openDialogBy(componentRef: any, data: { form: FormGroup, param: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '90vw',
      // height: '90vh',
      data: {
        form: data.form,
        param: data.param
      }
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      game_provider_code: new FormControl(this.gameProviderCode),
      currency_id: new FormControl(null),
      start_date_time: new FormControl(this.from),
      end_date_time: new FormControl(this.to),
      daily: new FormControl(0),
      timezone: new FormControl(this.timezone),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to
      }), // Do not remove: For Clearing The Range
      dummy: new FormControl(null)
    });
  }

  onChangeTimezone(item: any) {
    if (item.length) {
      this.selectedTimezone = item[0]['timezone'];
    }
    else {
      this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.selectedTimezone);
      this.form.patchValue({
        timezone: this.selectedTimezone
      });
    }
  }

  getLastBetLogSyncAt(){
    var userTimeZone = JSON.parse(localStorage.getItem('user_data')).timezone;
    var timeInterved = 5; // 5 minutes 
    const d = new Date();
    let minutes = d.getMinutes();
    const intMinutes =  minutes/timeInterved;
    const roundedMinute = (minutes/timeInterved).toFixed(0);
    var minus;
    if( Number(intMinutes) < Number(roundedMinute) || Number(intMinutes) == Number(roundedMinute)){
      if(Number(roundedMinute) > 0) minus = 1;
      else minus = -1;
    }
    else if( Number(intMinutes) > Number(roundedMinute)){
      minus = 0;
    }
    var  minutesConverted5min = ( Number(roundedMinute) - minus )  * timeInterved;
    var newMinutes;
    if(minutesConverted5min < 10){
      newMinutes = '0'+minutesConverted5min.toString();
    }else{
      newMinutes = minutesConverted5min.toString();
    }

    var timeNow =  DateTime.now().setZone(userTimeZone).toFormat('yyyy-MM-dd HH:'+newMinutes+':00 (ZZ)'); 
    this.lastBetLogSyncAt = timeNow.replace('(', '(GMT ');
  }
}
