<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <!-- Filter -->
                        <form class="row align-items-center" [formGroup]="form">
                            <!-- First Fow -->
                            <div class="col-12 row mb-2 pr-0">
                                <!-- Username -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                                </div>
                                <!-- Status -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="status" class="form-control">
                                        <option value="all"> All </option>
                                        <ng-container *ngFor="let item of status | keyvalue">
                                            <option [value]="item.key" *ngIf="+item.key >= 0">{{ item.value }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                                <!-- Date Time -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <select class="form-control" formControlName="date_type">
                                                <option value="claimed_at" selected>Claimed Date</option>
                                                <option value="expiry_at">Expiry Date</option>
                                                <option value="created_at">Created Date</option>
                                                <option value="updated_at">Updated Date</option>
                                            </select>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [opens]="'left'"  [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="{format: 'YYYY-MM-DD HH:mm:ss'}" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- Second Row -->
                            <div class="col-12 row mb-2 pr-0">
                                <!-- Promo Code -->
                                <div class="col-md-1 kt-form__label col-form-label no-break">
                                    <label class="mb-0">Promo Code:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="promo_code" placeholder="Search" class="form-control">
                                </div>
                                <!-- Currency -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="currency_id" class="form-control">
                                        <option value="all">All</option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <!-- Type -->
                                <div class="col-md-1 kt-form__label col-form-label" *ngIf="isDisplayTypeFilter()">
                                    <label>Type:</label>
                                </div>
                                <div class="col-md-2 kt-form__control" *ngIf="isDisplayTypeFilter()">
                                    <select formControlName="role" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="item.value" *ngFor="let item of dropdown.telemarketerRewardType">
                                            {{ item.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <!-- Third Row -->
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Account Type:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="account_type_condition" class="form-control" >
                                      <option value="And">AND Condition</option>
                                      <option value="Or">OR Condition</option>
                                    </select>
                                </div>
                                <div class="col-md-3 kt-form__control">
                                    <kt-dropdown-wo-lazyload class="dropdown-maxheight col-10" style="padding:0" [form]='form'
                                      [dropdownList]='accountTypeDropdownList' [dropdownSettings]='accountTypeDropdownSettings'
                                      [formName]="'account_type'" [selectionAttributes]="'id'"
                                      [selectedItems]='accountTypeSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Action Button -->
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <div class="dropdown dropdown-inline">
                    <button class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')" *ngIf="canAssignRewards"><i class="fas fa-plus"></i>Create</button>
                </div>
            </div>
            <!-- Content -->
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <!-- Table -->
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <!-- Header -->
                            <thead>
                                <tr>
                                    <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                                    <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('promo_code')" [ngClass]="sortingConfig.promo_code" [class.sort-selected]="sortingSelection.sort_by === 'promo_code'">Promo Code</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('currency_code')" [ngClass]="sortingConfig.currency_code" [class.sort-selected]="sortingSelection.sort_by === 'currency_code'">Currency</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('credit')" [ngClass]="sortingConfig.credit" [class.sort-selected]="sortingSelection.sort_by === 'credit'">Credit</th>
                                    <th class="text-center sort-enabled pr-4" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('claimed_at')" [ngClass]="sortingConfig.claimed_at" [class.sort-selected]="sortingSelection.sort_by === 'claimed_at'">Claimed At</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('expiry_date')" [ngClass]="sortingConfig.expiry_date" [class.sort-selected]="sortingSelection.sort_by === 'expiry_date'">Expiry Date</th>
                                    <th class="sort-enabled" (click)="onSortColumn('remarks')" [ngClass]="sortingConfig.remarks" [class.sort-selected]="sortingSelection.sort_by === 'remarks'">Remarks</th>
                                    <th class="sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                                    <th class="sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <!-- Body -->
                            <tbody *ngIf="(rewards$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.id }}</td>
                                    <td><a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog('member-information', row.member_id)">{{ row.username }}</a></td>
                                    <td class="text-center">{{ row.promo_code }}</td>
                                    <td class="text-center">{{ row.currency_code }}</td>
                                    <td class="text-right"> {{ row.credit }} </td>
                                    <td class="text-center">
                                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + statusColor[row.status]"> {{ row.reward_status_name }}</span>
                                    </td>
                                    <td>{{ isValidDate(row.claimed_at) ? (row.claimed_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</td>
                                    <td>{{ isValidDate(row.expiry_date) ? (row.expiry_date | timezoneDate: 'YYYY-MM-DD') : '-' }}</td>
                                    <td>{{ row.remarks }}</td>
                                    <td>
                                        {{ row.created_by ? row.created_by : 'System' }} <br>
                                        <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #createdDateTime>
                                            {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td>
                                        {{ row.updated_by ? row.updated_by : 'System' }} <br>
                                        <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #updatedDateTime>
                                            {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                        <button *ngIf="canEditRewards" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i  class="fas fa-edit"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <!-- Pagination -->
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
