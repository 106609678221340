import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RewardStatus } from '@core/enums/reward.enum';
import { Pagination } from '@core/models/pagination.model';
import { PlayerReward } from '@core/models/player-reward.model';
import { Rewards } from '@core/models/rewards.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { RewardsHttpService } from '@core/services/rewards-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MemberInformationDialogComponent } from '../members/dialogs/member-information/member-information.component';
import { MemberDataService } from '../members/services/member-data.service';
import { MemberRewardsDialogComponent } from '../members/dialogs/member-rewards/member-rewards.component';
import { TelemarketerRewardDetailsDialogComponent } from './dialogs/telemarketer-reward-details/telemarketer-reward-details.component';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { AppPermissionService } from '@core/services/app-permission.service';
import { specialPermissions } from '@core/store/auth/auth.selectors';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-telemarketer-rewards',
  templateUrl: './telemarketer-rewards.component.html',
  styleUrls: ['./telemarketer-rewards.component.scss']
})
export class TelemarketerRewardsComponent implements OnInit, OnDestroy {
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  form: FormGroup;
  dropdownSettings = {};
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage,
    telemarketerRewardType: this.dropdownHttpService.telemarketerRewardType
  };

  status = RewardStatus
  statusColor = {
    0: 12,
    1: 1,
    2: 6,
    3: 7,
    4: 8,
  };

  rewards$: Observable<PlayerReward[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  paramsWithDefaultValue = 'mode=telemarketer_reward';
  params = '';
  ranges: any;
  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '3.7';
  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'promo_code': 'desc',
    'currency_code': 'desc',
    'credit': 'desc',
    'status': 'desc',
    'claimed_at': 'desc',
    'expiry_date': 'desc',
    'remarks': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc'
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;

  accountTypeDropdownSettings = {};
  accountTypeDropdownList = [];
  accountTypeSelectedItems = [];

  isAdminUser = JSON.parse(localStorage.getItem('user_data')).is_admin;
  isTelemarketer = JSON.parse(localStorage.getItem('user_data')).application_role_name?.toLowerCase() == 'telemarketer' ? true : false;
  isAccountManager = JSON.parse(localStorage.getItem('user_data')).role_name.toLowerCase() == 'account manager' ? true : false;
  isVipAccountManager = JSON.parse(localStorage.getItem('user_data')).role_name.toLowerCase() == 'vip account manager' ? true : false;
  access$ = this.authHttpService.getUserAccess(13, 'Rewards');
  userPermissions$ = this.store.pipe(select(specialPermissions));
  
  canViewOwnTelemarketerReward: boolean;
  canViewAllTelemarketerReward: boolean;
  canViewOwnNormalAMReward: boolean;
  canViewAllNormalAMReward: boolean;
  canViewOwnVIPAMReward: boolean;
  canViewAllVIPAMReward: boolean;
  canEditReward: boolean;
  canAssignRewards: boolean;

  // permissions
  canViewMemberDialog: boolean;
  canEditRewards: boolean;

  
  constructor(
    public dialog: MatDialog,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private memberDataService: MemberDataService,
    private rewardsHttpService: RewardsHttpService,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
    private store: Store<AppState>,
    private translateService: TranslateService,
  ) { }

  private datePickerSubscription = new Subscription();
  private subscription = new Subscription();
  private subscriptions = new Subscription();

  ngOnInit() {
    this.setCurrencyDropdown();
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));

    this.accountTypeDropdownList = this.dropdownHttpService.accountTypesLists;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    
    this.accountTypeDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.formInit();
    this.pagination = this.rewardsHttpService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewOwnTelemarketerReward = appPermissions.view_own_telemarketer_rewards_list;
      this.canViewAllTelemarketerReward = appPermissions.view_all_telemarketer_rewards_list;
      this.canViewOwnNormalAMReward     = appPermissions.view_own_normal_account_manager_rewards_list;
      this.canViewAllNormalAMReward     = appPermissions.view_all_normal_account_manager_rewards_list;
      this.canViewOwnVIPAMReward        = appPermissions.view_own_vip_account_manager_rewards_list;
      this.canViewAllVIPAMReward        = appPermissions.view_all_vip_account_manager_rewards_list;
      this.canEditReward                = appPermissions.edit_telemarketer_reward;
      this.canAssignRewards             = appPermissions.telemarketer_assign_rewards;
      this.canViewMemberDialog          = appPermissions.telemarketing_rewards_view_member_dialog;
      this.canEditRewards               = appPermissions.telemarketing_rewards_edit_reward;

      this.filterDisplayOption();
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    // Indicate it's call from 13.7 Telemarketer Rewards
    params = params + `&${this.paramsWithDefaultValue}`;
    this.loadingBar.start();
    return this.rewards$ = this.rewardsHttpService.getPlayerRewards(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.dataLength = res.length;
        this.pagination = this.rewardsHttpService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, data?: number | Rewards) {
    switch (type) {
      case 'member-information':
        if (!this.canViewMemberDialog) {
          Swal.fire({
            title: 'Permission Error',
            text: 'You do not have access to view member dialog',
            icon: 'error'
          });
          return;
        }

        const member = this.memberDataService.getById(+data, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&additional_info=1`);
        this.subscription = member.pipe(
          tap((res) => {
            if (res) {
              this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' });
            }
          })
        )
          .subscribe();
        break;

      case 'edit':
        this.openDialogBy(TelemarketerRewardDetailsDialogComponent, {
          reward: (data as Rewards), mode: type
        });
        break;
      case 'create':
        this.openDialogBy(MemberRewardsDialogComponent, { member: null, mode: 'bulk_reward_by_telemarketer' });
        break;
    }

  }


  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        if(data['account_type']){
          data['account_type'] = data['account_type'].toString();
        }
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.page = 1;
        this.onViewPageBy();
      })
    ).subscribe();
  }

  onClearDate() {
    if (this.form.value.start_date_time !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    this.eventEmitterService.onClearMemberSearch();
    this.form.patchValue({
      username: null,
      status: 'all',
      start_date_time: null,
      end_date_time: null,
      date_type: 'claimed_at',
      promo_code: null,
      currency_id: 'all',
      defaultDate: null,
      role: 'all',
      account_type_condition: 'Or',
      account_type: [1, 2, 3],
    });
    this.filterDisplayOption();
    this.onSubmit(true);
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }
    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private openDialogBy(componentRef: any, data?: { member?: any, mode?: any, isAdmin?: boolean, reward?: Rewards }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'member-information' ? '1500px' : '800px',
      height: data.mode === 'member-information' ? '80vh' : 'auto',
      data,
      autoFocus: data.mode === 'member-information' ? false : true
    });
    if (data.mode !== 'member-information') {
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.onViewPageBy(this.page);
        }
      });
    }
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      status: new FormControl(window.history.state.status !== undefined ? window.history.state.status : 'all'),
      start_date_time: new FormControl(null),
      end_date_time: new FormControl(null),
      date_type: new FormControl('claimed_at'),
      promo_code: new FormControl(null),
      currency_id: new FormControl('all'),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      role: new FormControl('all'),
      account_type_condition: new FormControl('Or'),
      account_type: new FormControl([1,2,3]),
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

  filterDisplayOption() {
    if (!this.canViewOwnTelemarketerReward && !this.canViewAllTelemarketerReward) {
      this.dropdown.telemarketerRewardType = this.dropdown.telemarketerRewardType.filter(item => item.value !== 'telemarketer_reward');
    }

    if (!this.canViewOwnNormalAMReward && !this.canViewAllNormalAMReward) {
      this.dropdown.telemarketerRewardType = this.dropdown.telemarketerRewardType.filter(item => item.value !== 'account_manager_reward');
    }
    
    if (!this.canViewOwnVIPAMReward && !this.canViewAllVIPAMReward) {
      this.dropdown.telemarketerRewardType = this.dropdown.telemarketerRewardType.filter(item => item.value !== 'vip_account_manager_reward');
    }

    // Set default dropdown selection
    if (this.isTelemarketer) {
      const exists = this.dropdown.telemarketerRewardType.some(item => item.value === 'telemarketer_reward');
      if (exists) {
        this.form.patchValue({
          role: 'telemarketer_reward'
        });
      }
    } else if (this.isAccountManager) {
      const exists = this.dropdown.telemarketerRewardType.some(item => item.value === 'account_manager_reward');
      if (exists) {
        this.form.patchValue({
          role: 'account_manager_reward'
        });
      }
    } else if (this.isAccountManager) {
      const exists = this.dropdown.telemarketerRewardType.some(item => item.value === 'vip_account_manager_reward');
      if (exists) {
        this.form.patchValue({
          role: 'vip_account_manager_reward'
        });
      }
    }
  }

  isDisplayTypeFilter() {
    return (!this.isTelemarketer || !this.isAccountManager || !this.isVipAccountManager);
  }

}
