import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { loggedUser } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AppState } from '@store/reducers';
import { REFRESH_RATE_INTERVAL_IN_SECONDS } from 'app/app.module';
import * as moment from 'moment-timezone';
import { interval, of, Subscription } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AffiliateInformationComponent } from './../all-affiliates/dialogs/affiliate-information/affiliate-information.component';
import { AllAffiliatesDataService } from './../all-affiliates/services/all-affiliates-data.service';
import { AffiliateWithdrawEditDialogComponent } from './dialog/affiliate-withdraw-edit.component';
import { AffiliateWithdrawalDataService } from './services/affiliate-withdrawal-data.service';
import Echo from 'laravel-echo';
import { environment } from '@env/environment';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-affiliate-withdrawals',
  templateUrl: './affiliate-withdrawals.component.html',
  styleUrls: ['./affiliate-withdrawals.component.scss']
})
export class AffiliateWithdrawalsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    statuses: [
      {
        name: 'Pending', value: 0, checked: true
      },
      {
        name: 'In Progress', value: 3, checked: true
      },
      {
        name: 'Approved', value: 1, checked: false
      },
      {
        name: 'Rejected', value: 2, checked: false
      }
    ],
    perPage: this.dropdownHttpService.perPage
  };
  searchStatus = [0, 3];
  withdrawals$ = [];
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  autoRefresh: boolean = false;
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'status': 'desc',
    'aff_group': 'desc',
    'currency': 'desc',
    'amount': 'desc',
    'processing_fee': 'desc',
    'confirmed_amount': 'desc',
    'bank_account': 'desc',
    'created_at': 'desc',
    'processing_time': 'desc'
  };
  ranges = this.transactionHttpService.ranges;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = 'status[0]=0&status[1]=3';
  originalParams = '';
  disabledEditButton = false;
  currentUserId: number;
  currentUsername: string;
  echo: Echo;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  private datePickerSubscription = new Subscription();

  dateTimeFilterType = [
    {
      key: 'created_at',
      label: 'Created Date'
    },
    {
      key: 'updated_at',
      label: 'Updated Date'
    }
  ];
  
  // permissions
  canViewAffiliateDialog: boolean;
  canViewAffiliateWithdrawalDetails: boolean;
  canEditAffiliateWithdrawal: boolean;

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private affiliateWithdrawalDataService: AffiliateWithdrawalDataService,
    private loadingBar: LoadingBarService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private store: Store<AppState>,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) {
    const SocketIoClient = require('socket.io-client');
    const socketHost: string = environment.socketHost;
    try {
      this.echo = new Echo({
        broadcaster: 'socket.io',
        client: SocketIoClient,
        host: socketHost
      });
    } catch (e) {
      console.log(e);
    }
   }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();
    this.onSubmit(true);
    this.refreshEvery(REFRESH_RATE_INTERVAL_IN_SECONDS);
    this.store.pipe(select(loggedUser)).subscribe((res) => { this.currentUserId = res.id; this.currentUsername = res.username; });
    this.updateWithdrawalList();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewAffiliateDialog = appPermissions.affiliate_withdrawals_view_affiliate_dialog;
      this.canViewAffiliateWithdrawalDetails = appPermissions.view_affiliate_withdrawal_details;
      this.canEditAffiliateWithdrawal = appPermissions.edit_affiliate_withdrawal;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
    this.echo.disconnect();
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private updateWithdrawalList() {
    const listener = (newWithdrawals) => {
      var indexOfUpdateItem = this.withdrawals$.findIndex(item => item.id === newWithdrawals.id);
      //update approval list only for new incoming approval request
      if (this.searchStatus.includes(newWithdrawals.status)) {
        if (indexOfUpdateItem === -1) {
          this.pagination.total = this.pagination.total + 1;
          //push new approval request when user on first page
          if (this.pagination.current_page === 1) {
            this.withdrawals$.unshift(newWithdrawals)

            //if this page can show more request
            if (this.pagination.to < this.pagination.per_page) {
              this.pagination = {
                ...this.pagination,
                from: 1,
                to: this.pagination.to + 1,
              };
            }
            else {
              //remove last request when this page cannot show more request.
              this.withdrawals$.splice(this.pagination.per_page, 1)
            }
          }
        }
        else {
          //update relevant withdraw rows
          this.withdrawals$[indexOfUpdateItem] = newWithdrawals;
        }
      }
      else {
        if (indexOfUpdateItem !== -1) {
          this.withdrawals$.splice(indexOfUpdateItem, 1);
          this.pagination.total = this.pagination.total - 1;
          if (this.pagination.to <= this.pagination.per_page) {
            this.pagination = {
              ...this.pagination,
              from: 1,
              to: this.pagination.to - 1,
            };
          }
        }
      }

      this.cdr.detectChanges();
    }

    const affiliateWithdrawChannel = this.echo.channel('affiliate-withdraw-channel');
    const currencyIds = JSON.parse(localStorage.getItem('user_data'))['bo_currency_ids'];

    currencyIds.forEach(currencyId => {
      affiliateWithdrawChannel.listen(`.AffiliateWithdrawEvent-${currencyId}`, listener);
    });
  }

  onViewInfo(mode: string, row?: any) {
    const affiliate = this.allAffiliatesDataService.getById(row.affiliate_id);
    this.subscription = affiliate.pipe(
      tap((res: any) => {
        this.dialog.open(AffiliateInformationComponent, {
          width: '1500px',
          height: '80vh',
          data: {
            affiliate: res,
            mode: mode
          }
        });
      })
    ).subscribe();
}

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if(key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if(this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.pageSize = 30;
    this.searchStatus = [0, 3];
    this.form.patchValue({
        id: null,
        username: null,
        name: null,
        currency_id: 'all',
        status: this.searchStatus,
        start_datetime: null,
        end_datetime: null,
        defaultDate: null,
        date_type: this.dateTimeFilterType[0].key
    });
    this.dropdown.statuses.forEach(child => {
      if (child.name !== 'In Progress' && child.name !== 'Pending') {
        child.checked = false;
      } else {
        child.checked = true;
      }
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.withdrawals$ = [];
        const statusParams = this.generateStatusParams();
        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = paramsRef + '' + statusParams.newStatusParams;
        this.loadingBar.start();
        return this.affiliateWithdrawalDataService.getWithQuery(`?${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.withdrawals$ = res;
            this.loading = false;
            this.searchBtnLoading = false;
            this.clearBtnLoading = false;
            this.page = 1;
            this.pagination = this.affiliateWithdrawalDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.withdrawals$ = [];
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.affiliateWithdrawalDataService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.withdrawals$ = res;
        this.loading = false;
        this.pagination = this.affiliateWithdrawalDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params).subscribe();
  }

  onSearchStatus(event: any, id: number, ind: number) {
    const position = this.searchStatus.indexOf(id);
    if (event.target.checked) {
      this.searchStatus.push(id);
      this.dropdown.statuses[ind].checked = true;
    } else {
      this.searchStatus.splice(position, 1);
      this.dropdown.statuses[ind].checked = false;
    }
    this.form.patchValue({ status: this.searchStatus });
  }

  onClearDate() {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  appendNew(page = 1, pageSize?: number, params?: string) {
    this.withdrawals$ = [];
    this.loadingBar.start();
    this.autoRefresh = true;
    let newWithdrawals$ = null;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    newWithdrawals$ = this.affiliateWithdrawalDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.withdrawals$ = res;
        this.pagination = this.affiliateWithdrawalDataService.pagination;
      })
    );

    newWithdrawals$.subscribe(newWithdrawals => {
      if (this.withdrawals$.length > 0){
        this.withdrawals$.map(res => {
          if (res.length < newWithdrawals.length) {
            for (let i = 0; i < (newWithdrawals.length - res.length); i++) {
              res.unshift(newWithdrawals[newWithdrawals.length - 1 - i]);
            }
          } else if (res.length > newWithdrawals.length) {
            this.onViewPageBy(this.page).subscribe();
          }
        });
        this.loadingBar.complete();
        this.autoRefresh = false;
      }
    });

  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  onOpenDialog(type: string, id?: number) {
    this.disabledEditButton = true;
    if (id) {
      const withdraw = this.affiliateWithdrawalDataService.getById(id);
      this.subscription = withdraw.pipe(
        tap((res: any) => {
          if (res.bo_user_id > 0 && res.bo_user_id !== this.currentUserId && (res.status === 0 || res.status === 3 || res.status === 4)) {
            Swal.fire({
              title: 'Someone is on the same withdrawal!',
              text: 'Do you want to take over?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes',
              reverseButtons: true,
            }).then((result) => {
              if (result.value) {
                this.openDialogBy(AffiliateWithdrawEditDialogComponent, { affiliateWithdraw: res, mode: 'edit', newHandler: this.currentUsername });
              }
              this.disabledEditButton = false;
            });
          }
          else {
            this.openDialogBy(AffiliateWithdrawEditDialogComponent, { affiliateWithdraw: res, mode: 'edit' });
            this.disabledEditButton = false;
          }
        })).subscribe();
    }
  }

  private openDialogBy(componentRef: any, data?: { affiliateWithdraw?: any, mode?: any, newHandler?: string }) {
    const dialogWidth = '1200px';
    const dialogRef = this.dialog.open(componentRef, {
      width: dialogWidth,
      height: 'auto',
      data: {
        affiliateWithdraw: data.affiliateWithdraw,
        mode: data.mode,
        newHandler: data.newHandler
      },
      autoFocus: true
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      username: new FormControl(null),
      name: new FormControl(null),
      currency_id: new FormControl('all'),
      status: new FormControl(this.searchStatus),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      date_type: new FormControl(this.dateTimeFilterType[0].key)
    });
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private reload(refresh: boolean) {
    if (!refresh) {
      this.onViewPageBy(this.page).subscribe();
    } else {
      this.appendNew(this.page);
    }
  }

  private refreshEvery(seconds: number) {
    const refreshSource = interval(seconds * 1000);
    refreshSource.subscribe(() => {
      setTimeout(() => {
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
          if(this.originalParams == this.params && this.autoRefresh === false){
            this.reload(true);
          }
        }
      });
    });
  }

  private generateStatusParams() {
    return {
      newStatusParams: this.searchStatus.length > 0 ? '&' + Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&') : ''
    };
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate' && key !== 'status') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

}
